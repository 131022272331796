import React from 'react'
import { withLoader } from '../../utils/hoc/withLoader'
import {useDispatch} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import { googleLoginUser } from '../../data/reducers/authentication/auth.reducer'

const CallbackUI = ({setBusy}) => {

    const location = useLocation();
    const dispatch = useDispatch()
    const history = useHistory()

    React.useEffect(() => {
		const code = location.hash.split("#code=")[1].split("&state")[0];
		if (code) {
			checkCode(code);
		}
	}, [location]);

    const checkCode = async (code) => {
		try {
			setBusy(true);
			if (code) {
				const { payload } = await dispatch(
					googleLoginUser({ authCode: code, redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI })
				);
				if (payload) {
					localStorage.setItem("isLoggedIn", true);
					if(payload?.role === "TicketManager"){
						history.push("/inventory");
					} else {
						history.push("/");
					}
					// window.location.reload();
				} else {
					history.push("/");
				}
			}
		} catch (error) {
			console.error(error);
		} finally {
			setBusy(false);
		}
	};

  return (
    <div>Loading</div>
  )
}

export default withLoader(CallbackUI)