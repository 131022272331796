import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { vendorServices } from "../../services/vendors/vendors.services";
import fileDownload from "js-file-download";
import { fetchVendorsListForDropdown } from "./cached-vendors.reducer";
import thunk from "redux-thunk";

export const getVendorsCategory = createAsyncThunk(
  "CardInfo/getVendorsCategory",
  async (thunkAPI) => {
    const response = await vendorServices.getVendorsCategory();
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const updateVendors = createAsyncThunk(
  "CardInfo/updateVendors",
  async (payload, thunkAPI) => {
    const response = await vendorServices.updateVendors(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(fetchVendorsListForDropdown());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getCardsByVendorId = createAsyncThunk(
  "CardInfo/getVendorCardListById",
  async (payload, thunkAPI) => {
    const response = await vendorServices.getCardsByVendorId(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportCSVVendors = createAsyncThunk(
  "vendors/exportCSVVendors",
  async (thunkAPI) => {
    const response = await vendorServices.exportCSVVendors();
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "Vendors.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportSelectedAsCSVVendor = createAsyncThunk(
  "vendors/exportCSVVendor",
  async (payload, thunkAPI) => {
    const response = await vendorServices.exportSelectedAsCSVVendors(payload);
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "VendorsFilter.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportPdfVendors = createAsyncThunk(
  "vendors/exportPdfVendors",
  async (thunkAPI) => {
    const response = await vendorServices.exportPdfVendors();
    // if (response.isSuccessful === true) {
    //   fileDownload(response.data, "Vendors.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

export const exportSelectedAsPdfVendor = createAsyncThunk(
  "vendors/exportPdfVendor",
  async (payload, thunkAPI) => {
    const response = await vendorServices.exportSelectedAsPdfVendors(payload);
    // if (response.isSuccessful === true) {
    //   fileDownload(response.data, "VendorsFilter.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

export const syncSkyboxVendors = createAsyncThunk(
  "sync/Skybox",
  async (payload, thunkAPI) => {
    const response = await vendorServices.getSyncSkybox(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const searchVendors = createAsyncThunk(
  "vendors/searchVendors",
  async (payload, thunkAPI) => {
    const response = await vendorServices.searchVendor(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);
export const getAllVendors = createAsyncThunk(
  "vendors/getAllVendors",
  async (payload, thunkAPI) => {
    const response = await vendorServices.getAllVendors(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);
export const getVendorById = createAsyncThunk(
  "vendors/getVendorById",
  async (payload, thunkAPI) => {
    const response = await vendorServices.getVendorById(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getVendorCategorization = createAsyncThunk(
  "CardInfo/getVendorCategorizationOPtions",
  async (thunkAPI) => {
    const response = await vendorServices.getVendorCategorization();
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getCards = createAsyncThunk(
  "CardInfo/getCards",
  async (payload, thunkAPI) => {
    const response = await vendorServices.getCards(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

// upload vendor data csv file
export const uploadCsvFiles = createAsyncThunk(
  "vendors/uploadCsvFiles",
  async (paylod, thunkAPI) => {
    const response = await vendorServices.uploadCsvFiles(paylod);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

// download vendor data
export const downloadTemplateVendor = createAsyncThunk(
  "vendors/downloadTemplateVendor",
  async (thunkAPI) => {
    const response = await vendorServices.downloadTemplateVendor();
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "VendorsTemplate.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

// add custom vendor
export const addVendor = createAsyncThunk(
  "vendors/addVendor",
  async (payload, thunkAPI) => {
    const response = await vendorServices.addVendor(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(fetchVendorsListForDropdown());
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

//delete Vendor
export const deleteVendor = createAsyncThunk(
  "vendors/deleteVendor",
  async (payload, thunkAPI) => {
    const response = await vendorServices.deleteVendor(payload);
    if (response.isSuccessful) {
      thunkAPI.dispatch(fetchVendorsListForDropdown());
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

const vendorSlice = createSlice({
  name: "vendors",
  initialState: {
    vendors: [],
    uploadCsv: [],
    paging: {},
    selectedVendorDetails: {},
    syncDate: "",
    cardsById: {},
    category: [],
    categerizationOptions: [],
    cards: {},
  },
  extraReducers: {
    [getCards.fulfilled]: (state, action) => {
      state.cards = action.payload;
    },

    [searchVendors.fulfilled]: (state, action) => {
      state.vendors = action.payload.data;
      state.paging = action.payload.paging;
    },
    [getAllVendors.fulfilled]: (state, action) => {
      state.vendors = action.payload.data;
      state.paging = action.payload.paging;
    },
    [getVendorById.fulfilled]: (state, action) => {
      state.selectedVendorDetails = action.payload;
    },
    [syncSkyboxVendors.fulfilled]: (state, action) => {
      state.syncDate = action.payload.data;
    },
    [getCardsByVendorId.fulfilled]: (state, action) => {
      state.cardsById = action.payload;
    },
    [getVendorsCategory.fulfilled]: (state, action) => {
      state.category = action.payload;
    },
    [getVendorCategorization.fulfilled]: (state, action) => {
      state.categerizationOptions = action.payload;
    },
    [uploadCsvFiles.fulfilled]: (state, action) => {
      state.uploadCsv = action.payload;
    },
  },
});

export default vendorSlice.reducer;
