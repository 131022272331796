import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { inventoryServices } from "../../services/inventory/inventory.service";

export const getAllInventory = createAsyncThunk(
	"inventory/getAllInventory",
	async (payload, thunkAPI) => {
		const response = await inventoryServices.getAllInventory(payload);

		if (response.isSuccessful === true) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const revokeTicket = createAsyncThunk(
	"inventory/revokeTicket",
	async (payload, thunkAPI) => {
		const response = await inventoryServices.revokeTicket(payload);

		if (response.isSuccessful === true) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const getCustomerURL = createAsyncThunk(
	"inventory/getCustomerURL",
	async (payload, thunkAPI) => {
		const response = await inventoryServices.getCustomerURL(payload);
		if (response.isSuccessful === true) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const getMatchedTickets = createAsyncThunk(
	"inventory/getMatchedTickets",
	async (payload, thunkAPI) => {
		const response = await inventoryServices.getMatchedTickets(payload);
		if (response.isSuccessful === true) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const filterInventory = createAsyncThunk(
	"inventory/filterInventory",
	async (payload, thunkAPI) => {
		const response = await inventoryServices.filterInventory(payload);
		if (response.isSuccessful === true) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const getDeliveryMessagesValues = createAsyncThunk(
	"inventory/getDeliveryMessagesValues",
	async (payload, thunkAPI) => {
		const response = await inventoryServices.getDeliveryMessagesValues(payload);
		if (response.isSuccessful === true) {
			return response.data;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

const eventSlice = createSlice({
	name: "inventory",
	initialState: {
		inventory: [],
		matchedTickets: [],
		paging: {},
		deliveryMessages: [],
	},
	extraReducers: {
		[getAllInventory.fulfilled]: (state, action) => {
			state.inventory = action.payload.data;
			state.paging = action.payload.paging;
		},
		[getMatchedTickets.fulfilled]: (state, action) => {
			state.matchedTickets = action.payload.data;
		},
		[filterInventory.fulfilled]: (state, action) => {
			state.inventory = action.payload.data;
			state.paging = action.payload.paging;
		},
		[getDeliveryMessagesValues.fulfilled]: (state, action) => {
			state.deliveryMessages = action.payload;
		},
	},
});

export default eventSlice.reducer;
