import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import QuickTixTable from "../table/QuickTixTable";
import TableRowCard from "../table/TableRowCard";
import { columnError } from "../table/data/error";

const ShowInfoModal = ({ ModalName, columns, isVisible, handleThisClose }) => {
  const revokeCol = [];
  const eventTickets = [];
  return (
    <Modal dialogClassName="modal-lg" show={isVisible} onHide={handleThisClose}>
      <Modal.Header closeButton>
        <Modal.Title>{ModalName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {columns?.map((section) => {
          return (
            <Row key={section?.id}>
              {section?.section && (
                <>
                  <hr />
                  <p className="title-accounts">{section.section}</p>
                </>
              )}
              {section?.dataColumns?.map((dtColumn) => {
                return (
                  <Col
                    lg={dtColumn?.takeFullRow ? 12 : 6}
                    sm={12}
                    key={dtColumn?.id}>
                    <Row>
                      <Col md={dtColumn?.takeFullRow ? 3 : 6}>
                        <p style={{ color: "rgba(0, 0, 0, 0.75)" }}>
                          {dtColumn?.name}
                        </p>
                      </Col>
                      <Col md={dtColumn?.takeFullRow ? 9 : 6}>
                        <p
                          style={{
                            fontWeight: "500",
                            overflowWrap: "break-word",
                          }}>
                          {dtColumn?.value}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Row>
          );
        })}
        <QuickTixTable
          noHeader={true}
          columns={revokeCol}
          data={eventTickets || []}
          renderRow={(rowData, index) => (
            <TableRowCard
              key={rowData?.id}
              columns={rowData?.nodata ? columnError : revokeCol}
              rowData={rowData}
            />
          )}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ShowInfoModal;
