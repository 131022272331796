import { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select/creatable";
import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import { inputDate } from "../../../utils/formatter/dateFormatter";

const AddEditAccountRulesModal = ({
    isVisible,
    handleClose,
    onSubmit,
    selectedRecord,
}) => {

    const dispatch = useDispatch();
    const accountDetails = useSelector((state) => state.accounts).accountsList;
    const [isEdit, setEdit] = useState(false);

    // setup react hook form
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        control
    } = useForm();
    const [dropVal, setdropVal] = useState({
        name: ""
    });

    // reset form on visibility toggle
    useEffect(() => {
        reset();
    }, [isVisible]);

    const customStyles = {
        option: (provided, { isFocused, isSelected }) => ({
            ...provided,
            backgroundColor: (isFocused) ? "#329BEF" : "white",
            color: isFocused ? "white" : "black"
        }),
    }

    useEffect(() => {
        if (selectedRecord) {
            setEdit(true)
            setValue("card", selectedRecord.sourceId)
            for (const [key, value] of Object.entries(selectedRecord)) {
                setValue(key, value, {
                    shouldValidate: true,
                    shouldDirty: true,
                });
            }
            setdropVal({
                name: selectedRecord?.accountId ? accountDetails.filter(account => account.accountId == selectedRecord.accountId)[0] : "",
            });
            setValue('accountId', accountDetails.filter(account => account.accountId == selectedRecord.accountId)[0] || "")
        }
        else {       // if selected record is undefined, reset
            setEdit(false);
            reset();
            setdropVal({
                name: ""
            });
        }

    }, [selectedRecord, isVisible]);
    const handleReset = () => {
        for (const [key, value] of Object.entries(selectedRecord)) {
            setValue(key, value, {
                shouldValidate: true,
                shouldDirty: true,
            });
        }
        setdropVal({
            name: selectedRecord?.accountId ? accountDetails.filter(account => account.accountId == selectedRecord.accountId)[0] : "",
        });
    };

    const handleClear = () => {
        reset();
        setdropVal({
            name: ""
        });
    };

    return (
        <>
            <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>RULES</Modal.Title>
                    </Modal.Header>
                    <ModalBreadcrumb
                        breadcrumbLinks={[
                            {
                                to: "/accounts",
                                name: "Accounts",
                                active: false,
                            },
                            {
                                name: "New Rule",
                                active: true,
                            },
                        ]}
                    />
                    <Modal.Body>
                        <div className="container-fluid p-2 w-100 h-100">
                            <Row>
                                <p className="title-accounts">Basic Information</p>

                                <Col sm={6}><Form.Group as={Row}>
                                    <Form.Label column sm="4">
                                        Name on Account
                                    </Form.Label>
                                    <Col sm="7">
                                        <Controller
                                            name="accountId"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field, fieldState: { invalid, error } }) => {
                                                return (
                                                    <div>
                                                        <Select
                                                            required
                                                            value={
                                                                dropVal?.name
                                                            }
                                                            isInvalid={!!errors.accountId}
                                                            onChange={(e) => {
                                                                field.onChange(e?.accountId);
                                                                setdropVal({ ...dropVal, name: e });
                                                            }
                                                            }
                                                            styles={customStyles}
                                                            isClearable
                                                            options={accountDetails}
                                                            getOptionLabel={option => `${option.accountId}  ${option.name}`}
                                                        />
                                                        {invalid && error ? <div className="custom-invalid-feedback">Account Name is required.</div> : ""}
                                                    </div>
                                                );
                                            }}
                                        />

                                    </Col>
                                    <Form.Control.Feedback type="invalid">Name on Account is required.</Form.Control.Feedback>
                                </Form.Group>

                                </Col>

                                <Col sm={6}>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="4">
                                            Card
                                        </Form.Label>
                                        <Col sm="7">
                                            <Form.Control
                                                isInvalid={!!errors.card}
                                                {...register("card", { required: true })}
                                                placeholder="Card"
                                            />
                                            <Form.Control.Feedback type="invalid">Card is required.</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}><Form.Group as={Row} className="pt-3">
                                    <Form.Label column sm="4">
                                        Card Name
                                    </Form.Label>
                                    <Col sm="7">
                                        <Form.Control
                                            isInvalid={!!errors.name}
                                            {...register("name", { required: true })}
                                            placeholder="Card Name"
                                        />
                                        <Form.Control.Feedback type="invalid">Card Name is required.</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                </Col>
                            </Row>
                            {/* <Row className="mt-3">
                                <Col>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="4">
                                            Min Amount
                                        </Form.Label>
                                        <Col sm="7">
                                            <Form.Control
                                                type="float"
                                                isInvalid={!!errors.minAmount}
                                                {...register("minAmount", isEdit ? { required: false } : { required: true })}
                                                placeholder="$0.00"
                                            />
                                            <Form.Control.Feedback type="invalid">Min Amount is required.</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                </Col>

                                <Col>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="4">
                                            Start Date
                                        </Form.Label>
                                        <Col sm="7">
                                            <Form.Control
                                                type="date"
                                                isInvalid={!!errors.startDate}
                                                {...register("startDate", isEdit ? { required: false } : { required: true })}
                                                value={sDate}
                                                onChange={(e) => setsDate(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">Start Date is required.</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col sm={6}>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="4">
                                            Max Amount
                                        </Form.Label>
                                        <Col sm="7">
                                            <Form.Control
                                                type="float"
                                                presicion={2}
                                                isInvalid={!!errors.maxAmount}
                                                {...register("maxAmount", isEdit ? { required: false } : { required: true })}
                                                placeholder="$0.00"
                                            />
                                            <Form.Control.Feedback type="invalid">Max Amount is required.</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="4">
                                            End Date
                                        </Form.Label>
                                        <Col sm="7">
                                            <Form.Control
                                                type="date"
                                                isInvalid={!!errors.endDate}
                                                {...register("endDate", isEdit ? { required: false } : { required: true })}
                                                value={eDate}
                                                onChange={(e) => seteDate(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">End Date is required.</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row> */}
                        </div>
                    </Modal.Body>
                    <div className="d-flex justify-content-center my-4">
                        <Button
                            type="submit"
                            variant="primary"
                            className="ss-modal-primary-btn mx-2"
                        >
                            {isEdit ? "Save" : "Add"}
                        </Button>
                        <Button
                            variant="light"
                            onClick={() => isEdit ? handleReset() : handleClear()}
                            className="ss-modal-secondary-btn mx-2"
                        >
                            {isEdit ? "Reset" : "Clear"}
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default AddEditAccountRulesModal;
