import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import QuickTixTable from "../../../shared/components/table/QuickTixTable";
import TableRowCard from "../../../shared/components/table/TableRowCard";
import { columnError } from "../../../shared/components/table/data/error";

const ShowEmailLogs = ({
  workflowname,
  ModalName,
  data,
  isVisible,
  handleThisClose,
}) => {
  //   const revokeCol = [];
  const revokeCol = [
    {
      columnName: "Workflow Executed",
      render: (data) => {
        return <p>{workflowname}</p>;
      },
    },
    {
      columnName: "Last Activity time",
      render: (data) => <p>{new Date(data.createdAt).toLocaleString()}</p>,
    },
    {
      columnName: "Last Executed Task",
      render: (data) => <p>{data.lastExecutedTask}</p>,
    },
    {
      columnName: "Status",
      render: (data) => {
        let color = "black"; // Default color

        const status = data.workFlowStatus.toLowerCase(); // Convert to lowercase for case-insensitive comparison

        if (status === "in process") {
          color = "orange";
        } else if (status === "error") {
          color = "red";
        } else if (status === "completed") {
          color = "green";
        }

        return <p style={{ color }}>{data.workFlowStatus}</p>;
      },
    },
  ];
  return (
    <Modal dialogClassName="modal-lg" show={isVisible} onHide={handleThisClose}>
      <Modal.Header closeButton>
        <Modal.Title>{ModalName}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowY: "scroll", height: "70vh" }}>
        <QuickTixTable
          noHeader={true}
          columns={revokeCol}
          data={data || []}
          renderRow={(rowData, index) => (
            <TableRowCard
              key={rowData?.id}
              columns={rowData?.nodata ? columnError : revokeCol}
              rowData={rowData}
            />
          )}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ShowEmailLogs;
