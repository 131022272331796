import {
    api,
    getAuthHeaders,
    post,
    update,
    del,
    get,
} from "../services.common";

const getPurchaseOrderHistory = async (id) => {
        const url = `${api}PurchaseOrderHistory/get-po-history/${id}`;
        const authHeader = getAuthHeaders();
        return await get(url, { ...authHeader });
};

export const poHistoryServices = {
    getPurchaseOrderHistory
}