import {
  api,
  getAuthHeaders,
  get,
  post,
  update,
  del,
  getCSV,
  getPDF,
  downloadExcel,
} from "../services.common";

const getAllSeason = async (data) => {
  const sortDir = data.sortDir == false ? "Descending" : "Ascending";
  const url = `${api}Seasons/getall?PageNumber=${data.pageNumber}&PageSize=${
    data.pageSize
  }&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getSeasonList = async (data) => {
  const url = `${api}Season/getlist`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getSeasonById = async (id) => {
  const url = `${api}Seasons/get/${id}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const createSeason = async (data) => {
  const url = `${api}Seasons/create`;
  const authHeader = getAuthHeaders();
  return await post(url, data, { ...authHeader });
};

const updateSeason = async (data, removeLocations) => {
  let url = `${api}Seasons/update/${data.id}`;
  if (removeLocations) {
    url = `${api}Seasons/update/${data.id}?removeCopyLocations=true`;
  }
  const authHeader = getAuthHeaders();
  return await update(url, data, { ...authHeader });
};

const deleteSeason = async (id) => {
  const url = `${api}Seasons/delete/${id}`;
  const authHeader = getAuthHeaders();
  return await del(url, { ...authHeader });
};

const filterSeason = async (data) => {
  const sortDir = data.sortDir == false ? "Descending" : "Ascending";
  const url = `${api}Seasons/search?searchString=${
    data.searchText || ""
  }&PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SeasonName=${
    data.seasonName || ""
  }&SeasonCode=${data.seasonCode || ""}&Id=${data.id || 0}&SportName=${
    data.sportName || ""
  }&StartDate=${data.startDate || ""}&EndDate=${
    data.endDate || ""
  }&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const exportCSVSeason = async () => {
  const url = `${api}Seasons/exportcsv`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const exportPdfSeason = async () => {
  const url = `${api}Seasons/exportpdf`;
  const authHeader = getAuthHeaders();
  return await getPDF(url, { ...authHeader }, "Seasons.pdf");
};

const getSeasonsList = async () => {
  const url = `${api}Seasons/getalist`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const exportSelectedAsCSVSeason = async (data) => {
  const url = `${api}Seasons/searchexportcsv?searchString=${
    data.searchText
  }&SeasonName=${data.seasonName || ""}&SeasonCode=${
    data.seasonCode || ""
  }&Id=${data.id || 0}&SportName=${data.sportName || ""}&StartDate=${
    data.startDate || ""
  }&EndDate=${data.endDate || ""}`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const exportSelectedAsPdfSeason = async (data) => {
  const url = `${api}Seasons/searchexportpdf?searchString=${
    data.searchText
  }&SeasonName=${data.seasonName || ""}&SeasonCode=${
    data.seasonCode || ""
  }&Id=${data.id || 0}&SportName=${data.sportName || ""}&StartDate=${
    data.startDate || ""
  }&EndDate=${data.endDate || ""}`;
  const authHeader = getAuthHeaders();
  return await getPDF(url, { ...authHeader }, "SeasonsFilter.pdf");
};

const getSeasonDeatails = async (data) => {
  const sortDir = data.sortDir == false ? "Descending" : "Ascending";
  const url = `${api}Seasons/getseasondetails/${data?.seasonId}?PageNumber=${
    data.pageNumber
  }&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${
    data.sortField || ""
  }&vendorId=${data?.vendorId}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const exportPdfSingleSeason = async (data) => {
  const url = `${api}/Seasons/exportlocationpdf?seasonid=${data.seasonId}&vendorId=${data.vendorId}`;
  const authHeader = getAuthHeaders();
  return await getPDF(
    url,
    { ...authHeader },
    `Location-${data?.data?.name}.pdf`
  );
};

const exportExcelSingleSeason = async (data) => {
  const url = `${api}/Seasons/exportlocationexcel/${data.seasonId}?vendorId=${data.vendorId}`;
  const authHeader = getAuthHeaders();
  return await downloadExcel(url, null, { ...authHeader });
};

export const SeasonServices = {
  getAllSeason,
  createSeason,
  updateSeason,
  deleteSeason,
  filterSeason,
  exportCSVSeason,
  exportSelectedAsPdfSeason,
  exportPdfSeason,
  getSeasonList,
  exportSelectedAsCSVSeason,
  getSeasonById,
  getSeasonsList,
  getSeasonDeatails,
  exportPdfSingleSeason,
  exportExcelSingleSeason,
};
