import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import {
  getAccountTeamList,
  getAccountSportList,
  lookupAccounts,
} from "../../../data/reducers/accounts/accounts.reducers";
import {
  createTeam,
  createSport,
  updateSport,
  updateTeam,
} from "../../../data/reducers/sports&teams/sports&teams.reducer";
import {
  errorMessage,
  successMessage,
} from "../../../data/reducers/alerts/alert.reducer";

import { getAddressList } from "../../../data/reducers/addressBook/addressBook.reducer";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { withLoader } from "../../../utils/hoc/withLoader";
import { getAddressByID } from "../../../data/reducers/addressBook/addressBook.reducer";
import Add from "../../../images/add.svg";
import Done from "../../../images/done_white.svg";
import EditIcon from "../../../images/edit.svg";
import Loader from "../../../shared/components/loader/Loader";
import FullLoader from "../../../shared/components/loader/FullLoader";

const getOptionLabel = (option) => {
  if (
    option.address1 != undefined ||
    option.address2 != undefined ||
    option.city != undefined ||
    option.state != undefined ||
    option.postalCode != undefined
  )
    return `${option.address1} ${option.address2} ${option.city} ${option.state} ${option.postalCode}`;
};

const AddEditAccountModal = ({
  isVisible,
  handleThisClose,
  onSubmit,
  added,
  selectedRecord,
  setSelectedRecord,
  setModalOpen,
  modalOpen,
  addOrEdit,
}) => {
  const dispatch = useDispatch();
  const [dropVal, setdropVal] = useState({
    vendor: "",
    team: "",
    sport: "",
    name: "",
    address: "",
    category: "",
  });
  //If selected Record Exists aka Edit else Add
  const [isEdit, setIsEdit] = useState(addOrEdit);
  const [editedOption, setEditedOption] = useState("");
  const [newAddress, setNewAddress] = useState(null);
  const [openEdit, setOpenEdit] = useState({ sport: false, team: false });
  const [loading, setLoading] = useState(false);
  const [formattedNumber, setFormattedNumber] = useState("");
  //team and sport checks fields
  const [teamSport, setTeamSport] = useState({
    teamText: "",
    sportText: "",
  });

  const [teamSportError, setTeamSportError] = useState({
    team: false,
    sport: false,
  });

  const { loading: saveLoading } = useSelector((state) => state.cachedAccounts);

  const { vendorsListForDropdown } = useSelector(
    (state) => state.cachedVendors
  );

  const { addressList } = useSelector((state) => state.addressBook);

  const { sportList } = useSelector((state) => state.accounts);
  const { teamList } = useSelector((state) => state.accounts);
  const { newlyCreated } = useSelector((state) => state.sportsAndTeams);
  const { selectedAddress } = useSelector((state) => state.addressBook);
  const { categories } = useSelector((state) => state.accounts);

  const [teams, setTeams] = useState(teamList);

  useEffect(() => {
    if (!addressList.length) {
      dispatch(getAddressList());
    }

    if (!categories.length) {
      dispatch(lookupAccounts());
    }
  }, []);

  // setup react hook form

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    getValues,
  } = useForm();

  useEffect(() => {
    if (newlyCreated?.team?.id) {
      setValue("teamId", newlyCreated?.team);
      setdropVal({ ...dropVal, team: newlyCreated.team });
    }
    if (newlyCreated?.sport?.id) {
      setValue("sportId", newlyCreated?.sport);
      setdropVal({ ...dropVal, sport: newlyCreated.sport });
    }
  }, [newlyCreated.sport]);

  useEffect(() => {
    if (newlyCreated?.team?.id) {
      setValue("teamId", newlyCreated?.team);
      setdropVal({ ...dropVal, team: newlyCreated.team });
    }
  }, [newlyCreated.team]);

  useEffect(async () => {
    if (added) {
      await getAddressByID(added);
      dispatch(getAddressList());
    }
  }, [added, addressList]);
  //styles for Select DropDown
  const customStyles = {
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };

  // update values in form if selected record is changed
  useEffect(async () => {
    setOpenEdit({ sport: false, team: false });

    if (selectedRecord) {
      // as soon as selected record is changed, set values in modal popup
      setLoading(true);
      if (selectedRecord.addressBookId)
        await dispatch(getAddressByID(selectedRecord.addressBookId));
      // setIsEdit(true);

      if (added) {
        await getAddressByID(added);
      }

      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: !selectedRecord.redirect,
          shouldDirty: true,
        });
      }

      setValue("mobileNo", getFormattedNumber(selectedRecord?.mobileNo), {
        shouldValidate: true,
        shouldDirty: true,
      });
      setFormattedNumber(getFormattedNumber(selectedRecord?.mobileNo));
      callTeam();
      setdropVal({
        ...dropVal,
        name: {
          id: selectedRecord?.address,
          name: selectedRecord?.name,
          email: selectedRecord?.email,
        },
        address: selectedAddress
          ? addressList.filter((det) => det.id == selectedAddress)[0]
          : addressList.filter(
              (det) => det.id == selectedRecord.addressBookId
            )[0],
        vendor: vendorsListForDropdown.filter(
          (det) => det.id == selectedRecord.vendorId
        )[0],
        team: teamList.filter((det) => det.id == selectedRecord.teamId)[0],
        sport: sportList.filter((det) => det.id == selectedRecord.sportId)[0],
        category: categories.filter(
          (det) => det.id == selectedRecord.categoryId
        )[0],
      });

      setLoading(false);
    } else {
      // if selected record is undefined, reset
      setIsEdit(false);
      reset();
      setdropVal({
        vendor: "",
        sport: "",
        team: "",
        name: "",
        address: "",
        category: categories?.filter((c) => c.id == 1)[0],
      });
      setEditedOption("");
      // setValue("categoryId", c)
    }
  }, [isVisible, added]);

  useEffect(async () => {
    if (!isEdit)
      setdropVal({
        ...dropVal,
        category: categories[0],
      });
  }, [isEdit, categories]);

  const openAddress = (e) => {
    e.preventDefault();
    setSelectedRecord({
      name: getValues("name"),
      email: getValues("email"),
      sportId: dropVal?.sport?.id,
      teamId: dropVal?.team?.id,
      vendorId: dropVal?.vendor?.id,
      categoryId: dropVal?.category?.id,
      redirect: true,
      accountId: getValues("accountId"),
      accountCredits: getValues("accountCredits"),
      addressBookId: getValues("addressBookId"),
    });
    setModalOpen(true);
    handleThisClose();
  };
  //Handling Reset Button for moda;
  const handleReset = () => {
    for (const [key, value] of Object.entries(selectedRecord)) {
      setValue(key, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    setValue("mobileNo", getFormattedNumber(selectedRecord?.mobileNo), {
      shouldValidate: true,
      shouldDirty: true,
    });
    setFormattedNumber(getFormattedNumber(selectedRecord?.mobileNo));
    setdropVal({
      name: {
        id: selectedRecord?.address,
        name: selectedRecord?.name,
        email: selectedRecord?.email,
      },
      address: addressList.filter(
        (det) => det.id == selectedRecord.addressBookId
      )[0],
      vendor: vendorsListForDropdown.filter(
        (det) => det.id == selectedRecord.vendorId
      )[0],
      team: teamList.filter((det) => det.id == selectedRecord.teamId)[0],
      sport: sportList.filter((det) => det.id == selectedRecord.sportId)[0],
      category: categories.filter(
        (det) => det.id == selectedRecord.categoryId
      )[0],
    });

    resetTeamSport();
  };

  const handleEditTeamSport = (field1, field2, value1, value2) => {
    setTeamSport({
      ...teamSport,
      [field1]: value1,
    });
    setTeamSportError({
      ...teamSportError,
      [field2]: value2,
    });
  };

  //reseting the fields of the edit team and edit sport fields so that no bug occurs.
  const resetTeamSport = () => {
    setTeamSport({
      teamText: "",
      sportText: "",
    });

    setTeamSportError({
      team: false,
      sport: false,
    });
  };

  //handling clear button for modal
  const handleClear = () => {
    setdropVal({
      vendor: "",
      sport: "",
      team: "",
      name: "",
      address: "",
      category: "",
    });
    setValue("addressBookId", "");
    reset();
    setFormattedNumber("");
    resetTeamSport();
  };

  useEffect(() => {
    let filteredteam = teamList.filter((t) => t.sportId == dropVal.sport?.id);
    setOpenEdit({ ...openEdit, sport: false });
    if (dropVal.sport == "" || dropVal.sport == null) {
      setTeams([]);
      setValue("teamId", null);
      setdropVal({ ...dropVal, team: null });
    } else if (filteredteam.length > 0) setTeams(filteredteam);
  }, [dropVal.sport]);

  //adding sport in dropdown
  const handleAddSport = async (val) => {
    setLoading(true);
    await dispatch(createSport({ sportName: val }));
    await dispatch(getAccountSportList());
    setLoading(false);
  };

  const handleAddTeam = async (val) => {
    setLoading(true);
    const response = await dispatch(
      createTeam({ teamName: val, sportId: dropVal.sport.id })
    );
    if (response.error) {
      dispatch(errorMessage(response.error.message));
    }
    callTeam();
    setLoading(false);
  };

  const handleUpdateTeam = async () => {
    try {
      setLoading(true);
      const response = await dispatch(
        updateTeam({
          id: dropVal.team.id,
          teamName: getValues("editedTeam"),
          sportId: dropVal.sport.id,
          sportName: dropVal.sport.sportName,
        })
      );
      if (response.meta.requestStatus == "fulfilled") {
        callTeam();
        setdropVal({
          ...dropVal,
          team: { id: dropVal.team.id, teamName: getValues("editedTeam") },
        });
        setValue("editedTeam", "");
        setOpenEdit({ ...openEdit, team: false });
        setValue("teamId", dropVal.team);
      }
    } catch (error) {
      dispatch(errorMessage(error.message ?? "Error updating team"));
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateSport = async () => {
    const response = await dispatch(
      updateSport({ id: dropVal.sport.id, sportName: getValues("editedSport") })
    );
    if (response.meta.requestStatus == "fulfilled") {
      setdropVal({
        ...dropVal,
        sport: { id: dropVal.sport.id, sportName: getValues("editedSport") },
      });
      setValue("editedSport", "");
      await dispatch(getAccountSportList());
      setOpenEdit({ ...openEdit, sport: false });
      setValue("sportId", dropVal.sport);
    }
  };

  const callTeam = async () => {
    let payload = [];
    if (!teams.length) {
      const res = await dispatch(getAccountTeamList());
      payload = res.payload;
    } else {
      payload = teams;
    }
    const filteredteam = payload?.filter(
      (t) => t.sportId == dropVal?.sport?.id
    );
    if (filteredteam?.length > 0) setTeams(filteredteam);
  };

  useEffect(() => {
    setOpenEdit({ ...openEdit, team: false });
  }, [dropVal.team]);
  const validateSubmit = async (data) => {
    if (
      data?.sportId !== null &&
      typeof data.sportId == "object" &&
      data.sportId.isNew
    ) {
      data.sportId = dropVal?.sport?.id;
      data.teamId = dropVal?.team?.id;
    }
    data.password = null;
    if (data?.mobileNo) {
      data.mobileNo = data.mobileNo.split("-").join("");
    }
    let categoryId = isEdit ? data.categoryId : 1;
    if (
      selectedRecord &&
      selectedRecord.teamId == data.teamId &&
      selectedRecord.sportId == data.sportId &&
      selectedRecord.name == data.name &&
      selectedRecord.addressBookId == data.addressBookId &&
      selectedRecord.email == data.email &&
      selectedRecord.vendorId == data.vendorId &&
      selectedRecord.accountId == data.accountId &&
      selectedRecord.categoryId == categoryId &&
      selectedRecord.mobileNo == data.mobileNo
    ) {
      handleThisClose();
      dispatch(successMessage("Account Updated Successfully"));
      resetTeamSport();
    } else {
      onSubmit(data);
    }
  };

  const getFormattedNumber = (number) => {
    if (!number) {
      return "";
    }
    const removeDividers = number.split("-").join("");
    if (removeDividers.length >= 10) {
      const firstThree = removeDividers.slice(0, 3);
      const secondThree = removeDividers.slice(3, 6);
      const lastRemaining = removeDividers.slice(6);
      return `${firstThree}-${secondThree}-${lastRemaining}`;
    } else if (removeDividers.length >= 7) {
      const firstThree = removeDividers.slice(0, 3);
      const secondThree = removeDividers.slice(3, 6);
      const lastRemaining = removeDividers.slice(6);
      return `${firstThree}-${secondThree}-${lastRemaining}`;
    } else if (removeDividers.length >= 4) {
      const firstThree = removeDividers.slice(0, 3);
      const lastRemaining = removeDividers.slice(3);
      return `${firstThree}-${lastRemaining}`;
    } else {
      return removeDividers;
    }
  };

  const optimizedAddressList = useMemo(() => {
    return addressList
      .filter(
        (address) =>
          address.address1 != undefined ||
          address.address2 != undefined ||
          address.city != undefined ||
          address.state != undefined ||
          address.postalCode != undefined
      )
      .map(
        (address) =>
          `${address.address1} ${address.address2} ${address.city} ${address.state} ${address.postalCode}`
      );
  }, [addressList]);

  return (
    <>
      <Modal
        dialogClassName="modal-xl"
        show={isVisible}
        onHide={handleThisClose}
      >
        {loading && <FullLoader />}
        <Form onSubmit={handleSubmit(validateSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>ACCOUNTS</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/accounts",
                name: "Accounts",
                active: false,
              },
              {
                name: isEdit ? "Edit Account" : "Add Account",
                active: true,
              },
            ]}
          />
          <Modal.Body>
            <div className="container-fluid p-2 w-100 h-100">
              <Row>
                <p className="title-accounts">Basic Information</p>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Name on Account
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        autoComplete="nope"
                        isInvalid={!!errors.name}
                        {...register("name", { required: true })}
                        placeholder="Name on Account"
                      />
                      <Form.Control.Feedback type="invalid">
                        Name on Account is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Account ID
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        // type="number"
                        // max="9007199254740991"
                        isInvalid={!!errors.accountId}
                        {...register("accountId", { required: true })}
                        placeholder="Account ID"
                      />
                      <Form.Control.Feedback type="invalid">
                        Account ID is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <p className="title-accounts">Basic Information</p>
                <Col sm={6}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Vendor
                    </Form.Label>
                    <Col sm="7">
                      <Controller
                        name="vendorId"
                        control={control}
                        rules={{ required: true, message: "error message" }}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <div>
                              <Select
                                required
                                isClearable
                                isInvalid={!!errors.vendorId}
                                value={dropVal?.vendor}
                                onChange={(e) => {
                                  field.onChange(e?.id);
                                  setdropVal({ ...dropVal, vendor: e });
                                }}
                                styles={customStyles}
                                // isClearable
                                options={vendorsListForDropdown?.filter(
                                  (v) => v.categoryId === 1
                                )}
                                getOptionLabel={(option) =>
                                  `${option.id}  ${option.name}`
                                }
                              />
                              {invalid && error ? (
                                <div className="custom-invalid-feedback">
                                  Vendor is required.
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Type
                    </Form.Label>
                    <Col sm="7">
                      <Controller
                        name="categoryId"
                        control={control}
                        // rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <div>
                              <Select
                                required={true}
                                isInvalid={!!errors.categoryId}
                                value={dropVal?.category || categories[0]}
                                onChange={(e) => {
                                  field.onChange(e?.id);
                                  setdropVal({ ...dropVal, category: e });
                                }}
                                styles={customStyles}
                                options={categories}
                                getOptionLabel={(option) =>
                                  `${option.category}`
                                }
                              />
                              {invalid && error ? (
                                <div className="custom-invalid-feedback">
                                  Category is required.
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>

              <hr />
              <Row>
                <p className="title-accounts">Contact Details</p>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Address
                    </Form.Label>
                    <Col sm="6">
                      <Controller
                        name="addressBookId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <>
                              <CreatableSelect
                                required
                                isInvalid={!!errors.addressBookId}
                                value={dropVal?.address}
                                defaultInputValue={
                                  selectedRecord && selectedRecord?.address
                                }
                                styles={customStyles}
                                options={addressList}
                                getOptionLabel={getOptionLabel}
                                onChange={async (e) => {
                                  setNewAddress(e);
                                  if (e != undefined) {
                                    field.onChange(e?.id);
                                    setdropVal({ ...dropVal, address: e });
                                  }
                                }}
                              />
                              {invalid && error ? (
                                <div className="custom-invalid-feedback">
                                  Address is required.
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                    </Col>
                    <Col xs={1}>
                      <Button onClick={openAddress}>
                        <img src={Add} width={15} height={15} />
                      </Button>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Email
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        type="email"
                        isInvalid={!!errors.email}
                        {...register("email", { required: true })}
                        placeholder="Email"
                        autoComplete="nope"
                      />
                      <Form.Control.Feedback type="invalid">
                        Email is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="pt-3">
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Mobile No.
                    </Form.Label>
                    <Col sm="3">
                      <Controller
                        name="mobileNo"
                        control={control}
                        rules={{ required: false }}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <>
                              <Form.Control
                                type="text"
                                placeholder="Mobile Number"
                                value={formattedNumber}
                                autoComplete="nope"
                                onChange={(e) => {
                                  const number = getFormattedNumber(
                                    e?.target?.value
                                  );
                                  field.onChange(number);
                                  setFormattedNumber(number);
                                }}
                              />
                            </>
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <p className="title-accounts">Sports and Team</p>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Sport
                    </Form.Label>
                    <Col sm={dropVal?.sport?.id ? "6" : "7"}>
                      <Controller
                        name="sportId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <>
                              <CreatableSelect
                                required
                                isClearable
                                isInvalid={!!errors.sportId}
                                value={
                                  dropVal.sport ? { ...dropVal.sport } : null
                                }
                                onChange={async (e) => {
                                  field.onChange(e?.id);
                                  setdropVal({
                                    ...dropVal,
                                    sport: e,
                                    team: "",
                                  });
                                  let filteredteam = teamList.filter(
                                    (t) => t.sportId == e?.id
                                  );
                                  if (filteredteam.length > 0)
                                    setTeams(filteredteam);
                                }}
                                styles={customStyles}
                                options={sportList}
                                getOptionLabel={(option) => option.sportName}
                                onCreateOption={async (e) => {
                                  await handleAddSport(e);
                                  await field.onChange({
                                    sportName: e,
                                    isNew: true,
                                    id: null,
                                  });
                                }}
                                getNewOptionData={(
                                  inputValue,
                                  optionLabel
                                ) => ({
                                  sportName: optionLabel,
                                  id: "new",
                                  isNew: true,
                                })}
                              />
                              {invalid && error ? (
                                <div className="custom-invalid-feedback">
                                  Sport is required.
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                    </Col>
                    {dropVal?.sport?.id > 0 && (
                      <Col xs={1}>
                        <Button
                          className={
                            openEdit.sport
                              ? "active blue-hover-button"
                              : "blue-hover-button"
                          }
                          onClick={() =>
                            setOpenEdit({ ...openEdit, sport: !openEdit.sport })
                          }
                        >
                          <img src={EditIcon} width={15} height={15} />
                        </Button>
                      </Col>
                    )}
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Team
                    </Form.Label>
                    <Col sm={dropVal?.team?.id ? "6" : "7"}>
                      <Col>
                        <Controller
                          required
                          isInvalid={!!errors.teamId}
                          name="teamId"
                          control={control}
                          rules={{ required: true }}
                          render={({
                            field,
                            fieldState: { invalid, error },
                          }) => {
                            return (
                              <>
                                <CreatableSelect
                                  required
                                  isInvalid={!!errors.teamId}
                                  isClearable
                                  value={dropVal?.team}
                                  onChange={async (e) => {
                                    field.onChange(e?.id);
                                    setTeams(teams);
                                    setdropVal({ ...dropVal, team: e });
                                  }}
                                  isDisabled={!(dropVal?.sport?.id > 0)}
                                  styles={customStyles}
                                  options={teams}
                                  getOptionLabel={(option) => option?.teamName}
                                  getNewOptionData={(
                                    inputValue,
                                    optionLabel
                                  ) => ({
                                    teamName: optionLabel,
                                    id: "new",
                                    isNew: true,
                                  })}
                                  onCreateOption={async (e) => {
                                    await handleAddTeam(e);
                                    await field.onChange({
                                      teamName: e,
                                      isNew: true,
                                      id: null,
                                    });
                                  }}
                                />
                                {invalid && error ? (
                                  <div className="custom-invalid-feedback">
                                    Team is required.
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          }}
                        />
                      </Col>
                    </Col>
                    {dropVal?.team?.id > 0 && (
                      <Col xs={1}>
                        <Button
                          className={
                            openEdit.team
                              ? "active blue-hover-button"
                              : "blue-hover-button"
                          }
                          onClick={() =>
                            setOpenEdit({ ...openEdit, team: !openEdit.team })
                          }
                        >
                          <img src={EditIcon} width={15} height={15} />
                        </Button>
                      </Col>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  {openEdit.sport && (
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        Edit Sport
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          isInvalid={!!errors.editedSport}
                          {...register("editedSport", {
                            required: openEdit.sport,
                          })}
                          onChange={(e) => {
                            if (
                              e.target.value.toLowerCase() ===
                              dropVal.sport.sportName.toLowerCase()
                            ) {
                              handleEditTeamSport(
                                "sportText",
                                "sport",
                                e.target.value,
                                true
                              );
                            } else {
                              handleEditTeamSport(
                                "sportText",
                                "sport",
                                e.target.value,
                                false
                              );
                            }
                          }}
                          placeholder="Edit Sport"
                        />
                        {teamSportError.sport && (
                          <div className="custom-invalid-feedback">
                            Use existing sport.
                          </div>
                        )}
                      </Col>
                      <Col xs={1}>
                        <Button
                          onClick={() => {
                            if (
                              dropVal.sport.sportName.toLowerCase() ===
                              teamSport.sportText.toLowerCase()
                            ) {
                              return;
                            } else {
                              handleEditTeamSport(
                                "sportText",
                                "sport",
                                "",
                                false
                              );
                            }
                            if (getValues("editedSport")?.length > 0)
                              handleUpdateSport();
                          }}
                        >
                          <img src={Done} width={15} height={15} />
                        </Button>
                      </Col>
                    </Form.Group>
                  )}
                </Col>
                <Col>
                  {openEdit.team && (
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        Edit Team
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          isInvalid={!!errors.editedTeam}
                          {...register("editedTeam", {
                            required: !openEdit.team,
                          })}
                          onChange={(e) => {
                            if (
                              e.target.value.toLowerCase() ===
                              dropVal.team.teamName.toLowerCase()
                            ) {
                              handleEditTeamSport(
                                "teamText",
                                "team",
                                e.target.value,
                                true
                              );
                            } else {
                              handleEditTeamSport(
                                "teamText",
                                "team",
                                e.target.value,
                                false
                              );
                            }
                          }}
                          placeholder="Edit Team"
                        />
                        {teamSportError.team && (
                          <div className="custom-invalid-feedback">
                            Use existing team.
                          </div>
                        )}
                      </Col>

                      <Col xs={1}>
                        <Button
                          onClick={() => {
                            if (
                              teamSport.teamText.toLowerCase() ===
                              dropVal.team.teamName.toLowerCase()
                            ) {
                              return;
                            } else {
                              handleEditTeamSport(
                                "teamText",
                                "team",
                                "",
                                false
                              );
                            }
                            if (getValues("editedTeam")?.length > 0)
                              handleUpdateTeam();
                          }}
                        >
                          <img src={Done} width={15} height={15} />
                        </Button>
                      </Col>
                    </Form.Group>
                  )}
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <div className="d-flex justify-content-center my-4">
            <Button
              disabled={saveLoading}
              type="submit"
              variant="primary"
              className="ss-modal-primary-btn mx-2"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isEdit ? "Save" : "Add"}
                {saveLoading && <Loader style={{ marginLeft: "8px" }} />}
              </div>
            </Button>
            <Button
              disabled={saveLoading}
              variant="light"
              onClick={() => (isEdit ? handleReset() : handleClear())}
              className="ss-modal-secondary-btn mx-2"
            >
              {isEdit ? "Reset" : "Clear"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default withLoader(AddEditAccountModal);
