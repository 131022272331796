import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import { Modal, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { withLoader } from "../../../utils/hoc/withLoader";
import styles from "../styles/email.module.css";
import Download from "../../../images/download.jpg";
import QuickTixTable from "../../../shared/components/table/QuickTixTable";
import TableRowCard from "../../../shared/components/table/TableRowCard";
import {
  downloadTemplateEmail,
  downloadTampleteEmailData,
  downloadUploadedFiles,
} from "../../../data/reducers/email/email.reducer";
import { columnError } from "../../../shared/components/table/data/error";
import { formatDate } from "../../../utils/formatter/dateFormatter";
import { useEffect } from "react";
import VectorImg from "../../../images/VectorImg.svg";
import { successMessage } from "../../../data/reducers/alerts/alert.reducer";

const DownloadTemplateModal = ({ setBusy, isVisible, handleThisClose }) => {
  const dispatch = useDispatch();

  const tamplateEmailData = useSelector((state) => state.emails).tamplateData;

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const downloadTemplate = async () => {
    setBusy(true);
    await dispatch(downloadTemplateEmail());
    setBusy(false);
  };

  useEffect(async () => {
    downloadUploadedEmailData();
  }, []);

  const uploadCol = [
    {
      columnName: "File Name",
      columnKey: "fileName",
      flexGrow: 1,
    },
    {
      columnName: "Size",
      columnKey: "size",
      flexGrow: 1,
      render: (data) => {
        return <div>{(data?.size / 1024 / 1024).toFixed(4)} MB</div>;
      },
    },
    {
      columnName: "Upload Date",
      columnKey: "uploadedOn",
      flexGrow: 1,
      render: (data) => <>{formatDate(data.uploadedOn)}</>,
    },
    {
      columnName: "Upload By",
      columnKey: "uploadedBy",
      flexGrow: 1,
    },
    {
      columnName: "Actions",
      columnKey: "id",
      flexGrow: 1,
      render: (data) => {
        return (
          <div
            onClick={() => downloadUploadFiles(data)}
            className={styles.scorollData}
          >
            <img
              src={Download}
              alt=" "
              style={{ height: "1rem", cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const downloadUploadedEmailData = async () => {
    await dispatch(downloadTampleteEmailData());
  };

  const downloadUploadFiles = async (data) => {
    const message = `${data.fileName} file downloaded successfully!`;
    setBusy(true);
    await dispatch(downloadUploadedFiles(data));
    dispatch(successMessage(message));
    handleThisClose();
    setBusy(false);
  };

  return (
    <>
      <Modal
        dialogClassName="modal-xl"
        show={isVisible}
        onHide={handleThisClose}
      >
        <Form onSubmit={handleSubmit()} style={{ fontWeight: "500" }}>
          <Modal.Header closeButton>
            <Modal.Title>Emails</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/email",
                name: "Email",
                active: false,
              },
              {
                name: "Download template",
                active: true,
              },
            ]}
          />
          <Modal.Body>
            <div className="container-fluid p-2 w-100 h-100">
              <Col>
                <p className={styles.downloadTamplate}>Downlaod template</p>
                <div className={styles.downloadTamplateFile}>
                  <div className="d-flex justify-content-between">
                    <Col className="my-1 mx-4 pt-1">
                      <div>
                        {" "}
                        <img
                          src={VectorImg}
                          alt=" "
                          style={{ paddingRight: "10px" }}
                        />
                        <span>template.csv</span>
                      </div>
                      <p style={{ marginLeft: "2rem" }}>3 MB</p>
                    </Col>
                    <div
                      className="my-2 mx-4 pt-2"
                      style={{ height: "1.5rem", cursor: "pointer" }}
                      onClick={downloadTemplate}
                    >
                      <img
                        src={Download}
                        alt=" "
                        style={{ height: "1.5rem" }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <div
                style={{
                  height: "50vh",
                  overflowY: "scroll",
                }}
              >
                <QuickTixTable
                  headerName="Download from uploaded files"
                  data={tamplateEmailData || []}
                  columns={uploadCol}
                  renderRow={(rowData, index) => (
                    <TableRowCard
                      key={rowData.id}
                      columns={rowData.nodata ? columnError : uploadCol}
                      rowData={rowData}
                    />
                  )}
                />
              </div>
            </div>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
};

export default withLoader(DownloadTemplateModal);
