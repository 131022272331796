import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../services/api";

export const fetchTransactions = createAsyncThunk(
  "cachedTransactions/fetch",
  async (params) => {
    try {
      const response = await api.get("Charges/getall", params);
      return {
        params,
        response: response,
      };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const flushTransactions = createAsyncThunk(
  "cachedTransactions/flush",
  async () => true
);

export const clearError = createAsyncThunk(
  "cachedTransactions/clearError",
  async () => true
);

const cachedTransactionsSlice = createSlice({
  name: "cachedTransactions",
  initialState: {
    data: null,
    error: null,
    loading: false,
  },
  extraReducers: {
    [fetchTransactions.pending]: (state) => {
      state.loading = true;
    },
    [fetchTransactions.fulfilled]: (state, action) => {
      const { params, response } = action.payload;
      if (!state.data) {
        state.data = {
          [JSON.stringify(params)]: {
            data: response.data,
            paging: response.paging,
            timestamp: Date.now(),
          },
        };
      } else {
        state.data[JSON.stringify(params)] = {
          data: response.data,
          paging: response.paging,
          timestamp: Date.now(),
        };
      }
      state.loading = false;
      state.error = null;
    },
    [fetchTransactions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [flushTransactions.fulfilled]: (state) => {
      state.data = null;
      state.error = null;
      state.loading = false;
    },
  },
});

export default cachedTransactionsSlice.reducer;
