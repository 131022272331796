import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import { Modal, Button, Row, Col, Form, InputGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useRef } from "react";
import {
  errorMessage,
  successMessage,
} from "../../../data/reducers/alerts/alert.reducer";
import { useForm, Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { withLoader } from "../../../utils/hoc/withLoader";
import FilterPanel from "../../../shared/components/panels/filter-panel/FilterPanel";
import SelectTableHead from "../../../shared/components/table/parts/SelectTableHead";
import SelectRowCard from "../../../shared/components/table/SelectRowCard";
import { transactions } from "../../../shared/components/table/data/transactions";
import {
  getRecordsToMatch,
  unmatchTransactions,
  matchTransactions,
  modalSearch,
} from "../../../data/reducers/transactions/transactions.reducer";
import { formatDate } from "../../../utils/formatter/dateFormatter";
import { formatMoney } from "../../../utils/formatter/currencyFormatter";
import TableRowCard from "../../../shared/components/table/TableRowCard";
import { columnError } from "../../../shared/components/table/data/error";
import { formatTime } from "../../../utils/formatter/timeFormatter";
import { Link, useHistory } from "react-router-dom";
import Loader from "../../../shared/components/loader/Loader";
import FullLoader from "../../../shared/components/loader/FullLoader";
import { flushTransactionsCards } from "../../../data/reducers/transactions/cached-transaction-cards.reducer";
import { flushTransactions } from "../../../data/reducers/transactions/cached-transactions.reducer";

const transSelected = [
  {
    columnName: "Date",
    render: (data) => (
      <>
        {formatDate(data?.dateAndTime || data?.purchaseOrderDate)}
        <br />
        {data?.dateAndTime ? formatTime(data?.dateAndTime) : ""}
      </>
    ),
  },
  {
    columnName: "Card Account",
    render: (data) => (
      <>
        <Link
          variant="link"
          className="ss-link"
          to={{ pathname: "/fundSources/cards", state: data?.sourceId }}
        >
          {data.sourceType}
          <br />
          {data.sourceId}
        </Link>
      </>
    ),
  },
  {
    columnName: "Description",
    render: (data) => (
      <div style={{ overflow: "hidden" }}>{data.description}</div>
    ),
  },
  {
    columnName: "Vendor",
    render: (data) => (
      <Link variant="link" className="ss-link" to={"/vendors/" + data.vendorId}>
        {data.vendorName}
        <br />
        {data.vendorId ? data?.vendorId : ""}
      </Link>
    ),
    sort: true,
    sortName: "VendorId",
  },
  {
    columnName: "Account",
    sort: true,
    sortName: "AccountId",
    render: (data) => (
      <Link
        variant="link"
        className="ss-link"
        to={"/accounts/" + data.accountId}
      >
        {data.accountName}
        <br />
        {data.accountId == 0 ? "" : data.accountId}
      </Link>
    ),
  },
  {
    columnName: "Purchase Order",
    render: (data) => (
      <>
        <Link variant="link" className="ss-link" to={"/purchases/" + data.id}>
          {data.purchaseOrderAbbrev}
        </Link>
      </>
    ),
    columnKey: "purchaseOrderAbbrev",
  },
  {
    columnName: "Amount",
    render: (data) => <>{formatMoney(data?.amount)}</>,
  },
];

const SelectMatchModal = ({
  isVisible,
  handleClose,
  onSubmit,
  selectedRecord,
  match,
  setIsVisible,
}) => {
  const dispatch = useDispatch();
  const { reset } = useForm();
  const history = useHistory();
  const [selectId, setselectId] = useState(null);
  const [searchData, setsearchData] = useState(null);
  const [innerLoading, setInnerLoading] = useState(false);

  const records = useSelector((state) => state.transactions).recordsToMatch;

  const invalidateTransactions = () => {
    dispatch(flushTransactionsCards());
    dispatch(flushTransactions());
  };

  const fetchMatches = async () => {
    try {
      setInnerLoading("fetch");
      const response = await dispatch(
        getRecordsToMatch({
          id: selectedRecord?.id,
          update: match,
        })
      );
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setInnerLoading(false);
    }
  };

  const handleSearchSubmit = async (data) => {
    try {
      setInnerLoading("fetch");
      const formData = {
        searchText: data,
        id: selectedRecord?.id,
        update: match,
      };
      await dispatch(modalSearch(formData));
    } catch (e) {
      dispatch(errorMessage(e?.message ?? "Something went wrong"));
    } finally {
      setInnerLoading(false);
    }
  };

  // reset form on visibility toggle
  useEffect(async () => {
    if (selectedRecord) await fetchMatches();
    setselectId(null);
    reset();
  }, [isVisible]);

  useEffect(() => {
    if (searchData) {
      handleSearchSubmit(searchData);
    }
  }, [searchData]);

  const callUnmatch = async (e) => {
    e.preventDefault();
    try {
      setInnerLoading("unmatch");
      const response = await dispatch(
        unmatchTransactions({
          trans: selectedRecord,
        })
      );
      invalidateTransactions();
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        dispatch(successMessage("Charges unmatched successfully"));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      const route = window.location.pathname + window.location.search;
      history.push(route);
      setInnerLoading(false);
      handleClose();
    }
  };

  const callMatch = async (e) => {
    e.preventDefault();
    try {
      setInnerLoading("confirm_match");
      if (!selectedRecord.purchaseOrdersId) {
        const response = await dispatch(
          matchTransactions({
            select: selectId,
            trans: selectedRecord,
          })
        );
        invalidateTransactions();
        if (response.error) {
          dispatch(errorMessage(response.error.message));
        } else {
          dispatch(successMessage("Charges matched successfully"));

          handleClose();
        }
      } else {
        const response = await dispatch(
          unmatchTransactions({
            trans: selectedRecord,
          })
        );
        const res = await dispatch(
          matchTransactions({
            select: selectId,
            trans: selectedRecord,
          })
        );
        if (res.error) {
          dispatch(errorMessage(response.error.message));
        } else {
          dispatch(successMessage("Charges matched successfully"));
          handleClose();
        }
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      const route = window.location.pathname + window.location.search;
      history.push(route);
      setInnerLoading(false);
    }
  };

  return (
    <>
      <Modal
        dialogClassName="modal-xl"
        show={isVisible}
        onHide={() => setIsVisible(false)}
      >
        {innerLoading === "fetch" && <FullLoader />}
        <Form onSubmit={onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>SELECT MATCH </Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/tranactions",
                name: "Transactions",
                active: false,
              },
            ]}
          />
          <Modal.Body>
            <div className="container-fluid w-100 h-100">
              <FilterPanel
                searchPlaceholder="Search for name, vendor, account name or account number..."
                onReset={() => {
                  if (searchData) {
                    setsearchData(null);
                    reset();
                    fetchMatches();
                  }
                }}
                modal={true}
                setsearchData={setsearchData}
                noFilter={true}
              />

              <div className="container-fluid">
                <SelectTableHead columns={transSelected} />
                <div className="table-body">
                  <TableRowCard
                    columns={transSelected}
                    rowData={selectedRecord}
                  />
                </div>
                <div className="padded-div" style={{ minHeight: "40vh" }}>
                  <div>
                    <b>SELECT MATCH</b>
                  </div>
                  <SelectTableHead columns={transactions(setselectId)} />
                  <div style={{ maxHeight: "100vh", overflow: "scroll" }}>
                    {records.length > 0 ? (
                      records.map((data, i) => (
                        <SelectRowCard
                          key={i}
                          columns={transactions(setselectId)}
                          rowData={data}
                          isSelected={
                            records.indexOf(selectId) === records.indexOf(data)
                          }
                        />
                      ))
                    ) : (
                      <>
                        <br />
                        <h4 className="text-center">No records to match</h4>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <div className="d-flex justify-content-center my-4">
            <Button
              type="submit"
              variant="primary"
              className="ss-modal-primary-btn mx-2"
              onClick={callMatch}
              disabled={!selectId || innerLoading === "confirm_match"}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Confirm Match
                {innerLoading === "confirm_match" && (
                  <Loader style={{ marginLeft: "8px" }} />
                )}
              </div>
            </Button>

            {match && (
              <Button
                disabled={innerLoading === "unmatch"}
                variant="primary"
                className="ss-modal-primary-btn mx-2"
                onClick={callUnmatch}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Unmatch
                  {innerLoading === "unmatch" && (
                    <Loader style={{ marginLeft: "8px" }} />
                  )}
                </div>
              </Button>
            )}
            <Button
              disabled={innerLoading === "unmatch"}
              variant="primary"
              className="ss-modal-primary-btn mx-2"
              onClick={() => setIsVisible(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default SelectMatchModal;
