import {
    api,
    getAuthHeaders,
    post,
    update,
    del,
    get,
    getCSV,
    getPDF
  } from "../services.common";
  
  const createAccountLocation = async (data) => {
    const url = `${api}Accounts/addaccountlocation`;
    const authHeader = getAuthHeaders();
    return await post(url, data, { ...authHeader });
  };
  const updateAccountLocation = async (data) => {
    const url = `${api}Accounts/editaccountlocation`;
    const authHeader = getAuthHeaders();
    return await update(url, data, { ...authHeader });
  };
  const deleteAccountLocation = async ({id, accountId}) => {
    const url = `${api}Accounts/deleteAccountlocation/${id}/${accountId}`;
    const authHeader = getAuthHeaders();
    return await del(url, { ...authHeader });
  };

  const getAllAccountLocationDetails = async (data) => {
    const sortDir = data.sortDir == false ? '0' : '1'
    const url = `${api}Accounts/detailsaccountlocation?accountId=${data.accountId}&PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""}`
    const authHeader = getAuthHeaders();
    return await get(url, { ...authHeader });
  }
  
  const getAccountLocationHistory = async (data) => {
    const sortDir = data.sortDir == false ? '0' : '1'
    const url = `${api}Accounts/historysaccountlocation?accountId=${data.accountId}&PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""}`
    const authHeader = getAuthHeaders();
    return await get(url, { ...authHeader });
  };

  
  export const accountLocationServices = {
    createAccountLocation,
    updateAccountLocation,
    deleteAccountLocation,
    getAccountLocationHistory,
    getAllAccountLocationDetails
  };
  