import { Route, Switch } from "react-router-dom";
import InventoryDashboard from "../../../pages/inventory/InventoryDashboard";

const InventoryRoutes = () => {
  return (
    <Switch>
      <Route path="/inventory" exact>
        <InventoryDashboard />
      </Route>
      {/* <Route path="/accounts" exact>
        <AccountDashboard />
      </Route>
      <Route path="/accounts/:id" exact>
        <SingleAccountDetail />
      </Route> */}
    </Switch>
  );
};

export default InventoryRoutes;