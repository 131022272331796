import {
    api,
    getAuthHeaders,
    get,
    post,
    update,
    del,
    getCSV,
    getPDF
} from "../services.common";

const getAllTeams = async (data) => {
  const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
  const url = `${api}Accounts/getallteams?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};


const getAllSports = async (data) => {
  const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
  const url = `${api}Accounts/getallsports?PageNumber=${1}&PageSize=${100}&SortDir=${"Ascending"}&SortField=${""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const updateSport = async (data) => {
  const url = `${api}Accounts/updatesports/${data.id}`;
  const authHeader = getAuthHeaders();
  return await update(url, data, { ...authHeader });
};

const updateTeam = async (data) => {
  const url = `${api}Accounts/updateteams/${data.id}`;
  const authHeader = getAuthHeaders();
  return await update(url, data, { ...authHeader });
};

const deleteTeam = async (id) => {
  const url = `${api}Accounts/deleteteam/${id}`;
  const authHeader = getAuthHeaders();
  return await del(url, { ...authHeader });
};

const filterTeams = async (data) => {
  const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
  const url = `${api}Accounts/searchteams?searchString=${data.searchText || ""}&SportId=${data.sportId || ""}&TeamId=${data.teamId || ""}&PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const createTeam = async (data) => {
  const url = `${api}Accounts/createteam`;
  const authHeader = getAuthHeaders();
  return await post(url, data, { ...authHeader });
};

const createSport = async (data) => {
  const url = `${api}Accounts/createsport`;
  const authHeader = getAuthHeaders();
  return await post(url, data, { ...authHeader });
};

const exportCSVTeams = async () => {
    const url = `${api}Accounts/exportteamscsv`;
    const authHeader = getAuthHeaders();
    return await getCSV(url, { ...authHeader });
};

const exportPdfTeams = async () => {
    const url = `${api}Accounts/exportteamspdf`;
    const authHeader = getAuthHeaders();
    return await getPDF(url, { ...authHeader }, "Teams.pdf");
};

const exportSelectedAsCSVTeams = async (data) => {
    const url = `${api}Accounts/searchexportteams?searchString=${data.searchText || ""}&SportId=${data.sportId || ""}&TeamId=${data.teamId || ""}`;
    const authHeader = getAuthHeaders();
    return await getCSV(url, { ...authHeader });
};

const exportSelectedAsPdfTeams = async (data) => {
    const url = `${api}Accounts/searchexportteamspdf?searchString=${data.searchText || ""}&SportId=${data.sportId || ""}&TeamId=${data.teamId || ""}`;
    const authHeader = getAuthHeaders();
    return await getPDF(url, { ...authHeader },"TeamsFilter.pdf");
};


export const SportsAndTeamsServices = {
    getAllTeams,
    getAllSports,
    updateTeam,
    updateSport,
    createTeam,
    createSport,
    filterTeams,
    exportCSVTeams,
    exportSelectedAsPdfTeams,
    exportSelectedAsCSVTeams,
    exportPdfTeams,
    deleteTeam,
};
