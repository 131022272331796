import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LoanServices } from '../../services/finance/loans.service';
import fileDownload from 'js-file-download';
export const getAllLoans = createAsyncThunk(
	'loan/getAllLoans',
	async (payload, thunkAPI) => {
		const response = await LoanServices.getAllLoans(payload);
		if (response.isSuccessful == true) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const syncBanking = createAsyncThunk(
	'sync/banking',
	async (payload, thunkAPI) => {
		const response = await LoanServices.getSyncBanking(payload);
		if (response.isSuccessful === true) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const getBankBalance = createAsyncThunk(
	'loan/getBankBalance',
	async (thunkAPI) => {
		const response = await LoanServices.getBankBalance();
		if (response.isSuccessful == true) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);


export const filterLoan = createAsyncThunk(
	'loan/filterLoan',
	async (payload, thunkAPI) => {
		const response = await LoanServices.filterLoan(payload);
		if (response.isSuccessful === true) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const createLoan = createAsyncThunk(
	'loan/createLoan',
	async (payload, thunkAPI) => {
		const response = await LoanServices.createLoan(payload);
		if (response.isSuccessful === true) {
			return response.data;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const deleteLoan = createAsyncThunk(
	'Loan/DeleteLoan',
	async (payload, thunkAPI) => {
		const response = await LoanServices.deleteLoan(payload);
		if (response.isSuccessful === true) {
			return response.data;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const exportCSVLoan = createAsyncThunk(
	'Loans/exportcsv',
	async (thunkAPI) => {
		const response = await LoanServices.exportCSVLoan();
		if (response.isSuccessful === true) {
			await fileDownload(response.data?.data, 'Loans.csv')
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const exportPdfLoan = createAsyncThunk(
	'Loans/exportPdfLoan',
	async (thunkAPI) => {
		const response = await LoanServices.exportPdfLoan();
		if (response.isSuccessful === true) {
			await fileDownload(response.data, 'Loans.pdf')
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const exportSelectedAsCSVLoans = createAsyncThunk(
	"loans/exportCSVLoans",
	async (payload, thunkAPI) => {
		const response = await LoanServices.exportSelectedAsCSVLoans(payload);
		if (response.isSuccessful === true) {
			fileDownload(response.data?.data, "LoansFilter.csv")
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const exportSelectedAsPdfLoans = createAsyncThunk(
	"loans/exportPdfLoans",
	async (payload, thunkAPI) => {
		const response = await LoanServices.exportSelectedAsPdfLoans(payload);
		// if (response.isSuccessful === true) {
		// 	fileDownload(response.data, "LoansFilter.pdf")
		// } else {
		// 	thunkAPI.rejectWithValue(response.message);
		// 	throw new Error(response.message);
		// }
	}
);

export const updateLoan = createAsyncThunk(
	'Loans/UpdateLoan',
	async (payload, thunkAPI) => {
		const response = await LoanServices.updateLoan(payload);
		if (response.isSuccessful === true) {
			return response.data;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);


export const headerInfo = createAsyncThunk(
	'LoanCharges/getheaderinfoloans',
	async (thunkAPI) => {
		const response = await LoanServices.headerInfo();
		if (response.isSuccessful === true) {
			return response.data;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

const LoanSlice = createSlice({
	name: 'loans',
	initialState: {
		loans: [],
		banking: [],
		paging: {},
		cards: [],
		syncDate: ""
	},
	extraReducers: {
		[getAllLoans.fulfilled]: (state, action) => {
			state.loans = action.payload.data;
			state.paging = action.payload.paging;
		},
		[getBankBalance.fulfilled]: (state, action) => {
			state.banking = action.payload.data;
		},
		[filterLoan.fulfilled]: (state, action) => {
			state.loans = action.payload.data;
			state.paging = action.payload.paging;
		},
		[createLoan.fulfilled]: (state, action) => {
			state.loans.push(action.payload);
		},
		[deleteLoan.fulfilled]: (state, action) => {
			state.loans = [
				...state.loans.filter((Loan) => Loan.id !== action.payload.id),
			];
		},
		[headerInfo.fulfilled]: (state, action) => {
			state.cards = action.payload;
		},
		[syncBanking.fulfilled]: (state, action) => {
			state.syncDate = action.payload.data;
		},
	},
});

export default LoanSlice.reducer;

