import { Route, Switch } from "react-router-dom";
import RevealMarketDashboard from "../../../pages/settingstab/reveal-market/RevealMarketDashboard";


const AddressBookRoutes = () => {
    return (
        <Switch>
            <Route path="/settings/reveal-market" exact>
                <RevealMarketDashboard />
            </Route>

        </Switch>
    );
};

export default AddressBookRoutes;