import { Route, Switch } from 'react-router-dom';
import PayPalDashboard from '../../../pages/fundSources/paypal/PaypalDashboard';
import SingleIdPaypalDetails from '../../../pages/fundSources/paypal/SingleIdPaypalDetails';

const FundSourcesRoutes = () => {
	return (
		<Switch>
			<Route path='/fundSources/paypal' exact>
				<PayPalDashboard />
			</Route>
			<Route path='/fundSources/paypal/:id' exact>
				<SingleIdPaypalDetails />
			</Route>
		</Switch>
	);
};

export default FundSourcesRoutes;
