import ChargesCard from "../../cards/ChargesCard";
import { Col, Row } from "react-bootstrap";

const CardPanel = ({ data, loader }) => {
  return (
    <Row>
      <Col>
        <div style={{ position: "relative" }}>
          <ChargesCard
            title={data[0].head}
            amount={data[0].val}
            bg="rgba(24, 144, 255, 0.2)"
            color="#0241A0"
            loading={loader ? true : false}
          />
        </div>
      </Col>
      <Col>
        <div style={{ position: "relative" }}>
          <ChargesCard
            title={data[1].head}
            amount={data[1].val}
            bg="rgba(188, 219, 1, 0.2)"
            color="#849900"
            loading={loader ? true : false}
          />
        </div>
      </Col>
      <Col>
        <div style={{ position: "relative" }}>
          <ChargesCard
            title={data[2].head}
            amount={data[2].val}
            bg="rgba(255, 0, 0, 0.2)"
            color="#990000"
            loading={loader ? true : false}
          />
        </div>
      </Col>
      <Col>
        <div style={{ position: "relative" }}>
          <ChargesCard
            title={data[3].head}
            amount={data[3].val}
            render={data.render ? data.render : null}
            bg="rgba(231, 128, 9, 0.2)"
            color="#B26000"
            loading={loader ? true : false}
          />
        </div>
      </Col>
    </Row>
  );
};

export default CardPanel;
