// MODALS
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useHistory, useLocation, Link } from "react-router-dom";

import Up from "../../images/uparrow.svg";
import Down from "../../images/downarrow.svg";
import AddEditPOModal from "./modals/AddEditPOModal";
import { columnError } from "../../shared/components/table/data/error";
import { formatMoney } from "../../utils/formatter/currencyFormatter";
import MovePOConfirmationModal from "./modals/MovePOConfirmationModal";
import Export from "../../images/export.svg";
import Add from "../../images/add_blue.svg";
import Reset from "../../images/reset.svg";
import DeleteIcon from "../../images/delete.svg";
import FileMoveIcon from "../../images/drive_file_move.svg";
import EditIcon from "../../images/edit.svg";
import HistoryIcon from "../../images/history1.svg";
import AddEditSeasonModal from "../season/modals/AddEditSeasonModal";
import SelectMatch from "../transactions/modals/SelectMatch";
import CollapsibleTable from "./CollapsibleTable";
import IconButton from "@mui/material/IconButton";
import HistoryPO from "./modals/HistoryPO";
import CardPanel from "../../shared/components/panels/card-panel/CardPanel";
import TableRowCard from "../../shared/components/table/TableRowCard";
import DateFilter from "../transactions/modals/DateFilter";
import DeleteConfirmationModal from "../../shared/components/modals/DeleteConfirmationModal";
import { withLoader } from "../../utils/hoc/withLoader";
import FilterPanelPO from "./filter-panel";
import Loader from "../../shared/components/loader/Loader";
import {
  errorMessage,
  successMessage,
} from "../../data/reducers/alerts/alert.reducer";
import {
  createSeason,
  getSeasonsList,
} from "../../data/reducers/season/season.reducer";
import {
  formatDate,
  dateSlashFormatToDateObject,
  formatPlanDate,
} from "../../utils/formatter/dateFormatter";
import {
  createPO,
  updatePO,
  deletePO,
  exportSelectedAsCSVPaymentPalns,
  exportCSV,
  exportPDF,
  changePOEditState,
  togglePOSkyboxRef,
  exportCSVPaymentPlans,
} from "../../data/reducers/purchaseOrders/po.reducer";
import "../../shared/styles/ModalStyles.scss";
import {
  Mixpanel,
  mixpanel_button_name,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../mixpanel";
import {
  fetchPurchaseOrders,
  flushPurchaseOrders,
} from "../../data/reducers/purchaseOrders/cached-po.reducer";
import {
  fetchPurchaseOrdersCards,
  flushPurchaseOrdersCards,
  loadPurchaseOrderTransactionCards,
} from "../../data/reducers/purchaseOrders/cached-po-cards.reducer";

import { runRules } from "../../data/reducers/transactions/transactions.reducer";
import Run from "../../images/run.svg";
const ISREVIEWED = {
  0: "ALL",
  1: "NOSKYBOX",
  2: "REVOKED",
};

const ISREVIEWED_STR_TO_NUM = {
  ALL: 0,
  NOSKYBOX: 1,
  REVOKED: 2,
};

const getValidFilters = (filters) => {
  const arr = Object.entries(filters).filter(([key, value]) => {
    if (key === "PageNumber") {
      return value !== 1;
    }
    if (key === "PageSize") {
      return value !== 40;
    }
    if (key === "SortDir") {
      return value !== "Ascending";
    }
    if (key === "isReviewed") {
      return value !== "ALL";
    }

    if (
      key === "PurchaseOrderId" ||
      key === "Description" ||
      key === "SkyBoxPurchaseId" ||
      key === "SeasonId" ||
      key === "month" ||
      key === "SortField" ||
      key === "searchString" ||
      key === "SportId" ||
      key === "AccountId" ||
      key === "TeamId" ||
      key === "VendorId"
    ) {
      return value !== "";
    }
    return true;
  });

  const newFilters = Object.fromEntries(arr);
  if (newFilters.StartDate) {
    newFilters.StartDate = formatDate(newFilters.StartDate);
  }
  if (newFilters.EndDate) {
    newFilters.EndDate = formatDate(newFilters.EndDate);
  }
  return newFilters;
};

const MONTH_ARRAY = [
  {
    id: 1,
    name: "Last 12 Month",
    value: 12,
  },
  {
    id: 2,
    name: "Last 24 Month",
    value: 24,
  },
  {
    id: 3,
    name: "Last 36 Month",
    value: 36,
  },
  {
    id: 4,
    name: "Custom Range",
    value: null,
  },
];

const PurchaseOrderDashboard = ({ setBusy }) => {
  //dispatch
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  // SELECTORS

  const {
    data: cachedPurchaseOrdersCards,
    loading: cardsLoading,
    callCardsApi,
  } = useSelector((state) => state.cachedPurchaseOrdersCards);

  const { data: cachedPurchaseOrders, loading } = useSelector(
    (state) => state.cachedPurchaseOrders
  );

  // USESTATES
  const [monthSelect, setMonthSelect] = useState(12);
  const [exportLoading, setExportLoading] = useState(false);
  const [Runrules, setRunrules] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [matchedVisibility, setmatchedVisibility] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(undefined);
  const [addPOModalVisible, setAddEditPOModalVisibility] = useState(false);
  const [historyModalVisible, setHistoryModalVisibility] = useState(false);
  const [moveConfirmationVisible, setMoveConfirmationVisible] = useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisibility] =
    useState(false);
  const [addSeasonModalVisibility, setAddSeasonModalVisibility] =
    useState(false);

  const [filters, setFilters] = useState({
    searchString: params.get("searchString") || "",
    VendorId: params.get("VendorId") || "",
    PageNumber: Number(params.get("PageNumber")) || 1,
    PageSize: Number(params.get("PageSize")) || 40,
    PurchaseOrderId: params.get("PurchaseOrderId") || "",
    Description: params.get("Description") || "",
    AccountId: params.get("AccountId") || "",
    SortDir: params.get("SortDir") || "Ascending",
    SortField: params.get("SortField") || "",
    isReviewed: Number(params.get("isReviewed")) || "ALL",
    SportId: Number(params.get("SportId")) || "",
    TeamId: params.get("TeamId") || "",
    StartDate:
      dateSlashFormatToDateObject(params.get("StartDate")) ||
      new Date(new Date().setMonth(new Date().getMonth() - 12)),
    EndDate: dateSlashFormatToDateObject(params.get("endDate")) || new Date(),
    SeasonId: params.get("SeasonId") || "",
    SkyBoxPurchaseId: params.get("SkyBoxPurchaseId") || "",
    month: params.get("month") || "",
  });

  // MEMOISED VALUES
  const { purchaseOrders, paging, timestamp } = useMemo(() => {
    const queryParams = new URLSearchParams(getValidFilters(filters));
    history.push(`/purchases?${queryParams}`);
    const data =
      cachedPurchaseOrders?.[
        JSON.stringify({
          ...filters,
          StartDate: formatDate(filters.StartDate),
          EndDate: formatDate(filters.EndDate),
        })
      ];
    if (!data) {
      return { purchaseOrders: undefined, paging: undefined, timestamp: null };
    }
    return data;
  }, [cachedPurchaseOrders, filters]);

  const { cards } = useMemo(() => {
    const stringifiedFilters = JSON.stringify({
      ...filters,
      StartDate: formatPlanDate(filters.StartDate),
      EndDate: formatPlanDate(filters.EndDate),
      isReviewed: true,
    });
    const data = cachedPurchaseOrdersCards?.[stringifiedFilters];
    if (!data) {
      return { data: undefined };
    }
    return data;
  }, [cachedPurchaseOrdersCards, filters]);

  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.PURCHASEORDERS,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.PURCHASEORDERS,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (!purchaseOrders) {
      dispatch(
        fetchPurchaseOrders({
          ...filters,
          StartDate: formatDate(filters.StartDate),
          EndDate: formatDate(filters.EndDate),
        })
      );
    }
  }, [filters]);

  useEffect(() => {
    if (!cards && callCardsApi != null) {
      dispatch(
        fetchPurchaseOrdersCards({
          ...filters,
          StartDate: formatPlanDate(filters.StartDate),
          EndDate: formatPlanDate(filters.EndDate),
          isReviewed: true,
        })
      );
    }
  }, [callCardsApi]);

  const previousPage = () => {
    setFilters({
      ...filters,
      PageNumber: paging.currentPage - 1,
    });
  };

  const nextPage = () => {
    setFilters({
      ...filters,
      PageNumber: paging.currentPage + 1,
    });
  };

  const refetchAccountsAndCards = async () => {
    dispatch(
      fetchPurchaseOrders({
        ...filters,
        StartDate: formatDate(filters.StartDate),
        EndDate: formatDate(filters.EndDate),
      })
    );
  };

  // Run Rules
  const callRunRules = async () => {
    try {
      setRunrules(true);
      await dispatch(runRules());
    } catch (e) {
      dispatch(errorMessage("Something went wrong"));
    } finally {
      setRunrules(false);
    }
  };

  const resetHandler = async () => {
    dispatch(flushPurchaseOrders());
    dispatch(flushPurchaseOrdersCards());
    refetchAccountsAndCards();
  };

  // DELETE
  const onDeletePurchaseOrder = async (id) => {
    try {
      setBusy(true);
      const response = await dispatch(deletePO(id));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        setDeleteConfirmationVisibility(false);
        resetHandler();
        dispatch(successMessage("PurchaseOrder was deleted successfully"));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  const addSeason = async (data) => {
    if (data?.startDate > data?.endDate) {
      dispatch(errorMessage("Start Date Cannot be Greater Than End Date"));
    } else {
      try {
        const message = data.id
          ? `Season Updated Succesfully`
          : `New Season Created Succesfully`;
        setBusy(true);
        const action = createSeason(data);
        const response = await dispatch(action);
        await dispatch(getSeasonsList());
        if (response.error) {
          dispatch(errorMessage(response.error.message));
        } else {
          dispatch(successMessage(message));
          setAddSeasonModalVisibility(false);
          setAddEditPOModalVisibility(true);
        }
      } catch (e) {
        dispatch(errorMessage(e));
      } finally {
        setBusy(false);
      }
    }
  };

  const movePOHandler = async (id) => {
    try {
      setBusy(true);

      const response = await dispatch(togglePOSkyboxRef(id));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        setMoveConfirmationVisible(false);
        resetHandler();
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  // ADD/UPDATE PO
  const addEditHandler = async (_data) => {
    const data = _data;
    data.showInNoSkyboxRef = !!_data.showInNoSkyboxRef;
    data.totalPaid = _data.totalPaid == null ? 0 : _data.totalPaid;
    try {
      setBusy(true);
      const message = data.id
        ? `Purchase Order Updated Succesfully`
        : `New Purchase Order Created Succesfully`;
      const action = data?.id
        ? updatePO({
            ...data,
            purchaseOrderId: String(data?.purchaseOrderId),
            totalScheduled:
              data?.totalScheduled != ""
                ? parseFloat(data?.totalScheduled)
                : parseFloat(0),
          })
        : createPO({
            ...data,
            purchaseOrderId: String(data?.purchaseOrderId),
            totalScheduled:
              data?.totalScheduled != ""
                ? parseFloat(data?.totalScheduled)
                : parseFloat(0),
            skyBoxRef:
              data?.skyBoxRef === "" ? null : data?.skyBoxRef?.toString(),
          });
      const response = await dispatch(action);
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        dispatch(successMessage(message));
        setAddEditPOModalVisibility(false);
        resetHandler();
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  //cards data
  const cardsData = [
    {
      head: "Scheduled",
      val:
        cards?.scheduled == null || cards?.scheduled == undefined
          ? "--"
          : formatMoney(cards?.scheduled),
    },
    {
      head: "Paid",
      val:
        cards?.paid == null || cards?.paid == undefined
          ? "--"
          : formatMoney(cards?.paid),
    },
    {
      head: "Receivables",
      val:
        cards?.receivables == null || cards?.receivables == undefined
          ? "--"
          : formatMoney(cards?.receivables),
    },
    {
      head: "Transaction",
      val:
        cards?.numberofTransaction == null ||
        cards?.numberofTransaction == undefined
          ? "--"
          : cards?.numberofTransaction,
    },
  ];

  const exportSelectedAsCSV = async () => {
    try {
      setExportLoading(true);
      await dispatch(
        exportCSV({
          ...filters,
          isReviewed: ISREVIEWED_STR_TO_NUM[filters.isReviewed],
          StartDate: formatPlanDate(filters.StartDate),
          EndDate: formatPlanDate(filters.EndDate),
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(errorMessage("Something went wrong!"));
    } finally {
      setExportLoading(false);
    }
  };

  const onExportSelectedAsPdf = async () => {
    try {
      setExportLoading(true);
      await dispatch(
        exportPDF({
          ...filters,
          isReviewed: ISREVIEWED_STR_TO_NUM[filters.isReviewed],
          StartDate: formatPlanDate(filters.StartDate),
          EndDate: formatPlanDate(filters.EndDate),
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(errorMessage("Something went wrong!"));
    } finally {
      setExportLoading(false);
    }
  };

  const exportSelectedAsCSVPaymentPlans = async () => {
    try {
      setExportLoading(true);

      const {
        EndDate,
        StartDate,
        PageNumber,
        PageSize,
        SortDir,
        isReviewed,
        ...exportFilters
      } = filters;

      const hasFilters = Object.values(exportFilters).some((val) => val !== "");
      console.log("hasFilters", hasFilters);
      if (hasFilters) {
        await dispatch(
          exportSelectedAsCSVPaymentPalns({
            ...filters,
            isReviewed: ISREVIEWED_STR_TO_NUM[filters.isReviewed],
            StartDate: formatPlanDate(filters.StartDate),
            EndDate: formatPlanDate(filters.EndDate),
          })
        );
      } else {
        await dispatch(exportCSVPaymentPlans());
      }
    } catch (error) {
      console.error(error);
      dispatch(errorMessage("Something went wrong!"));
    } finally {
      setExportLoading(false);
    }
  };

  const selectedTabIndex = ISREVIEWED_STR_TO_NUM[filters.isReviewed];
  //table data
  const poCol = [
    {
      columnName: "Purchase Order",
      sort: true,
      sortName: "PurchaseOrderId",
      flexGrow: 2,
      render: (data) => (
        <>
          <Link
            variant="link"
            className="ss-link"
            to={"/purchases/" + data.id}
            style={{ overflowWrap: "break-word" }}
          >
            {data.purchaseOrderId}
          </Link>
        </>
      ),
    },
    {
      columnName: "Date",
      sort: true,
      sortName: "PurchaseOrderDate",
      render: (data) => (
        <>{data?.purchaseOrderDate ? formatDate(data.purchaseOrderDate) : ""}</>
      ),
    },
    {
      columnName: "Vendor",
      render: (data) => (
        <Link
          variant="link"
          className="ss-link"
          to={"/vendors/" + data.vendorId}
          style={{ overflowWrap: "break-word" }}
        >
          {data.vendorName}
          <br />
          {data.vendorId ? data?.vendorId : ""}
        </Link>
      ),
      sort: true,
      sortName: "VendorId",
    },
    {
      columnName: "Name / Account ID",
      sort: true,
      sortName: "AccountId",
      render: (data) => (
        <>
          {" "}
          {data?.autoGenerated === false ? (
            <Link
              variant="link"
              className="ss-link"
              to={"/accounts/" + data.accountId}
            >
              {data.accountName} <br />
              {data.accountId == 0 ? "" : data.accountId}
            </Link>
          ) : (
            <span>
              {data.accountName} <br />
              {data.accountId == 0 ? "" : data.accountId}
            </span>
          )}
        </>
      ),
    },
    {
      columnName: "Season",
      columnKey: "seasonName",
      render: (row) => <>{row?.seasonName}</>,
      //  sort: true,
      // sortName: 'SeasonName',
      // render: (data) => <>{data.}</>,
    },
    {
      columnName: "Description",
      sort: true,
      sortName: "Description",
      flexGrow: 2,
      render: (data) => <>{data.description}</>,
    },
    {
      columnName: (
        <>
          SkyBox <br /> Ref{" "}
        </>
      ),
      sort: true,
      sortName: "SkyBoxRef",
      render: (row, ind, open, setOpen, getPurchaseOrderRefsData, data) => (
        <div>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (open[ind]) {
                setOpen(Array(data?.length).fill(false));
              } else {
                const temp = Array(data?.length).fill(false);
                temp[ind] = !temp[ind];
                setOpen(temp);
              }
              if (!open[ind]) {
                getPurchaseOrderRefsData(row?.id);
              }
            }}
          >
            <span style={{ fontSize: "14px" }}>{row.skyBoxRef}</span>
            <img
              src={open[ind] ? Up : Down}
              style={{ marginLeft: "5px" }}
              alt="up down image"
            />
          </IconButton>
        </div>
      ),
    },
    {
      columnName: "SkyBox Value",
      render: (data) => <>{formatMoney(data?.skyboxRefAmount)}</>,
    },
    {
      columnName: "Total Scheduled",
      sort: true,
      sortName: "TotalScheduled",
      render: (data) => <>{formatMoney(data.totalScheduled)}</>,
    },
    {
      columnName: (
        <>
          {" "}
          Total <br /> Paid{" "}
        </>
      ),
      render: (data) => <>{formatMoney(data.totalPaid)}</>,
    },
    {
      columnName: "Actions",
      flexGrow: 2,
      render: (data) => (
        <>
          <small className="table-row-card-actions d-flex gap-1">
            <Button
              variant="link"
              className="table-action-button green_bg"
              onClick={() => {
                dispatch(changePOEditState(true));
                setSelectedRecordId(data.id);
                setSelectedRecord(data);
                setAddEditPOModalVisibility(true);
              }}
            >
              <img
                style={{ height: "100%", width: "13px" }}
                src={EditIcon}
                alt="Edit Purchase Orders"
              />
            </Button>

            <Button
              variant="link"
              className="table-action-button"
              style={{
                display: "grid",
                placeContent: "center",
                backgroundColor: "transparent",
              }}
              onClick={() => {
                setSelectedRecordId(data.id);
                setSelectedRecord(data);
                setHistoryModalVisibility(true);
              }}
            >
              <img
                style={{ height: "auto", width: "25px" }}
                src={HistoryIcon}
                alt="Edit Purchase Orders"
              />
            </Button>

            {data?.autoGenerate === false && (
              <Button
                variant="link"
                className="table-action-button"
                onClick={() => {
                  setSelectedRecordId(data.id);
                  setSelectedRecord(data);
                  setDeleteConfirmationVisibility(true);
                }}
              >
                <img
                  style={{ height: "100%", width: "13px" }}
                  src={DeleteIcon}
                  alt="Delete Purchase Orders"
                />
              </Button>
            )}
            {((selectedTabIndex == 0 && data?.showInNoSkyBoxRef == false) ||
              selectedTabIndex == 1) && (
              <Button
                variant="link"
                className="table-action-button"
                style={{
                  display: "grid",
                  placeContent: "center",
                  backgroundColor: "transparent",
                }}
                title={
                  selectedTabIndex == 0
                    ? 'Move to "no skybox ref"'
                    : 'Move to "all"'
                }
                onClick={() => {
                  setSelectedRecordId(data.id);
                  setSelectedRecord(data);
                  setMoveConfirmationVisible(true);
                }}
              >
                <img
                  style={{ height: "auto", width: "30px" }}
                  src={FileMoveIcon}
                  alt={
                    selectedTabIndex == 0
                      ? 'Move to "no skybox ref"'
                      : 'Move to "all"'
                  }
                />
              </Button>
            )}
          </small>
        </>
      ),
    },
  ];

  //table head options
  const headOptions = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small>Results per Page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue={filters.PageSize}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  PageSize: e.target.value,
                });
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div>
          <div className="text-end mt-3 d-flex">
            {selectedTabIndex === 0 && (
              <Button
                variant="primary"
                style={{ textWrap: "nowrap" }}
                className="mx-2 ss-light-button"
                disabled={Runrules}
                onClick={async (e) => {
                  e.currentTarget.blur();
                  Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                    buttonName: mixpanel_button_name.RUNRULES,
                    page: mixpanel_contants.TRANSACTIONS,
                  });
                  await callRunRules();
                  resetHandler();
                }}
              >
                {Runrules ? <Loader /> : <img src={Run} alt=" " />}
                <span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    Run Rules
                  </div>
                </span>
              </Button>
            )}

            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-autoclose-false"
                className="button ss-light-button"
                disabled={exportLoading}
              >
                <img className="filter-reset-svg" src={Export} alt=" " />
                <span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    Export
                    {exportLoading && <Loader style={{ marginLeft: "8px" }} />}
                  </div>
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    exportSelectedAsCSV();
                    Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                      buttonName: mixpanel_button_name.EXPORT_SELECTED_AS_CSV,
                      page: mixpanel_contants.PURCHASEORDERS,
                    });
                  }}
                >
                  Export as CSV
                </Dropdown.Item>
                <hr className="hr-full" />
                <Dropdown.Item
                  onClick={() => {
                    onExportSelectedAsPdf();
                    Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                      buttonName: mixpanel_button_name.EXPORT_SELECTED_AS_PDF,
                      page: mixpanel_contants.PURCHASEORDERS,
                    });
                  }}
                >
                  Export as PDF
                </Dropdown.Item>
                <hr className="hr-full" />
                <Dropdown.Item
                  onClick={() => {
                    exportSelectedAsCSVPaymentPlans();
                    Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                      buttonName:
                        mixpanel_button_name.EXPORT_SELECTED_PAYMENT_PLAN_AS_CSV,
                      page: mixpanel_contants.PURCHASEORDERS,
                    });
                  }}
                >
                  Export Payment Plans as CSV
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              onClick={async () => {
                dispatch(changePOEditState(false));
                await setSelectedRecord(undefined);
                await setSelectedRecordId(undefined);
                await setAddEditPOModalVisibility(true);
                Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                  buttonName: mixpanel_button_name.ADD_PO,
                  page: mixpanel_contants.PURCHASEORDERS,
                });
              }}
              variant="primary"
              className="ms-2 ss-light-button"
            >
              <img src={Add} alt=" " />
              <span>Add</span>
            </Button>
            <Button
              onClick={() => {
                resetHandler();
                dispatch(successMessage("Refresh successful"));
              }}
              variant="primary"
              className="button ss-light-button"
            >
              <img className="filter-reset-svg" src={Reset} alt=" " />
              <span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  Refresh
                </div>
              </span>
            </Button>
          </div>
          <p
            style={{
              visibility: timestamp == null && "hidden",
              textAlign: "end",
              fontSize: "12px",
              lineHeight: 0,
              paddingTop: "16px",
              color: "#00000090",
            }}
          >
            Last sync {new Date(timestamp).toLocaleDateString()}{" "}
            {new Date(timestamp).toLocaleTimeString()}
          </p>
        </div>
      </>
    );
  };

  return (
    <div
      onClick={() => {
        Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
          page: mixpanel_contants.PURCHASEORDERS,
        });
      }}
      className="container-fluid pt-3"
    >
      <DateFilter
        monthArray={MONTH_ARRAY}
        monthSelect={monthSelect}
        setMonthSelect={setMonthSelect}
        setEndDate={(date) => {
          setFilters((prevFilters) => ({
            ...prevFilters,
            EndDate: date,
          }));
        }}
        setStartDate={(date) => {
          setFilters((prevFilters) => ({
            ...prevFilters,
            StartDate: date,
          }));
        }}
        startDate={filters.StartDate}
        endDate={filters.EndDate}
        loadButtonAction={loadPurchaseOrderTransactionCards(!callCardsApi)}
        showLoadButton={true}
        loadButtonText={"Load Card Values"}
      />

      {/* Summary Cards */}
      <CardPanel data={cardsData} loader={cardsLoading} />

      {/* Filter Panel */}
      <FilterPanelPO filters={filters} setFilters={setFilters} />

      {/* Data Table */}
      <CollapsibleTable
        loading={loading}
        align={"left"}
        previousPage={previousPage}
        nextPage={nextPage}
        paramerterNavigation={true}
        headerName="PURCHASE ORDERS"
        paging={paging}
        pageType="purchase-orders"
        columns={poCol}
        data={purchaseOrders || []}
        headOptions={headOptions}
        tabs={["All", "No Skybox Ref", "Revoked"]}
        selectedTabIndex={ISREVIEWED_STR_TO_NUM[filters.isReviewed]}
        onTabSelectionChange={(i) => {
          setFilters({
            ...filters,
            isReviewed: ISREVIEWED[i],
            PageNumber: 1,
          });
        }}
        setBusy={setBusy}
        onChangeFieldDirection={({ sortField, sortDirection }) => {
          setFilters({
            ...filters,
            SortField: sortField,
            SortDir: sortDirection ? "Ascending" : "Descending",
          });
        }}
        sortField={filters.SortField}
        sortDir={filters.SortDir === "Ascending" || filters.SortDir === ""}
        setSortField={() => {}}
        setSortDir={() => {}}
        setpageNumber={() => {}}
        renderRow={(rowData) => (
          <TableRowCard
            key={rowData.id}
            columns={rowData.nodata ? columnError : poCol}
            rowData={rowData}
          />
        )}
      />

      {/* MODALS */}
      {addPOModalVisible && (
        <AddEditPOModal
          isVisible={addPOModalVisible}
          handleClose={() => setAddEditPOModalVisibility(false)}
          onSubmit={addEditHandler}
          selectedRecord={selectedRecord}
          setModalOpen={setAddSeasonModalVisibility}
          setSelectedRecord={setSelectedRecord}
          setBusy={setBusy}
        />
      )}

      {historyModalVisible && (
        <HistoryPO
          isVisible={historyModalVisible}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          handleClose={() => setHistoryModalVisibility(false)}
        />
      )}

      {deleteConfirmationVisible && (
        <DeleteConfirmationModal
          isVisible={deleteConfirmationVisible}
          handleClose={() => setDeleteConfirmationVisibility(false)}
          onConfirmation={() => onDeletePurchaseOrder(selectedRecordId)}
        />
      )}

      {moveConfirmationVisible && (
        <MovePOConfirmationModal
          isVisible={moveConfirmationVisible}
          handleClose={() => {
            setMoveConfirmationVisible(false);
          }}
          selectedRecord={selectedRecord}
          onConfirmation={movePOHandler}
        />
      )}

      {addSeasonModalVisibility && (
        <AddEditSeasonModal
          isVisible={addSeasonModalVisibility}
          onSubmit={addSeason}
          handleClose={() => {
            setAddSeasonModalVisibility(false);
            setAddEditPOModalVisibility(true);
          }}
        />
      )}

      {matchedVisibility && (
        <SelectMatch
          isVisible={matchedVisibility}
          setIsVisible={setmatchedVisibility}
          handleClose={() => setmatchedVisibility(false)}
          match={true}
          selectedRecord={selectedRecord}
        />
      )}
    </div>
  );
};

export default withLoader(PurchaseOrderDashboard);
