import { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import Not from "../../../images/notDone.svg";
import Select from "react-select";
import Loader from "../../../shared/components/loader/Loader";

const LinkModal = ({
  loading,
  isVisible,
  handleClose,
  onSubmit,
  selectedRecord,
}) => {
  const [isEdit, setEdit] = useState(false);

  // setup react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm();

  const [dropVal, setdropVal] = useState({
    name: 0,
    vendor: 0,
  });

  const [accounts, setAccounts] = useState("");
  const [crossClicked, setCrossClicked] = useState(false);

  const { vendorsListForDropdown: vendorDetails } = useSelector(
    (state) => state.cachedVendors
  );

  const accountsDetails = useSelector((state) => state.accounts).accountsList;
  const accountListByVendor = useSelector(
    (state) => state.accounts
  ).accountListByVendor;

  const ignoreTransactionObj = {
    accountId: 9090908,
    name: "Ignore Transactions",
  };

  const customStyles = {
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };

  // update values in form if selected record is changed
  useEffect(() => {
    reset();
    if (!isVisible) setdropVal({ name: 0, vendor: 0 });
    if (selectedRecord) {
      setdropVal({
        vendor: selectedRecord.vendorId
          ? vendorDetails.filter(
              (vendor) => vendor?.id == selectedRecord.vendorId
            )[0]
          : 0,
        name: selectedRecord?.accountId
          ? accountsDetails.filter(
              (account) => account.accountId == selectedRecord.accountId
            )[0]
          : 0,
      });

      if (selectedRecord.vendorId !== 0) {
        const filteredAccounts = accountsDetails.filter(
          (account) => account.vendorId == selectedRecord.vendorId
        );
        const selectedVendorDetails = vendorDetails?.filter(
          (x) => x.id == selectedRecord.vendorId
        );
        if (
          selectedVendorDetails &&
          selectedVendorDetails.length > 0 &&
          selectedVendorDetails[0].categoryId == 3
        ) {
          filteredAccounts.push(ignoreTransactionObj);
        }
        setAccounts(filteredAccounts);
      }
    }
  }, [isVisible, selectedRecord]);

  const handleClear = () => {
    reset();
    setdropVal({
      name: 0,
      vendor: 0,
    });
    setAccounts("");
    setCrossClicked(true);
  };

  const validateSubmit = () => {
    setCrossClicked(false);
    onSubmit(selectedRecord, dropVal);
  };

  const handleClose1 = () => {
    setdropVal({
      name: 0,
      vendor: 0,
    });
    setAccounts("");
    handleClose();
    setCrossClicked(false);
  };

  return (
    <>
      <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose1}>
        <Form onSubmit={handleSubmit(validateSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>TRANSACTIONS</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/transactions",
                name: "Transactions",
                active: false,
              },
              {
                name: isEdit ? "Edit Link" : "New Link",
                active: true,
              },
            ]}
          />
          <Modal.Body>
            <div className="container-fluid p-2 w-100 h-100">
              <Row>
                <Col sm={5}>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4}>
                      Vendor
                    </Form.Label>
                    <Col sm={7}>
                      <Controller
                        name="vendorId"
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <>
                              <Select
                                isInvalid={!!errors.vendorId}
                                value={dropVal?.vendor}
                                onChange={async (e) => {
                                  field.onChange(e?.id);
                                  setdropVal({
                                    vendor: e,
                                    name:
                                      selectedRecord?.accountId == 9090909 &&
                                      !crossClicked
                                        ? {
                                            accountId: 9090909,
                                            categoryId: 1,
                                            email: "default@email.com",
                                            name: "Default Account",
                                            vendorId: e?.id,
                                          }
                                        : 0,
                                  });

                                  const filteredAccounts =
                                    accountListByVendor.filter(
                                      (t) => t.vendorId == e?.id
                                    );

                                  const filterIgnoreTransactionAccount =
                                    filteredAccounts.filter(
                                      (x) => x.accountId == 9090908
                                    );
                                  if (
                                    e.categoryId == 3 &&
                                    filterIgnoreTransactionAccount?.length == 0
                                  ) {
                                    filteredAccounts.push(ignoreTransactionObj);
                                  }

                                  setAccounts(filteredAccounts);
                                }}
                                styles={customStyles}
                                options={vendorDetails}
                                getOptionLabel={(option) =>
                                  option.id + " " + option.name
                                }
                              />
                              {invalid && error ? (
                                <div className="custom-invalid-feedback">
                                  Vendor is required.
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                    </Col>
                    <Col xs={1}>
                      <Button
                        variant="light"
                        onClick={() => {
                          setAccounts("");
                          setdropVal({ ...dropVal, vendor: 0 });
                        }}
                      >
                        <img src={Not} width={15} height={15} />
                      </Button>
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm={1} />
                <Col sm={5}>
                  <Form.Group as={Row}>
                    <Form.Label column sm={5}>
                      Account
                    </Form.Label>
                    <Col sm={7}>
                      <Controller
                        isInvalid={!!errors.accountId}
                        name="accountId"
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <>
                              <Select
                                isDisabled={
                                  selectedRecord?.accountId == 9090909 &&
                                  !crossClicked
                                    ? true
                                    : false
                                }
                                isInvalid={!!errors.teamId}
                                value={
                                  selectedRecord?.accountId == 9090909 &&
                                  !crossClicked
                                    ? {
                                        accountId: 9090909,
                                        categoryId: 1,
                                        email: "default@email.com",
                                        name: "Default Account",
                                        vendorId: dropVal?.vendor?.id,
                                      }
                                    : dropVal?.name
                                }
                                onChange={async (e) => {
                                  field.onChange(e?.id);
                                  setdropVal({ ...dropVal, name: e });
                                }}
                                styles={customStyles}
                                options={
                                  accounts === "" ? accountsDetails : accounts
                                }
                                getOptionLabel={(option) =>
                                  option?.accountId + " " + option?.name
                                }
                              />
                              {invalid && error ? (
                                <div className="custom-invalid-feedback">
                                  Account is required.
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                    </Col>
                    <Form.Control.Feedback type="invalid">
                      Name on Account is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={1}>
                  <Button
                    variant="light"
                    onClick={(e) => {
                      e.preventDefault();
                      setCrossClicked(true);
                      setdropVal({ ...dropVal, name: 0 });
                    }}
                  >
                    <img src={Not} width={15} height={15} />
                  </Button>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <div className="d-flex justify-content-center my-4">
            <Button
              type="submit"
              variant="primary"
              className="ss-modal-primary-btn mx-2"
              disabled={
                (selectedRecord?.accountId === dropVal?.name?.accountId ||
                  selectedRecord?.accountId === dropVal?.name) &&
                (selectedRecord?.vendorId === dropVal?.vendor?.id ||
                  selectedRecord?.vendorId === dropVal?.vendor)
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Update
                {loading && <Loader style={{ marginLeft: "8px" }} />}
              </div>
            </Button>
            <Button
              variant="light"
              onClick={() => handleClear()}
              className="ss-modal-secondary-btn mx-2"
            >
              Clear
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default LinkModal;
