import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import fileDownload from "js-file-download";
import { accountLocationServices } from "../../services/accounts/account-location.service";

export const createAccountLocation = createAsyncThunk(
  "accounts/createAccountLocation",
  async (payload, thunkAPI) => {
    const response = await accountLocationServices.createAccountLocation(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const updateAccountLocation = createAsyncThunk(
  "accounts/updateAccountLocation",
  async (payload, thunkAPI) => {
    const response = await accountLocationServices.updateAccountLocation(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const deleteAccountLocation = createAsyncThunk(
  "accounts/deleteAccountLocation",
  async (payload, thunkAPI) => {
    const response = await accountLocationServices.deleteAccountLocation(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAccountLocationHistory = createAsyncThunk(
  "accounts/getAccountLocationHistory",
  async (payload, thunkAPI) => {
    const response = await accountLocationServices.getAccountLocationHistory(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAllAccountLocationDetails = createAsyncThunk(
    "accounts/getAllAccountLocationDetails",
    async (payload, thunkAPI) => {
      const response = await accountLocationServices.getAllAccountLocationDetails(payload);
      if (response.isSuccessful === true) {
        return response;
      } else {
        thunkAPI.rejectWithValue(response.message);
        throw new Error(response.message);
      }
    }
  );
  


const accountLocationSlice = createSlice({
  name: "accountLocationDetails",
  initialState: {
    paging: {},
    accountLocationRecords: [],
    accountLocationHistory: [],
    historyPaging: {}
  },
  extraReducers: {
    [getAccountLocationHistory.fulfilled]: (state, action) => {
        state.accountLocationHistory = action.payload.data;
        state.historyPaging = action.payload.paging
    //   state.accountsPurchaseOrders = action.payload.data;
    //   state.paging = action.payload.paging;
    },
    [getAllAccountLocationDetails.fulfilled]: (state, action) => {
        state.accountLocationRecords = action.payload.data;
        state.paging = action.payload.paging
    },
   
  },
});

export default accountLocationSlice.reducer;
