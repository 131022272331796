import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SkyboxServices } from "../../services/skybox-purchases/skybox-purchases.service";
import fileDownload from "js-file-download";
import { flushSkyboxPurchases } from "./cached-skybox-purchases.reducer";

export const getAllSkybox = createAsyncThunk(
  "Skybox/getAllSkybox",
  async (payload, thunkAPI) => {
    const response = await SkyboxServices.getAllSkybox(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getListSkybox = createAsyncThunk(
  "Skybox/getListSkybox",
  async (payload, thunkAPI) => {
    const response = await SkyboxServices.getListSkybox(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getListSkyboxForMultipeRefs = createAsyncThunk(
  "Skybox/getListSkyboxForMultipeRefs",
  async (payload, thunkAPI) => {
    const response = await SkyboxServices.getListSkybox(payload.input);
    if (response.isSuccessful === true) {
      return { id: payload.id, response: response };
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const syncSkybox = createAsyncThunk(
  "sync/Skybox",
  async (payload, thunkAPI) => {
    const response = await SkyboxServices.getSyncSkybox(payload);
    thunkAPI.dispatch(flushSkyboxPurchases());
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const filterSkybox = createAsyncThunk(
  "Skybox/filterSkybox",
  async (payload, thunkAPI) => {
    const response = await SkyboxServices.filterSkybox(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getPurchaseOrderRefs = createAsyncThunk(
  "Skybox/getPurchaseOrderRefs",
  async (payload, thunkAPI) => {
    const response = await SkyboxServices.getPurchaseOrderRefs(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

const SkyboxSlice = createSlice({
  name: "skybox",
  initialState: {
    skyboxPurchases: [],
    paging: {},
    syncDate: "",
    skyboxList: [],
    skyboxMultipleRefData: {},
    skyBoxRefData: [],
  },
  reducers: {
    resetskyBoxRefData: (state, action) => {
      state.skyboxMultipleRefData = [];
    },
  },
  extraReducers: {
    [getAllSkybox.fulfilled]: (state, action) => {
      state.skyboxPurchases = action.payload.data;
      state.paging = action.payload.paging;
    },
    [getListSkybox.fulfilled]: (state, action) => {
      state.skyboxList = action.payload.data;
    },
    [getPurchaseOrderRefs.fulfilled]: (state, action) => {
      state.skyBoxRefData = action.payload.data;
    },
    [getListSkyboxForMultipeRefs.fulfilled]: (state, action) => {
      state.skyboxMultipleRefData = {
        ...state.skyboxMultipleRefData,
        [action.payload.id]: action.payload.response.data,
      };
    },
    [filterSkybox.fulfilled]: (state, action) => {
      state.skyboxPurchases = action.payload.data;
      state.paging = action.payload.paging;
    },
    [syncSkybox.fulfilled]: (state, action) => {
      state.syncDate = action.payload.data;
    },
  },
});
export const { resetskyBoxRefData } = SkyboxSlice.actions;

export default SkyboxSlice.reducer;
