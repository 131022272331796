import React, { useState, useEffect } from "react";
import "./biReport.scss";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllBIReport } from "../../data/reducers/bi-report/bi-report.reducers";
import {
  Mixpanel,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../mixpanel";

const BIReportDashboard = () => {
  const { report } = useSelector((state) => state.biReport);
  const dispatch = useDispatch();
  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.BI_REPORT,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.BI_REPORT,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);
  useEffect(() => {
    dispatch(getAllBIReport());
  }, []);
  return (
    <Container className="bi-container">
      {report && (
        <iframe
          className="iframe"
          src={report.reportSrc || ""}
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      )}
    </Container>
  );
};

export default BIReportDashboard;
