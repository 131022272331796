export const todayDate = () => {
  const date = new Date();
  let d = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  let hours = date.getHours();
  let minutes = date.getMinutes();


  if (d < 10) {
    d = "0" + d;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  return `${year}-${month}-${d}T${hours}:${minutes}`;

};


export const formatDateAndTime = (date) => {
  let d = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  let hours = date.getHours();
  let minutes = date.getMinutes();


  if (d < 10) {
    d = "0" + d;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  return `${month}-${d}-${year}T${hours}:${minutes}`;

};