import React from 'react'
import { Modal, Button, Row, Col, Form, InputGroup } from "react-bootstrap";

const AddRecoveryEmail = ({show, onClose, onSubmit}) => {

    const [formValidated, setFormValidated] = React.useState(false);
    const [recoveryEmail, setRecoveryEmail] = React.useState("")

    React.useEffect(() => {
        if(show){
            setRecoveryEmail("");
            setFormValidated(false);
        }
    }, [show])

    const onSubmitData = (e) => {
        e.preventDefault();
        const formValidity = e.currentTarget.checkValidity();
        if(formValidity){
            onSubmit(recoveryEmail)
        } else{
            setFormValidated(true)
        }
    }

  return (
    <Modal
		dialogClassName="modal-xl"
		show={show}
		onHide={onClose}
	>
        <Form noValidate validated={formValidated} onSubmit={onSubmitData}>
            <Modal.Header closeButton>
				<Modal.Title>Add Recovery Email</Modal.Title>
			</Modal.Header>
            <Modal.Body>
            <div className="container-fluid p-2 w-100 h-100"> 
                <Row>
                    <Col sm={6}>
                    <Form.Group>
						<Form.Label>
                            Recovery Email
						</Form.Label>
						<Form.Control
                            type="email"
							placeholder="Enter Email Address"
                            onFocus={e => e.target.select()}
                            onChange={e => setRecoveryEmail(e.target.value)}
                            value={recoveryEmail}
                            required
                            name="address"
							/>
                        <Form.Control.Feedback
                        type="invalid"
                        data-testid="add-entry-error-message"
                        >
                            {!recoveryEmail ? "Recovery Email Required" : "Enter Valid Recovery Email"}
                        </Form.Control.Feedback>
					</Form.Group>
                    </Col>
                </Row>
            </div>
            </Modal.Body>
            <div className="d-flex justify-content-center my-4">
						<Button
							type="submit"
							variant="primary"
							className="ss-modal-primary-btn mx-2"
						>
							Add
						</Button>
						<Button
							variant="light"
                            onClick={() => setRecoveryEmail("")}
							className="ss-modal-secondary-btn mx-2"
						>
							{"Clear"}
						</Button>
			</div>
        </Form>

    </Modal>
  )
}

export default AddRecoveryEmail