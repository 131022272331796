import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { vendorPurchaseOrdersServices } from "../../services/vendors/vendor-purchaseOrders.service";
import fileDownload from "js-file-download";
import { flushPurchaseOrders } from "../purchaseOrders/cached-po.reducer";
import { flushPurchaseOrdersCards } from "../purchaseOrders/cached-po-cards.reducer";

export const exportCSVVendorPurchase = createAsyncThunk(
  "vendorPurchaseOrders/exportCSVVendorPurchase",
  async (payload, thunkAPI) => {
    const response = await vendorPurchaseOrdersServices.exportCSVVendorPurchase(
      payload
    );
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "VendorPurchaseOrders.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportPdfVendorPurchase = createAsyncThunk(
  "vendorPurchaseOrders/exportPdfVendorPurchase",
  async (payload, thunkAPI) => {
    const response = await vendorPurchaseOrdersServices.exportPdfVendorPurchase(
      payload
    );
    // if (response.isSuccessful === true) {
    //   fileDownload(response.data, "VendorPurchaseOrders.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

export const createVendorPurchaseOrders = createAsyncThunk(
  "vendorPurchaseOrders/createVendorPurchaseOrders",
  async (payload, thunkAPI) => {
    const response =
      await vendorPurchaseOrdersServices.createVendorPurchaseOrder(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushPurchaseOrders());
      thunkAPI.dispatch(flushPurchaseOrdersCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);
export const getAllVendorPurchaseOrders = createAsyncThunk(
  "vendorPurchaseOrders/getAllVendorPurchaseOrders",
  async (payload, thunkAPI) => {
    const response =
      await vendorPurchaseOrdersServices.getAllVendorPurchaseOrders(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);
export const updateVendorPurchaseOrders = createAsyncThunk(
  "vendorPurchaseOrders/updateVendorPurchaseOrders",
  async (payload, thunkAPI) => {
    const response =
      await vendorPurchaseOrdersServices.updateVendorPurchaseOrder(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushPurchaseOrders());
      thunkAPI.dispatch(flushPurchaseOrdersCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);
export const deleteVendorPurchaseOrders = createAsyncThunk(
  "vendorPurchaseOrders/deleteVendorPurchaseOrders",
  async (payload, thunkAPI) => {
    const response =
      await vendorPurchaseOrdersServices.deleteVendorPurchaseOrder(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushPurchaseOrders());
      thunkAPI.dispatch(flushPurchaseOrdersCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);
export const getVendorPurchaseOrders = createAsyncThunk(
  "vendorPurchaseOrders/getVendorPurchaseOrders",
  async (payload, thunkAPI) => {
    const response =
      await vendorPurchaseOrdersServices.getAllVendorPurchaseOrders(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

const vendorPurchaseOrdersSlice = createSlice({
  name: "vendorPurchaseOrders",
  initialState: {
    vendorsPurchaseOrders: [],
    paging: {},
    selectedVendorPurchaseOrder: {},
  },
  reducers: {
    removeSelected: (state) => {
      state.selectedVendorPurchaseOrder = {};
    },
  },
  extraReducers: {
    [getVendorPurchaseOrders.fulfilled]: (state, action) => {
      state.vendorsPurchaseOrders = action.payload.data;
      state.paging = action.payload.paging;
    },
    [createVendorPurchaseOrders.fulfilled]: (state, action) => {
      state.vendorsPurchaseOrders.push(action.payload);
    },
    [updateVendorPurchaseOrders.fulfilled]: (state, action) => {
      const index = state.vendorsPurchaseOrders.findIndex(
        (v) => v.id == action.payload.id
      );
      state.vendorsPurchaseOrders[index] = action.payload;
      state.selectedVendorPurchaseOrder = action.payload;
    },
    [deleteVendorPurchaseOrders.fulfilled]: (state, action) => {
      state.vendorsPurchaseOrders = [
        ...state.vendorsPurchaseOrders.filter(
          (vendor) => vendor.id !== action.payload.id
        ),
      ];
    },
  },
});
export const { removeSelected } = vendorPurchaseOrdersSlice.actions;
export default vendorPurchaseOrdersSlice.reducer;
