const Loader = ({ size, style }) => {
  let _size = "20px";
  switch (size) {
    case "sm":
      _size = "10px";
      break;
    case "md":
      _size = "20px";
      break;
    case "lg":
      _size = "30px";
      break;
    case "xl":
      _size = "40px";
      break;
  }

  return (
    <div
      className="loader"
      style={{
        border: "4px solid #f3f3f3",
        borderTop: "4px solid rgb(0, 52, 142)",
        borderRadius: "50%",
        width: _size,
        height: _size,
        ...style,
      }}
    />
  );
};

export default Loader;
