import {
  api,
  getAuthHeaders,
  post,
  update,
  del,
  get,
  getCSV,
  getPDF
} from "../services.common";

const getAllMarket = async (data) => {
  const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
  const url = `${api}RevealMarket/getall?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const filterMarket = async (data) => {
  const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
  const url = `${api}RevealMarket/search?searchString=${data.searchText || ""}&Invoice=${data.invoice || ""}&Date=${data.date || ""}&Performer=${data.performer || ""}&Fullfillment=${data.fullfillment || ""}&EventDate=${data.eventDate || ""}&Customer=${data.customer || ""}&Payment=${data.payment || ""}&Balance=${data.balance || ""}&EventStatus=${data.eventStatus || ""}&ExternalRef=${data.externalRef || ""}&PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
}

const uploadTemplateMarket = async (payload) => {
	const url = `${api}RevealMarket/importrevealmarkettemplatecsv`;
	const authHeader = getAuthHeaders();
	return await post(url, payload, {
		...authHeader,
		"Content-Type": "multipart/form-data",
	});
};

const downloadTemplateMarket = async () => {
	const url = `${api}/RevealMarket/revealmarkettemplatecsv`;
	const authHeader = getAuthHeaders();
	return await getCSV(url, { ...authHeader });
};

export const RevealMarketServices = {
    getAllMarket,
    uploadTemplateMarket,
  downloadTemplateMarket,
    filterMarket
};
