const columnError =
  [{
    render: () => {
      return (
        <>
          <h5>No record to display</h5>
        </>
      );
    }
  }];

export { columnError };