import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";

// images
import Usrimg from "../../../images/userimg.png";
import LogImg from "../../../images/log_out.svg";
import ProImg from "../../../images/user.svg";
import { Mixpanel } from "../../../mixpanel";
// redux
import { logoutUser } from "../../../data/reducers/authentication/auth.reducer";

const Header = () => {
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logoutUser());
    Mixpanel.reset();
  };
  return (
    <div
      className="d-flex header-ss w-100 justify-content-between py-1 align-items-center"
      style={{ zIndex: 2 }}
    >
      <div className="brand d-flex align-items-center">
        <h4 className="brand-ss d-flex align-items-center justify-content-center">
          <span className="text-primary">Salem</span>
          <span className="mx-1 text-secondary">Seats</span>
        </h4>
        <h4 className="brand-quicktix mx-3">Quicktix</h4>
      </div>
      <div className="user-dropdown">
        <Dropdown>
          <Dropdown.Toggle variant="link">
            <img src={Usrimg} className="profile-image"></img>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              <div className="p-2">
                <img src={ProImg} alt=" " />
                <span className="ps-2">My Profile</span>
              </div>
            </Dropdown.Item>
            <Dropdown.Item>
              <div onClick={logoutHandler} className="p-2">
                <img src={LogImg} alt=" " />
                <span className="ps-2">Log Out</span>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default Header;
