import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import { errorMessage } from "../alerts/alert.reducer";

export const loadPurchaseOrderTransactionCards = createAsyncThunk(
  "cachedPurchaseOrdersCards/purcahseOrdersCards",
  async (params) => {
    return params
  }
)

const cachedPurchaseOrdersCardsSlice = createSlice({
  name: "cachedPurchaseOrdersCards",
  initialState: {
    data: null,
    loading: false,
    error: null,
    callCardsApi: null
  },
  reducers: {
    fetchDataSuccess: (state, action) => {
      const { params, response } = action.payload;
      if (!state.data) {
        state.data = {
          [JSON.stringify(params)]: {
            cards: response.data,
          },
        };
      } else {
        state.data[JSON.stringify(params)] = {
          cards: response.data,
        };
      }
      state.loading = false;
    },
    fetchDataFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    flushStore: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
    },
    loadingStart: (state) => {
      state.loading = true;
    },
    loadingEnd: (state) => {
      state.loading = false;
    },

  },
  extraReducers: {
    [loadPurchaseOrderTransactionCards.fulfilled]: (state, action) => {
      state.callCardsApi = action.payload
    }
  }
});

export const flushPurchaseOrdersCards = createAsyncThunk(
  "cachedPurchaseOrdersCards/flush",
  async (params, { dispatch }) => {
    dispatch(cachedPurchaseOrdersCardsSlice.actions.flushStore());
  }
);

export const fetchPurchaseOrdersCards = createAsyncThunk(
  "cachedPurchaseOrdersCards/get",
  async (params, { dispatch }) => {
    try {
      dispatch(cachedPurchaseOrdersCardsSlice.actions.loadingStart());
      const response = await api.get("PurchaseOrdercards/get", params);
      dispatch(
        cachedPurchaseOrdersCardsSlice.actions.fetchDataSuccess({
          params,
          response,
        })
      );
    } catch (error) {
      dispatch(
        cachedPurchaseOrdersCardsSlice.actions.fetchDataFailure(error.message)
      );
      dispatch(errorMessage(error?.message ?? "Something went wrong!"));
    }
  }
);

export default cachedPurchaseOrdersCardsSlice.reducer;
