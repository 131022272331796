import ModalBreadcrumb from "../../../../shared/components/modals/ModalBreadcrumb";
import {
  getAddressList,
  getAddressByID,
} from "../../../../data/reducers/addressBook/addressBook.reducer";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { withLoader } from "../../../../utils/hoc/withLoader";
import Add from "../../../../images/add.svg";
import { getCardTypeDetails } from "../../../../data/reducers/cards/cards.routes";

const AddEditCardModal = ({
  setBusy,
  isVisible,
  handleClose,
  isWEX,
  setIsWEX,
  onSubmit,
  selectedRecord,
  setSelectedRecord,
  setAddEditAddressModal,
  added,
  setAdded,
}) => {
  const dispatch = useDispatch();

  // setup react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    getValues,
  } = useForm();

  const addressDetails = useSelector((state) => state.addressBook).addressList;

  const [isEdit, setIsEdit] = useState(false);
  const [address, setAddress] = useState("");
  const [dropCC, setDropCC] = useState({ value: "WEX", label: "WEX" });
  const [cardType, setCardType] = useState(null);

  const { cardTypeLookup } = useSelector((state) => state.cards);

  useEffect(() => {
    //resetting cardtype whenever ccAccount is changed
    setCardType(null);
  }, [isWEX]);

  // reset form on visibility toggle
  useEffect(() => {
    reset();
    //-----------------------------------------------------------//
    setIsWEX(true);
    setValue("ccAccount", "WEX");
    //-----------------------------------------------------------//
    setAddress("");
    dispatch(getAddressList());
    setCardType(null);
  }, [isVisible]);

  useEffect(() => {
    dispatch(getCardTypeDetails());
  }, []);

  useEffect(() => {
    if (selectedRecord) {
      if (selectedRecord?.entered) {
        setIsWEX(true);
        setDropCC({ label: "WEX", value: "WEX" });
        setValue("firstName", selectedRecord?.firstName, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue("lastName", selectedRecord?.lastName, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue("emailId", selectedRecord?.emailId, {
          shouldValidate: true,
          shouldDirty: true,
        });
      } else {
        setIsEdit(true);
        for (const [key, value] of Object.entries(selectedRecord)) {
          setValue(key, value, {
            shouldValidate: true,
            shouldDirty: true,
          });

          if (key == "ccAccount") setDropCC({ label: value, value });
        }

        if (selectedRecord?.ccAccount === "WEX") {
          if (added) {
            setAddress("");
            setAdded(false);
          } else {
            let temp = addressDetails.filter((add) => {
              return add.id === selectedRecord.addressId;
            });
            setAddress(temp);
            setValue("addressBookId", temp);
          }
        }
      }
    } else {
      setIsEdit(false);
      reset();
    }
  }, [selectedRecord, isVisible]);

  const customStyles = {
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };

  //Handling Reset Button for moda;
  const handleReset = () => {
    for (const [key, value] of Object.entries(selectedRecord)) {
      setValue(key, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    let temp = addressDetails.filter((add) => {
      return add.id === selectedRecord.addressId;
    });
    setAddress(temp);
    setValue("addressBookId", temp);
  };

  //handling clear button for modal
  const handleClear = () => {
    reset();
    setAddress("");
  };

  const openAddress = (e) => {
    e.preventDefault();

    setSelectedRecord({
      ...selectedRecord,
      entered: !isEdit,
      firstName: getValues("firstName"),
      lastName: getValues("lastName"),
      emailId: getValues("emailId"),
    });
    setAddEditAddressModal(true);
    handleClose();
  };

  useEffect(() => {
    if (dropCC) {
      if (dropCC.value !== "") {
        if (dropCC.value === "WEX") setIsWEX(true);
        else setIsWEX(false);
        setValue("ccAccount", dropCC.value);
      }
    }
  }, [dropCC]);

  const handleOnSubmit = (data) => {
    if (typeof data.addressBookId === "number") {
      data.addressId = data.addressBookId;
    } else {
      data.addressId = data.addressBookId[0].id;
    }
    onSubmit(data);
  };

  return (
    <>
      <Modal
        dialogClassName="modal-xl"
        show={isVisible}
        onHide={() => {
          setIsWEX(null);
          handleClose();
        }}
      >
        <Form onSubmit={handleSubmit(handleOnSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>CARDS</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                name: "Fund Sources",
                active: true,
              },
              {
                to: "/fundSources/cards",
                name: "Cards",
                active: false,
              },
              {
                name: isEdit ? "Edit Account" : "Add Account",
                active: true,
              },
            ]}
          />
          <Modal.Body>
            <div className="container-fluid p-2 w-100 h-100">
              {selectedRecord?.entered === false ? (
                <>
                  <Row>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          CCAccount
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            isInvalid={!!errors.ccAccount}
                            {...register("ccAccount", { required: true })}
                            placeholder="ccAccount"
                            readOnly
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col>
                      {isWEX && (
                        <Form.Group as={Row}>
                          <Form.Label column sm="4">
                            Card Type
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control
                              isInvalid={!!errors.ccAccount}
                              {...register("cardType", { required: true })}
                              placeholder="Card Type"
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      )}
                    </Col>
                  </Row>
                  {isWEX !== null && <hr />}
                  <Row>
                    <p className="title-accounts">Basic Information</p>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          First Name
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            isInvalid={!!errors.firstName}
                            {...register("firstName", { required: true })}
                            placeholder="First Name"
                          />
                          <Form.Control.Feedback type="invalid">
                            First Name is required.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          Last Name
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            isInvalid={!!errors.lastName}
                            {...register("lastName", { required: true })}
                            placeholder="Last Name"
                          />
                          <Form.Control.Feedback type="invalid">
                            Last Name is required.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    {isWEX == true && (
                      <Col>
                        <Form.Group as={Row}>
                          <Form.Label column sm="4">
                            Email
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control
                              type="text"
                              isInvalid={!!errors.emailId}
                              {...register("emailId", {
                                pattern:
                                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              })}
                              placeholder="Email"
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid email.
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                      </Col>
                    )}
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          Status
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            {...register("active")}
                            placeholder="Status"
                            as="select"
                          >
                            <option value={true}>Active</option>
                            <option value={false}>Inactive</option>
                          </Form.Control>

                          <Form.Control.Feedback type="invalid">
                            Status is required.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>

                    {isWEX == false && <Col />}
                  </Row>

                  <br />
                  {isWEX == true && (
                    <Row>
                      <Col>
                        <Form.Group as={Row}>
                          <Form.Label column sm="4">
                            Team Name
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control
                              // isInvalid={!!errors.teamName}
                              {...register("teamName")}
                              placeholder="Team Name"
                            />
                            {/* <Form.Control.Feedback type="invalid">
                              Team Name is required.
                            </Form.Control.Feedback> */}
                          </Col>
                        </Form.Group>
                      </Col>

                      <Col />
                    </Row>
                  )}

                  <hr />
                  <Row>
                    <p className="title-accounts">Company Details</p>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          Company
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            isInvalid={!!errors.accountCredits}
                            {...register("company", { required: true })}
                            placeholder="Company"
                            readOnly
                          />
                          <Form.Control.Feedback type="invalid">
                            Company is required.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          CC Number
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            placeholder="CC Number"
                            isInvalid={!!errors.ccNumber}
                            readOnly
                            {...register("ccNumber", { required: true })}
                          />
                          <Form.Control.Feedback type="invalid">
                            CC Number is required.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          Card ID
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            placeholder="Card ID"
                            readOnly
                            {...register("cardId", { required: true })}
                          />
                          <Form.Control.Feedback type="invalid">
                            Card ID is required.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          Card Nickname
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            placeholder="Card Nickname"
                            // readOnly
                            {...register("nickName")}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          Security Code
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            isInvalid={!!errors.securityCode}
                            placeholder="Security Code"
                            readOnly={isWEX}
                            type="number"
                            {...register(
                              "securityCode",
                              !isWEX && {
                                minLength: 3,
                                maxLength: 3,
                              }
                            )}
                          />
                          <Form.Control.Feedback type="invalid">
                            Security Code should be 3 digit long.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          Notes
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            placeholder="Notes"
                            // readOnly
                            {...register("notes")}
                            defaultValue="--"
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  {isWEX === true && (
                    <>
                      <hr />
                      <Row>
                        <p className="title-accounts">Address Information</p>
                        <Col>
                          <Form.Group as={Row}>
                            <Form.Label column sm="2">
                              Address
                            </Form.Label>
                            <Col sm="4">
                              <Controller
                                name="addressBookId"
                                control={control}
                                rules={{ required: true }}
                                render={({
                                  field,
                                  fieldState: { invalid, error },
                                }) => {
                                  return (
                                    <>
                                      <CreatableSelect
                                        required
                                        isInvalid={!!errors.addressBookId}
                                        value={address}
                                        onChange={async (e) => {
                                          if (e != undefined) {
                                            field.onChange(e?.id);
                                            setAddress(e);
                                          }
                                        }}
                                        styles={customStyles}
                                        options={addressDetails}
                                        getOptionLabel={(option) => {
                                          if (
                                            option.address1 != undefined ||
                                            option.address2 != undefined ||
                                            option.city != undefined ||
                                            option.state != undefined ||
                                            option.postalCode != undefined
                                          )
                                            return `${option.address1} ${option.address2} ${option.city} ${option.state} ${option.postalCode}`;
                                        }}
                                      />
                                      {invalid && error ? (
                                        <div className="custom-invalid-feedback">
                                          Address is required.
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  );
                                }}
                              />
                            </Col>
                            <Col xs={1}>
                              <Button onClick={openAddress}>
                                <img src={Add} width={15} height={15} />
                              </Button>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Row>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          CCAccount
                        </Form.Label>
                        <Col sm="7">
                          <Controller
                            name="ccAccount"
                            control={control}
                            rules={{ required: true }}
                            render={({
                              field,
                              fieldState: { invalid, error },
                            }) => {
                              return (
                                <>
                                  <Select
                                    required
                                    defaultValue={{
                                      value: "WEX",
                                      label: "WEX",
                                    }}
                                    onChange={(option) => {
                                      field.onChange(option?.value ?? null);
                                      setDropCC(option);
                                    }}
                                    placeholder="ccAccount"
                                    options={[
                                      { value: "WEX", label: "WEX" },
                                      // {
                                      // 	value: "COMDATA",
                                      // 	label: "COMDATA",
                                      // },
                                      // { value: "Others", label: "Others" },
                                    ]}
                                    styles={customStyles}
                                    isClearable
                                  />
                                  {invalid && error ? (
                                    <div className="custom-invalid-feedback">
                                      Card Type is required.
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            }}
                          />

                          <Form.Control.Feedback type="invalid">
                            Account is required.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col>
                      {isWEX && (
                        <Form.Group as={Row}>
                          <Form.Label column sm="4">
                            Card Type
                          </Form.Label>
                          <Col sm="7">
                            <Controller
                              name="cardTypeId"
                              control={control}
                              rules={{ required: true }}
                              render={({
                                field,
                                fieldState: { invalid, error },
                              }) => {
                                return (
                                  <>
                                    <Select
                                      required
                                      onChange={(option) => {
                                        field.onChange(option?.id ?? null);
                                        setCardType(option);
                                      }}
                                      isInvalid={!!errors.cardTypeId}
                                      placeholder="Card Type"
                                      options={cardTypeLookup}
                                      getOptionLabel={({ cardType }) =>
                                        cardType
                                      }
                                      value={cardType ? cardType : null}
                                      isClearable
                                      styles={customStyles}
                                    />

                                    {invalid && error ? (
                                      <div className="custom-invalid-feedback">
                                        Card Type is required.
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              }}
                            />
                          </Col>
                        </Form.Group>
                      )}
                      {/* {isWEX && (
                               <Form.Group as={Row}>
                               <Form.Label column sm="4">
                                 Card Type
                               </Form.Label>
                               <Col sm="7">
                                 <Select
                                   onChange={setCardType}
                                   placeholder="Card Type"
                                   options={cardTypeLookup}
                                   getOptionLabel={({cardType}) => cardType}
                                   value={cardType ? cardType : null}
                                   isClearable
                                   styles={customStyles}
                                 />
       
                                 <Form.Control.Feedback type="invalid">
                                   Account is required.
                                 </Form.Control.Feedback>
                               </Col>
                             </Form.Group>
                            )} */}
                    </Col>
                  </Row>
                  {isWEX !== null && <hr />}
                  {isWEX === false && (
                    <>
                      <Row>
                        <p className="title-accounts">Basic Information</p>
                        <Col>
                          <Form.Group as={Row}>
                            <Form.Label column sm="4">
                              First Name
                            </Form.Label>
                            <Col sm="7">
                              <Form.Control
                                isInvalid={!!errors.firstName}
                                {...register("firstName", { required: true })}
                                placeholder="First Name"
                              />
                              <Form.Control.Feedback type="invalid">
                                First Name is required.
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row}>
                            <Form.Label column sm="4">
                              Last Name
                            </Form.Label>
                            <Col sm="7">
                              <Form.Control
                                isInvalid={!!errors.lastName}
                                {...register("lastName", { required: true })}
                                placeholder="Last Name"
                              />
                              <Form.Control.Feedback type="invalid">
                                Last Name is required.
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <Form.Group as={Row}>
                            <Form.Label column sm="4">
                              Status
                            </Form.Label>
                            <Col sm="7">
                              <Form.Control
                                {...register("active")}
                                placeholder="Status"
                                as="select"
                              >
                                <option value={true}>Active</option>
                                <option value={false}>Inactive</option>
                              </Form.Control>

                              <Form.Control.Feedback type="invalid">
                                Status is required.
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col />
                      </Row>
                      <hr />
                      <Row>
                        <p className="title-accounts">Company Details</p>
                        <Col>
                          <Form.Group as={Row}>
                            <Form.Label column sm="4">
                              Company
                            </Form.Label>
                            <Col sm="7">
                              <Form.Control
                                isInvalid={!!errors.company}
                                {...register("company", { required: true })}
                                placeholder="Company"
                              />
                              <Form.Control.Feedback type="invalid">
                                Company is required.
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row}>
                            <Form.Label column sm="4">
                              CC Number
                            </Form.Label>
                            <Col sm="7">
                              <Form.Control
                                placeholder="CC Number"
                                isInvalid={!!errors.ccNumber}
                                // readOnly
                                {...register("ccNumber", { required: true })}
                              />
                              <Form.Control.Feedback type="invalid">
                                CC Number is required.
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <Form.Group as={Row}>
                            <Form.Label column sm="4">
                              Card ID
                            </Form.Label>
                            <Col sm="7">
                              <Form.Control
                                placeholder="Card ID"
                                isInvalid={!!errors.cardId}
                                {...register("cardId", { required: true })}
                              />
                              <Form.Control.Feedback type="invalid">
                                Card ID is required.
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row}>
                            <Form.Label column sm="4">
                              Card Nickname
                            </Form.Label>
                            <Col sm="7">
                              <Form.Control
                                placeholder="Card Nickname"
                                // readOnly
                                {...register("nickName")}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <Form.Group as={Row}>
                            <Form.Label column sm="4">
                              Security Code
                            </Form.Label>
                            <Col sm="7">
                              <Form.Control
                                isInvalid={!!errors.securityCode}
                                placeholder="Security Code"
                                type="number"
                                {...register("securityCode", {
                                  minLength: 3,
                                  maxLength: 3,
                                })}
                              />
                              <Form.Control.Feedback type="invalid">
                                Security Code should be 3 digit long.
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row}>
                            <Form.Label column sm="4">
                              Notes
                            </Form.Label>
                            <Col sm="7">
                              <Form.Control
                                placeholder="Notes"
                                // readOnly
                                {...register("notes")}
                                defaultValue="--"
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  )}
                  {isWEX === true && (
                    <>
                      <Row>
                        <p className="title-accounts">Basic Information</p>
                        <Col>
                          <Form.Group as={Row}>
                            <Form.Label column sm="4">
                              First Name
                            </Form.Label>
                            <Col sm="7">
                              <Form.Control
                                isInvalid={!!errors.firstName}
                                {...register("firstName", { required: true })}
                                placeholder="First Name"
                              />
                              <Form.Control.Feedback type="invalid">
                                First Name is required.
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row}>
                            <Form.Label column sm="4">
                              Last Name
                            </Form.Label>
                            <Col sm="7">
                              <Form.Control
                                isInvalid={!!errors.lastName}
                                {...register("lastName", { required: true })}
                                placeholder="Last Name"
                              />
                              <Form.Control.Feedback type="invalid">
                                Last Name is required.
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <Form.Group as={Row}>
                            <Form.Label column sm="4">
                              Email
                            </Form.Label>
                            <Col sm="7">
                              <Form.Control
                                type="text"
                                isInvalid={!!errors.emailId}
                                {...register("emailId", {
                                  pattern:
                                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                })}
                                placeholder="Email"
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid email.
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                        </Col>

                        {isWEX == true ? (
                          <Col>
                            <Form.Group as={Row}>
                              <Form.Label column sm="4">
                                Team Name
                              </Form.Label>
                              <Col sm="7">
                                <Form.Control
                                  //   isInvalid={!!errors.teamName}
                                  {...register("teamName", { required: false })}
                                  placeholder="Team Name"
                                />
                              </Col>
                            </Form.Group>
                          </Col>
                        ) : (
                          <Col />
                        )}
                      </Row>
                      <hr />
                      <Row>
                        <p className="title-accounts">Address Information</p>
                        <Col>
                          <Form.Group as={Row}>
                            <Form.Label column sm="2">
                              Address
                            </Form.Label>
                            <Col sm="4">
                              <Controller
                                name="addressBookId"
                                control={control}
                                rules={{ required: true }}
                                render={({
                                  field,
                                  fieldState: { invalid, error },
                                }) => {
                                  return (
                                    <>
                                      <CreatableSelect
                                        required
                                        isInvalid={!!errors.addressBookId}
                                        value={address}
                                        onChange={async (e) => {
                                          if (e != undefined) {
                                            field.onChange(e?.id);
                                            setAddress(e);
                                          }
                                        }}
                                        styles={customStyles}
                                        options={addressDetails}
                                        getOptionLabel={(option) => {
                                          if (
                                            option.address1 != undefined ||
                                            option.address2 != undefined ||
                                            option.city != undefined ||
                                            option.state != undefined ||
                                            option.postalCode != undefined
                                          )
                                            return `${option.address1} ${option.address2} ${option.city} ${option.state} ${option.postalCode}`;
                                        }}
                                      />
                                      {invalid && error ? (
                                        <div className="custom-invalid-feedback">
                                          Address is required.
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  );
                                }}
                              />
                            </Col>
                            <Col xs={1}>
                              <Button onClick={openAddress}>
                                <img src={Add} width={15} height={15} />
                              </Button>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
            </div>
          </Modal.Body>
          <div className="d-flex justify-content-center my-4">
            <Button
              type="submit"
              variant="primary"
              className="ss-modal-primary-btn mx-2"
            >
              {isEdit ? "Save" : "Add"}
            </Button>
            <Button
              variant="light"
              onClick={() => (isEdit ? handleReset() : handleClear())}
              className="ss-modal-secondary-btn mx-2"
            >
              {isEdit ? "Reset" : "Clear"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default withLoader(AddEditCardModal);
