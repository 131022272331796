import React, { useState } from "react";

const Loader = () => (
  <div
    style={{
      width: "100vw",
      height: "100vh",
      background: "rgba(0,0,0,0.6)",
      zIndex: "1200",
      position: "fixed",
      top: 0,
      left: 0,
    }}
  >
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <div className="spinner-border text-secondary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  </div>
);

const LoaderForParent = () => (
  <div
    style={{
      width: "100%",
      height: "100%",
      background: "rgba(0,0,0,0.4)",
      zIndex: "0",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: "5px",
    }}
  >
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <div className="dot-elastic"></div>
    </div>
  </div>
);

export const withLoader = (WrappedComponent) => {
  const ComponentWithLoader = (props) => {
    const [loading, setLoading] = useState(false);

    return (
      <>
        {loading && <Loader />}
        <WrappedComponent {...props} setBusy={setLoading} />
      </>
    );
  };

  return ComponentWithLoader;
};

export const withLoaderForImmediateParent = (WrappedComponent) => {
  const ComponentWithLoader = (props) => {
    return (
      <>
        {props?.loading && <LoaderForParent />}
        <WrappedComponent {...props} />
      </>
    );
  };

  return ComponentWithLoader;
};
