import { api, getAuthHeaders, get } from "../services.common";

const getAllSyncData = async (data) => {
	const sortDir = data.sortDir == false ? "Descending" : "Ascending";
	const url = `${api}DailyLoadLogs/getall?PageNumber=${
		data.pageNumber
	}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${
		data.sortField || ""
	}`;
	const authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};

const filterSyncData = async (data) => {
	const sortDir = data.sortDir == false ? "Descending" : "Ascending";
	const url = `${api}DailyLoadLogs/search?searchString=${
		data.searchText || ""
	}&LoadName=${data.loadName || ""}&Date=${data.date || ""}&isSuccess=${
		data.isSuccess || 0
	}&ErrorMessage=${data.errorMessage || ""}&PageNumber=${
		data.pageNumber
	}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${
		data.sortField || ""
	}`;
	const authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};

const getPaymentCalendarData = async (data) => {
	const sortDir = data.sortDir == false ? "Descending" : "Ascending";
	const url = `${api}Calendar/payment-calendar?startDate=${data?.startDate}&endDate=${data?.endDate}`;
	const authHeader = getAuthHeaders();
    const paymentData = await get(url, { ...authHeader });
	return paymentData;
};

export const SyncDataServices = {
	filterSyncData,
	getAllSyncData,
	getPaymentCalendarData,
};
