/* eslint-disable no-mixed-spaces-and-tabs */
import ModalBreadcrumb from '../../../../shared/components/modals/ModalBreadcrumb';
import { Modal, Button, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

const AddEditPaypalIDModal = ({
	isVisible,
	selectedRecord,
	handleClose,
	onSubmit,
}) => {
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		control,
	} = useForm();

	const [isEdit, setIsEdit] = useState(false);

	useEffect(() => {
		reset();
	}, [isVisible]);

	useEffect(() => {
		if (selectedRecord) {
			setIsEdit(true);
			for (const [key, value] of Object.entries(selectedRecord)) {
				setValue(key, value, {
					shouldValidate: true,
					shouldDirty: true,
				});
			}
		} else {
			setIsEdit(false);
			reset();
		}
	}, [selectedRecord, isVisible]);

	const handleReset = () => {
		if (selectedRecord) {
			for (const [key, value] of Object.entries(selectedRecord)) {
				setValue(key, value, {
					shouldValidate: true,
					shouldDirty: true,
				});
			}
		} else {
			setIsEdit(false);
			reset();
		}
	};

	const names = [
		{
			name: 'TicketMaster',
			id: 1,
		},
	];

	const vendors = [
		{
			name: 'TicketMaster',
			id: 1,
		},
	];

	return (
		<>
			<Modal
				dialogClassName='modal-xl'
				show={isVisible}
				onHide={handleClose}>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Modal.Header closeButton>
						<Modal.Title>Fund Resources</Modal.Title>
					</Modal.Header>
					<ModalBreadcrumb
						breadcrumbLinks={[
							{
								name: 'Fund Sources',
								active: true,
							},
							{
								to: '/paypal/AzQyhnj2+85641vgbWnm954gvbh5',
								name: 'PayPal1',
								active: false,
							},
							{
								name: isEdit
									? 'Edit Transaction'
									: 'Add Transaction',
								active: false,
							},
						]}
					/>
					<Modal.Body>
						<div className='container-fluid p-2 w-100 h-100'>
							<Row>
								<p className='title-accounts'>Basic Details</p>
								<Col>
									<Form.Group as={Row}>
										<Form.Label column sm='3'>
											Name
										</Form.Label>
										<Col sm='7'>
											<Controller
												name='name'
												control={control}
												rules={{
													required: true,
													message: 'error message',
												}}
												render={({
													field,
													fieldState: {
														invalid,
														error,
													},
												}) => {
													return (
														<div>
															<Select
																required
																onChange={(
																	e
																) => {
																	field.onChange(
																		e.value
																	);
																}}
																options={names.map(
																	(x) => {
																		return {
																			value: x.id,
																			label: x.name,
																		};
																	}
																)}
															/>
															{invalid &&
															error ? (
																<div className='custom-invalid-feedback'>
																	Name is
																	required.
																</div>
															) : (
																''
															)}
														</div>
													);
												}}
											/>
										</Col>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group as={Row}>
										<Form.Label column sm='3'>
											Vendor
										</Form.Label>
										<Col sm='7'>
											<Controller
												name='vendor'
												control={control}
												rules={{
													required: true,
													message: 'error message',
												}}
												render={({
													field,
													fieldState: {
														invalid,
														error,
													},
												}) => {
													return (
														<div>
															<Select
																required
																onChange={(
																	e
																) => {
																	field.onChange(
																		e.value
																	);
																}}
																options={vendors.map(
																	(x) => {
																		return {
																			value: x.id,
																			label: x.name,
																		};
																	}
																)}
															/>
															{invalid &&
															error ? (
																<div className='custom-invalid-feedback'>
																	Vendor is
																	required.
																</div>
															) : (
																''
															)}
														</div>
													);
												}}
											/>
										</Col>
									</Form.Group>
								</Col>
							</Row>
							<hr />
							<Row>
								<p className='title-accounts'>
									Purchase Order Details
								</p>
								<br />
								<Col>
									<Form.Group as={Row}>
										<Form.Label column sm='3'>
											Purchase Order
										</Form.Label>
										<Col sm='7'>
											<Form.Control
												isInvalid={
													!!errors.purchaseOrder
												}
												placeholder='Purchase Order'
												{...register('purchaseOrder', {
													required: true,
												})}
											/>
											<Form.Control.Feedback type='invalid'>
												Purchase Order is required.
											</Form.Control.Feedback>
										</Col>
									</Form.Group>
								</Col>

								<Col>
									<Form.Group as={Row}>
										<Form.Label column sm='3'>
											Date &amp; Time
										</Form.Label>
										<Col sm='7'>
											<Form.Control
												type='datetime-local'
												disabled={isEdit}
												isInvalid={!!errors.dateAndTime}
												{...register(
													'dateAndTime',
													isEdit
														? { required: false }
														: { required: true }
												)}
												value={
													isEdit
														? String(
																selectedRecord?.dateAndTime
														  ).split('T')[0]
														: null
												}
												placeholder='dateAndTime'
											/>
											<Form.Control.Feedback type='invalid'>
												Date &amp; Time is required.
											</Form.Control.Feedback>
										</Col>
									</Form.Group>
								</Col>
							</Row>
							<br />
							<Row>
								<Col>
									<Form.Group as={Row}>
										<Form.Label column sm='3'>
											Amount
										</Form.Label>
										<Col sm='7'>
											<InputGroup>
												<InputGroup.Prepend>
													<InputGroup.Text>
														$
													</InputGroup.Text>
												</InputGroup.Prepend>
												<Form.Control
													type='float'
													min='0'
													isInvalid={!!errors.amount}
													{...register('amount', {
														required: true,
													})}
													disabled={isEdit}
													placeholder='Amount'
												/>
												<Form.Control.Feedback type='invalid'>
													Amount is required.
												</Form.Control.Feedback>
											</InputGroup>
										</Col>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group as={Row}>
										<Form.Label column sm='3'>
											Description
										</Form.Label>
										<Col sm='7'>
											<Form.Control
												disabled={isEdit}
												isInvalid={!!errors.description}
												placeholder='Description'
												{...register('description', {
													required: false,
												})}
											/>
										</Col>
									</Form.Group>
								</Col>
							</Row>
							<br />
							<br />

							<Row>
								<Col>
									<Form.Group as={Row}>
										<Form.Label column sm='3'>
											Notes
										</Form.Label>
										<Col sm='7'>
											<Form.Control
												isInvalid={!!errors.notes}
												placeholder='Notes'
												{...register('notes', {
													required: false,
												})}
											/>
										</Col>
									</Form.Group>
								</Col>
								<Col></Col>
							</Row>
						</div>
					</Modal.Body>
					<div className='d-flex justify-content-center my-4'>
						<Button
							type='submit'
							variant='primary'
							className='ss-modal-primary-btn mx-2'>
							{isEdit ? 'Save' : 'Add'}
						</Button>
						<Button
							variant='light'
							onClick={handleReset}
							className='ss-modal-secondary-btn mx-2'>
							{isEdit ? 'Reset' : 'Clear'}
						</Button>
					</div>
				</Form>
			</Modal>
		</>
	);
};

export default AddEditPaypalIDModal;
