import { Route, Switch } from "react-router-dom";
import LoginPage from "../../pages/authentication/LoginPage";
import ForgotPassword from "../../pages/authentication/ForgotPassword";
import CallbackUI from "../../pages/authentication/CallbackUI";

const AuthRoutes = () => {
  return (
    <Switch>
      <Route path="/forgot-password" exact> 
        <ForgotPassword /> 
      </Route>
      <Route path="/authorization-code/callback" exact> 
        <CallbackUI /> 
      </Route>
      <Route>
        <LoginPage />
      </Route>
    </Switch>
  );
};

export default AuthRoutes;