import { Controller } from "react-hook-form";
import {
  errorMessage,
  successMessage,
} from "../../data/reducers/alerts/alert.reducer";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select, { components } from "react-select";
import { withLoader } from "../../utils/hoc/withLoader";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//components
import FilterPanel from "../../shared/components/panels/filter-panel/FilterPanel";
import QuickTixTable from "../../shared/components/table/QuickTixTable";
import TableRowCard from "../../shared/components/table/TableRowCard";
import { columnError } from "../../shared/components/table/data/error";

// images
import Download from "../../images/download.svg";
import Reset from "../../images/reset.svg";
import AddBtn from "../../images/AddBtn.svg";
import UploadEmail from "../../images/UploadEmail.svg";
import TicketMasterSuccess from "../../images/ticket-green.svg";
import TicketMasterPending from "../../images/ticket-yellow.svg";
import TicketMasterFailure from "../../images/ticket-red.svg";
import TicketMasterNotCreated from "../../images/ticket-black.svg";

import ForwardSuccess from "../../images/forwarded-green.svg";
import ForwardPending from "../../images/forwarded-yellow.svg";
import ForwardFailure from "../../images/forwarded-red.svg";
import ForwardNotStarted from "../../images/forwarded-black.svg";

import CreatePending from "../../images/mail-yellow.svg";
import CreateFail from "../../images/mail-red.svg";
import CreatedSuccess from "../../images/mail-green.svg";

import "../../shared/styles/ModalStyles.scss";

import Down from "../../images/downarrow.svg";
import Up from "../../images/uparrow.svg";

//reducers

import {
  getAllEmail,
  createEmail,
  filterData,
  downloadEmailList,
  getBalance,
  getRecoveryEmailData,
  createRecoveryEmail,
  setDefaultRecoveryEmail,
  getEmailLogs,
} from "../../data/reducers/email/email.reducer";
import AddEmail from "./modals/AddEmail";
import UploadFileModal from "./modals/UploadFileModal";
import DownloadTemplate from "./modals/DownloadTemplate";
import { formatDate } from "../../utils/formatter/dateFormatter";
import moment from "moment";
import {
  genderEmail,
  getEmailData,
} from "../../data/reducers/email/email.reducer";
import AddEditAddress from "../addressBook/modals/AddEditAddress";
import {
  createAddress,
  getAddressList,
} from "../../data/reducers/addressBook/addressBook.reducer";
import CopyDetailsModal from "./modals/CopyDetailsModal";
import {
  formatMoney,
  formatMobileNumber,
} from "../../utils/formatter/currencyFormatter";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import AddRecoveryEmail from "./modals/AddRecoveryEmail";
import CollapsibleTable from "../accounts/collapsible-table";
import {
  Mixpanel,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../mixpanel";

const EmailData = ({ setBusy }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  //---------------------------------------------------------------------------------------------------------------//

  const queryParameters = new URLSearchParams(window.location.search);
  const page = queryParameters.get("page");
  const email = queryParameters.get("emailId");

  //---------------------------------------------------------------------------------------------------------------//

  const [filterUrlData, setFilterUrlData] = useState(null);
  const [filterArray, setFilterArray] = useState([]);
  const [filterOption, setFilterOption] = useState("all");
  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.EMAIL,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.EMAIL,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (filterUrlData) {
      onSubmit(filterUrlData);
      setFilterArray([
        { key: "searchText", value: filterUrlData?.searchText },
        { key: "firstName", value: filterUrlData?.firstName },
        { key: "mobileNumber", value: filterUrlData?.mobileNumber },
        { key: "lastName", value: filterUrlData?.lastName },
        { key: "genderId", value: filterUrlData?.genderId },
        { key: "username", value: filterUrlData?.username },
        { key: "dateOfBirth", value: filterUrlData?.dateOfBirth },
        {
          key: "isGeneric",
          value:
            filterUrlData?.isGeneric === null
              ? null
              : filterUrlData?.isGeneric == "true",
        },
      ]);
    }
  }, [filterUrlData, filterOption]);

  //-------------------- Param navigation ---------------//

  useEffect(() => {
    if (page) {
      setpageNumber(page);
      setFilterUrlData({
        searchText: queryParameters.get("searchText"),
        firstName: queryParameters.get("firstName"),
        mobileNumber: queryParameters.get("mobileNumber"),
        genderId: queryParameters.get("genderId"),
        lastName: queryParameters.get("lastName"),
        username: queryParameters.get("username"),
        dateOfBirth: queryParameters.get("dateOfBirth"),
        isGeneric: queryParameters.get("isGeneric"),
      });
    } else {
      navigateToMainPage();
    }
  }, [page, location]);

  useEffect(() => {
    if (email) {
      copyDetailsModal({ id: email });
    }
  }, [email, location]);

  //--------------------------- filter queries ------------------------//
  const searchQuery = () => {
    var array = window.location.search.slice(1).split("&&");
    var query = "";
    for (let i = 1; i < array.length; i++) {
      query += "&&" + array[i];
    }
    return query;
  };

  //-------------- on page size change --------------//
  const navigateToMainPage = () => {
    history.push(`/email?page=1`);
  };

  const previousPage = () => {
    if (Number(pageNumber) > 1) {
      history.push(`/email?page=${Number(pageNumber) - 1}${searchQuery()}`);
      setpageNumber(Number(pageNumber - 1));
    }
  };

  const nextPage = () => {
    history.push(`/email?page=${Number(pageNumber) + 1}${searchQuery()}`);
    setpageNumber(Number(pageNumber + 1));
  };

  //--------------------- filter Navigation -------------------//

  const filterNavigation = (data) => {
    // var url = `/email?page=${data.searchText ? 1 : page}`
    var url = `/email?page=1`;

    if (data.searchText) {
      url += `&&searchText=${data.searchText}`;
      setpageNumber(1);
    }
    if (data.firstName) url += `&&firstName=${data.firstName}`;
    if (data.lastName) url += `&&lastName=${data.lastName}`;
    if (data.genderId) url += `&&genderId=${data.genderId}`;
    if (data.mobileNumber) url += `&&mobileNumber=${data.mobileNumber}`;
    if (data.username) url += `&&username=${data.username}`;
    if (data.dateOfBirth) url += `&&dateOfBirth=${data.dateOfBirth}`;
    if (data.isGeneric === true || data.isGeneric === false)
      url += `&&isGeneric=${data.isGeneric ? "true" : "false"}`;

    history.push(url);
  };

  //---------------------- change Page Size and make page=1 by default ---------------------//
  const changePageSize = () => {
    let search = String(window.location.search).slice(1);
    const str = search.split("&&");
    let remainStr = "";
    for (let i = 1; i < str.length; i++) {
      remainStr += "&&" + str[i];
    }
    history.push(window.location.pathname + "?page=1" + remainStr);
  };

  //---------------------------------------------------------------------------------------------------------------//

  //selectors
  const emailData = useSelector((state) => state.emails).emails;
  const paging = useSelector((state) => state.emails).paging;
  const genderData = useSelector((state) => state.emails).genderList;
  const copyData = useSelector((state) => state.emails).copyData;
  const balance = useSelector((state) => state.emails).balance;
  const recoveryEmails = useSelector((state) => state.emails).recoveryEmails;
  const authInfo = useSelector((state) => state.auth);

  //use states
  const [selectedTabIndex, setTabIndex] = useState(0);
  const [detailModal, setDetailModal] = useState(false);
  const [addEditAddressModalVisibility, setAddEditAddressModalVisibility] =
    useState(false);
  const [sortField, setSortField] = useState(null);
  const [sortDir, setSortDir] = useState(true);
  const [addEmailModalVisible, setAddEditEmailModalVisibility] =
    useState(false);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [pageSize, setpageSize] = useState(10);
  const [pageNumber, setpageNumber] = useState(1);
  const [password, setPassword] = useState("");

  const [searchData, setsearchData] = useState(null);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const [triggerUseEffect, setTriggerUseEffect] = useState(false);
  const [added, setAdded] = useState(null);
  const [dropVal, setdropVal] = useState({ gender: "" });
  const [showRecoveryModal, setShowRecoveryModal] = useState(false);
  const [Admin, setAdmin] = useState(false);
  //use effect

  //------------------------ set default value --------------------//
  useEffect(() => {
    if (localStorage.getItem("USER_ROLE") === "Admin") setAdmin(true);
  }, []);

  useEffect(() => {
    if (filterUrlData && genderData) {
      3;

      genderData.map((obj) => {
        if (obj.id == filterUrlData.genderId) {
          setdropVal({ ...dropVal, gender: obj });
        }
      });
    }
  }, [filterUrlData, genderData]);

  useEffect(() => {
    // setpageNumber(1);
    if (selectedTabIndex == 0) setFilterOption("forwarded");
    else if (selectedTabIndex == 1) setFilterOption("all");
    else if (selectedTabIndex == 2) setFilterOption("created");
    else if (selectedTabIndex == 3) setFilterOption("emailfailed");
    else if (selectedTabIndex == 4) setFilterOption("forwardfailed");
    else if (selectedTabIndex == 5) setFilterOption("flagged");
  }, [selectedTabIndex]);

  // Dispatch apis
  useEffect(async (data) => {
    await dispatch(genderEmail());
  }, []);

  useEffect(() => {
    getBalances();
    fetchAllRecoveryEmails();
  }, []);

  const getBalances = async () => {
    try {
      setBusy(true);
      await dispatch(getBalance());
    } catch (error) {
      console.error(error);
    } finally {
      setBusy(false);
    }
  };

  const fetchAllRecoveryEmails = async () => {
    try {
      setBusy(true);
      await dispatch(getRecoveryEmailData());
    } catch (error) {
      console.error(error);
    } finally {
      setBusy(false);
    }
  };

  const onSubmitForm = async (email) => {
    try {
      setBusy(true);
      await dispatch(createRecoveryEmail(email));
    } catch (error) {
      console.error(error);
    } finally {
      setBusy(false);
      fetchAllRecoveryEmails();
      setShowRecoveryModal(false);
    }
  };

  const setDefault = async (email) => {
    try {
      setBusy(true);
      await dispatch(setDefaultRecoveryEmail(email));
    } catch (error) {
      console.error(error);
    } finally {
      setBusy(false);
      fetchAllRecoveryEmails();
    }
  };

  // fetch all Emails
  const fetchEmailData = async () => {
    try {
      setBusy(true);
      const data = {
        pageNumber: page,
        pageSize: pageSize,
        sortDir,
        sortField,
        filterOption,
      };
      // let action;
      const action = getAllEmail(data);
      const response = await dispatch(action);
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  // search data
  const onSubmit = async (data) => {
    if (data && !data.firstName) {
      data.firstName = "";
    }
    if (data && !data.lastName) {
      data.lastName = "";
    }
    if (data && !data.mobileNumber) {
      data.mobileNumber = "";
    }
    if (data && !data.username) {
      data.username = "";
    }
    if (data && data.dateOfBirth == undefined) {
      data.dateOfBirth = "";
    }
    if (data && data.genderId == undefined) {
      data.genderId = "";
    }
    if (data && data?.searchText == undefined) {
      data.searchText = "";
    }

    let formData = {
      ...data,
      searchText:
        data?.searchText !== undefined ? data?.searchText?.trim() : "",
      dateOfBirth: data?.dateOfBirth
        ? moment(data.dateOfBirth).format("MM-DD-YYYY")
        : "",
      pageNumber,
      sortField,
      sortDir,
      pageSize,
      filterOption,
    };

    setBusy(true);
    if (
      formData?.searchText ||
      formData?.firstName ||
      formData?.lastName ||
      formData?.mobileNumber ||
      formData?.username ||
      formData?.dateOfBirth ||
      formData?.genderId ||
      formData?.isGeneric
    ) {
      if (formData.searchText && formData.searchText.includes("@")) {
        formData = {
          ...formData,
          searchText: formData.searchText.split("@")[0],
        };
      }
      setsearchData(formData);
      await dispatch(filterData(formData));
    } else {
      // setpageNumber(1);
      setsearchData(null);
      await fetchEmailData();
    }
    setBusy(false);
  };

  // add email details
  const onEntrySubmitted = async (data) => {
    try {
      const message = `Email added succesfully!`;
      setBusy(true);
      const action = createEmail({
        ...data,
        dateOfBirth: data?.dateOfBirth ? data?.dateOfBirth : null,
        cardTypeId: data?.isCreateCardChecked ? data?.cardTypeId : null,
        mobileNumber:
          data && data.mobileNumber
            ? data.mobileNumber.split("-").join("")
            : null,
      });

      const response = await dispatch(action);
      await dispatch(getAddressList());
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        dispatch(successMessage(message));
        setAddEditEmailModalVisibility(false);
        fetchEmailData();
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
      setSelectedRecord(null);
    }
  };

  // Download tamplate
  const onDownloadTamplate = () => {};
  const customStyles = {
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };

  // Download email list data
  const downloadTemplate = async () => {
    try {
      const message = `Download email list successfully`;
      setBusy(true);
      await dispatch(downloadEmailList());
      dispatch(successMessage(message));
    } catch (e) {
      console.log(e);
    } finally {
      setBusy(false);
    }
  };

  //add address function
  const addAddress = async (data) => {
    try {
      const message = data.id
        ? `Address Updated Succesfully`
        : `New Address Created Succesfully`;
      setBusy(true);
      const action = createAddress(data);
      const response = await dispatch(action);
      if (response?.payload) {
        setSelectedRecord({
          ...selectedRecord,
          addressId: response?.payload?.id,
        });
      }
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        if (response.payload.id)
          setSelectedRecord({
            ...selectedRecord,
            addressId: response.payload.id,
          });
        setAdded(response.payload.id);
        dispatch(successMessage(message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
      setAddEditAddressModalVisibility(false);
      setAddEditEmailModalVisibility(true);
    }
  };

  const copyDetailsModal = async (data) => {
    if (data?.id) {
      setDetailModal(true);
      try {
        setBusy(true);
        await dispatch(getEmailData(data.id));
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setBusy(false);
        if (searchData) {
          onSubmit(searchData);
        } else {
          fetchEmailData();
        }
      }
    } else {
      console.error("The 'id' property is undefined");
    }
  };

  const emailCol = [
    {
      columnName: <> Name </>,
      columnKey: "firstName",
      flexGrow: 0.9,
      render: (data) => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            textAlign: "left",
          }}
        >
          {data?.isGeneric && data?.isNameChanged == false && (
            <span
              style={{ color: "#FFB904", fontSize: "10px", marginRight: "8px" }}
            >
              <i
                style={{ cursor: "pointer" }}
                title="Name change in progress"
                className="fa fa-exclamation-triangle"
                aria-hidden="true"
              ></i>
            </span>
          )}

          {`${
            data?.firstName.charAt(0).toUpperCase() + data?.firstName.slice(1)
          } ${
            data?.lastName.charAt(0).toUpperCase() + data?.lastName.slice(1)
          }`}
        </div>
      ),
    },
    {
      columnName: "Email",
      // columnKey: "id",
      flexGrow: 1.1,
      render: (data) => (
        <div
          onClick={(e) => {
            if (data?.id) {
              e.currentTarget.blur();
              history.push(`/email?page=${pageNumber}&emailId=${data?.id}`);
              // copyDetailsModal(data);
            } else {
              console.error("The 'id' property is undefined");
            }
          }}
          style={{ color: "#0241a0", cursor: "pointer" }}
        >
          {`${data?.username?.toLowerCase()}@gmail.com`}
        </div>
      ),
    },
    {
      columnName: "Password",
      // flexGrow: 0.7,
      render: (data) => {
        const [isShown, setIsShown] = useState(false);
        const togglePassword = () => {
          setIsShown(isShown ? false : true);
        };

        return (
          <div
            className="d-flex
              align-items-center 
              justify-content-center"
          >
            <input
              style={{ outline: "none", border: "none", width: "5rem" }}
              name="password"
              value={isShown ? data?.password : "12345678"}
              type={isShown ? "text" : "password"}
            />
            <p
              onClick={togglePassword}
              style={{
                marginTop: "1rem",
                color: "#0241a0",
                cursor: "pointer",
              }}
            >
              {password === "password" ? (
                <i className="fa fa-eye-slash"></i>
              ) : (
                <i className="fa fa-eye"></i>
              )}
            </p>
          </div>
        );
      },
    },
    {
      columnName: (
        <>
          GM <br /> No{" "}
        </>
      ),
      columnKey: "mobileNumber",
      flexGrow: 1.3,
      render: (data) => {
        const formattedMbNO = formatMobileNumber(data?.mobileNumber);
        return <>{formattedMbNO ? formattedMbNO : "-"}</>;
      },
    },
    {
      columnName: (
        <>
          {" "}
          TM <br /> No{" "}
        </>
      ),
      // columnKey: "",
      flexGrow: 1.3,
      render: (data) => {
        const formattedMbNO = formatMobileNumber(data?.tmNumber);
        return (
          <>{formattedMbNO && data?.tmCreateStatus ? formattedMbNO : "-"}</>
        );
      },
    },
    {
      columnName: (
        <>
          Date <br /> Created
        </>
      ),
      columnKey: "createdDate",
      // flexGrow: 0.7,
      render: (data) => (
        <>{data?.createdDate ? formatDate(data?.createdDate) : "-"}</>
      ),
    },
    {
      columnName: "Status",
      columnKey: "isCreated",
      render: (data) => {
        let createIConLink = "";
        //Show Create Icon according to Status
        if (data?.isCreated === null) {
          createIConLink = CreatePending;
        } else if (
          data?.isCreated === false ||
          data?.isAccountBlocked == true
        ) {
          createIConLink = CreateFail;
        } else if (data?.isCreated) {
          createIConLink = CreatedSuccess;
        } else {
          createIConLink = CreateFail;
        }

        //Show TicketMaster Status Icon
        let ticketMasterIcon = "";
        if (!data?.isCreateTicketMasterAccountChecked) {
          ticketMasterIcon = TicketMasterNotCreated;
        } else if (data?.tmCreateStatus) {
          ticketMasterIcon = TicketMasterSuccess;
        } else if (
          data.tmCreateStatus === null ||
          data?.tmCreateAttempts <= 5
        ) {
          ticketMasterIcon = TicketMasterPending;
        } else if (data.tmCreateStatus === false || data.tmCreateAttempts > 5) {
          ticketMasterIcon = TicketMasterFailure;
        } else {
          ticketMasterIcon = TicketMasterFailure;
        }

        //Show Forward Status
        let forwardStatusIcon = "";
        if (data?.isCreated === false) {
          forwardStatusIcon = ForwardNotStarted;
        } else if (data.isForwardingEnabled === true) {
          forwardStatusIcon = ForwardSuccess;
        } else if (data.isCreated === null) {
          forwardStatusIcon = ForwardPending;
        } else if (
          data?.verificationLinkSentAttempts >= 5 ||
          data?.verificationCompleteAttempts >= 5 ||
          data?.forwardingAttempts >= 5
        ) {
          forwardStatusIcon = ForwardFailure;
        } else if (data.isForwardingEnabled === false) {
          forwardStatusIcon = ForwardPending;
        } else {
          forwardStatusIcon = ForwardFailure;
        }
        return (
          <small className="table-row-card-actions d-flex">
            <button
              style={{ background: "none", border: "none" }}
              className="table-action-button"
              variant="link"
            >
              <img style={{ height: "20px" }} src={createIConLink} />
            </button>
            <button
              style={{ background: "none", border: "none" }}
              className="table-action-button"
              variant="link"
            >
              <img style={{ height: "20px" }} src={ticketMasterIcon} />
            </button>
            <button
              style={{ background: "none", border: "none" }}
              className="table-action-button"
              variant="link"
            >
              <img style={{ height: "20px" }} src={forwardStatusIcon} />
            </button>
          </small>
        );
      },
    },
    {
      columnName: "Actions",
      columnKey: "Actions",
      render: (data, ind, open, setOpen) => {
        return (
          <small className="table-row-card-actions d-flex">
            <Button
              variant="link"
              onClick={() => {
                if (open[ind]) {
                  setOpen(Array(data?.length).fill(false));
                } else {
                  const temp = Array(data?.length).fill(false);
                  temp[ind] = !temp[ind];
                  setOpen(temp);
                }
                dispatch(getEmailLogs(data.username));
              }}
            >
              <img src={open[ind] ? Up : Down} alt="up down image" />
            </Button>
          </small>
        );
      },
    },
  ];

  const handleRefersh = async (data) => {
    setBusy(true);
    try {
      const message = ` Refresh Successful`;
      if (searchData) {
        await onSubmit(searchData);
      } else {
        await fetchEmailData();
      }
      dispatch(successMessage(message));
    } catch (e) {
      console.log(e);
    } finally {
      setBusy(false);
    }
  };

  //table head
  const headOptions = () => {
    return (
      <>
        <div
          className="d-flex align-items-center justify-content-end"
          style={{ color: "#212529" }}
        >
          <p className="mb-0" style={{ paddingRight: "10px" }}>
            <small>
              TextVerifed:{" "}
              {balance.textVerified < 5 ? (
                <span style={{ color: "red" }}>
                  {balance.textVerified
                    ? formatMoney(parseFloat(balance.textVerified))
                    : "$0"}
                </span>
              ) : (
                <span>
                  {" "}
                  {balance.textVerified
                    ? formatMoney(parseFloat(balance.textVerified))
                    : "$0"}
                </span>
              )}
            </small>
          </p>
          <p className="mb-0 mt-0" style={{ paddingRight: "10px" }}>
            <small style={{ marginBottom: "5px" }}>
              SMS Pool:{" "}
              {balance.smsPool < 5 ? (
                <span style={{ color: "red" }}>
                  {balance.smsPool
                    ? formatMoney(parseFloat(balance.smsPool))
                    : "$0"}
                </span>
              ) : (
                <span>
                  {" "}
                  {balance.smsPool
                    ? formatMoney(parseFloat(balance.smsPool))
                    : "$0"}
                </span>
              )}
            </small>
          </p>
          <small>Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue="10"
              onChange={(e) => {
                setpageNumber(1);
                setpageSize(e.target.value);
                changePageSize();
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div className="text-end mt-3 d-flex" style={{ fontWeight: "500" }}>
          <div className="d-flex justify-content-evenly">
            <Button
              onClick={handleRefersh}
              className="ms-2 ss-light-button"
              style={{ fontSize: "0.71rem", marginTop: "-0.80rem" }}
            >
              <img src={Reset} alt=" " />
              <span>Refresh</span>
            </Button>
            <Button
              className="ms-2 ss-light-button"
              onClick={downloadTemplate}
              style={{ fontSize: "0.71rem", marginTop: "-0.80rem" }}
            >
              <img src={Download} alt=" " />
              <span>Download email list</span>
            </Button>
            <Button
              onClick={async (e) => {
                e.currentTarget.blur();
                await setDownloadModalVisible(true);
              }}
              className="ms-2 ss-light-button"
              style={{ fontSize: "0.71rem", marginTop: "-0.80rem" }}
            >
              <img src={Download} alt=" " />
              <span>Download template</span>
            </Button>
            <Button
              onClick={async (e) => {
                e.currentTarget.blur();
                await setUploadModalVisible(true);
              }}
              className="ms-2 ss-light-button"
              style={{ fontSize: "0.71rem", marginTop: "-0.80rem" }}
            >
              <img src={UploadEmail} alt=" " />
              <span>Upload</span>
            </Button>
            <Button
              onClick={async (e) => {
                e.currentTarget.blur();
                await setAddEditEmailModalVisibility(true);
              }}
              className="ms-2 ss-light-button"
              style={{ fontSize: "0.71rem", marginTop: "-0.80rem" }}
            >
              <img src={AddBtn} alt=" " />
              <span>Add</span>
            </Button>
          </div>
        </div>
      </>
    );
  };

  const onResetData = () => {
    setsearchData(null);
    setpageNumber(1);
    setTriggerUseEffect((x) => !x);
    setdropVal({
      gender: "",
    });
  };

  const customStylesRecovery = {
    control: (styles) => ({
      ...styles,
      width: "100%",
    }),
    menu: (styles) => ({ ...styles, width: "100%" }),
  };

  return (
    <div
      onClick={() => {
        Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
          page: mixpanel_contants.EMAIL,
        });
      }}
      className="container-fluid pt-3"
      style={{ fontWeight: "500" }}
    >
      {/* Filter Panel */}
      <Row>
        <Col>
          <FilterPanel
            defaultValues={filterArray}
            setpageNumber={setpageNumber}
            searchPlaceholder="Search for first name, last name, username, or mobile number..."
            onReset={() => {
              onResetData();
              navigateToMainPage();
            }}
            dashboard={mixpanel_contants.EMAIL}
            onSubmit={filterNavigation}
            renderAdvancedPanel={(register, handleSubmit, errors, control) => (
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      First Name
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="First Name"
                        {...register("firstName")}
                        defaultValue={searchData?.firstName}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group className="pt-3" as={Row}>
                    <Form.Label column sm="4">
                      Mobile Number
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="Mobile Number"
                        {...register("mobileNumber")}
                        defaultValue={searchData?.mobileNumber}
                      />
                    </Col>
                    <Col>
                      {/* Generic Emails Select */}
                      <Form.Group as={Row} className="pt-3">
                        <Form.Label column sm="4">
                          Generic Emails
                        </Form.Label>
                        <Col sm="8">
                          <Controller
                            name="isGeneric"
                            control={control}
                            render={({ field }) => (
                              <Select
                                value={
                                  field.value === null
                                    ? ""
                                    : field.value
                                    ? { label: "Yes", value: true }
                                    : { label: "No", value: false }
                                }
                                onChange={(selectedOption) =>
                                  field.onChange(selectedOption.value)
                                }
                                options={[
                                  { label: "Yes", value: true },
                                  { label: "No", value: false },
                                ]}
                              />
                            )}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Last Name
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        {...register("lastName")}
                        defaultValue={searchData?.lastName}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Date Created
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        // dateFormat="mm-dd-yyyy"
                        placeholder="dateOfBirth"
                        type="date"
                        {...register("dateOfBirth")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      User Name
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="Username"
                        {...register("username")}
                        defaultValue={searchData?.username}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Gender
                    </Form.Label>
                    <Col sm="8">
                      <Controller
                        name="genderId"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              value={dropVal?.gender}
                              onChange={(e) => {
                                field.onChange(e?.id);
                                setdropVal({ ...dropVal, gender: e });
                              }}
                              styles={customStyles}
                              options={genderData}
                              getOptionLabel={(option) => `${option.name}`}
                            />
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            )}
          />
        </Col>
      </Row>
      <div className="p-4" style={{ backgroundColor: "white" }}>
        {authInfo.role === "Admin" && (
          <div
            className="d-flex"
            style={{ alignItems: "center", justifyContent: "flex-end" }}
          >
            <p style={{ marginBottom: 0, color: "#212529" }}>
              All created emails are forwarded to this email.
            </p>
            <div style={{ width: "30%" }}>
              <Select
                value={recoveryEmails?.find((x) => x.isDefault)}
                onChange={(e) => {
                  setDefault(e?.name);
                }}
                styles={customStylesRecovery}
                options={recoveryEmails}
                getOptionLabel={(option) => `${option.name}`}
              />
            </div>
            <Button
              onClick={async (e) => {
                e.currentTarget.blur();
                setShowRecoveryModal(true);
              }}
              style={{
                backgroundColor: "white",
                border: "1px solid #B4B4B44D",
                minHeight: "38px",
              }}
              className="ms-2"
            >
              <img src={AddBtn} alt=" " />
            </Button>
          </div>
        )}
        {/* Data Table */}
        <CollapsibleTable
          previousPage={previousPage}
          nextPage={nextPage}
          paramerterNavigation={true}
          headerName="EMAILS"
          paging={paging}
          pageType="emails"
          columns={Admin ? emailCol : emailCol.slice(0, -1)}
          data={emailData || []}
          tabs={[
            "Active",
            "All",
            "Created",
            "Email Pending",
            "Forward Pending",
            "Flagged",
          ]}
          selectedTabIndex={selectedTabIndex}
          sortDir={sortDir}
          onTabSelectionChange={(i) => {
            setTabIndex(i);
          }}
          setSortDir={setSortDir}
          sortField={sortField}
          setSortField={setSortField}
          headOptions={headOptions}
          setpageNumber={setpageNumber}
          renderRow={(rowData, index) => (
            <TableRowCard
              key={rowData.id}
              columns={
                rowData.nodata
                  ? columnError
                  : Admin
                  ? emailCol
                  : emailCol.slice(0, -1)
              }
              rowData={rowData}
            />
          )}
        />
      </div>

      {/* Modal Popups */}
      {addEmailModalVisible && (
        <AddEmail
          added={added}
          setSelectedRecord={setSelectedRecord}
          isVisible={addEmailModalVisible}
          handleThisClose={() => {
            setAddEditEmailModalVisibility(false);
            setSelectedRecord(null);
          }}
          onSubmit={onEntrySubmitted}
          selectedRecord={selectedRecord}
          modalOpen={addEditAddressModalVisibility}
          setModalOpen={() => {
            setAddEditEmailModalVisibility(false);
            setAddEditAddressModalVisibility(true);
          }}
        />
      )}

      {uploadModalVisible && (
        <UploadFileModal
          setSelectedRecord={setSelectedRecord}
          isVisible={uploadModalVisible}
          handleThisClose={() => setUploadModalVisible(false)}
          // onSubmit={onUploadData}
          selectedRecord={selectedRecord}
          onUploadCompleted={() => {
            onSubmit(searchData);
          }}
        />
      )}

      {downloadModalVisible && (
        <DownloadTemplate
          setSelectedRecord={setSelectedRecord}
          isVisible={downloadModalVisible}
          handleThisClose={() => setDownloadModalVisible(false)}
          onSubmit={onDownloadTamplate}
          selectedRecord={selectedRecord}
        />
      )}

      {addEditAddressModalVisibility && (
        <AddEditAddress
          isVisible={addEditAddressModalVisibility}
          onSubmit={addAddress}
          handleClose={() => {
            setAddEditAddressModalVisibility(false);
            setAddEditEmailModalVisibility(true);
          }}
        />
      )}

      {detailModal && (
        <CopyDetailsModal
          selectedEmailData={copyData}
          isVisible={detailModal}
          handleThisClose={() => {
            setDetailModal(false);
            window.history.replaceState(null, "", "/email?page=" + pageNumber);
            // window.location.replace(window.location.origin + "/email?page=" + pageNumber)
          }}
          getEmailData={copyDetailsModal}
        />
      )}

      <AddRecoveryEmail
        show={showRecoveryModal}
        onClose={() => setShowRecoveryModal(false)}
        onSubmit={onSubmitForm}
      />
    </div>
  );
};

export default withLoader(EmailData);
