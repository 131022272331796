
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Col, Row, Button, Dropdown, Form, Container } from "react-bootstrap";

//reducers
import { errorMessage, successMessage } from "../../../data/reducers/alerts/alert.reducer";
import { getCardById, exportCSV, getTransactionById } from "../../../data/reducers/cards/cards.routes"

//components
import CardDetails from "./CardDetails";
import QuickTixTable from "../../../shared/components/table/QuickTixTable";
import TableRowCard from "../../../shared/components/table/TableRowCard";
import { columnError } from "../../../shared/components/table/data/error";
import { withLoader } from "../../../utils/hoc/withLoader";
import {
  transactions
} from "../../../shared/components/table/data/fundsCards";

// images
import Export from "../../../images/export.svg";
import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import { exportPdfTrans } from "../../../data/reducers/transactions/transactions.reducer";

const SingleCardDetail = ({ setBusy }) => {
  const dispatch = useDispatch();


  {/* Note: id here is NOT the main ID, but rather the AccountID */ }
  const { id } = useParams();

  {/* useStates */ }
  const [pageSize, setpageSize] = useState(40);
  const [sortField, setSortField] = useState(null);
  const [sortDir, setSortDir] = useState(true);
  const [pageNumber, setpageNumber] = useState(1);
  {/* Note: MainID is the superset of AccountID */ }
  const [myMainId, setMainId] = useState(undefined);

  {/* useSelectors */ }
  const selectedRecord = useSelector((state) => state.cards).selectedCard;
  const cardsTransaction = useSelector((state) => state.cards).cardsTransactions;
  const paging = useSelector((state) => state.cards).paging;

  // fetch all cards information
  const fetchCardsData = async () => {
    try {
      setBusy(true);
      const data = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        id,
        sortDir, sortField
      };
      const response = await dispatch(getTransactionById(data));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  useEffect(() => {
    dispatch(getCardById(id));
    fetchCardsData()
  }, [id]);

  useEffect(() => {
    fetchCardsData()
  }, [pageNumber, pageSize, sortField, sortDir]);

  const headOptions = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small>Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue="40"
              onChange={(e) => setpageSize(e.target.value)}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div className="text-end mt-3">

          <Dropdown style={{ float: 'right' }}>
            <Dropdown.Toggle
              className="ss-light-button"
              id="dropdown-basic"
            >
              <img src={Export} alt=" " />
              <span>Export</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={onExportCSV}>
                Export as CSV
              </Dropdown.Item>

              <hr className="hr-full" />
              <Dropdown.Item onClick={onExportPdf}>
                Export as PDF
              </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  };


  const onExportCSV = async () => {
    setBusy(true);
    await dispatch(exportCSV());
    setBusy(false);
  };

    const onExportPdf = async () => {
    setBusy(true);
    await dispatch(exportPdfTrans());
    setBusy(false);
  };

  return (
    <div className="container-fluid pt-3">
      {/* Upper Navigation */}
      <Row>
        <Col>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                name: "Cards",
                active: true,
              },
              {
                name: selectedRecord?.cardId,
                active: false,
              }
            ]}
          />

        </Col>
        <Col md={{ span: 4, offset: 4 }} className="text-end page-upper-navigation p-4">
          <NavLink className="ss-back-button text-end" to="/fundSources/cards">
            <small>Back to Cards</small>
          </NavLink>
        </Col>
      </Row>

      {/* Account Details */}
      <CardDetails details={selectedRecord} />

      {/* Data Table */}
      <QuickTixTable
        headerName="Transactions"
        paging={paging}
        sortDir={sortDir}
        setSortDir={setSortDir}
        sortField={sortField}
        setSortField={setSortField}
        columns={transactions()}
        data={cardsTransaction || []}
        headOptions={headOptions}
        setpageNumber={setpageNumber}
        renderRow={(rowData) => (
          <TableRowCard
            key={rowData.id}
            columns={rowData.nodata ? columnError : transactions()}
            rowData={rowData}
          />
        )}
      />
    </div>
  );
};

export default withLoader(SingleCardDetail);
