import {
    api,
    getAuthHeaders,
    get,
    post,
    update,
    del,
    getCSV,
} from '../services.common';

const getAllPaypal = async (data) => {
    const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
    const url = `${api}CardInfo/getallpaypalaccount?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
    const authHeader = getAuthHeaders();
    return await get(url, { ...authHeader });
};

const createPaypal = async (formData) => {
    const url = `${api}CardInfo/createpaypalaccount`;
    const authHeader = getAuthHeaders();
    return await post(url, formData, { ...authHeader });
};

const updatePaypal = async (data) => {
    const url = `${api}CardInfo/updatepaypalaccount/${data.id}`;
    const authHeader = getAuthHeaders();
    return await update(url, data, { ...authHeader });
};

const deletePaypal = async (id) => {
    const url = `${api}CardInfo/deletepaypalaccount/${id}`;
    const authHeader = getAuthHeaders();
    return await del(url, { ...authHeader });
};


export const PaypalServices = {
    getAllPaypal,
    createPaypal,
    updatePaypal,
    deletePaypal
}