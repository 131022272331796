import React, { useEffect, useState } from "react";
import mailIcon from "../../images/outgoing_mail.svg";
import { Col, Row, Button } from "react-bootstrap";
import ForwardSuccess from "../../images/forwarded-green.svg";
import ForwardPending from "../../images/forwarded-yellow.svg";
import ForwardFailure from "../../images/forwarded-red.svg";
import ForwardNotStarted from "../../images/forwarded-black.svg";
import TicketMasterSuccess from "../../images/ticket-green.svg";
import TicketMasterPending from "../../images/ticket-yellow.svg";
import TicketMasterFailure from "../../images/ticket-red.svg";
import TicketMasterNotCreated from "../../images/ticket-black.svg";
import CardYellow from "../../images/card-yellow.svg";
import CardGreen from "../../images/card-green.svg";
import CardRed from "../../images/card-red.svg";
import CardBlack from "../../images/card-black.svg";
import copy from "clipboard-copy";
import { useDispatch } from "react-redux";
import { successMessage } from "../../data/reducers/alerts/alert.reducer";
const EmailRow = ({ data, selectOne }) => {
  const [showPass, setPass] = useState(false);
  const [showTmPass, setShowTmPass] = useState(false);
  const dispatch = useDispatch();

  const togglePass = () => {
    setPass(!showPass);
  };
  const toggleTmPass = () => {
    setShowTmPass(!showTmPass);
  };

  const getCardStatus = () => {
    if (!data.isCreateCardChecked) {
      return CardBlack;
    } else if (
      data.isCreateTicketMasterAccountChecked &&
      data.tmCreateAttempts > 10
    ) {
      return CardRed;
    } else if (data.isCreateCardChecked && !data.cardId) {
      return CardYellow;
    } else if (data.cardId) {
      return CardGreen;
    }
  };
  const getFormatedDate = (payload) => {
    const date = new Date(payload);
    return date.toLocaleDateString();
  };

  const getTicketmasterStatus = () => {
    if (!data?.isCreateTicketMasterAccountChecked)
      return TicketMasterNotCreated;
    else if (data.tmCreateStatus == true) return TicketMasterSuccess;
    else if (data.tmCreateAttempts == null || data.tmCreateAttempts <= 10)
      return TicketMasterPending;
    else return TicketMasterFailure;
  };

  const getForwardingStatus = () => {
    if (data.isForwardingEnabled) {
      return ForwardSuccess;
    }
    if (
      !data.isVerificationLinkSent &&
      !data.isVerificationComplete &&
      !data.isForwardingEnabled
    ) {
      return ForwardNotStarted;
    }
    if (
      (!data.verificationLinkSentAttempts ||
        data.verificationLinkSentAttempts < 5) &&
      (!data.verificationCompleteAttempts ||
        data.verificationCompleteAttempts < 5) &&
      (!data.forwardingAttempts || data.forwardingAttempts < 5)
    ) {
      return ForwardPending;
    }
    if (
      data.verificationLinkSentAttempts != null &&
      data.verificationLinkSentAttempts >= 5 &&
      data.verificationCompleteAttempts != null &&
      data.verificationCompleteAttempts >= 5 &&
      data.forwardingAttempts != null &&
      data.forwardingAttempts >= 5
    ) {
      return ForwardFailure;
    }
  };
  return (
    <>
      <Row className="emailBody">
        {/* <Col style={{ width: '20px', maxWidth: '20px' }} className="text-center"><input type="checkbox" className="email-check" checked={data.checked} onChange={(e) => selectOne(data.id, e.target.checked)} /></Col> */}
        <Col className="left"> {data.email}</Col>
        <Col
          id="password"
          style={{ gridTemplateColumns: "1fr 1fr" }}
          className="left"
        >
          <div style={{ textAlign: "left" }}>
            <input
              id="passInp"
              style={{ textAlign: "left" }}
              type={showPass ? "text" : "password"}
              value={data.password}
              readOnly
            />
          </div>
          <div
            className="d2"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <span onClick={togglePass}>
              {showPass ? (
                <i className="fa fa-eye-slash"></i>
              ) : (
                <i className="fa fa-eye"></i>
              )}
            </span>
            <span
              onClick={() => {
                copy(data.password);
                dispatch(successMessage("Password Copied"));
              }}
            >
              <i className="fa fa-copy"></i>
            </span>
          </div>
        </Col>
        <Col
          id="password"
          style={{ gridTemplateColumns: "1fr 1fr" }}
          className="left"
        >
          <div style={{ textAlign: "left" }}>
            <input
              id="passInp"
              style={{ textAlign: "center" }}
              type={showTmPass ? "text" : "password"}
              value={data.tmPassword ?? "-"}
              readOnly
            />
          </div>
          <div
            className="d2"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <span onClick={toggleTmPass}>
              {showTmPass ? (
                <i className="fa fa-eye-slash"></i>
              ) : (
                <i className="fa fa-eye"></i>
              )}
            </span>
            <span
              onClick={() => {
                copy(data.tmPassword ?? "");
                dispatch(successMessage("Password Copied"));
              }}
            >
              <i className="fa fa-copy"></i>
            </span>
          </div>
        </Col>
        <Col className="center">
          {data.recoveryEmail ? data.recoveryEmail : "-"}
        </Col>
        <Col className="center">
          {" "}
          {data.lastLogin ? getFormatedDate(data.lastLogin) : "-"}{" "}
        </Col>
        <Col className="center"> {getFormatedDate(data.uploadedOn)} </Col>
        <Col className="center">
          {data.sentMail ? getFormatedDate(data.sentMail) : "-"}
        </Col>
        <Col
          className="center"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            style={{ height: "20px", marginRight: "8px" }}
            src={getTicketmasterStatus()}
          />
          <img
            style={{ height: "50px", width: "50px", marginRight: "8px" }}
            src={getCardStatus()}
          />
          <img style={{ height: "20px" }} src={getForwardingStatus()} />
        </Col>
        {/* <Col className="center" onClick={() => { console.log("sigle send email clicked"); }}><img src={mailIcon} alt="" /></Col> */}
      </Row>
    </>
  );
};

export default EmailRow;
