import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { vendorChargesServices } from "../../services/vendors/vendor-charges.service";
import fileDownload from "js-file-download";
import { flushTransactions } from "../transactions/cached-transactions.reducer";
import { flushTransactionsCards } from "../transactions/cached-transaction-cards.reducer";

export const exportCSVVendorCharges = createAsyncThunk(
  "vendorCharges/exportCSVVendorCharges",
  async (payload, thunkAPI) => {
    const response = await vendorChargesServices.exportCSVVendorCharges(
      payload
    );
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "VendorCharges.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportPdfVendorCharges = createAsyncThunk(
  "vendorCharges/exportPdfVendorCharges",
  async (payload, thunkAPI) => {
    const response = await vendorChargesServices.exportPdfVendorCharges(
      payload
    );
    // if (response.isSuccessful === true) {
    //   fileDownload(response.data, "VendorCharges.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

export const getAllVendorCharges = createAsyncThunk(
  "vendorCharges/getAllVendorCharges",
  async (payload, thunkAPI) => {
    const response = await vendorChargesServices.getAllVendorCharges(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const createVendorCharges = createAsyncThunk(
  "vendorCharges/createVendorCharges",
  async (payload, thunkAPI) => {
    const response = await vendorChargesServices.createVendorCharge(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushTransactions());
      thunkAPI.dispatch(flushTransactionsCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const updateVendorCharges = createAsyncThunk(
  "vendorCharges/updateVendorCharges",
  async (payload, thunkAPI) => {
    const response = await vendorChargesServices.updateVendorCharge(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushTransactions());
      thunkAPI.dispatch(flushTransactionsCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const deleteVendorCharges = createAsyncThunk(
  "vendorCharges/deleteVendorCharges",
  async (payload, thunkAPI) => {
    const response = await vendorChargesServices.deleteVendorCharge(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushTransactions());
      thunkAPI.dispatch(flushTransactionsCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getVendorCharges = createAsyncThunk(
  "vendorCharges/getVendorCharges",
  async (payload, thunkAPI) => {
    const response = await vendorChargesServices.getAllVendorCharges(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

const vendorChargesSlice = createSlice({
  name: "vendorCharges",
  initialState: {
    vendorsCharges: [],
    paging: {},
    selectedVendorCharge: {},
  },
  reducers: {
    removeSelected: (state) => {
      state.selectedVendorCharge = {};
    },
  },
  extraReducers: {
    [getVendorCharges.fulfilled]: (state, action) => {
      state.vendorsCharges = action.payload.data;
      state.paging = action.payload.paging;
    },
    [createVendorCharges.fulfilled]: (state, action) => {
      state.vendorsCharges.push(action.payload);
    },
    [updateVendorCharges.fulfilled]: (state, action) => {
      const index = state.vendorsCharges.findIndex(
        (v) => v.id == action.payload.id
      );
      state.vendorsCharges[index] = action.payload;
      state.selectedVendorCharge = action.payload;
    },
    [deleteVendorCharges.fulfilled]: (state, action) => {
      state.vendorsCharges = action.payload;
    },
    [deleteVendorCharges.fulfilled]: (state, action) => {
      state.vendorsCharges = [
        ...state.vendorsCharges.filter(
          (vendor) => vendor.id !== action.payload.id
        ),
      ];
    },
  },
});
export const { removeSelected } = vendorChargesSlice.actions;
export default vendorChargesSlice.reducer;
