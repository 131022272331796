import { useDispatch, useSelector } from "react-redux";
import Collapse from "@mui/material/Collapse";
import { TableContainer } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { successMessage } from "../../data/reducers/alerts/alert.reducer";
import { formatNumberToFourDigits } from "../../utils/formatter/currencyFormatter";
import TableRowCard from "../../shared/components/table/TableRowCard";
import TableHead from "../../shared/components/table/parts/TableHead";
import { columnError } from "../../shared/components/table/data/error";
import { withLoader } from "../../utils/hoc/withLoader";
import ShowInfoModal from "../../shared/components/modals/ShowInfoModal";
import { getEmailLogs } from "../../data/reducers/email/email.reducer";
import ShowEmailLogs from "./modals/ShowEmailLogs";
import ProgressBar from "react-bootstrap/ProgressBar";

const Row = (props) => {
  const { ind, row, open, setOpen, data, renderButtons, setBusy, columns } =
    props;

  const cardData = useSelector((state) => state.emails).emailLogs;
  const [isVisible, setIsVisible] = useState(false);
  const SkyBoxRefData = [
    {
      columnName: "Email Health",
      render: (data) => {
        let variant = "warning";
        let now = 50;

        if (data.gmailHealth === "LOW") {
          variant = "danger";
          now = 25;
        } else if (data.gmailHealth === "AVERAGE") {
          variant = "warning";
          now = 50;
        } else if (data.gmailHealth === "HIGH") {
          variant = "success";
          now = 100;
        }

        return (
          <div>
            <ProgressBar variant={variant} now={now} />
            <p>{data.gmailHealth}</p>
          </div>
        );
      },
    },
    {
      columnName: "Last Task Executed",
      render: (data) => (
        <p>
          {data && data.activities && data.activities.length
            ? data.activities[0].lastExecutedTask
            : "-"}
        </p>
      ),
    },
    {
      columnName: "Last Activity Time",
      render: (data) => (
        <p>
          {data && data.activities && data.activities.length
            ? new Date(data.activities[0].createdAt).toLocaleString()
            : "-"}
        </p>
      ),
    },
    {
      columnName: "#Activity Executed",
      render: (data) => <p>{data && data.totalActivitiesCount}</p>,
    },
    {
      columnName: "Success count",
      render: (data) => <p>{data && data.successfullActivitiesCount}</p>,
    },
    {
      columnName: "Actions",
      render: (data) => (
        <p
          style={{ color: "#0241A0", fontWeight: "bold" }}
          onClick={() => {
            setIsVisible(true);
          }}>
          View All Logs
        </p>
      ),
    },
  ];

  return (
    <>
      <div
        className="table-row-card my-3 d-flex justify-content-between px-3 align-items-center"
        style={{
          minHeight: "64px",
          boxShadow: "0px 2px 6px rgb(0 0 0 / 5%)",
          backgroundColor: "white",
        }}>
        {columns?.map((column) => (
          <small
            key={data?.id}
            style={{
              flexGrow: column.flexGrow > 0 ? column.flexGrow : 1,
              flexBasis: 0,
            }}
            className="mx-2"
            align="center">
            {column.render(row, ind, open, setOpen)}
          </small>
        ))}
      </div>
      <div
        style={{
          flexGrow: 1,
          flexBasis: 0,
          backgroundColor: "#FBFBFB",
        }}>
        <Collapse in={open[ind]} timeout="auto" unmountOnExit>
          <div
            id="myDiv"
            style={{ marginRight: cardData?.length >= 4 ? "20px" : "0" }}>
            <TableHead columns={SkyBoxRefData} bcolor="#e8f1fb" color="black" />
          </div>

          <TableContainer
            sx={{
              maxHeight: 300,
            }}>
            <div className="table-body ">
              {data.length > 0 ? (
                <TableRowCard
                  columns={!cardData ? columnError : SkyBoxRefData}
                  rowData={cardData}
                />
              ) : (
                <h5 className="p-5 text-center">No records to display</h5>
              )}
            </div>
          </TableContainer>
        </Collapse>
      </div>
      <ShowEmailLogs
        ModalName="Email Logs"
        isVisible={isVisible}
        handleThisClose={() => {
          setIsVisible(false);
        }}
        workflowname={cardData ? cardData.workFlowName : ""}
        data={cardData.activities}
      />
    </>
  );
};

export default withLoader(Row);
