import React, { useState, useEffect } from "react";
import { withLoader } from "../../utils/hoc/withLoader";
import { useDispatch, useSelector } from "react-redux";

import {
  errorMessage,
  successMessage,
} from "../../data/reducers/alerts/alert.reducer";
import { Row, Col, Button, Form, Dropdown } from "react-bootstrap";

//components
import FilterPanel from "../../shared/components/panels/filter-panel/FilterPanel";
import CollapsibleTable from "./CollapsibleTable";
import SyncCredentialsEventModal from "./modals/SyncCredentialsEventModal";
import FulfillmentEventTableModal from "./modals/FulfillmentEventTableModal";
import FulfillmentEventModal from "./modals/FulfillmentEventModal";
import ConfirmationModal from "../../shared/components/modals/ConfirmationModal";
import RevokeEventModal from "./modals/RevokeEventModal";
import TicketInformationModal from "./modals/TicketInformationModal";

import "../../shared/styles/ModalStyles.scss";
import {
  getAllEvents,
  filterEvent,
  getEventsCsv,
  addSyncEventsPriorityData,
} from "../../data/reducers/events/events.reducers";
import { Controller } from "react-hook-form";
import moment from "moment";
import Select from "react-select";
import {
  getAccountSportList,
  getAccountTeamList,
} from "../../data/reducers/accounts/accounts.reducers";
import {
  getDeliveryMessagesValues,
  revokeTicket,
} from "../../data/reducers/inventory/inventory.reducers";
import { useLocation } from "react-router-dom";
import Export from "../../images/export.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Mixpanel,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../mixpanel";

const EventsDashboard = ({ setBusy }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  //---------------------------------------------------------------------------------------------------------------//
  const queryParameters = new URLSearchParams(window.location.search);
  const page = queryParameters.get("page");

  const [filterUrlData, setFilterUrlData] = useState(null);
  const [filterArray, setFilterArray] = useState([]);
  const [sortField, setSortField] = useState(null);
  const [sortDir, setSortDir] = useState(true);
  const [selectedTabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.EVENTS,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.EVENTS,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (filterUrlData) {
      onSubmit(filterUrlData);
      setFilterArray([
        { key: "searchText", value: filterUrlData?.searchText },
        { key: "email", value: filterUrlData?.email },
        { key: "leagueId", value: filterUrlData?.leagueId },
        { key: "teamId", value: filterUrlData?.teamId },
        { key: "section", value: filterUrlData?.section },
        { key: "startDate", value: filterUrlData?.startDate },
        { key: "endDate", value: filterUrlData?.endDate },
      ]);
    }
  }, [filterUrlData, selectedTabIndex, sortDir, sortField]);

  //-------------------- Param navigation ---------------//

  useEffect(() => {
    if (page) {
      setpageNumber(page);
      setFilterUrlData({
        searchText: queryParameters.get("searchText"),
        email: queryParameters.get("email"),
        leagueId: queryParameters.get("leagueId"),
        teamId: queryParameters.get("teamId"),
        section: queryParameters.get("section"),
        startDate: queryParameters.get("startDate"),
        endDate: queryParameters.get("endDate"),
      });
    } else {
      navigateToMainPage();
    }
  }, [page, location]);

  //--------------------------- filter queries ------------------------//
  const searchQuery = () => {
    var array = window.location.search.slice(1).split("&&");
    var query = "";
    for (let i = 1; i < array.length; i++) {
      query += "&&" + array[i];
    }
    return query;
  };

  //-------------- on page size change --------------//
  const navigateToMainPage = () => {
    history.push(`/events?page=1`);
  };

  const previousPage = () => {
    if (Number(pageNumber) > 1) {
      history.push(`/events?page=${Number(pageNumber) - 1}${searchQuery()}`);
      setpageNumber(Number(pageNumber - 1));
    }
  };

  const nextPage = () => {
    history.push(`/events?page=${Number(pageNumber) + 1}${searchQuery()}`);
    setpageNumber(Number(pageNumber + 1));
  };

  //--------------------- filter Navigation -------------------//

  const filterNavigation = (data) => {
    // var url = `/events?page=${data.searchText ? 1 : page}`
    var url = `/events?page=1`;

    if (data.searchText) {
      url += `&&searchText=${data.searchText}`;
      setpageNumber(1);
    }
    if (data.teamId) url += `&&teamId=${data.teamId}`;
    if (data.email) url += `&&email=${data.email}`;
    if (data.leagueId) url += `&&leagueId=${data.leagueId}`;
    if (data.section) url += `&&section=${data.section}`;
    if (data.startDate) url += `&&startDate=${data.startDate}`;
    if (data.endDate) url += `&&endDate=${data.endDate}`;

    history.push(url);
  };

  //---------------------- change Page Size and make page=1 by default ---------------------//
  const changePageSize = () => {
    let search = String(window.location.search).slice(1);
    const str = search.split("&&");
    let remainStr = "";
    for (let i = 1; i < str.length; i++) {
      remainStr += "&&" + str[i];
    }
    history.push(window.location.pathname + "?page=1" + remainStr);
  };

  //---------------------------------------------------------------------------------------------------------------//

  //use states
  const [addEditEventModalVisibility, setAddEditEventModalVisibility] =
    useState(false);
  const [addEditEventFormModalVisibility, setAddEditEventFormModalVisibility] =
    useState(false);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [selectedTickets, setSelectedTickets] = useState({
    open: null,
    section: null,
    row: null,
    tickets: [],
    ticketsId: [],
  });
  const [pageSize, setpageSize] = useState(40);
  const [pageNumber, setpageNumber] = useState(1);
  const [searchData, setsearchData] = useState(
    location?.state?.email ? { email: location?.state?.email } : null
  );
  const [syncCredentialModal, setSyncCredentialModal] = useState(false);
  const [dropVal, setdropVal] = useState({
    team: "",
    league: "",
  });
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [showConfirmationModalVisible, setShowConfirmationModalVisible] =
    useState(false);

  const [showRevokeEventModal, setShowRevokeEventModal] = useState(false);
  const [showTicketInformationModal, setShowTicketInformationModal] =
    useState(false);
  const [revokeTokenId, setRevokeTokenId] = useState(null);
  const [showErrorMessageData, setShowErrorMessageData] = useState(null);

  //selectors
  const AllEvents = useSelector((state) => state.events).events;
  const paging = useSelector((state) => state.events).paging;
  const syncDate = useSelector((state) => state.vendors).syncDate;
  const teams = useSelector((state) => state.accounts).teamList;
  const sports = useSelector((state) => state.accounts).sportList;
  const { deliveryMessages } = useSelector((state) => state.inventory);

  //--------------------- setting default values --------------------//
  useEffect(() => {
    if (sports && filterUrlData) {
      sports.map((obj) => {
        if (obj.id == filterUrlData.leagueId) {
          setdropVal({ ...dropVal, league: obj });
        }
      });
    }
  }, [sports, filterUrlData]);

  useEffect(() => {
    if (teams && filterUrlData) {
      teams.map((obj) => {
        if (obj.id == filterUrlData.teamId) {
          setdropVal({ ...dropVal, team: obj });
        }
      });
    }
  }, [teams, filterUrlData]);

  //useeffects
  useEffect(() => {
    document.getElementById("filterButton").click();
  }, []);

  useEffect(() => {
    fetchDeliveryMessagesValues();
    dispatch(getAccountSportList());
    dispatch(getAccountTeamList());
  }, []);
  //resets the url state data.
  window.history.replaceState({}, document.title);

  //fetch send delivery message values
  const fetchDeliveryMessagesValues = async () => {
    try {
      setBusy(true);
      dispatch(getDeliveryMessagesValues());
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  // fetch all account information
  const fetchAccountsData = async () => {
    try {
      setBusy(true);
      const data = {
        pageNumber: page,
        pageSize,
        sortDir,
        sortField,
        IsStatusActive: selectedTabIndex,
        currentDateTime: moment(new Date()).format("MM/DD/YYYY hh:mm:ss"),
      };

      const response = await dispatch(getAllEvents(data));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  //search data
  const onSubmit = async (data) => {
    if (data && data.team == undefined) {
      data.team = "";
    }
    if (data && data.league == undefined) {
      data.league = "";
    }
    if (data && data.startDate == undefined) {
      data.startDate = "";
    }
    if (data && data.endDate == undefined) {
      data.endDate = "";
    }
    if (data && data.email == undefined) {
      data.email = "";
    }
    if (data && data.section == undefined) {
      data.section = "";
    }
    if (data && data.verified == undefined) {
      data.verified = "";
    }
    if (data && data?.searchText == undefined) {
      data.searchText = "";
    }
    const formData = {
      ...data,
      searchText:
        data?.searchText !== undefined ? data?.searchText?.trim() : "",
      teamId: data?.teamId ?? "",
      leagueId: data?.leagueId ?? "",
      pageNumber: page,
      sortField,
      sortDir,
      pageSize,
      IsStatusActive: selectedTabIndex,
      currentDateTime: moment(new Date()).format("MM/DD/YYYY hh:mm:ss"),
    };

    setBusy(true);

    if (
      formData?.searchText ||
      formData.team ||
      formData.startDate ||
      formData.endDate ||
      formData.email ||
      formData.section ||
      formData.verified ||
      formData.searchText ||
      formData.startDate ||
      formData.endDate ||
      formData?.teamId ||
      formData?.leagueId
    ) {
      setsearchData(formData);
      await dispatch(filterEvent(formData));
    } else {
      setsearchData(null);
      await fetchAccountsData();
    }

    setBusy(false);
  };

  const onRevokeTicket = async (revokeTokenId) => {
    try {
      setBusy(true);
      const data = {
        fromEvent: revokeTokenId?.soldInventoryId === 0,
        tokenId: revokeTokenId?.tokenId,
        id: revokeTokenId?.soldInventoryId,
      };
      dispatch(revokeTicket(data));
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
      onSubmit(searchData);
      setShowConfirmationModalVisible(false);
    }
  };

  const onExportCSV = async (selected) => {
    setBusy(true);
    if (searchData && selected) {
      await dispatch(getEventsCsv(searchData));
    } else {
      const data = {
        IsStatusActive: selectedTabIndex,
        currentDateTime: moment(new Date()).format("MM/DD/YYYY hh:mm:ss"),
        getAll: true,
      };
      await dispatch(getEventsCsv(data));
    }
    setBusy(false);
  };

  //accounts table row and data
  const eventsCol = [
    {
      columnName: "Sport",
    },
    {
      columnName: "Title",
      sort: true,
      sortName: "name",
    },
    {
      columnName: "Team",
    },
    {
      columnName: "Location",
      sort: true,
      sortName: "venueName",
    },
    {
      columnName: "Date/Time",
      sort: true,
      sortName: "eventDate",
    },
    {
      columnName: "Qty",
    },
    {
      columnName: "Action",
    },
  ];

  //head details
  const headOptions = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small>Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue="40"
              onChange={(e) => {
                setpageSize(e.target.value);
                changePageSize();
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div className="text-end mt-3 d-flex">
          <Dropdown
            className="position-inherit"
            onClick={(e) => e.currentTarget.blur()}
          >
            <Dropdown.Toggle className="ss-light-button" id="dropdown-basic">
              <img src={Export} alt=" " />
              <span>Export</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => onExportCSV(searchData)}>
                Export as CSV
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <div className="d-flex align-items-center justify-content-end">
            <Button
              variant="secondary"
              className="ms-2"
              onClick={() => {
                setSyncCredentialModal(true);
              }}
            >
              <span>Sync Events</span>
            </Button>
          </div>
        </div>
      </>
    );
  };

  const customStyles = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };

  const onSubmitEntries = async (data) => {
    try {
      setBusy(true);
      const response = await dispatch(addSyncEventsPriorityData(data));
      if (response.payload.isSuccessful === false) {
        await dispatch(errorMessage(response.payload.message));
        setShowErrorMessageData(response.payload.data);
      } else {
        await dispatch(
          successMessage(
            "Sync Events Priority Data is Added Successfully. Please check in 24 hours for updated events"
          )
        );
        setSyncCredentialModal(false);
        setShowErrorMessageData(null);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setBusy(false);
    }
  };

  //style for dropdown

  return (
    <div
      onClick={() => {
        Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
          page: mixpanel_contants.EVENTS,
        });
      }}
      className="container-fluid pt-3"
    >
      {/* Filter Panel */}
      <Row>
        <Col>
          <FilterPanel
            dashboard={mixpanel_contants.EVENTS}
            defaultValues={filterArray}
            setPageNumber={setpageNumber}
            searchPlaceholder="Search for team, sport, email, section...."
            onReset={() => {
              setDates({
                startDate: null,
                endDate: null,
              });
              setpageNumber(1);
              setsearchData(null);
              navigateToMainPage();
              setdropVal({
                league: null,
                team: null,
              });
            }}
            onSubmit={filterNavigation}
            resetValues={
              location?.state?.email
                ? [{ name: "email", resetValue: "" }]
                : null
            }
            renderAdvancedPanel={(register, handleSubmit, errors, control) => (
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Team
                    </Form.Label>
                    <Col sm="8">
                      <Controller
                        name="teamId"
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <div>
                              <Select
                                isInvalid={!!errors.sourceType}
                                value={dropVal.team}
                                onChange={(e) => {
                                  field.onChange(e?.id);
                                  setdropVal({ ...dropVal, team: e });
                                }}
                                styles={customStyles}
                                // isClearable
                                options={teams}
                                getOptionLabel={(option) =>
                                  `${option.teamName}`
                                }
                                isClearable
                              />
                            </div>
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Sport
                    </Form.Label>
                    <Col sm="8">
                      <Controller
                        name="leagueId"
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <div>
                              <Select
                                isInvalid={!!errors.sourceType}
                                value={dropVal.league}
                                onChange={(e) => {
                                  field.onChange(e?.id);
                                  setdropVal({ ...dropVal, league: e });
                                }}
                                styles={customStyles}
                                // isClearable
                                options={sports}
                                getOptionLabel={(option) =>
                                  `${option.sportName}`
                                }
                                isClearable
                              />
                            </div>
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row} className="">
                    <Form.Label column sm="4">
                      Start Date
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        // dateFormat="mm-dd-yyyy"
                        placeholder="Start Date"
                        type="date"
                        {...register("startDate")}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      End Date
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        // dateFormat="mm-dd-yyyy"
                        placeholder="End Date"
                        type="date"
                        {...register("endDate")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Section
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="Section"
                        {...register("section")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label style={{ whiteSpace: "nowrap" }} column sm="4">
                      Email
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Email"
                        {...register("email")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            )}
          />
        </Col>
      </Row>

      {/* Data Table */}
      <CollapsibleTable
        previousPage={previousPage}
        nextPage={nextPage}
        paramerterNavigation={true}
        headerName="EVENTS"
        paging={paging}
        columns={eventsCol}
        data={AllEvents || []}
        tabs={["Active", "Past"]}
        selectedTabIndex={selectedTabIndex}
        onTabSelectionChange={(i) => {
          setpageNumber(1);
          setTabIndex(i);
        }}
        sortDir={sortDir}
        setSortDir={setSortDir}
        sortField={sortField}
        setSortField={setSortField}
        headOptions={headOptions}
        setpageNumber={setpageNumber}
        selectedRecord={selectedRecord}
        setSelectedRecord={setSelectedRecord}
        setSelectedTickets={setSelectedTickets}
        setAddEditEventModalVisibility={setAddEditEventModalVisibility}
        setShowRevokeEventModal={setShowRevokeEventModal}
      />

      <FulfillmentEventTableModal
        isVisible={addEditEventModalVisibility}
        handleClose={() => {
          setAddEditEventModalVisibility(false);
          setSelectedTickets({
            open: null,
            section: null,
            row: null,
            tickets: [],
            ticketsId: [],
          });
        }}
        selectedRecord={selectedRecord}
        setSelectedRecord={setSelectedRecord}
        selectedTabIndex={selectedTabIndex}
        paging={paging}
        setSortField={setSortField}
        sortDir={sortDir}
        sortField={sortField}
        setSortDir={setSortDir}
        headOptions={headOptions}
        setpageNumber={setpageNumber}
        selectedTickets={selectedTickets}
        setSelectedTickets={setSelectedTickets}
        addEditEventModalVisibility={addEditEventModalVisibility}
        setAddEditEventModalVisibility={setAddEditEventModalVisibility}
        setAddEditEventFormModalVisibility={setAddEditEventFormModalVisibility}
      />

      <FulfillmentEventModal
        isVisible={addEditEventFormModalVisibility}
        selectedRecord={selectedRecord}
        setAddEditInventoryModalVisibility={setAddEditEventModalVisibility}
        handleClose={() => {
          if (searchData) {
            onSubmit(searchData);
          } else {
            fetchAccountsData();
          }
          setAddEditEventFormModalVisibility(false);
        }}
        selectedTickets={selectedTickets}
        //onSubmit={onSubmitForm}
        setBusy={setBusy}
        deliveryMessages={deliveryMessages}
      />

      <RevokeEventModal
        isVisible={showRevokeEventModal}
        handleClose={() => setShowRevokeEventModal(false)}
        selectedRecord={selectedRecord}
        setSelectedRecord={setSelectedRecord}
        setShowRevokeEventModal={setShowRevokeEventModal}
        setShowConfirmationModalVisible={setShowConfirmationModalVisible}
        setRevokeTokenId={setRevokeTokenId}
        setShowTicketInformationModal={setShowTicketInformationModal}
      />

      {showTicketInformationModal && (
        <TicketInformationModal
          isVisible={showTicketInformationModal}
          handleClose={() => {
            setShowTicketInformationModal(false);
            setShowRevokeEventModal(true);
          }}
          selectedRecord={selectedRecord}
          deliveryMessages={deliveryMessages}
        />
      )}

      <ConfirmationModal
        isVisible={showConfirmationModalVisible}
        handleClose={() => {
          setShowConfirmationModalVisible(false);
          setShowRevokeEventModal(true);
        }}
        onConfirmation={() => onRevokeTicket(revokeTokenId)}
        confirmationTxt={
          revokeTokenId?.soldInventoryId > 0
            ? "These tickets are linked to a sales entry, revoking will set the fulfilment status to pending, are you sure you want to revoke?"
            : "Are you sure you want to revoke the Event Ticket?"
        }
      />

      <SyncCredentialsEventModal
        isVisible={syncCredentialModal}
        handleClose={() => {
          setSyncCredentialModal(false);
          setShowErrorMessageData(null);
        }}
        onSubmitEntry={onSubmitEntries}
        showErrorMessageData={showErrorMessageData}
      />
    </div>
  );
};

export default withLoader(EventsDashboard);
