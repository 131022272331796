import React, { useEffect } from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import Down from "../../images/downarrow.svg";
import Up from "../../images/uparrow.svg";
import HeaderPart from "../../shared/components/table/parts/HeaderPart";
import FooterPart from "../../shared/components/table/parts/FooterPart";
import TableHead from "../../shared/components/table/parts/TableHead";
import TableRowCard from "../../shared/components/table/TableRowCard";
import { columnError } from "../../shared/components/table/data/error";
import moment from "moment";
import { formatMoney } from "../../utils/formatter/currencyFormatter";

function Row(props) {
	const { ind, row, open, setOpen, data } = props;

	const eventDropCol = [
		{
			columnName: "Section",
			render: (data) => <>{data.section}</>,
		},
		{
			columnName: "Row",
			render: (data) => <>{data.row}</>,
		},
		{
			columnName: "Seats",
			render: (data) => (
				<>
					{data.lowSeat} - {data?.highSeat}
				</>
			),
		},
		{
			columnName: "Qty",
			render: (data) => <>{data.quantity}</>,
		},
	];

	const creditCard = (row) => {
		const creditCardData = row?.cardInfoDetails?.map((card) => {
			const expC = (
				<>
					{card.ccNumber}
					<br />
					{card.cardCompany}
				</>
			);
			return expC;
		});
		return creditCardData
		? creditCardData.reduce((prev, curr) => prev ?  [prev, "/", curr] : [curr], "")
		: null;
	};

	const getCardExpCvcData = (row) => {
		const epvCvcCard = row?.cardInfoDetails?.map((card) => {
			const expC = (
				<>
					{card.expDate}
					<br />
					{card.securityCode}
				</>
			);
			return expC;
		});
		return epvCvcCard
		? epvCvcCard.reduce((prev, curr) => prev ?  [prev, "/", curr] : [curr], "")
		: null;
	};

	return (
		<>
			<div className="table-row-card my-3 d-flex justify-content-between px-3 py-2 align-items-center">
				<small
					style={{ flexGrow: 1, flexBasis: 0, textAlign: "center" }}
					className="mx-2"
				>
					{row.accountName}
				</small>
				<small
					style={{ flexGrow: 1, flexBasis: 0, textAlign: "center" }}
					className="mx-2"
				>
					{row.vendorName}
				</small>
				<small
					style={{ flexGrow: 1, flexBasis: 0, textAlign: "center" }}
					className="mx-2"
				>
					{formatMoney(row.total)}
				</small>
				<small
					style={{
						flexGrow: 1,
						flexBasis: 0,
						textAlign: "center",
						overflow: "hidden",
						textOverflow: "ellipsis",
					}}
					className="mx-2"
					align="center"
				>
					{row.email}
					{row?.phoneNumber && "/"}
					{row.phoneNumber}
				</small>
				<small
					style={{ flexGrow: 1, flexBasis: 0, textAlign: "center" }}
					className="mx-2"
					align="center"
				>
					{row?.address}
				</small>
				<small
					style={{ flexGrow: 1, flexBasis: 0, textAlign: "center" }}
					className="mx-2"
					align="center"
				>
					{creditCard(row)}
				</small>
				<small
					style={{ flexGrow: 1, flexBasis: 0, textAlign: "center" }}
					className="mx-2"
					align="center"
				>
					{getCardExpCvcData(row)}
				</small>
				<small
					style={{ flexGrow: 1, flexBasis: 0, textAlign: "center" }}
					className="mx-2"
					align="center"
				>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => {
							if (open[ind]) {
								setOpen(Array(data.length).fill(false));
							} else {
								const temp = Array(data.length).fill(false);
								temp[ind] = !temp[ind];
								setOpen(temp);
							}
						}}
					>
						<img src={open[ind] ? Up : Down} alt="up down image" />
					</IconButton>
				</small>
			</div>
			<div
				style={{
					flexGrow: 1,
					flexBasis: 0,
					backgroundColor: "#FBFBFB",
				}}
			>
				<Collapse in={open[ind]} timeout="auto" unmountOnExit>
					<div id="myDiv">
						<TableHead columns={eventDropCol} bcolor="#e8f1fb" color="black" />
					</div>

					<TableContainer
					// sx={{
					// 	height: 300,
					// }}
					>
						<div className="table-body">
							{row.seatsDetails.length > 0 ? (
								row.seatsDetails.map((d) => (
									<TableRowCard
										key={d.id}
										columns={d.nodata ? columnError : eventDropCol}
										rowData={d}
									/>
								))
							) : (
								<h5 className="p-5 text-center">No records to display</h5>
							)}
						</div>
					</TableContainer>
				</Collapse>
			</div>
		</>
	);
}

export default function CollapsibleTable({
	headerName,
	paging,
	columns,
	data,
	selectedTabIndex,
	tabs,
	onTabSelectionChange,
	headOptions,
	headButton,
	setpageNumber,
	setSortField,
	sortDir,
	middleOptions,
	setSortDir,
	sortField,
}) {
	const [open, setOpen] = React.useState([]);

	useEffect(() => {
		setOpen(Array(data.length).fill(false));
	}, []);

	return (
		<div className="quicktix-table">
			<HeaderPart
				headerName={headerName}
				tabs={tabs}
				headButton={headButton}
				selectedTabIndex={selectedTabIndex}
				onTabSelectionChange={onTabSelectionChange}
				headOptions={headOptions}
				middleOptions={middleOptions}
			/>

			<TableHead
				columns={columns}
				setSortField={setSortField}
				sortDir={sortDir}
				setSortDir={setSortDir}
				sortField={sortField}
			/>

			<TableContainer>
				<div className="table-body">
					{data?.length > 0 ? (
						data.map((row, ind) => (
							<Row
								key={ind}
								ind={ind}
								row={row}
								open={open}
								setOpen={setOpen}
								data={data}
							/>
						))
					) : (
						<h5 className="p-5 text-center">No records to display</h5>
					)}
				</div>
			</TableContainer>

			{paging && (
				<FooterPart
					paging={paging}
					setpageNumber={setpageNumber}
					setSortDir={setSortDir}
					sortDir={sortDir}
					sortField={sortField}
					setSortField={setSortField}
				/>
			)}
		</div>
	);
}
