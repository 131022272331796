import moment from "moment";
import {
  api,
  getAuthHeaders,
  get,
  post,
  update,
  del,
  getCSV,
  getPDF,
} from "../services.common";
import { buildUrlWithParams } from "../api";

const getAllPO = async (data) => {
  const sortDir = data.sortDir == false ? "Descending" : "Ascending";
  const url = `${api}PurchaseOrders/getall?searchString=${
    data.searchText || ""
  }&VendorId=${data.vendorId || ""}&PageNumber=${
    data.pageNumber || ""
  }&PageSize=${data.pageSize || ""}&Description=${
    data.description || ""
  }&PurchaseOrderId=${data.purchaseOrderId || ""}&AccountId=${
    data.accountId || ""
  }&SortDir=${sortDir}&SortField=${data.sortField || ""}&isReviewed=${
    data?.isReviewed
  }&SportId=${data?.sportId || ""}&TeamId=${data?.teamId || ""}&StartDate=${
    data?.startDate || ""
  }&EndDate=${data?.endDate || ""}&SeasonId=${
    data?.seasonId || ""
  }&SkyBoxPurchaseId=${
    data?.skyBoxPurchaseId || data?.skyBoxPurchaseId === 0
      ? data?.skyBoxPurchaseId
      : ""
  }&month=${data?.month || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getPurchaseOrderById = async (id) => {
  const url = `${api}PurchaseOrders/get/${id}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getPurchaseOrderList = async (id) => {
  const url = `${api}PurchaseOrders/getlist`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const createPO = async (data) => {
  const url = `${api}PurchaseOrders/create`;
  const authHeader = getAuthHeaders();
  return await post(url, data, { ...authHeader });
};

const updatePO = async (data) => {
  const url = `${api}PurchaseOrders/update/${data.id}`;
  const authHeader = getAuthHeaders();
  return await update(url, data, { ...authHeader });
};
const togglePOSkyboxRef = async (id) => {
  const url = `${api}PurchaseOrders/togglePOSkyboxRef/${id}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const deletePO = async (id) => {
  const url = `${api}PurchaseOrders/delete/${id}`;
  const authHeader = getAuthHeaders();
  return await del(url, { ...authHeader });
};

const searchPO = async (str) => {
  const url = `${api}PurchaseOrders/search?searchString=${str}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const exportCSVPurchaseOrder = async () => {
  const url = `${api}PurchaseOrders/exportcsv`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const exportPdfPurchaseOrder = async () => {
  const url = `${api}PurchaseOrders/exportpdf`;
  const authHeader = getAuthHeaders();
  return await getPDF(url, { ...authHeader }, "PurchaseOrders.pdf");
};

const filterPO = async (data) => {
  const sortDir = data.sortDir == false ? "Descending" : "Ascending";
  const url = `${api}PurchaseOrders/search?searchString=${
    data.searchText || ""
  }&VendorId=${data.vendorId}&PageNumber=${data.pageNumber}&PageSize=${
    data.pageSize
  }&Description=${data.description}&PurchaseOrderId=${
    data.purchaseOrderId
  }&AccountId=${data.accountId || ""}&SortDir=${sortDir}&SortField=${
    data.sortField || ""
  }&isReviewed=${data?.isReviewed}&SportId=${data?.sportId || ""}&TeamId=${
    data?.teamId || ""
  }&StartDate=${data?.startDate || ""}&EndDate=${
    data?.endDate || ""
  }&SeasonId=${data?.seasonId}&SkyBoxPurchaseId=${
    data?.skyBoxPurchaseId || data?.skyBoxPurchaseId === 0
      ? data?.skyBoxPurchaseId
      : ""
  }&month=${data?.month || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const exportSelectedAsCSVPO = async (data) => {
  const url = `${api}PurchaseOrders/searchexportcsv?searchString=${
    data.searchText || ""
  }
  &AccountId=${data.accountId || ""}&VendorId=${
    data.vendorId || ""
  }&PurchaseOrderId=${data.purchaseOrderId || ""}
  &Description=${data.description || ""}&isReviewed=${
    data.isReviewed
  }&StartDate=${data?.startDate || ""}&EndDate=${data?.endDate || ""}
  &TeamId=${data?.teamId || ""}&SportId=${data?.sportId || ""}&SeasonId=${
    data?.seasonId || ""
  }&SkyBoxPurchaseId=${
    data?.skyBoxPurchaseId || data?.skyBoxPurchaseId === 0
      ? data?.skyBoxPurchaseId
      : ""
  }&month=${data?.month || ""}`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const exportSelectedAsPdfPO = async (data) => {
  const url = `${api}PurchaseOrders/searchexportpdf?searchString=${
    data.searchText || ""
  }&AccountId=${data.accountId || ""}&VendorId=${
    data.vendorId || ""
  }&PurchaseOrderId=${data.purchaseOrderId || ""}&Description=${
    data.description || ""
  }&isReviewed=${data.isReviewed}&StartDate=${data?.startDate || ""}&EndDate=${
    data?.endDate || ""
  }&TeamId=${data?.teamId || ""}&SportId=${data?.sportId || ""}&SeasonId=${
    data?.seasonId || ""
  }&SkyBoxPurchaseId=${
    data?.skyBoxPurchaseId || data?.skyBoxPurchaseId === 0
      ? data?.skyBoxPurchaseId
      : ""
  }&month=${data?.month || ""}`;
  const authHeader = getAuthHeaders();
  return await getPDF(url, { ...authHeader }, "PurchaseOrdersFilter.pdf");
};

const exportCSV = async (data) => {
  const url = buildUrlWithParams(
    `${api}PurchaseOrders/export-purchaseorder/CSV`,
    data
  );
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const exportPDF = async (data) => {
  const url = buildUrlWithParams(
    `${api}PurchaseOrders/export-purchaseorder/PDF`,
    data
  );
  const authHeader = getAuthHeaders();
  return await getPDF(
    url,
    { ...authHeader },
    `PurchaseOrder-${moment().format("DD/MM/YYYY")}.pdf`
  );
};

const getPOCards = async (data) => {
  const url = `${api}PurchaseOrdercards/get?searchString=${
    data.searchText || ""
  }&VendorId=${data.vendorId || ""}&Description=${
    data.description || ""
  }&PurchaseOrderId=${data.purchaseOrderId || ""}&AccountId=${
    data.accountId || ""
  }&isReviewed=${true}&SportId=${data?.sportId || ""}&TeamId=${
    data?.teamId || ""
  }&StartDate=${data?.startDate || ""}&EndDate=${data.endDate || ""}&SeasonId=${
    data?.seasonId || ""
  }&SkyBoxPurchaseId=${
    data?.skyBoxPurchaseId || data?.skyBoxPurchaseId === 0
      ? data?.skyBoxPurchaseId
      : ""
  }&month=${data?.month || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getPOCardsById = async (data) => {
  const url = `${api}PurchaseOrdercards/getbypurchaseorderid/${data}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const exportCSVPaymentPlans = async () => {
  const url = `${api}purchaseorderpaymentplans/paymentplansexportcsv`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const exportSelectedAsCSVPaymentPlans = async (data) => {
  const url = buildUrlWithParams(
    `${api}purchaseorderpaymentplans/paymentplansFilterexportcsv`,
    data
  );
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

export const poServices = {
  getAllPO,
  createPO,
  updatePO,
  deletePO,
  searchPO,
  filterPO,
  exportPdfPurchaseOrder,
  getPurchaseOrderById,
  getPurchaseOrderList,
  exportCSVPurchaseOrder,
  getPOCardsById,
  exportSelectedAsCSVPO,
  getPOCards,
  exportSelectedAsPdfPO,
  exportSelectedAsCSVPaymentPlans,
  exportCSVPaymentPlans,
  exportCSV,
  exportPDF,
  togglePOSkyboxRef,
};
