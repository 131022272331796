import { Route, Switch } from "react-router-dom";
import SeasonDashboard from "../../../pages/season/SeasonDashboard";
import SingleSeasonDashboard from "../../../pages/season/SingleSeasonDashboard";


const AddressBookRoutes = () => {
    return (
        <Switch>
            <Route path="/season" exact>
                <SeasonDashboard />
            </Route>
            <Route path="/season/:id" exact>
                <SingleSeasonDashboard />
            </Route>
        </Switch>
    );
};

export default AddressBookRoutes;