import {
  api,
  getAuthHeaders,
  get,
  post,
  del,
  update,
  getCSV,
  getPDF
} from "../services.common";

const getAllVendorRules = async (data) => {
  const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
  if (data.id) {
    const url = `${api}VendorRules/getall?vendorId=${data.id}&PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
    const authHeader = getAuthHeaders();
    return await get(url, { ...authHeader });
  }
};

const getVendorRuleById = async (data) => {
  const url = `${api}VendorRules/get/${data.id}?vendorId=${data.vendorId}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const exportCSVVendorRules = async (id) => {
  const url = `${api}VendorRules/exportcsv?vendorId=${id}`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const exportPdfVendorRules = async (id) => {
  const url = `${api}VendorRules/exportpdf?vendorId=${id}`;
  const authHeader = getAuthHeaders();
  return await getPDF(url, { ...authHeader }, "VendorRules.pdf");
};

const createVendorRule = async (data) => {
  const url = `${api}VendorRules/create?vendorId=${data?.vendorId}&searchId=${data?.searchId}`;
  const authHeader = getAuthHeaders();
  return await post(url, data.formData, { ...authHeader });
};

const updateVendorRule = async (data) => {
  const url = `${api}VendorRules/update/${data?.formData.id}?vendorId=${data?.vendorId}`;
  const authHeader = getAuthHeaders();
  return await update(url, data.formData, { ...authHeader });
};

const deleteVendorRule = async (data) => {
  const url = `${api}VendorRules/delete/${data?.mainId}?vendorId=${data?.id}`;
  const authHeader = getAuthHeaders();
  return await del(url, { ...authHeader });
};

export const vendorRulesServices = {
  getAllVendorRules,
  getVendorRuleById,
  createVendorRule,
  updateVendorRule,
  deleteVendorRule,
  exportCSVVendorRules,
  exportPdfVendorRules
};
