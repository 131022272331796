import {
  api,
  getAuthHeaders,
  post,
  update,
  del,
  get,
  getCSV,
  getPDF
} from "../services.common";

const createAccountRules = async (data) => {
  const url = `${api}AccountRules/create?accountId=${data.Id}`;
  const authHeader = getAuthHeaders();
  return await post(url, data.formData, { ...authHeader });
};
const updateAccountRules = async (data) => {
  const url = `${api}AccountRules/update/${data.id}?accountId=${data.accountId}`;
  const authHeader = getAuthHeaders();
  return await update(url, data, { ...authHeader });
};
const exportCSVAccountRules = async (id) => {
  const url = `${api}AccountRules/exportcsv?accountId=${id}`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};
const exportPdfAccountRules = async (id) => {
  const url = `${api}AccountRules/exportpdf?accountId=${id}`;
  const authHeader = getAuthHeaders();
  return await getPDF(url, { ...authHeader }, "AccountRules.pdf");
};
const deleteAccountRules = async (data) => {
  const url = `${api}AccountRules/delete/${data.mainId}?accountId=${data.id}`;
  const authHeader = getAuthHeaders();
  return await del(url, { ...authHeader });
};

const getAccountRules = async (data) => {
  const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
  const url = `${api}AccountRules/getall?accountId=${data.id}&PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getSelectedRules = async (data) => {
  const url = `${api}/AccountRules/get/${data.id}?accountId=${data.accID}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const accountRulesServices = {
  createAccountRules,
  updateAccountRules,
  deleteAccountRules,
  getSelectedRules,
  getAccountRules,
  exportCSVAccountRules,
  exportPdfAccountRules
};
