import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../services/api";

export const fetchSkyboxPurchases = createAsyncThunk(
  "cachedSkyboxPurchases/fetchData",
  async (params) => {
    try {
      const response = await api.get("SkyboxPurchase/search", params);
      return { params, response };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const flushSkyboxPurchases = createAsyncThunk(
  "cachedSkyboxPurchases/flushSkyboxPurchases",
  () => true
);

const cachedSkyboxPurchasesSlice = createSlice({
  name: "cachedSkyboxPurchases",
  initialState: {
    data: null,
    error: null,
    loading: false,
  },
  extraReducers: {
    [fetchSkyboxPurchases.pending]: (state) => {
      state.loading = true;
    },
    [fetchSkyboxPurchases.fulfilled]: (state, action) => {
      const { params, response } = action.payload;
      state.loading = false;
      state.error = null;
      if (!state.data) {
        state.data = {
          [JSON.stringify(params)]: {
            skyboxPurchases: response.data,
            paging: response.paging,
            timestamp: Date.now(),
          },
        };
      } else {
        state.data[JSON.stringify(params)] = {
          skyboxPurchases: response.data,
          paging: response.paging,
          timestamp: Date.now(),
        };
      }
    },
    [fetchSkyboxPurchases.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // flush
    [flushSkyboxPurchases.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = null;
    },
  },
});

export default cachedSkyboxPurchasesSlice.reducer;
