import React from "react";
import PaymentBox from "./PaymentBox";
import moment from "moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Accordion from "react-bootstrap/Accordion";
import { Card, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import Calendar from "../../../images/calendar.svg";
import ChevronUP from "../../../images/chevron-up.svg";
import ChevronRight from "../../../images/chevron-right.svg";
import { formatMoney } from "../../../utils/formatter/currencyFormatter";
import AccountInfo from "./AccountInfo";
import NewWindowIcon from "../../../images/next_window.svg";
import { useHistory, Link } from "react-router-dom";

const PaymentRow = ({
  weekDays,
  dates,
  selectedData,
  arrayRef,
  clickedDate,
  setClickedDate,
}) => {
  const history = useHistory();
  const { calendarData } = useSelector((state) => state.sync);
  const [accordionIndex, setAccordionIndex] = React.useState(null);

  const [vendorId, setVendorId] = React.useState(undefined);

  const currentDate = moment().format("YYYY-MM-DD");
  const isPresentDay = (index) => {
    const tempDate = moment([
      selectedData?.year,
      selectedData?.month,
      dates[index]?.date,
    ]).format("YYYY-MM-DD");
    return moment(tempDate).isSame(currentDate) && dates[index]?.isCurrentMonth;
  };

  const getTempDate = (index) => {
    const date = dates[index];
    let month = "";
    let year = selectedData?.year;
    if (date?.isPreviousMonth) {
      if (selectedData?.month === 0) {
        year = year - 1;
        month = 11;
      } else {
        month = selectedData?.month - 1;
      }
    } else if (date?.isNextMonth) {
      if (selectedData?.month === 11) {
        year = year + 1;
        month = 0;
      } else {
        month = selectedData?.month + 1;
      }
    } else {
      month = selectedData?.month;
    }

    const tempDate = moment([year, month, date?.date]);
    return tempDate;
  };

  const getPaymentData = (index) => {
    const tempDate = getTempDate(index).format("YYYY-MM-DD");

    const paymentData = calendarData?.find(
      (x) => moment(x?.paymentDate).format("YYYY-MM-DD") === tempDate
    );
    return paymentData;
  };

  const getMaxHeight = (vendors, vendorId) => {
    const selectedVendor = vendors.find((x) => x.id === vendorId);
    if (selectedVendor) {
      const maxHeight =
        ((selectedVendor?.accounts?.length > 5
          ? 5
          : selectedVendor?.accounts?.length) * 60 ?? 0) + 120;
      return maxHeight;
    } else {
      return "120px";
    }
  };

  const popover = (paymentData, index) => {
    return (
      <Popover id="popover-basic" style={{ maxWidth: "100%", width: "870px" }}>
        <Popover.Title
          as="h3"
          style={{ backgroundColor: "#0241A0", color: "white" }}
          className="py-4 px-2"
        >
          <div className="d-flex justify-content-between align-items-center">
            <p
              className="m-0 d-flex align-items-center"
              style={{ flex: "1", minWidth: "140px" }}
            >
              <img
                src={Calendar}
                style={{ width: "16px", height: "16px", marginRight: "5px" }}
              />
              <span className="ml-2">
                {paymentData?.paymentDate
                  ? moment(paymentData?.paymentDate).format("DD MMMM YYYY")
                  : getTempDate(index).format("DD MMMM YYYY")}
              </span>
            </p>{" "}
            <div
              className="m-0 text-center"
              style={{ flex: "1", minWidth: "120px" }}
            >
              <p className="mb-1">
                {formatMoney(paymentData?.totalScheduled ?? 0)}{" "}
              </p>
              <p className="mb-0 px-1">Scheduled</p>
            </div>
            <div
              className="m-0 text-center"
              style={{ flex: "1", minWidth: "120px" }}
            >
              <p className="mb-1">
                {formatMoney(paymentData?.totalPaid ?? 0)}{" "}
              </p>
              <p className="mb-0 px-1">Actual Paid</p>
            </div>
            <div
              className="m-0 text-center"
              style={{ flex: "1", minWidth: "120px" }}
            >
              <p className="mb-1">
                {formatMoney(
                  (paymentData?.totalScheduled ?? 0) -
                    (paymentData?.totalPaid ?? 0)
                )}
              </p>
              <p className="mb-0 px-1">Pending</p>
            </div>
          </div>
        </Popover.Title>
        <Popover.Content>
          <p className="text-muted">Card Details</p>
          {!paymentData?.details && <p className="text-center">No Data</p>}
          <Accordion
            defaultActiveKey={`${accordionIndex}`}
            // style={{ backgroundColor: "rgba(60, 149, 255, 0.1)" }}
          >
            {paymentData?.details?.map((data, index) => (
              <div
                key={index}
                style={{
                  backgroundColor:
                    index === accordionIndex
                      ? "rgba(60, 149, 255, 0.1)"
                      : "unset",
                }}
                className="my-2"
              >
                <Accordion.Toggle
                  eventKey={`${index}`}
                  style={{
                    backgroundColor: "rgba(167, 167, 167, 0.2)",
                    border: "none",
                    borderRadius: "6px",
                    color: "black",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    if (index === accordionIndex) {
                      setAccordionIndex(null);
                    } else {
                      setAccordionIndex(index);
                    }
                  }}
                  className="d-flex justify-content-between align-items-center p-2 w-100"
                >
                  <p
                    className="m-0"
                    style={{ flex: "1", minWidth: "100px", textAlign: "left" }}
                  >
                    {[data?.card, data?.cardType]
                      ?.filter((x) => !!x)
                      .join(", ")}
                  </p>
                  <div className="m-0" style={{ flex: "1", minWidth: "100px" }}>
                    <p className="mb-0 px-1 text-center">
                      {formatMoney(data?.cardTotalScheduled ?? 0)}{" "}
                    </p>
                  </div>
                  <div className="m-0" style={{ flex: "1", minWidth: "100px" }}>
                    <p className="mb-0 px-1 text-center">
                      {formatMoney(data?.cardTotalPaid ?? 0)}{" "}
                    </p>
                  </div>
                  <p
                    className="m-0 text-center"
                    style={{ flex: "1", minWidth: "100px" }}
                  >
                    {formatMoney(
                      (data?.cardTotalScheduled ?? 0) -
                        (data?.cardTotalPaid ?? 0)
                    )}{" "}
                    <span>
                      <img
                        src={
                          accordionIndex === index ? ChevronUP : ChevronRight
                        }
                        style={{
                          width: "16px",
                          height: "16px",
                          marginLeft: "5px",
                        }}
                      />
                    </span>
                  </p>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={`${index}`}>
                  <div
                    style={{
                      maxHeight: getMaxHeight(data?.vendors, vendorId),
                      overflowX: "scroll",
                    }}
                  >
                    {data?.vendors?.map((vendor) => (
                      <>
                        <div
                          className="d-flex justify-content-between align-items-center p-2 w-100"
                          key={vendor?.id}
                          style={{
                            backgroundColor:
                              vendorId === vendor?.id ? "#3C95FF1A" : "inherit",
                            color:
                              vendorId === vendor?.id ? "#0241A0" : "inherit",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (vendor?.id === vendorId) {
                              setVendorId(undefined);
                            } else {
                              setVendorId(vendor?.id);
                            }
                          }}
                        >
                          <p
                            className="mr-2 mb-0 ml-0"
                            style={{
                              flex: "1",
                              minWidth: "100px",
                              cursor: "pointer",
                            }}
                          >
                            {vendor?.name}

                            {/* opening in vendor tab in Purchase Orders */}
                            <Link
                              to={`vendors/${vendor?.id}?tabIdx=2`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={NewWindowIcon}
                                style={{ marginLeft: "7px", width: "13px" }}
                                alt="Open in new window"
                                onClick={(e)=>e.stopPropagation()}
                              />
                              
                            </Link>
                          </p>
                          <div
                            className="m-0 text-center"
                            style={{ flex: "1", minWidth: "100px" }}
                          >
                            <p className="mb-0 px-1 text-center">
                              {formatMoney(vendor?.vendorTotalScheduled ?? 0)}{" "}
                            </p>
                          </div>
                          <div
                            className="m-0"
                            style={{ flex: "1", minWidth: "100px" }}
                          >
                            <p className="mb-0 px-1 text-center">
                              {formatMoney(vendor?.vendorTotalPaid ?? 0)}{" "}
                            </p>
                          </div>
                          <div
                            className="m-0"
                            style={{ flex: "1", minWidth: "100px" }}
                          >
                            <p className="mb-0 px-1 text-center">
                              {formatMoney(
                                (vendor?.vendorTotalScheduled ?? 0) -
                                  (vendor?.vendorTotalPaid ?? 0)
                              )}{" "}
                              <span>
                                <img
                                  src={
                                    vendorId === vendor?.id
                                      ? ChevronUP
                                      : ChevronRight
                                  }
                                  style={{
                                    width: "16px",
                                    height: "16px",
                                    marginLeft: "5px",
                                  }}
                                />
                              </span>
                            </p>
                          </div>
                        </div>
                        <AccountInfo vendor={vendor} vendorId={vendorId} />
                      </>
                    ))}
                  </div>
                </Accordion.Collapse>
              </div>
            ))}
          </Accordion>
        </Popover.Content>
      </Popover>
    );
  };

  return (
    <div className="d-flex justify-content-between align-items-center collection-calendar">
      {weekDays.map((column, index) => (
        <OverlayTrigger
          placement="auto"
          overlay={popover(getPaymentData(index), index)}
          key={index}
          trigger={getPaymentData(index) ? ["click"] : "click"}
        >
          <div
            style={{
              flex: "1 1 0px",
              textAlign: "left",
              minHeight: "170px",
              minWidth: "160px",
              // maxWidth: "160px",
              backgroundColor:
                isPresentDay(index) ||
                clickedDate === getTempDate(index).format("YYYY-MM-DD")
                  ? `rgba(60, 149, 255, 0.1)`
                  : "white",
              cursor: "pointer",
              // border: "1px solid rgba(0, 0, 0, 0.13)",
            }}
            className="child d-flex flex-column justify-content-between p-2 hover-blue"
            ref={(elm) => {
              arrayRef.current[getTempDate(index).format("YYYY-MM-DD")] = elm;
            }}
            onClick={() => {
              setAccordionIndex(null);
              if (!clickedDate) {
                setClickedDate(getTempDate(index).format("YYYY-MM-DD"));
              } else if (
                getTempDate(index).format("YYYY-MM-DD") === clickedDate
              ) {
                setClickedDate(null);
              } else {
                arrayRef.current[clickedDate].click();
                setClickedDate(getTempDate(index).format("YYYY-MM-DD"));
              }
            }}
          >
            <PaymentBox
              weekDay={column}
              date={dates[index]}
              selectedData={selectedData}
              isPresentDay={isPresentDay(index)}
              clickedDate={clickedDate}
            />
          </div>
        </OverlayTrigger>
      ))}
    </div>
  );
};

export default PaymentRow;
