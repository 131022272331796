import {
	api,
	getAuthHeaders,
	get,
	post,
	update,
	del,
	getCSV,
	getPDF,
} from '../services.common';

const getAllPSL = async (data) => {
	const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
	const url = `${api}PSLCharges/getall?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
	const authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};

const deletePSL = async (id) => {
	const url = `${api}PSLCharges/delete/${id}`;
	const authHeader = getAuthHeaders();
	return await del(url, { ...authHeader });
};

const filterPSL = async (data) => {
	const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
	const url = `${api}PSLCharges/search?searchString=${data.searchText || ""}&Source=${data.source || ""}&SportId=${data.sportId || ""}&TeamId=${data.teamId || ""}&PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&Quantity=${data.quantity || ""}&Section=${data.section || ""}&Row=${data.row || ""}&Seats=${data.seats || ""}&Cost=${data.cost || ""}&Date=${data.date || ""}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
	const authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};

const exportSelectedAsCSVPSL = async (data) => {
	const url = `${api}PSLCharges/searchexportcsv?searchString=${data.searchText || ""}&Source=${data.source || ""}&SportId=${data.sportId}&TeamId=${data.teamId}&Quantity=${data.quantity}&Section=${data.section}&Row=${data.row}&Seats=${data.seats}&Cost=${data.cost || ""}&Date=${data.date}`;
	const authHeader = getAuthHeaders();
	return await getCSV(url, { ...authHeader });
};

const exportSelectedAsPdfPSL = async (data) => {
	const url = `${api}PSLCharges/searchexportpdf?searchString=${data.searchText || ""}&Source=${data.source}&SportId=${data.sportId}&TeamId=${data.teamId}&Quantity=${data.quantity}&Section=${data.section}&Row=${data.row}&Seats=${data.seats}&Cost=${data.cost || ""}&Date=${data.date}`;
	const authHeader = getAuthHeaders();
	return await getPDF(url, { ...authHeader }, "PSLFilter.pdf");
};

const createPSL = async (formData) => {
	const url = `${api}PSLCharges/create`;
	const authHeader = getAuthHeaders();
	return await post(url, formData, { ...authHeader });
};

const updatePSL = async (data) => {
	const url = `${api}PSLCharges/update/${data.id}`;
	const authHeader = getAuthHeaders();
	return await update(url, data, { ...authHeader });
};

const exportCSVPSL = async () => {
	const url = `${api}PslCharges/exportcsv`;
	const authHeader = getAuthHeaders();
	return await getCSV(url, { ...authHeader });
};

const exportPdfPSL = async () => {
	const url = `${api}PslCharges/exportpdf`;
	const authHeader = getAuthHeaders();
	return await getPDF(url, { ...authHeader }, "PSL.pdf");
};

const headerInfo = async () => {
	const url = `${api}PSLCharges/getheaderinfo`;
	const authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};


export const PSLServices = {
	exportCSVPSL,
	deletePSL,
	filterPSL,
	getAllPSL,
	createPSL,
	updatePSL,
	headerInfo,
	exportSelectedAsCSVPSL,
	exportSelectedAsPdfPSL,
	exportPdfPSL


};
