import { Route, Switch } from "react-router-dom";
import EmailData from "../../../pages/email/EmailDashboard"
import EmailUploadDashboard from "../../../pages/email-upload/EmailUploadDashboard";

const EmailRoutes = () => {
  return (
    <Switch>
      <Route path="/email" exact>
        <EmailData />
      </Route>
      <Route path="/upload/email" exact>
        <EmailUploadDashboard />
      </Route>
    </Switch>
  );
};

export default EmailRoutes;