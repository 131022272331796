import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../services/api";

export const fetchVendorsCards = createAsyncThunk(
  "cachedVendorsCards/fetchData",
  async (params) => {
    try {
      const response = await api.get("vendors/getcards", params);
      return {
        params,
        response: response,
      };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const flushVendorsCards = createAsyncThunk(
  "cachedVendorsCards/flushStore",
  async () => true
);

const cachedAccounsCardsSlice = createSlice({
  name: "cachedVendorsCards",
  initialState: {
    data: null,
    error: null,
    loading: false,
  },
  extraReducers: {
    [fetchVendorsCards.pending]: (state) => {
      state.loading = true;
    },
    [fetchVendorsCards.fulfilled]: (state, action) => {
      const { params, response } = action.payload;
      if (!state.data) {
        state.data = {
          [JSON.stringify(params)]: {
            data: response.data,
          },
        };
      } else {
        state.data[JSON.stringify(params)] = {
          data: response.data,
        };
      }

      state.loading = false;
      state.error = null;
    },
    [fetchVendorsCards.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [flushVendorsCards.fulfilled]: (state) => {
      state.data = null;
      state.error = null;
      state.loading = false;
    },
  },
});

export default cachedAccounsCardsSlice.reducer;
