import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import fileDownload from "js-file-download";
import { TransactionsServices } from "../../services/transactions/transactions.service";
import { errorMessage, successMessage } from "../alerts/alert.reducer";
import { flushTransactions } from "../transactions/cached-transactions.reducer";
import { flushTransactionsCards } from "../transactions/cached-transaction-cards.reducer";

export const getAllMatched = createAsyncThunk(
  "Transactions/getAllMatched",
  async (payload, thunkAPI) => {
    const response = await TransactionsServices.getAllMatched(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAllReviewed = createAsyncThunk(
  "Transactions/getAllReviewed",
  async (payload, thunkAPI) => {
    const response = await TransactionsServices.getAllReviewed(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAllCreditsOrDeclined = createAsyncThunk(
  "Transactions/getAllCreditsOrDeclined",
  async (payload, thunkAPI) => {
    const response = await TransactionsServices.getAllCreditsOrDeclined(
      payload
    );
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const unmatchTransactions = createAsyncThunk(
  "Transactions/UpdateTransactions",
  async (payload, thunkAPI) => {
    const response = await TransactionsServices.unmatchTransactions(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const matchTransactions = createAsyncThunk(
  "Transactions/matchTransactions",
  async (payload, thunkAPI) => {
    const response = await TransactionsServices.matchTransactions(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getRecordsToMatch = createAsyncThunk(
  "transactions/getRecordsToMatch",
  async (payload, thunkAPI) => {
    const response = await TransactionsServices.getRecordsToMatch(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportCSVTransactions = createAsyncThunk(
  "transactions/exportCSVTransactions",
  async (payload, thunkAPI) => {
    const response = await TransactionsServices.exportCSVTransactions(payload);
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "Transactions.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportPdfTrans = createAsyncThunk(
  "transactions/exportPdfTrans",
  async (payload, thunkAPI) => {
    const response = await TransactionsServices.exportPdfTrans(payload);
    // if (response.isSuccessful === true) {
    //     fileDownload(response.data, "Transactions.pdf")
    // } else {
    //     thunkAPI.rejectWithValue(response.message);
    //     throw new Error(response.message);
    // }
  }
);

export const exportPdfTransactions = createAsyncThunk(
  "transactions/exportPdfTransactions",
  async (payload, thunkAPI) => {
    const response = await TransactionsServices.exportPdfTransactions(payload);
    // if (response.isSuccessful === true) {
    //     fileDownload(response.data, "Transactions.pdf")
    // } else {
    //     thunkAPI.rejectWithValue(response.message);
    //     throw new Error(response.message);
    // }
  }
);

export const downloadTemplateTransactions = createAsyncThunk(
  "transactions/downloadTemplateTransactions",
  async (thunkAPI) => {
    const response = await TransactionsServices.downloadTemplateTrans();
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "Transactions Template.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const uploadTemplateTransactions = createAsyncThunk(
  "transactions/uploadTemplateTransactions",
  async (payload, thunkAPI) => {
    const response = await TransactionsServices.uploadTemplateTrans(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushTransactions());
      thunkAPI.dispatch(flushTransactionsCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const monthDropdown = createAsyncThunk(
  "transaction/monthFilter",
  async (payload, thunkAPI) => {
    const response = await TransactionsServices.monthDropdown();

    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const modalSearch = createAsyncThunk(
  "transactions/filterTransModal",
  async (payload, thunkAPI) => {
    const response = await TransactionsServices.modalSearch(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportSelectedCSVTrans = createAsyncThunk(
  "transactions/exportSelectedCSV",
  async (params, thunkAPI) => {
    const response = await TransactionsServices.exportSelectedCSVTrans(params);
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "Transactions Filter.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportSelectedPdfTrans = createAsyncThunk(
  "transactions/exportSelectedPdfTrans",
  async (payload, thunkAPI) => {
    const response = await TransactionsServices.exportSelectedPdfTrans(payload);

    if (response.isSuccessful === true) {
      fileDownload(response.data, "Transactions Filter.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const runRules = createAsyncThunk(
  "Transactions/runRules",
  async (_, thunkAPI) => {
    const response = await TransactionsServices.runRules();
    if (response.isSuccessful === true) {
      if (response.statusMessage) {
        thunkAPI.dispatch(successMessage(response.statusMessage));
      }
      return response;
    } else {
      thunkAPI.dispatch(errorMessage(response.message));
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const resetAccount = createAsyncThunk(
  "Transactions/resetAccount",
  async (payload, thunkAPI) => {
    const response = await TransactionsServices.resetAccount(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const resetVendor = createAsyncThunk(
  "Transactions/resetVendor",
  async (payload, thunkAPI) => {
    const response = await TransactionsServices.resetVendor(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

const TransactionsSlice = createSlice({
  name: "transactions",
  initialState: {
    data: [],
    paging: {},
    recordsToMatch: [],
    cards: {},
    monthDropdownData: [],
  },
  extraReducers: {
    [getAllMatched.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.paging = action.payload.paging;
    },
    [getAllReviewed.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.paging = action.payload.paging;
    },
    [getAllCreditsOrDeclined.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.paging = action.payload.paging;
    },
    [getRecordsToMatch.fulfilled]: (state, action) => {
      state.recordsToMatch = action.payload.data;
      if (action.payload.paging) {
        state.paging = action.payload.paging;
      }
    },
    [unmatchTransactions.fulfilled]: (state, action) => {
      const index = state.data.findIndex(
        (Transactions) => Transactions?.id == action.payload?.id
      );
      state.data[index] = action.payload;
    },
    [modalSearch.fulfilled]: (state, action) => {
      state.recordsToMatch = action.payload.data;
    },
    [monthDropdown.fulfilled]: (state, action) => {
      state.monthDropdownData = action.payload.data;
    },
  },
});

export default TransactionsSlice.reducer;
