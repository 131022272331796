import {
  api,
  getAuthHeaders,
  post,
  update,
  del,
  get,
  getCSV,
  getPDF
} from "../services.common";

const createPurchaseOrderCharges = async (data) => {
  if (data.purchaseOrderId) {
    const url = `${api}PurchaseOrdersCharges/create?purchaseOrderId=${data?.purchaseOrderId}`;
    const authHeader = getAuthHeaders();
    return await post(url, data, { ...authHeader });
  }
};

const exportCSVPurchaseOrderCharges = async (id) => {
  const url = `${api}PurchaseOrdersCharges/exportcsv?purchaseOrderId=${id}`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const exportPdfPurchaseOrderCharges = async (id) => {
  const url = `${api}PurchaseOrdersCharges/exportpdf?purchaseOrderId=${id}`;
  const authHeader = getAuthHeaders();
  return await getPDF(url, { ...authHeader }, "PurchaseOrderCharges.pdf");
};

const updatePurchaseOrderCharges = async (data) => {
  const url = `${api}PurchaseOrdersCharges/update/${data.id}?purchaseOrderId=${data?.purchaseOrderId}`;
  const authHeader = getAuthHeaders();
  return await update(url, data, { ...authHeader });
};

const deletePurchaseOrderCharges = async (data) => {
  const url = `${api}PurchaseOrdersCharges/delete/${data.mainId}?purchaseOrderId=${data.id}`;
  const authHeader = getAuthHeaders();
  return await del(url, { ...authHeader });
};

const getPurchaseOrderCharges = async (data) => {
  const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
  const url = `${api}PurchaseOrdersCharges/getall?purchaseOrderId=${data.id}&PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const poChargesServices = {
  createPurchaseOrderCharges,
  updatePurchaseOrderCharges,
  deletePurchaseOrderCharges,
  getPurchaseOrderCharges,
  exportCSVPurchaseOrderCharges,
  exportPdfPurchaseOrderCharges
};
