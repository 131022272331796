import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { eventServices } from "../../services/events/event.service";
import fileDownload from "js-file-download";
import { errorMessage, successMessage } from "../alerts/alert.reducer";

export const getAllEvents = createAsyncThunk(
	"events/getAllEvents",
	async (payload, thunkAPI) => {
		const response = await eventServices.getAllEvents(payload);
		if (response.isSuccessful === true) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const filterEvent = createAsyncThunk(
	"events/filterEvent",
	async (payload, thunkAPI) => {
		const response = await eventServices.filterEvent(payload);
		if (response.isSuccessful === true) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const getEventsTicketTokens = createAsyncThunk(
	"events/getEventsTicketTokens",
	async (payload, thunkAPI) => {
		const response = await eventServices.getEventsTicketTokens(payload);
		if (response.isSuccessful === true) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const getEventsCsv = createAsyncThunk(
	"events/getEventsCsv",
	async (payload, thunkAPI) => {
		const response = await eventServices.getEventsCsv(payload);
		if (response.isSuccessful === true) {
			fileDownload(response.data?.data, "EventsExport.csv");
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const addSyncEventsPriorityData = createAsyncThunk(
	"events/add-sync-events-priority-data",
	async (payload, thunkAPI) => {
		const response = await eventServices.addSyncEventsPriorityData(payload);
		return response
	}
);

const eventSlice = createSlice({
	name: "events",
	initialState: {
		events: [],
		eventTickets: [],
		paging: {},
	},
	extraReducers: {
		[getAllEvents.fulfilled]: (state, action) => {
			state.events = action.payload.data;
			state.paging = action.payload.paging;
		},
		[filterEvent.fulfilled]: (state, action) => {
			state.events = action.payload.data;
			state.paging = action.payload.paging;
		},
		[getEventsTicketTokens.fulfilled]: (state, action) => {
			state.eventTickets = action.payload.data;
		},
	},
});

export default eventSlice.reducer;
