import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  errorMessage,
  successMessage,
} from "../../../data/reducers/alerts/alert.reducer";
import { getPurchaseOrderRefs } from "../../../data/reducers/skybox-purchases/skybox-purchases.reducer";
import {
  formatMoney,
  formatNumberToFourDigits,
} from "../../../utils/formatter/currencyFormatter";
import { formatDate } from "../../../utils/formatter/dateFormatter";
import IconButton from "@mui/material/IconButton";
import Down from "../../../images/downarrow.svg";
import Up from "../../../images/uparrow.svg";
import Collapse from "@mui/material/Collapse";
import TableHead from "../../../shared/components/table/parts/TableHead";
import { TableContainer } from "@mui/material";
import TableRowCard from "../../../shared/components/table/TableRowCard";
import { columnError } from "../../../shared/components/table/data/error";
import { withLoader } from "../../../utils/hoc/withLoader";
import moment from "moment";
import Copy from "../../../images/copy-2.svg";
import ShowInfoModal from "../../../shared/components/modals/ShowInfoModal";
import { useState } from "react";

const Row = (props) => {
  const { ind, row, open, setOpen, data, loading, columns } = props;
  const dispatch = useDispatch();
  const cardData = useSelector((state) => state.addressBook).accountsByAddress;
  const [isVisible, setIsVisible] = useState(false);
  const [selectedCardData, setSelectedCardData] = useState(null);

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      await dispatch(successMessage("Copied Successfully!"));
    } catch (error) {
      console.error(error);
    }
  };
  const rows = [
    {
      id: 1,
      section: "",
      dataColumns: [
        {
          id: 1,
          name: "CC Account",
          value: selectedCardData?.ccAccount ?? "-",
        },
        {
          id: 2,
          name: "Card Type",
          value: selectedCardData?.cardType ?? "-",
        },
      ],
    },
    {
      id: 4,
      section: "Basic Information",
      dataColumns: [
        {
          id: 1,
          name: "First Name",
          value: selectedCardData?.firstName ?? "-",
        },
        {
          id: 2,
          name: "Last Name",
          value: selectedCardData?.lastName ?? "-",
        },
        {
          id: 3,
          name: "Email",
          value: selectedCardData?.emailId ?? "-",
        },
        {
          id: 4,
          name: "Status",
          value: selectedCardData?.isActive ? "Active" : "Not Active",
        },
      ],
    },
    {
      id: 2,
      section: "Company Details",
      dataColumns: [
        {
          id: 1,
          name: "Company",
          value: selectedCardData?.company ?? "-",
        },
        {
          id: 2,
          name: "CC Number",
          value: selectedCardData?.ccNumber
            ? formatNumberToFourDigits(selectedCardData.ccNumber)
            : "-",
        },
        {
          id: 3,
          name: "Card ID",
          value: selectedCardData?.cardId ?? "-",
        },
        {
          id: 4,
          name: "Card Nickname",
          value: selectedCardData?.nickName ?? "-",
        },
        {
          id: 5,
          name: "CVV",
          value: selectedCardData?.securityCode ?? "-",
        },
        {
          id: 6,
          name: "Notes",
          value: selectedCardData?.notes ?? "-",
        },
      ],
    },
    {
      id: 3,
      section: "Address Details",
      dataColumns: [
        {
          id: 1,
          name: "Address",
          takeFullRow: true,
          value: [
            selectedCardData?.address1,
            selectedCardData?.address2,
            selectedCardData?.city,
            selectedCardData?.state,
            selectedCardData?.postalCode,
          ]
            ?.filter((x) => x)
            .join(" "),
        },
      ],
    },
  ];

  const AddCopyFunctionality = (value) => (
    <div className="d-flex align-items-center justify-content-center">
      <p className="mb-0">{value ? value : "-"}</p>
      {value && (
        <img
          src={Copy}
          alt="copy"
          style={{ cursor: "pointer", paddingLeft: "7px" }}
          onClick={(e) => {
            copyToClipboard(value);
          }}
        />
      )}
    </div>
  );

  const SkyBoxRefData = [
    {
      columnName: "Team/Sport",
      render: (data) => (
        <div className="">
          <p className="mb-0">
            {data?.sportName && data?.teamName ? (
              <span>
                {data?.teamName}
                <br />
                {data?.sportName}
              </span>
            ) : (
              data.teamId
            )}
          </p>
        </div>
      ),
    },
    {
      columnName: "Name/Account",
      render: (data) => (
        <div >
          <Link
            className="mb-0 ss-link"
            to={`/accounts/${data?.accountId}?accountRowId=${data?.id}`}
         
          >
            {data.name}
            <br />
            {data?.accountId}
          </Link>
        </div>
      ),
    },
    {
      columnName: "Vendor",
      render: (data) => (
        <Link variant="link" to={`vendors/${data.vendorId}?tabIdx=0`} className="ss-link">
          <p className="mb-0">{data.vendorName}</p>
          <p className="mb-0">{data.vendorId}</p>
        </Link>
      ),
    },
  ];

  return (
    <>
      <div
        className="table-row-card my-3 d-flex justify-content-between px-3 align-items-center"
        style={{
          minHeight: "64px",
          boxShadow: "0px 2px 6px rgb(0 0 0 / 5%)",
          backgroundColor: "white",
        }}
      >
        {columns?.map((column) => (
          <small
            key={data?.id}
            style={{
              flexGrow: column.flexGrow > 0 ? column.flexGrow : 1,
              flexBasis: 0,
              wordBreak: "break-word",
              // textAlign: "left",
            }}
            className="mx-2"
            align="center"
          >
            {column.render(row, ind, open, setOpen)}
          </small>
        ))}
      </div>
      <div
        style={{
          flexGrow: 1,
          flexBasis: 0,
          backgroundColor: "#FBFBFB",
        }}
      >
        <Collapse in={open[ind]} timeout="auto" unmountOnExit>
          <div
            id="myDiv"
            style={{ marginRight: cardData?.length >= 4 ? "20px" : "0" }}
          >
            <TableHead columns={SkyBoxRefData} bcolor="#e8f1fb" color="black" />
          </div>

          <TableContainer
            sx={{
              maxHeight: 300,
            }}
          >
            <div className="table-body">
              {cardData?.length === 0 && (
                <h5 className="p-5 text-center">No records to display</h5>
              )}
              {data.length > 0 ? (
                cardData.map((d, indx) => (
                  <TableRowCard
                    key={d.id}
                    columns={d.nodata ? columnError : SkyBoxRefData}
                    rowData={d}
                    ind={ind}
                    accountId={row.accountId}
                    email={row.email}
                    loading={loading}
                  />
                ))
              ) : (
                <h5 className="p-5 text-center">No records to display</h5>
              )}
            </div>
          </TableContainer>
        </Collapse>
      </div>
      <ShowInfoModal
        ModalName="Card"
        isVisible={isVisible}
        handleThisClose={() => {
          setIsVisible(false);
        }}
        columns={rows}
      />
    </>
  );
};

export default withLoader(Row);
