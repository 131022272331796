import PropTypes from "prop-types";
import "../TableStyle.scss";
// import EditIcon from '../../../../images/edit.svg'
import Down from "../../../../images/arrowDown.svg";
import Up from "../../../../images/arrowUp.svg";

const TableHead = ({
  columns,
  setSortField,
  onChangeFieldDirection,
  sortDir,
  setSortDir,
  sortField,
  bcolor,
  color,
}) => {
  // const name = ["Action", "City", "State", "Zip Code", "CVV", "Actions", "Address", "Exp Date", "CC Number", "Email"];

  return (
    <div
      className="table-head d-flex justify-content-between px-3 py-2 align-items-center"
      style={{ background: bcolor, color: color, fontWeight: 500 }}
    >
      {columns.map((column, index) => (
        <div
          key={index}
          style={{
            flexGrow: column.flexGrow > 0 ? column.flexGrow : 1,
            flexBasis: 0,
            textAlign: "center",
          }}
          className="mx-2"
        >
          <small>{column.columnName}</small>

          {column.sort == true && (
            <>
              <img
                onClick={() => {
                  onChangeFieldDirection &&
                    onChangeFieldDirection({
                      sortField: column.sortName,
                      sortDirection: !sortDir,
                    });
                  setSortField(column.sortName);
                  setSortDir(!sortDir);
                }}
                src={
                  sortDir == true && column.sortName == sortField ? Down : Up
                }
                style={{
                  cursor: "pointer",
                }}
                alt=" "
              />
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default TableHead;
