import {
  api,
  getAuthHeaders,
  get,
  post,
  update,
  del,
  getCSV
} from "../services.common";

const getAllCards = async (data) => {
  const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
  const url = `${api}CardInfo/getall?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getAllCardsTransactions = async (data) => {
  const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
  const url = `${api}CardInfo/getalltransactions?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getTransactionById = async (data) => {
  const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
  const url = `${api}CardInfo/gettransactionsbyid/${data.id}?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getCardById = async (id) => {
  const url = `${api}CardInfo/get/${id}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getTopCards = async (id) => {
  const url = `${api}CardInfo/trancationcardByCardInfo?SourceId=${id}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const createCardWEX = async (data) => {
  const url = `${api}CardInfo/wexcreatecard`;
  const authHeader = getAuthHeaders();
  return await post(url, data, { ...authHeader });
};
const createCard = async (data) => {
  const url = `${api}CardInfo/create`;
  const authHeader = getAuthHeaders();
  return await post(url, data, { ...authHeader });
};

const updateCard = async (data) => {
  const url = `${api}CardInfo/update/${data.id}`;
  const authHeader = getAuthHeaders();
  return await update(url, data, { ...authHeader });
};
const updateCardWEX = async (data) => {
  const url = `${api}CardInfo/wexupdatecard`;
  const authHeader = getAuthHeaders();
  return await update(url, data, { ...authHeader });
};

const deleteCard = async (id) => {
  const url = `${api}CardInfo/delete/${id}`;
  const authHeader = getAuthHeaders();
  return await del(url, { ...authHeader });
};

const deleteCardWEX = async (id) => {
  const url = `${api}CardInfo/wexdeletecard/${id}`;
  const authHeader = getAuthHeaders();
  return await del(url, { ...authHeader });
};

const filterCard = async (data) => {
  const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
  const url = `${api}CardInfo/search?searchString=${data.searchText || ""}&CardId=${data.cardId || ""}&CcNumber=${data.ccNumber || ""}&CcAccount=${data.ccAccount || ""}&FirstName=${data.firstName}&LastName=${data.lastName}&Active=${data.status || 2}&Notes=${data.notes}&Company=${data.company}&PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const exportCSVCards = async () => {
  const url = `${api}CardInfo/exporttransactionscsv`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const getCardTypeDetails = async () => {
  const url = `${api}CardInfo/CardTypeDetails`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader })
}

export const cardServices = {
  getAllCards,
  createCard,
  createCardWEX,
  updateCard,
  updateCardWEX,
  deleteCard,
  deleteCardWEX,
  filterCard,
  exportCSVCards,
  getCardById,
  getAllCardsTransactions,
  getTransactionById,
  getTopCards,
  getCardTypeDetails
};
