import { poServices } from "../../services/purchaseOrders/po.service";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import fileDownload from "js-file-download";
import moment from "moment";
import { flushPurchaseOrders } from "../purchaseOrders/cached-po.reducer";
import { flushPurchaseOrdersCards } from "../purchaseOrders/cached-po-cards.reducer";

export const createPO = createAsyncThunk(
  "purchaseOrders/CreatePO",
  async (payload, thunkAPI) => {
    const response = await poServices.createPO(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushPurchaseOrders());
      thunkAPI.dispatch(flushPurchaseOrdersCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAllPOByID = createAsyncThunk(
  "purchaseOrder/getAllPOByID",
  async (payload, thunkAPI) => {
    const response = await poServices.getPurchaseOrderById(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const togglePOSkyboxRef = createAsyncThunk(
  "purchaseOrders/togglePOSkyboxRef",
  async (payload, thunkAPI) => {
    const response = await poServices.togglePOSkyboxRef(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const updatePO = createAsyncThunk(
  "purchaseOrders/updatePO",
  async (payload, thunkAPI) => {
    const response = await poServices.updatePO(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushPurchaseOrders());
      thunkAPI.dispatch(flushPurchaseOrdersCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const deletePO = createAsyncThunk(
  "purchaseOrders/deletePO",
  async (payload, thunkAPI) => {
    const response = await poServices.deletePO(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushPurchaseOrders());
      thunkAPI.dispatch(flushPurchaseOrdersCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAllPO = createAsyncThunk(
  "purchaseOrders/getAllPO",
  async (payload, thunkAPI) => {
    const response = await poServices.getAllPO(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getPOList = createAsyncThunk(
  "purchaseOrders/getPurchaseOrderList",
  async (payload, thunkAPI) => {
    const response = await poServices.getPurchaseOrderList(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getPOCards = createAsyncThunk(
  "po/getAccountsCards",
  async (payload, thunkAPI) => {
    const response = await poServices.getPOCards(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getPOCardsById = createAsyncThunk(
  "po/getAccountsCardsById",
  async (payload, thunkAPI) => {
    const response = await poServices.getPOCardsById(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportCSVPurchaseOrder = createAsyncThunk(
  "purchaseOrders/exportCSVPurchaseOrder",
  async (thunkAPI) => {
    const response = await poServices.exportCSVPurchaseOrder();
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "PurchaseOrders.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportPdfPurchaseOrder = createAsyncThunk(
  "purchaseOrders/exportPdfPurchaseOrder",
  async (thunkAPI) => {
    const response = await poServices.exportPdfPurchaseOrder();
    // if (response.isSuccessful === true) {
    //   fileDownload(response.data, "PurchaseOrders.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

export const searchPO = createAsyncThunk(
  "accounts/searchPO",
  async (payload, thunkAPI) => {
    const response = await poServices.searchPO(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportSelectedAsCSVPO = createAsyncThunk(
  "po/exportCSVPO",
  async (payload, thunkAPI) => {
    const response = await poServices.exportSelectedAsCSVPO(payload);
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "PurchaseOrderFilter.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportCSVPaymentPlans = createAsyncThunk(
  "purchaseOrders/exportCSVPaymentPlans",
  async (thunkAPI) => {
    const response = await poServices.exportCSVPaymentPlans();
    if (response.isSuccessful === true) {
      fileDownload(
        response.data?.data,
        `PurchaseOrders-PaymentPlans-${moment().format("MM/DD/YYYY")}.csv`
      );
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportSelectedAsCSVPaymentPalns = createAsyncThunk(
  "po/exportSelectedAsCSVPaymentPalns",
  async (payload, thunkAPI) => {
    const response = await poServices.exportSelectedAsCSVPaymentPlans(payload);
    if (response.isSuccessful === true) {
      fileDownload(
        response.data?.data,
        `PaymentPlansFilter-${moment().format("MM/DD/YYYY")}.csv`
      );
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportCSV = createAsyncThunk(
  "po/exportCSVorPDF",
  async (payload, thunkAPI) => {
    const response = await poServices.exportCSV(payload);
    if (response.isSuccessful === true) {
      fileDownload(
        response.data?.data,
        `PurchaseOrder-${moment().format("DD/MM/YYYY")}.csv`
      );
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);
export const exportPDF = createAsyncThunk(
  "po/exportCSVorPDF",
  async (payload, thunkAPI) => {
    const response = await poServices.exportPDF(payload);
    if (response.isSuccessful === true) {
      fileDownload(response.data);
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportSelectedAsPdfPO = createAsyncThunk(
  "po/exportPdfPO",
  async (payload, thunkAPI) => {
    const response = await poServices.exportSelectedAsPdfPO(payload);
    // if (response.isSuccessful === true) {
    //   fileDownload(response.data, "PurchaseOrderFilter.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

export const filterPO = createAsyncThunk(
  "accounts/filterPO",
  async (payload, thunkAPI) => {
    const response = await poServices.filterPO(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

const poSlice = createSlice({
  name: "purchaseOrder",
  initialState: {
    purchaseOrders: [],
    POList: [],
    selectedPO: {},
    paging: {},
    cards: {},
    allCards: {},
    addOrEdit: false,
  },
  reducers: {
    changePOEditState: (state, action) => {
      state.addOrEdit = action.payload;
    },
  },
  extraReducers: {
    [searchPO.fulfilled]: (state, action) => {
      state.purchaseOrders = action.payload;
    },
    [filterPO.fulfilled]: (state, action) => {
      state.purchaseOrders = action.payload.data;
      state.paging = action.payload.paging;
    },
    [getAllPO.fulfilled]: (state, action) => {
      state.purchaseOrders = action.payload.data;
      state.paging = action.payload.paging;
    },
    [getPOList.fulfilled]: (state, action) => {
      state.POList = action.payload;
    },
    [createPO.fulfilled]: (state, action) => {
      state.purchaseOrders.push(action.payload);
    },
    [getAllPOByID.fulfilled]: (state, action) => {
      state.selectedPO = action.payload;
    },
    [getPOCardsById.fulfilled]: (state, action) => {
      state.cards = action.payload;
    },
    [getPOCards.fulfilled]: (state, action) => {
      state.allCards = action.payload;
    },
    [updatePO.fulfilled]: (state, action) => {
      const index = state.purchaseOrders.findIndex(
        (po) => po.id == action.payload.id
      );
      state.purchaseOrders[index] = action.payload;
      state.selectedCharges = action.payload;
    },
    [deletePO.fulfilled]: (state, action) => {
      state.purchaseOrders = [
        ...state.purchaseOrders.filter((po) => po.id !== action.payload.id),
      ];
    },
  },
});

export const { removeSelected, changePOEditState } = poSlice.actions;

export default poSlice.reducer;
