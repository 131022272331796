import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState, Fragment } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import AddIcon from "../../../images/addPlus.svg";
import DeleteIcon from "../../../images/delete.svg";

const SyncCredentialsEventModal = ({
  isVisible,
  handleClose,
  onSubmitEntry,
  showErrorMessageData,
}) => {
  const { vendorsListForDropdown: vendorDetails } = useSelector(
    (state) => state.cachedVendors
  );

  const accountDetails = useSelector((state) => state.accounts).accountsList;

  const [showType, setshowType] = useState(undefined);

  const [rowData, setRowData] = useState([
    {
      id: Date.now(),
      accountId: null,
      vendorId: null,
      shouldShow: false,
    },
  ]);
  // reset form on visibility toggle
  useEffect(() => {
    reset();
    if (isVisible) {
      setRowData([
        {
          id: Date.now(),
          accountId: null,
          vendorId: null,
          shouldShow: false,
        },
      ]);
    }
  }, [isVisible]);

  // update values in form if selected record is changed
  // useEffect(() => {
  // 	if (selectedRecord) {
  // 		// as soon as selected record is changed, set values in modal popup
  // 		setEdit(true);
  // 		for (const [key, value] of Object.entries(selectedRecord)) {
  // 			setValue(key, value, {
  // 				shouldValidate: true,
  // 				shouldDirty: true,
  // 			});
  // 		}
  // 		if (selectedRecord?.categoryId && categories?.length > 0)
  // 			setType(
  // 				categories?.filter((c) => c?.id === selectedRecord?.categoryId)[0]
  // 			);
  // 	} else {
  // 		// if selected record is undefined, reset
  // 		setEdit(false);
  // 		reset();
  // 	}
  // }, [selectedRecord, isVisible]);
  // setup react hook form
  const { handleSubmit, reset, control } = useForm();

  const customStyles = {
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };

  const validateSumbit = () => {
    if (rowData?.length === 0) {
      return;
    }
    if (!rowData?.some((row) => shouldShow(row))) {
      onSubmitEntry([
        ...rowData.map((x) => {
          return {
            vendorId: x?.vendorId?.id,
            accountId: x?.accountId?.accountId,
          };
        }),
      ]);
    }
  };

  const shouldShow = (row) => {
    if (row?.accountId && row?.vendorId) {
      return rowData?.some(
        (x) =>
          row.id !== x.id &&
          x.vendorId?.id === row?.vendorId?.id &&
          x.accountId?.accountId === row?.accountId?.accountId
      );
    }
    return false;
  };

  const isEventInQueue = (row) => {
    if (row?.accountId && row?.vendorId) {
      return showErrorMessageData?.some(
        (x) =>
          x.vendorId === row?.vendorId?.id &&
          x.accountId === row?.accountId?.accountId
      );
    }
    return false;
  };

  return (
    <>
      <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
        {/* <Form> */}
        <Form onSubmit={handleSubmit(validateSumbit)}>
          <Modal.Header closeButton>
            <Modal.Title>SYNC CREDENTIALS</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                name: "Events",
                active: true,
              },
              {
                name: "Sync Credentials",
                active: false,
              },
            ]}
          />
          <Modal.Body>
            <div className="container-fluid p-2 w-100 h-100">
              {showType && (
                <div className="text-start mt-1">
                  <small style={{ color: "red" }}>
                    Maximum 3 rows are allowed
                  </small>
                </div>
              )}
              {rowData?.map((row) => (
                <Fragment key={row?.id}>
                  <Row className="mt-3">
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          Team
                        </Form.Label>
                        <Col sm="6">
                          <Controller
                            name={`team-${row?.id}`}
                            control={control}
                            rules={{ required: true, message: "error message" }}
                            render={({
                              field,
                              fieldState: { invalid, error },
                            }) => {
                              return (
                                <div>
                                  <Select
                                    placeholder="Team Name"
                                    value={row?.vendorId}
                                    disabled
                                    onChange={(e) => {
                                      field.onChange(e?.id);
                                      const rows = rowData?.map((x) => {
                                        if (x?.id === row?.id) {
                                          return {
                                            ...x,
                                            vendorId: e,
                                          };
                                        } else {
                                          return x;
                                        }
                                      });
                                      setRowData([...rows]);
                                    }}
                                    styles={customStyles}
                                    options={
                                      row?.accountId
                                        ? vendorDetails?.filter(
                                            (x) =>
                                              x.id ===
                                                row?.accountId?.vendorId &&
                                              !!x.accountUrl
                                          )
                                        : vendorDetails.filter(
                                            (x) => !!x.accountUrl
                                          )
                                    }
                                    getOptionLabel={(option) =>
                                      `${option?.id}  ${option.name}`
                                    }
                                    getOptionValue={({ id }) => id}
                                    isClearable
                                  />
                                  {invalid && error ? (
                                    <div className="custom-invalid-feedback">
                                      Team is required.
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              );
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          Email
                        </Form.Label>
                        <Col sm="6">
                          <Controller
                            name={`email-${row?.id}`}
                            control={control}
                            rules={{ required: true, message: "error message" }}
                            render={({
                              field,
                              fieldState: { invalid, error },
                            }) => {
                              return (
                                <div>
                                  <Select
                                    value={row?.accountId}
                                    placeholder="Email Address"
                                    disabled
                                    onChange={(e) => {
                                      field.onChange(e?.accountId ?? null);
                                      const rows = rowData?.map((x) => {
                                        if (x?.id === row?.id) {
                                          return {
                                            ...x,
                                            accountId: e,
                                          };
                                        } else {
                                          return x;
                                        }
                                      });
                                      setRowData([...rows]);
                                    }}
                                    styles={customStyles}
                                    options={
                                      row?.vendorId
                                        ? accountDetails?.filter(
                                            (x) =>
                                              x.vendorId === row?.vendorId?.id
                                          )
                                        : accountDetails
                                    }
                                    getOptionLabel={(option) =>
                                      `${option.email}`
                                    }
                                    isClearable
                                    getOptionValue={({ accountId }) =>
                                      accountId
                                    }
                                  />
                                  {invalid && error ? (
                                    <div className="custom-invalid-feedback">
                                      Email is required.
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              );
                            }}
                          />
                        </Col>

                        <Col sm="1">
                          <Button
                            style={{ cursor: "pointer" }}
                            variant="link"
                            className="table-action-button"
                            onClick={(e) => {
                              e.preventDefault();

                              setRowData([
                                ...rowData.filter((x) => x?.id !== row?.id),
                              ]);
                              setshowType(false);
                            }}
                          >
                            <img
                              style={{ width: "15px", height: "100%" }}
                              src={DeleteIcon}
                              alt="Delete Purchase Orders"
                            />
                          </Button>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  {shouldShow(row) && (
                    <div className="text-start mt-1">
                      <small style={{ color: "red" }}>
                        Same Team and Email are selected
                      </small>
                    </div>
                  )}
                  {showErrorMessageData && isEventInQueue(row) && (
                    <div className="text-start mt-1">
                      <small style={{ color: "red" }}>
                        Event is added in queue for sync please check after 24
                        hrs
                      </small>
                    </div>
                  )}
                </Fragment>
              ))}

              <Row className="mt-3">
                <Col sm={6}>
                  <div
                    style={{ color: "#0241a0", cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (rowData?.length < 3) {
                        setRowData([
                          ...rowData,
                          {
                            id: Date.now(),
                            vendorId: null,
                            accountId: null,
                            shouldShow: false,
                          },
                        ]);
                        setshowType(false);
                      } else {
                        setshowType(true);
                      }
                    }}
                  >
                    <img src={AddIcon} alt="add icon" /> Add New Row
                  </div>
                </Col>
              </Row>
            </div>
          </Modal.Body>

          <div className="d-flex justify-content-center my-4">
            <Button
              variant="secondary"
              className="ss-modal-primary-btn mx-2 ms-2"
              onClick={() => {}}
              type="submit"
            >
              Sync
            </Button>
            <Button
              variant="light"
              className="ss-modal-secondary-btn mx-2"
              onClick={() => {
                setRowData([
                  {
                    id: Date.now(),
                    vendorId: null,
                    accountId: null,
                    shouldShow: false,
                  },
                ]);
                reset();
              }}
            >
              Clear
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default SyncCredentialsEventModal;
