import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useMemo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import "../../shared/styles/ModalStyles.scss";

import Reset from "../../images/reset.svg";
import CollapsibleTable from "./CollapsibleTable";
import DateFilter from "../transactions/modals/DateFilter";
import VendorDetailModal from "./modals/VendorDetailModal";
import Loader from "../../shared/components/loader/Loader";
import SkyboxFilterPanel from "./components/SkyboxFilterPanel";
import TableRowCard from "../../shared/components/table/TableRowCard";
import { formatMoney } from "../../utils/formatter/currencyFormatter";
import { errorMessage } from "../../data/reducers/alerts/alert.reducer";
import { monthDropdown } from "../../data/reducers/transactions/transactions.reducer";
import { syncSkybox } from "../../data/reducers/skybox-purchases/skybox-purchases.reducer";

import {
  dateHyephenToDateObject,
  formatPlanDate,
} from "../../utils/formatter/dateFormatter";
import {
  successMessage,
} from "../../data/reducers/alerts/alert.reducer";
import {
  Mixpanel,
  mixpanel_contants,
  mixpanel_event_constants,
  mixpanel_button_name,
} from "../../mixpanel";
import {
  fetchSkyboxPurchases,
  flushSkyboxPurchases,
} from "../../data/reducers/skybox-purchases/cached-skybox-purchases.reducer";

const getValidFilters = (filters) => {
  const arr = Object.entries(filters).filter(([key, value]) => {
    if (key === "PageNumber") {
      return value !== 1;
    }
    if (key === "PageSize") {
      return value !== 40;
    }
    if (key === "SortDir") {
      return value !== "Ascending";
    }
    if (
      key === "MinAmount" ||
      key === "MaxAmount" ||
      key === "month" ||
      key === "SortField" ||
      key === "searchString" ||
      key === "VendorId"
    ) {
      return value !== "";
    }
    return true;
  });

  const newFilters = Object.fromEntries(arr);
  if (newFilters.StartDate) {
    newFilters.StartDate = formatPlanDate(newFilters.StartDate);
  }
  if (newFilters.EndDate) {
    newFilters.EndDate = formatPlanDate(newFilters.EndDate);
  }
  return newFilters;
};
import { runRules } from "../../data/reducers/transactions/transactions.reducer";
import Run from "../../images/run.svg";

const SkyboxDashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const [exportLoading, setExportLoading] = useState(false);
  const {
    data: cachedSkyboxPurchasesObject,
    error: skyboxFetchingError,
    loading,
  } = useSelector((state) => state.cachedSkyboxPurchases);

  useEffect(() => {
    if (skyboxFetchingError) {
      dispatch(flushSkyboxPurchases());
    }
  }, []);

  const skyList = useSelector((state) => state.skybox).skyboxPurchases;
  const { syncDate } = useSelector((state) => state.skybox);

  const convertUTCDateTimeToLocalDateTime = (dateString, options = {}) => {
    const date = new Date(`${dateString} UTC`); // Append 'Z' to treat as UTC
    return date.toLocaleString();
  };

  const localTime = convertUTCDateTimeToLocalDateTime(syncDate);

  const { monthDropdownData } = useSelector((state) => state.transactions);

  // month dropdown usestate
  const [syncLoading, setSyncLoading] = useState(false);
  const [monthSelect, setMonthSelect] = useState(12);
  const [monthArray, setMonthArray] = useState([]);
  const [filters, setFilters] = useState({
    searchString: params.get("searchString") || "",
    StartDate:
      dateHyephenToDateObject(params.get("StartDate")) ||
      new Date(new Date().setMonth(new Date().getMonth() - 12)),
    EndDate: dateHyephenToDateObject(params.get("endDate")) || new Date(),
    VendorId: params.get("VendorId") || "",
    PageNumber: Number(params.get("PageNumber")) || 1,
    PageSize: Number(params.get("PageSize")) || 40,
    MinAmount: Number(params.get("MinAmount")) || "",
    MaxAmount: Number(params.get("MaxAmount")) || "",
    SortDir: params.get("SortDir") || "Ascending",
    SortField: params.get("SortField") || "",
    month: params.get("month") || "",
  });
  const [vendorModal, setVendorModal] = useState(false);

  const previousPage = () => {
    setFilters({
      ...filters,
      PageNumber: paging.currentPage - 1,
    });
  };

  const nextPage = () => {
    setFilters({
      ...filters,
      PageNumber: paging.currentPage + 1,
    });
  };

  const { skyboxPurchases, paging, timestamp } = useMemo(() => {
    const queryParams = new URLSearchParams(getValidFilters(filters));
    history.push(`/skybox-purchases?${queryParams}`);
    const data =
      cachedSkyboxPurchasesObject?.[
      JSON.stringify({
        ...filters,
        StartDate: formatPlanDate(filters.StartDate),
        EndDate: formatPlanDate(filters.EndDate),
        MinAmount: filters.MinAmount === "" ? 0 : parseInt(filters.MinAmount),
        MaxAmount: filters.MaxAmount === "" ? 0 : parseInt(filters.MaxAmount),
      })
      ];
    if (!data) {
      return { skyboxPurchases: undefined, paging: undefined, timestamp: null };
    }
    return data;
  }, [cachedSkyboxPurchasesObject, filters]);

  const resetHandler = async () => {
    dispatch(flushSkyboxPurchases());
  };

  useEffect(() => {
    if (skyboxFetchingError) {
      dispatch(
        errorMessage(skyboxFetchingError?.message ?? "Something went wrong")
      );
    } else if (!skyboxPurchases) {
      dispatch(
        fetchSkyboxPurchases({
          ...filters,
          StartDate: formatPlanDate(filters.StartDate),
          EndDate: formatPlanDate(filters.EndDate),
          MinAmount: filters.MinAmount === "" ? 0 : parseInt(filters.MinAmount),
          MaxAmount: filters.MaxAmount === "" ? 0 : parseInt(filters.MaxAmount),
        })
      );
    }
  }, [skyboxFetchingError, skyboxPurchases, filters]);

  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.SKYBOX_PURCHASES,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.SKYBOX_PURCHASES,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);

  useEffect(() => {
    setMonthArray(monthDropdownData);
  }, [monthDropdownData]);

  useEffect(() => {
    if (!monthDropdownData?.length) {
      dispatch(monthDropdown());
    }
  }, [monthDropdownData]);

  const callRunRules = async () => {
    try {
      setExportLoading("runRules");
      await dispatch(runRules());
    } catch (e) {
      dispatch(errorMessage("Something went wrong"));
    } finally {
      setExportLoading(false);
    }
  };

  const skyCol = [
    {
      columnName: "ID",
      columnKey: "id",
      sort: true,
      sortName: "Id",
      render: (data) => (
        <Link
          to=""
          variant="link"
          className="ss-link"
          onClick={(e) => {
            setVendorModal(true);
          }}
        >
          {data?.id}
        </Link>
      ),
    },
    {
      columnName: "Vendor",
      columnKey: "vendor",
      sort: true,
      sortName: "VendorId",
      render: (data) => (
        <Link
          variant="link"
          className="ss-link"
          to={"/vendors/" + data.vendorId}
        >
          {data.vendorName}
          <br />
          {data.vendorId ? data?.vendorId : ""}
        </Link>
      ),
    },
    {
      columnName: (
        <p className="mb-0">
          <br /> Created Date
        </p>
      ),
      sort: true,
      sortName: "CreatedDate",
      render: (data) => <>{formatPlanDate(data?.createdDate)}</>,
    },
    {
      columnName: "Value",
      sort: true,
      sortName: "total",
      render: (data) => <>{formatMoney(data?.total)}</>,
    },
    {
      columnName: (
        <>
          Alloted <br /> Value
        </>
      ),
      sort: false,
      sortName: "allocatableAmount",
      render: (data) => <>{formatMoney(data?.allocatableAmount)}</>,
    },
    {
      columnName: (
        <>
          Balance <br /> Value
        </>
      ),
      sort: false,
      sortName: "balanceAmount",
      render: (data) => <>{formatMoney(data?.balanceAmount)}</>,
    },
    {
      columnName: (
        <>
          SkyBox <br /> Ref
        </>
      ),
      sort: false,
      sortName: "id",
      render: (data) => (
        <a
          target="blank"
          variant="link"
          className="ss-link"
          href={"https://skybox.vividseats.com/purchases/" + data.id}
        >
          Skybox/{data.id}
        </a>
      ),
    },
    {
      columnName: "Action",
    },
  ];

  const bottomOptions = () => {
    return (
      <div className="p-2 d-flex justify-content-between gap-2">
        {/* Refresh button */}
        <div className="d-flex gap-2 flex-column align-items-start">
          <Button
            onClick={() => { resetHandler(); dispatch(successMessage("Refresh successful")); }}
            variant="primary"
            className="ss-light-button"
          >
            <img className="filter-reset-svg" src={Reset} alt=" " />
            <span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                Refresh
              </div>
            </span>
          </Button>
          <small style={{ color: "grey" }}>
            {timestamp
              ? `Last sync ${new Date(timestamp).toLocaleDateString()}
              ${new Date(timestamp).toLocaleTimeString()}`
              : "Synching..."}
          </small>
        </div>

        {/* Sync to Skybox button */}
        <div className="d-flex flex-column align-items-end gap-2">
          <div className="d-flex">
            <Button
              variant="primary"
              style={{ textWrap: "nowrap" }}
              className="mx-2 ss-light-button"
              disabled={exportLoading === "runRules"}
              onClick={async (e) => {
                e.currentTarget.blur();
                Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                  buttonName: mixpanel_button_name.RUNRULES,
                  page: mixpanel_contants.TRANSACTIONS,
                });
                await callRunRules();
                resetHandler();
              }}
            >
              {exportLoading === "runRules" ? (
                <Loader />
              ) : (
                <img src={Run} alt=" " />
              )}
              <span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  Run Rules
                </div>
              </span>
            </Button>
            <Button
              disabled={syncLoading}
              variant="secondary"
              className="ms-2"
              onClick={async () => {
                try {
                  setSyncLoading(true);
                  await dispatch(syncSkybox());
                } catch (e) {
                  dispatch(errorMessage(e.message ?? "Something went wrong"));
                } finally {
                  setSyncLoading(false);
                }
              }}
            >
              <span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  Sync to SkyBox
                  {syncLoading && <Loader style={{ marginLeft: "8px" }} />}
                </div>
              </span>
            </Button>
          </div>
          <small style={{ color: "green" }}>
            {syncLoading
              ? "Synching..."
              : syncDate
                ? "Last synced on " + localTime
                : "Not synced yet"}
          </small>
        </div>
      </div>
    );
  };

  const headOptions = () => {
    return (
      <div className="d-flex align-items-center justify-content-end">
        <small className="mx-2">Results per page</small>
        <Form.Group className="">
          <Form.Control
            as="select"
            defaultValue={filters.PageSize}
            onChange={(e) => {
              setFilters({
                ...filters,
                PageSize: e.target.value,
              });
            }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
          </Form.Control>
        </Form.Group>
      </div>
    );
  };

  return (
    <div
      onClick={() => {
        Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
          page: mixpanel_contants.SKYBOX_PURCHASES,
        });
      }}
      className="container-fluid pt-3"
    >
      {/* month dropdown */}
      <DateFilter
        monthArray={monthArray}
        monthSelect={monthSelect}
        setMonthSelect={setMonthSelect}
        setEndDate={(date) => {
          setFilters((prevFilters) => ({
            ...prevFilters,
            EndDate: date,
          }));
        }}
        setStartDate={(date) => {
          setFilters((prevFilters) => ({
            ...prevFilters,
            StartDate: date,
          }));
        }}
        startDate={filters.StartDate}
        endDate={filters.EndDate}
      />

      <SkyboxFilterPanel filters={filters} setFilters={setFilters} resetHandler={resetHandler} error={skyboxFetchingError} />

      <CollapsibleTable
        loading={loading}
        previousPage={previousPage}
        nextPage={nextPage}
        headerName="Skybox Purchase Orders"
        paging={paging}
        pageType="skybox"
        columns={skyCol}
        data={skyboxPurchases}
        headOptions={headOptions}
        bottomOptions={bottomOptions}
        sortField={filters.SortField}
        sortDir={filters.SortDir === "Ascending" || filters.SortDir === ""}
        onChangeFieldDirection={({ sortField, sortDirection }) => {
          setFilters({
            ...filters,
            SortField: sortField,
            SortDir: sortDirection ? "Ascending" : "Descending",
          });
        }}
        setSortField={() => { }}
        setSortDir={() => { }}
        setpageNumber={() => { }}
        renderRow={(rowData, index) => (
          <TableRowCard key={rowData.id} columns={skyCol} rowData={rowData} />
        )}
      />
      {vendorModal && (
        <VendorDetailModal
          isVisible={vendorModal}
          handleClose={() => setVendorModal(false)}
          selectedRecord={skyList[0]}
        />
      )}
    </div>
  );
};

export default SkyboxDashboard;
