import { poPaymentServices } from "../../services/purchaseOrders/po-paymentPlans.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import fileDownload from "js-file-download";

export const createPOPayment = createAsyncThunk(
  "purchaseOrderPayment/createPOPayment",
  async (payload, thunkAPI) => {
    const response = await poPaymentServices.createPurchaseOrderPayment(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const updatePOPayment = createAsyncThunk(
  "purchaseOrderPayment/updatePOPayment",
  async (payload, thunkAPI) => {
    const response = await poPaymentServices.updatePurchaseOrderPayment(payload);
    (response)
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const downloadTemplatePOPP = createAsyncThunk(
    "po/downloadTemplatePOPP",
    async (thunkAPI) => {
        const response = await poPaymentServices.downloadTemplatePOPP();
        if (response.isSuccessful === true) {
            fileDownload(response?.data?.data, "Payment Plan Template.csv")
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const uploadTemplatePOPP = createAsyncThunk(
    "po/downloadTemplatePOPP",
    async (payload, thunkAPI) => {
        const response = await poPaymentServices.uploadTemplatePOPP(payload);
        if (response.isSuccessful === true) {
            return response.data; 
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const deletePOPayment = createAsyncThunk(
  "purchaseOrderPayment/deletePOPayment",
  async (payload, thunkAPI) => {
    const response = await poPaymentServices.deletePurchaseOrderPayment(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAllPOPayment = createAsyncThunk(
  "purchaseOrderPayment/getAllPOPayment",
  async (payload, thunkAPI) => {
    const response = await poPaymentServices.getPurchaseOrderPayment(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportCSVPayment = createAsyncThunk(
  "purchaseOrderPayment/exportCSVPayment",
  async (payload, thunkAPI) => {
    const response = await poPaymentServices.exportCSVPayment(payload);
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "PurchaseOrderPaymentPlan.csv")
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportPdfPayment = createAsyncThunk(
  "purchaseOrderPayment/exportPdfPayment",
  async (payload, thunkAPI) => {
    const response = await poPaymentServices.exportPdfPayment(payload);
    // if (response.isSuccessful === true) {
    //   fileDownload(response.data, "PurchaseOrderPaymentPlan.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

export const getAllPOPaymentByID = createAsyncThunk(
  "purchaseOrderPayment/getAllPOPaymentByID",
  async (payload, thunkAPI) => {
    const response = await poPaymentServices.getPurchaseOrderPaymentById(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

const poPaymentlice = createSlice({
  name: "purchaseOrderCharge",
  initialState: {
    purchaseOrderPayment: [],
    selectedPayment: {},
    paging: {}
  },
  extraReducers: {
    [getAllPOPayment.fulfilled]: (state, action) => {
      state.purchaseOrderPayment = action.payload.data;
      state.paging = action.payload.paging;
    },
    [getAllPOPaymentByID.fulfilled]: (state, action) => {
      state.selectedPayment = action.payload.data;
    },
    [createPOPayment.fulfilled]: (state, action) => {
      state.purchaseOrderPayment = action.payload;
    },
    [updatePOPayment.fulfilled]: (state, action) => {
      state.purchaseOrderPayment = action.payload;
    },
    [deletePOPayment.fulfilled]: (state, action) => {
      state.purchaseOrderPayment = [
        ...state.purchaseOrderPayment.filter(
          (po) => po.id !== action.payload.id
        ),
      ];
    },
  },
});

export const { removeSelected } = poPaymentlice.actions;

export default poPaymentlice.reducer;
