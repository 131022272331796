import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { formatMoney } from "../../../utils/formatter/currencyFormatter";

const PaymentBox = ({
	weekDay,
	date,
	selectedData,
	isPresentDay,
	clickedDate,
}) => {
	const { calendarData } = useSelector((state) => state.sync);

	let month = "";
	let year = selectedData?.year;
	if (date?.isPreviousMonth) {
		if (selectedData?.month === 0) {
			year = year - 1;
			month = 11;
		} else {
			selectedData?.month - 1;
		}
	} else if (date?.isNextMonth) {
		if (selectedData?.month === 11) {
			year = year + 1;
			month = 0;
		} else {
			month = selectedData?.month + 1;
		}
	} else {
		month = selectedData?.month;
	}

	const tempDate = moment([year, month, date?.date]).format("YYYY-MM-DD");

	const paymentData = calendarData?.find(
		(x) => moment(x?.paymentDate).format("YYYY-MM-DD") === tempDate
	);

	const currentDate = moment().format("YYYY-MM-DD");
	const currentMonth = moment().format("MM");
	const currentYear = moment().format("YYYY");
	const shouldShowColor = () => {
		if (parseInt(currentYear) < selectedData?.year) {
			return true;
		}
		if (
			parseInt(currentMonth) < selectedData?.month + 1 &&
			parseInt(currentYear) === selectedData?.year
		) {
			return true;
		}
		return (
			moment(tempDate).isSameOrAfter(currentDate) && !date?.isPreviousMonth
		);
	};
	if(!date?.isCurrentMonth){
		return null
	}

	return (
		<>
			<p
				style={{
					opacity: date?.isCurrentMonth ? "1" : "0",
				}}
			>
				<span
					className={`${
						isPresentDay
							? "numberCircle"
							: tempDate === clickedDate
							? "numCircle"
							: ""
					}`}
				>
					{date?.date}
				</span>
			</p>
			<div>
			{(isPresentDay || !shouldShowColor()) && (
				<div
					className="d-flex justify-content-between p-1 mb-1"
					style={{
						backgroundColor: shouldShowColor()
							? "rgba(188, 219, 1, 0.15)"
							: "rgba(167, 167, 167, 0.3)",
						borderLeft: "2px solid #000000",
						color: date?.isCurrentMonth ? "black" : "rgba(0, 0, 0, 0.7)",
						opacity: date?.isCurrentMonth ? "1" : "0",
						flexFlow: "row wrap",
					}}
				>
					<p className="m-0">
						Actual Paid
					</p>
					<p className="m-0" style={{color:"#0241A0"}}>
						{`${formatMoney(paymentData ? paymentData?.totalPaid : 0)}`}
					</p>
				</div>
			)}
			<div className="d-flex justify-content-between p-1 mb-1" style={{
					backgroundColor: shouldShowColor()
						? "rgba(188, 219, 1, 0.15)"
						: "rgba(167, 167, 167, 0.3)",
					borderLeft: "2px solid #000000",
					color: date?.isCurrentMonth ? "black" : "rgba(0, 0, 0, 0.7)",
					opacity: date?.isCurrentMonth ? "1" : "0",
					flexFlow: "row wrap",
				}}>
					<p className="m-0">
					Scheduled
				</p>
				<p className="m-0">
					{`${formatMoney(paymentData ? paymentData?.totalScheduled : 0)}`}
				</p>
			</div>
		
			</div>
		</>
	);
};

export default PaymentBox;
