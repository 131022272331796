/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { useHistory, useLocation, Link } from "react-router-dom";

import {
  errorMessage,
  successMessage,
} from "../../data/reducers/alerts/alert.reducer";
import Down from "../../images/downarrow.svg";
import Up from "../../images/uparrow.svg";
import CollapsibleTable from "./CollapsibleTable";
import { withLoader } from "../../utils/hoc/withLoader";
import { formatMoney } from "../../utils/formatter/currencyFormatter";
import TableRowCard from "../../shared/components/table/TableRowCard";
import { columnError } from "../../shared/components/table/data/error";
import { createAccountRules } from "../../data/reducers/accounts/account-rules.reducer";
// images
import Export from "../../images/export.svg";
import {
  monthDropdown,
  runRules,
  downloadTemplateTransactions,
  uploadTemplateTransactions,
  resetVendor,
  exportSelectedCSVTrans,
  exportSelectedPdfTrans,
} from "../../data/reducers/transactions/transactions.reducer";
import "../../shared/styles/ModalStyles.scss";
import { createVendorRules } from "../../data/reducers/vendors/vendor-rules.reducer";
import SelectMatchModal from "./modals/SelectMatch";
import AddEditAccRulesModal from "./modals/AccRulesModal";
import AddEditVendorRulesModal from "./modals/VendorRulesModal";
import {
  dateHyephenToDateObject,
  formatDate,
  formatPlanDate,
} from "../../utils/formatter/dateFormatter";
import Run from "../../images/run.svg";
import LinkImg from "../../images/transLink.svg";
import TransactionImg from "../../images/transPayment.svg";
import DownloadImg from "../../images/download.svg";
import UploadImg from "../../images/upload.svg";
import Reset from "../../images/reset.svg";
import { formatTime } from "../../utils/formatter/timeFormatter";
import LinkModal from "./modals/LinkModal";

import {
  Mixpanel,
  mixpanel_button_name,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../mixpanel";
import {
  fetchTransactions,
  flushTransactions,
} from "../../data/reducers/transactions/cached-transactions.reducer";
import TransactionsFilterPanel from "./filter-panel";
import DateFilter from "./modals/DateFilter";
import TransactionCards from "./cards/index.jsx";
import {
  flushTransactionsCards,
  loadTransactionCards,
} from "../../data/reducers/transactions/cached-transaction-cards.reducer.js";
import Loader from "../../shared/components/loader/Loader.jsx";

export const IS_REVIEWED_NUM_TO_STR = {
  0: "all",
  1: "sports",
  2: "false",
  3: "true",
  4: "novendor",
  5: "noaccount",
  6: "nonticketpurchases",
  7: "inactiveandrevokedaccount",
  8: "credit",
  9: "declined",
  10: "ignoredtransactions",
  11: "fraud",
  12: "approved",
};

const getValidFilters = (filters) => {
  const arr = Object.entries(filters).filter(([key, value]) => {
    if (key === "PageNumber") {
      return value !== 1;
    }
    if (key === "PageSize") {
      return value !== 40;
    }
    if (key === "SortDir") {
      return value !== "Descending";
    }
    if (key === "isReviewed") {
      return value != "0";
    }

    if (
      key === "SourceType" ||
      key === "searchString" ||
      key === "MinAmount" ||
      key === "MaxAmount" ||
      key === "VendorId" ||
      key === "Description" ||
      key === "PurchaseOrdersId" ||
      key === "AccountId" ||
      key === "CcNumber" ||
      key === "SourceId" ||
      key === "CardName" ||
      key === "SortField" ||
      key === "TeamId" ||
      key === "SportId" ||
      key === "month" ||
      key === "SeasonId"
    ) {
      return value !== "";
    }
    return true;
  });
  const newFilters = Object.fromEntries(arr);

  if (newFilters.isReviewed != null) {
    newFilters.isReviewed =
      IS_REVIEWED_NUM_TO_STR[Number(newFilters.isReviewed)];
  }

  if (newFilters.StartDate) {
    newFilters.StartDate = formatPlanDate(newFilters.StartDate);
  }
  if (newFilters.EndDate) {
    newFilters.EndDate = formatPlanDate(newFilters.EndDate);
  }
  return newFilters;
};

const TransactionDashboard = ({ setBusy }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const { callApi } = useSelector((state) => state.cachedTransactionsCards);

  const monthDropdownData = useSelector(
    (state) => state.transactions
  ).monthDropdownData;

  useEffect(() => {
    if (!monthDropdownData.length) {
      dispatch(monthDropdown());
    }
  }, [monthDropdownData]);

  //---------------------------------------------------------------------------------------------------------------//

  const [monthSelect, setMonthSelect] = useState(12);

  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.TRANSACTIONS,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.TRANSACTIONS,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);

  //-------------- on page size change --------------//
  const previousPage = () => {
    setFilters({
      ...filters,
      PageNumber: paging.currentPage - 1,
    });
  };

  const nextPage = () => {
    setFilters({
      ...filters,
      PageNumber: paging.currentPage + 1,
    });
  };

  const [filters, setFilters] = useState({
    searchString: params.get("searchString") || "",
    SourceType: params.get("SourceType") || "",
    isReviewed: params.get("isReviewed") || 0,
    StartDate:
      dateHyephenToDateObject(params.get("StartDate")) ||
      new Date(new Date().setMonth(new Date().getMonth() - 12)),
    EndDate: dateHyephenToDateObject(params.get("EndDate")) || new Date(),
    MinAmount: params.get("MinAmount") || "",
    MaxAmount: params.get("MaxAmount") || "",
    VendorId: params.get("VendorId") || "",
    Description: params.get("Description") || "",
    PurchaseOrdersId: params.get("PurchaseOrdersId") || "",
    AccountId: params.get("AccountId") || "",
    CcNumber: params.get("CcNumber") || "",
    SourceId: params.get("SourceId") || "",
    CardName: params.get("CardName") || "",
    PageNumber: params.get("PageNumber") || 1,
    PageSize: params.get("PageSize") || 40,
    SortDir: params.get("SortDir") || "Descending",
    SortField: params.get("SortField") || "",
    TeamId: params.get("TeamId") || "",
    SportId: params.get("SportId") || "",
    month: params.get("month") || "",
    SeasonId: params.get("SeasonId") || "",
  });

  // fetch transactions  -------------------------------start
  const {
    data: cachedTransactionsObj,
    error,
    loading,
  } = useSelector((state) => state.cachedTransactions);

  useEffect(() => {
    if (error) {
      dispatch(flushTransactions());
    }
    dispatch(loadTransactionCards(null));
  }, []);

  const {
    data: transactions,
    paging,
    timestamp,
  } = useMemo(() => {
    const queryParams = new URLSearchParams(getValidFilters(filters));
    history.push(`/transactions?${queryParams}`);
    const data =
      cachedTransactionsObj?.[
        JSON.stringify({
          ...filters,
          StartDate: formatPlanDate(filters.StartDate),
          EndDate: formatPlanDate(filters.EndDate),
          isReviewed: IS_REVIEWED_NUM_TO_STR[Number(filters.isReviewed)],
        })
      ];
    if (!data) {
      return { data: undefined, paging: undefined };
    }
    return data;
  }, [cachedTransactionsObj, filters]);

  useEffect(() => {
    if (error) {
      dispatch(errorMessage(error?.message ?? "Something went wrong"));
    } else if (!transactions) {
      dispatch(
        fetchTransactions({
          ...filters,
          StartDate: formatPlanDate(filters.StartDate),
          EndDate: formatPlanDate(filters.EndDate),
          isReviewed: IS_REVIEWED_NUM_TO_STR[Number(filters.isReviewed)],
        })
      );
    }
  }, [error, transactions, filters]);

  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [matchedVisibility, setmatchedVisibility] = useState(false);
  const [accRulesModal, setAccRulesModal] = useState(false);
  const [vendorRulesModal, setVendorRulesModal] = useState(false);
  const [linkModal, setLinkModal] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [cardError, setCardError] = useState(null);
  const fileRef = useRef();

  const handleChange = async (e) => {
    try {
      setBusy(true);
      const [file] = e.target.files;
      const formData = new FormData();
      formData.append("file", file);
      const response = await dispatch(uploadTemplateTransactions(formData));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
        e.target.value = "";
      } else {
        window.location.reload();
        dispatch(successMessage("Upload successful"));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  const exportSelectedAsCSV = async () => {
    Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
      buttonName: mixpanel_button_name.EXPORT_SELECTED_AS_CSV,
      page: mixpanel_contants.TRANSACTIONS,
    });
    try {
      setExportLoading("export");
      await dispatch(
        exportSelectedCSVTrans({
          ...filters,
          StartDate: formatPlanDate(filters.StartDate),
          EndDate: formatPlanDate(filters.EndDate),
          isReviewed: IS_REVIEWED_NUM_TO_STR[Number(filters.isReviewed)],
        })
      );
    } catch (e) {
      dispatch(errorMessage("Something went wrong"));
    } finally {
      setExportLoading(false);
    }
  };

  const onExportSelectedAsPdf = async () => {
    Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
      buttonName: mixpanel_button_name.EXPORT_SELECTED_AS_CSV,
      page: mixpanel_contants.TRANSACTIONS,
    });
    try {
      setExportLoading("export");
      await dispatch(
        exportSelectedPdfTrans({
          ...filters,
          StartDate: formatPlanDate(filters.StartDate),
          EndDate: formatPlanDate(filters.EndDate),
          isReviewed: IS_REVIEWED_NUM_TO_STR[Number(filters.isReviewed)],
        })
      );
    } catch (e) {
      dispatch(errorMessage("Something went wrong"));
    } finally {
      setExportLoading(false);
    }
  };

  const checkNullAndEmpty = (str) => {
    return str === null || str?.trim() === "";
  };

  const transCol = [
    {
      columnName: "Date",
      sort: true,
      flexGrow: 0.5,
      sortName: "DateAndTime",
      render: (data) => (
        <>
          {formatDate(data?.dateAndTime)}
          <br />
          {formatTime(data?.dateAndTime)}
        </>
      ),
    },

    {
      columnName: "Name on Card / Last 4",
      sort: true,
      render: (data) => (
        <>
          <Link
            variant="link"
            className="ss-link"
            to={
              data?.cardInfoId > 0
                ? "/fundSources/cards/" + data?.cardInfoId
                : "#"
            }
          >
            {checkNullAndEmpty(data?.cardName) ? "-" : data?.cardName}
            <br />
            {checkNullAndEmpty(data?.ccNumber) ? "-" : data?.ccNumber}
          </Link>
        </>
      ),
      sortName: "sourceId",
    },
    {
      columnName: "Description",
      sort: true,
      sortName: "Description",
      render: (data) => (
        <div style={{ overflow: "hidden" }}>{data?.description}</div>
      ),
    },

    {
      columnName: "Vendor",
      render: (data) => (
        <Link
          variant="link"
          className="ss-link"
          to={"/vendors/" + data?.vendorId}
        >
          {checkNullAndEmpty(data?.vendorName) ? "-" : data?.vendorName}
          <br />
          {data?.vendorId || ""}
        </Link>
      ),
      sort: true,
      sortName: "VendorId",
    },
    {
      columnName: "Account",
      sort: true,
      flexGrow: 0.5,
      sortName: "AccountId",
      render: (data) => (
        <>
          {data?.autoGenerated == true ? (
            <span>
              {data.accountName}
              <br />
              {data.accountId == 0 ? "" : data.accountId}
            </span>
          ) : (
            <Link
              variant="link"
              className="ss-link"
              to={"/accounts/" + data.accountId}
            >
              {checkNullAndEmpty(data?.accountName) ? "-" : data?.accountName}
              <br />
              {data.accountId == 0 ? "" : data.accountId}
            </Link>
          )}
        </>
      ),
    },
    {
      columnName: "Purchase Order",
      sort: true,
      flexGrow: 0.5,
      sortName: "PurchaseOrderId",
      render: (data) => (
        <>
          <Link
            variant="link"
            className="ss-link"
            to={"/purchases/" + data?.purchaseOrderId}
          >
            {checkNullAndEmpty(data?.purchaseOrdersId)
              ? "-"
              : data?.purchaseOrdersId}
          </Link>
        </>
      ),
      columnKey: "purchaseOrdersId",
    },
    {
      columnName: (
        <>
          SkyBox <br /> Ref{" "}
        </>
      ),
      sort: true,
      sortName: "SkyBoxRef",
      render: (row, ind, open, setOpen, getPurchaseOrderRefsData, data) => (
        <div>
          {row.skyboxRefCount}
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (open[ind]) {
                setOpen(Array(data?.length).fill(false));
              } else {
                const temp = Array(data?.length).fill(false);
                temp[ind] = !temp[ind];
                setOpen(temp);
              }
              if (!open[ind]) {
                getPurchaseOrderRefsData(row?.purchaseOrderId);
              }
            }}
          >
            <span style={{ fontSize: "14px" }}>{row.skyBoxRef}</span>
            <img
              src={open[ind] ? Up : Down}
              style={{ marginLeft: "5px" }}
              alt="up down image"
            />
          </IconButton>
        </div>
      ),
    },
    {
      columnName: "Amount",
      sort: true,
      flexGrow: 0.5,
      sortName: "Amount",
      render: (data) => <>{formatMoney(data?.amount)}</>,
    },
    filters.isReviewed != 7 && filters.isReviewed != 8
      ? {
          columnName: "Actions",
          render: (data) => (
            <>
              {data?.autoGenerated === false &&
                filters.isReviewed != 6 &&
                filters.isReviewed != 7 && (
                  <>
                    <small className="table-row-card-actions d-flex">
                      <Button
                        className="table-action-button"
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedRecord(data);
                          setVendorRulesModal(true);
                          Mixpanel.track(
                            mixpanel_event_constants.BUTTON_CLICK,
                            {
                              buttonName: mixpanel_button_name.VENDOR_RULES,
                              page: mixpanel_contants.TRANSACTIONS,
                            }
                          );
                        }}
                      >
                        <span
                          className="text-primary"
                          style={{ fontWeight: "bold" }}
                        >
                          V
                        </span>
                      </Button>
                      <Button
                        className="table-action-button green_bg"
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedRecord(data);
                          setAccRulesModal(true);
                          Mixpanel.track(
                            mixpanel_event_constants.BUTTON_CLICK,
                            {
                              buttonName: mixpanel_button_name.ACCOUNTS_RULES,
                              page: mixpanel_contants.TRANSACTIONS,
                            }
                          );
                        }}
                      >
                        <span
                          className=""
                          style={{ fontWeight: "bold", color: "#A0BA00" }}
                        >
                          A
                        </span>
                      </Button>
                      {(filters.isReviewed == 2 || filters.isReviewed == 3) && (
                        <Button
                          variant="link"
                          className="table-action-button align-items-center red_bg"
                          onClick={() => {
                            setSelectedRecord(data);
                            setmatchedVisibility(!matchedVisibility);
                            Mixpanel.track(
                              mixpanel_event_constants.BUTTON_CLICK,
                              {
                                buttonName: mixpanel_button_name.PURCHASE_ORDER,
                                page: mixpanel_contants.TRANSACTIONS,
                              }
                            );
                          }}
                        >
                          <img src={TransactionImg} alt="delete" />
                        </Button>
                      )}
                      <Button
                        variant="link"
                        className="table-action-button align-items-center gray_bg"
                        onClick={() => {
                          setSelectedRecord(data);
                          setLinkModal(true);
                          Mixpanel.track(
                            mixpanel_event_constants.BUTTON_CLICK,
                            {
                              buttonName:
                                mixpanel_button_name.UNLINK_VENDOR_ACCOUNT,
                              page: mixpanel_contants.TRANSACTIONS,
                            }
                          );
                        }}
                      >
                        <img src={LinkImg} alt="delete" />
                      </Button>
                      {/* )} */}
                    </small>
                  </>
                )}
              {data?.autoGenerated && filters.isReviewed == 0 && (
                <>
                  <small className="table-row-card-actions d-flex">
                    <Button
                      variant="link"
                      className="table-action-button align-items-center gray_bg"
                      onClick={() => {
                        setSelectedRecord(data);
                        setLinkModal(true);
                      }}
                    >
                      <img src={LinkImg} alt="link " />
                    </Button>
                  </small>
                </>
              )}
            </>
          ),
        }
      : {
          columnName: "Status",
          flexGrow: 0.5,
          render: (data) => <>{data?.category}</>,
        },
  ];

  const callRunRules = async () => {
    try {
      setExportLoading("runRules");
      await dispatch(runRules());
    } catch (e) {
      dispatch(errorMessage("Something went wrong"));
    } finally {
      setExportLoading(false);
    }
  };

  const downloadTemplate = async () => {
    setBusy(true);
    await dispatch(downloadTemplateTransactions());
    setBusy(false);
  };

  const resetHandler = async () => {
    dispatch(flushTransactionsCards());
    dispatch(flushTransactions());
  };

  const headOptions = () => {
    return (
      <>
        <div className="d-flex justify-content-end">
          <div className="d-flex align-items-center justify-content-end">
            <small>Results per page</small>
            <Form.Group className="ms-2">
              <Form.Control
                as="select"
                defaultValue={filters.PageSize}
                onChange={(e) => {
                  setFilters((prev) => ({ ...prev, PageSize: e.target.value }));
                }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
              </Form.Control>
            </Form.Group>
          </div>
        </div>

        <div className="text-end mt-3">
          <div className="d-flex align-items-center justify-content-end">
            <Button
              onClick={() => {
                downloadTemplate();
                Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                  buttonName: mixpanel_button_name.DOWNLOAD_TEMPLATE,
                  page: mixpanel_contants.TRANSACTIONS,
                });
              }}
              variant="primary"
              className="mx-2 ss-light-button"
            >
              <img src={DownloadImg} alt=" " />
              <span style={{ whiteSpace: "nowrap" }}>Download Template</span>
            </Button>
            <div>
              <Button
                onClick={() => {
                  fileRef.current.click();
                  Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                    buttonName: mixpanel_button_name.UPLOAD,
                    page: mixpanel_contants.TRANSACTIONS,
                  });
                }}
                variant="primary"
                className="mx-2 ss-light-button"
              >
                <img src={UploadImg} alt=" " />
                <span>Upload</span>
              </Button>
              <input
                ref={fileRef}
                onChange={handleChange}
                multiple={false}
                type="file"
                hidden
              />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end mt-2">
            <Dropdown onClick={(e) => e.currentTarget.blur()}>
              <Dropdown.Toggle
                className=" ms-2 ss-light-button"
                id="dropdown-basic"
                disabled={exportLoading === "export"}
              >
                <img src={Export} alt=" " />
                <span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    Export
                    {exportLoading === "export" && (
                      <Loader style={{ marginLeft: "8px" }} />
                    )}
                  </div>
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => exportSelectedAsCSV()}>
                  Export as CSV
                </Dropdown.Item>
                <hr className="hr-full" />
                <Dropdown.Item onClick={onExportSelectedAsPdf}>
                  Export as PDF
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              variant="primary"
              style={{ textWrap: "nowrap" }}
              className="mx-2 ss-light-button"
              disabled={exportLoading === "runRules"}
              onClick={async (e) => {
                e.currentTarget.blur();
                Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                  buttonName: mixpanel_button_name.RUNRULES,
                  page: mixpanel_contants.TRANSACTIONS,
                });
                await callRunRules();
                resetHandler();
              }}
            >
              <img src={Run} alt=" " />
              <span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  Run Rules
                  {exportLoading === "runRules" && (
                    <Loader style={{ marginLeft: "8px" }} />
                  )}
                </div>
              </span>
            </Button>
            <Button
              onClick={() => {
                resetHandler();
                dispatch(successMessage("Refresh successful"));
              }}
              variant="primary"
              className="button ss-light-button"
            >
              <img className="filter-reset-svg" src={Reset} alt=" " />
              <span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  Refresh
                </div>
              </span>
            </Button>
          </div>
          <p
            style={{
              visibility: timestamp == null && "hidden",
              textAlign: "end",
              fontSize: "12px",
              lineHeight: 0,
              paddingTop: "16px",
              color: "#00000090",
            }}
          >
            Last sync {new Date(timestamp).toLocaleDateString()}{" "}
            {new Date(timestamp).toLocaleTimeString()}
          </p>
        </div>
      </>
    );
  };

  const onAccRulesSubmitted = async (data) => {
    const formData = {
      name: data?.name,
      card: data?.card,
    };
    const action = createAccountRules({
      formData,
      Id: data?.accountId?.accountId || data?.accountId,
    });
    const response = await dispatch(action);
    if (response.error) {
      dispatch(errorMessage(response.error.message));
    } else {
      // dispatch(getAccountRules(id));
      dispatch(successMessage("Account Rules created successfully"));
      setAccRulesModal(false);
    }
  };

  const handleClose = () => {
    setmatchedVisibility(false);
    // if (searchData) onSubmit(searchData);
  };

  const unlinkData = async (selectedRecord, dropVal) => {
    try {
      setExportLoading("unlink");
      const action = resetVendor({
        ...selectedRecord,
        accountId: dropVal?.name === 0 ? 0 : dropVal?.name?.accountId,
        vendorId: dropVal?.vendor === 0 ? 0 : dropVal?.vendor?.id,
      });

      const response = await dispatch(action);
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        dispatch(successMessage("Update successful"));
        setLinkModal(false);
        resetHandler();
        // handleSubmit(filterUrlData);
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setExportLoading(false);
    }
  };
  const onVendorRulesSubmitted = async (data) => {
    const action = createVendorRules({
      formData: {
        ...data,
        vendorId: parseInt(data?.vendorId),
        searchId: parseInt(data?.searchId),
        matchingRule: data?.description,
      },
      vendorId: parseInt(data?.vendorId),
      searchId: parseInt(data?.searchId),
    });
    const response = await dispatch(action);
    if (response.error) {
      dispatch(errorMessage(response.error.message));
    } else {
      dispatch(successMessage("Vendor Rules created successfully"));
      setVendorRulesModal(false);
    }
  };

  return (
    <div
      onClick={() => {
        Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
          page: mixpanel_contants.TRANSACTIONS,
        });
      }}
      className="container-fluid pt-3"
    >
      {/* Previous Month Section */}
      <DateFilter
        monthArray={monthDropdownData}
        monthSelect={monthSelect}
        setMonthSelect={setMonthSelect}
        setEndDate={(date) => {
          setFilters((prevFilters) => ({
            ...prevFilters,
            EndDate: date,
          }));
        }}
        setStartDate={(date) => {
          setFilters((prevFilters) => ({
            ...prevFilters,
            StartDate: date,
          }));
        }}
        startDate={filters.StartDate}
        endDate={filters.EndDate}
        loadButtonAction={loadTransactionCards(!callApi)}
        showLoadButton={true}
        loadButtonText={"Load Card Values"}
      />
      {/* Summary Cards */}
      <TransactionCards filters={filters} setCardError={setCardError} />

      {/* Filter Panel */}
      <TransactionsFilterPanel
        filters={filters}
        setFilters={setFilters}
        resetHandler={resetHandler}
        error={error}
        cardError={cardError}
      />

      <CollapsibleTable
        loading={loading}
        align={"left"}
        previousPage={previousPage}
        nextPage={nextPage}
        paramerterNavigation={true}
        headerName="TRANSACTIONS"
        paging={paging}
        columns={
          filters.isReviewed === 8 || filters.isReviewed === 9
            ? transCol.slice(0, -1)
            : transCol
        }
        data={transactions || []}
        headOptions={headOptions}
        tabs={[
          "All",
          "Sports",
          "For Review",
          "Matched",
          "No Vendor",
          "No Account",
          "Non Ticket Purchases",
          "Inactive/Revoked Accounts",
          "Credits",
          "Declined",
          "Ignored",
          "Fraud",
          "Approved",
        ]}
        selectedTabIndex={filters.isReviewed}
        onTabSelectionChange={(i) => {
          setFilters((prev) => ({ ...prev, isReviewed: i, PageNumber: 1 }));
        }}
        onChangeFieldDirection={({ sortField, sortDirection }) => {
          setFilters({
            ...filters,
            SortField: sortField,
            SortDir: sortDirection ? "Ascending" : "Descending",
          });
        }}
        sortDir={filters.SortDir === "Ascending" || filters.SortDir === ""}
        setSortDir={() => {}}
        sortField={filters.SortField}
        setSortField={() => {}}
        setpageNumber={() => {}}
        renderRow={(rowData) => (
          <TableRowCard
            key={rowData?.id}
            columns={
              rowData.nodata
                ? columnError
                : filters.isReviewed === 8 || filters.isReviewed === 9
                ? transCol.slice(0, -1)
                : transCol
            }
            rowData={rowData}
          />
        )}
      />

      {/* Modal Popups */}
      {matchedVisibility && (
        <SelectMatchModal
          isVisible={matchedVisibility}
          setIsVisible={setmatchedVisibility}
          handleClose={handleClose}
          match={filters.isReviewed == 3 ? true : false}
          selectedRecord={selectedRecord}
        />
      )}
      {linkModal && (
        <LinkModal
          isVisible={linkModal}
          setIsVisible={setLinkModal}
          loading={exportLoading === "unlink"}
          onSubmit={unlinkData}
          handleClose={() => setLinkModal(false)}
          selectedRecord={selectedRecord}
        />
      )}

      {accRulesModal && (
        <AddEditAccRulesModal
          isVisible={accRulesModal}
          handleClose={() => setAccRulesModal(false)}
          onSubmit={(data) => onAccRulesSubmitted(data)}
          selectedRecord={selectedRecord}
        />
      )}

      {vendorRulesModal && (
        <AddEditVendorRulesModal
          isVisible={vendorRulesModal}
          handleClose={() => setVendorRulesModal(false)}
          onSubmit={(data) => onVendorRulesSubmitted(data)}
          selectedRecord={selectedRecord}
        />
      )}
    </div>
  );
};

export default withLoader(TransactionDashboard);
