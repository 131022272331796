import { Route, Switch } from 'react-router-dom';
import PSLDashboard from '../../../pages/psl/PSLDashboard';

const PSLRoutes = () => {
	return (
		<Switch>
			<Route path='/psl' exact>
				<PSLDashboard />
			</Route>
		</Switch>
	);
};

export default PSLRoutes;
