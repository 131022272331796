import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { accountChargesServices } from "../../services/accounts/account-charges.service";
import fileDownload from "js-file-download";
import { flushTransactions } from "../transactions/cached-transactions.reducer";
import { flushTransactionsCards } from "../transactions/cached-transaction-cards.reducer";

export const getAccountCharges = createAsyncThunk(
  "accounts/getAccountCharges",
  async (payload, thunkAPI) => {
    const response = await accountChargesServices.getAccountCharges(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getSelectedCharges = createAsyncThunk(
  "accounts/getSelectedCharges",
  async (payload, thunkAPI) => {
    const response = await accountChargesServices.getSelectedCharges(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportCSVAccount = createAsyncThunk(
  "accounts/exportCSVAccount",
  async (payload, thunkAPI) => {
    const response = await accountChargesServices.exportCSVAccountCharges(
      payload
    );
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "AccountCharges.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportPdfAccCharges = createAsyncThunk(
  "accounts/exportPdfAccCharges",
  async (payload, thunkAPI) => {
    await accountChargesServices.exportPdfAccountCharges(payload);
  }
);

export const createAccountCharges = createAsyncThunk(
  "accounts/CreateAccountCharges",
  async (payload, thunkAPI) => {
    const response = await accountChargesServices.createAccountCharges(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushTransactions());
      thunkAPI.dispatch(flushTransactionsCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const updateAccountCharges = createAsyncThunk(
  "accounts/UpdateAccountCharges",
  async (payload, thunkAPI) => {
    const response = await accountChargesServices.updateAccountCharges(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushTransactions());
      thunkAPI.dispatch(flushTransactionsCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const deleteAccountCharges = createAsyncThunk(
  "accounts/DeleteAccountCharges",
  async (payload, thunkAPI) => {
    const response = await accountChargesServices.deleteAccountCharges(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushTransactions());
      thunkAPI.dispatch(flushTransactionsCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

const accountChargesSlice = createSlice({
  name: "accountCharges",
  initialState: {
    paging: {},
    accountsCharges: [],
    selectedAccCharges: null,
  },
  reducers: {
    removeSelected: (state) => {
      state.selectedAccCharges = {};
    },
  },
  extraReducers: {
    [createAccountCharges.fulfilled]: (state, action) => {
      state.accountsCharges.push(action.payload);
    },
    [updateAccountCharges.fulfilled]: (state, action) => {
      const index = state.accountsCharges.findIndex(
        (account) => account.id == action.payload.id
      );
      state.accountsCharges[index] = action.payload;
      state.selectedAccCharges = action.payload;
    },
    [getAccountCharges.fulfilled]: (state, action) => {
      state.paging = action.payload.paging;
      state.accountsCharges = action.payload.data;
    },
    [getSelectedCharges.fulfilled]: (state, action) => {
      state.selectedAccCharges = action.payload;
    },
    [deleteAccountCharges.fulfilled]: (state, action) => {
      state.accountsCharges = [
        ...state.accountsCharges.filter(
          (account) => account.id !== action.payload.id
        ),
      ];
    },
  },
});

export const { removeSelected } = accountChargesSlice.actions;

export default accountChargesSlice.reducer;
