import { api, getAuthHeaders, get, post } from "../services.common";

const getAllInventory = async (data) => {
	const sortDir = data.sortDir == false ? "Descending" : "Ascending";
	const url = `${api}api/SkyboxSoldInventory/getall?PageNumber=${
		data.pageNumber
	}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${
		data.sortField || ""
	}&fulfillmentId=${data.selectedTabIndex}`;
	const authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};

const getMatchedTickets = async (data) => {
	const url = `${api}api/SkyboxSoldInventory/matchedticket`;
	const authHeader = getAuthHeaders();
	return await post(url, data, { ...authHeader });
};

const getCustomerURL = async (data) => {
	const url = `${api}api/SkyboxSoldInventory/updateskyboxtickets`;
	const authHeader = getAuthHeaders();
	return await post(url, data, { ...authHeader });
};

const filterInventory = async (data) => {
	const sortDir = data.sortDir == false ? "Descending" : "Ascending";
	const url = `${api}api/SkyboxSoldInventory/search?searchString=${
		data.searchText || ""
	}&EventId=${data.eventId}&EventName=${data.eventName}&EventDate=${
		data.eventDate
	}&VendorName=${data.vendor}&Section=${data.section}&Row=${
		data.row
	}&Quantity=${data.quantity}&Total=${data.amount}&PerformerName=${
		data.performerName
	}&PageNumber=${data.pageNumber}&PageSize=${
		data.pageSize
	}&SortDir=${sortDir}&SortField=${data.sortField || ""}&fulfillmentId=${
		data.selectedTabIndex
	}&VendorId=${data?.vendorId}`;
	const authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};

const getDeliveryMessagesValues = async () => {
	const url = `${api}api/SkyboxSoldInventory/DeliveryMessage`;
	const authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};

const revokeTicket = async (data) => {
	const url = data.fromEvent ? `${api}api/SkyboxSoldInventory/revoketoken?id=0&tokenId=${data.tokenId}` : `${api}api/SkyboxSoldInventory/revoketoken?id=${data.id}`;
	const authHeader = getAuthHeaders();
	return await post(url, {}, { ...authHeader });
};

export const inventoryServices = {
	getAllInventory,
	getMatchedTickets,
	filterInventory,
	getCustomerURL,
	getDeliveryMessagesValues,
	revokeTicket,
};
