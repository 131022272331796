import Select from "react-select";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Container,
  InputGroup,
} from "react-bootstrap";

import Add from "../../../images/add.svg";
import DeleteIcon from "../../../images/delete.svg";
import Search from "../../../images/search-white.svg";
import Loader from "../../../shared/components/loader/Loader";
import { inputDate } from "../../../utils/formatter/dateFormatter";
import { formatMoney } from "../../../utils/formatter/currencyFormatter";
import { errorMessage } from "../../../data/reducers/alerts/alert.reducer";
import { getSeasonsList } from "../../../data/reducers/season/season.reducer";
import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import { getAccountsListByVendor } from "../../../data/reducers/accounts/accounts.reducers";

import {
  getListSkybox,
  getListSkyboxForMultipeRefs,
  getPurchaseOrderRefs,
  resetskyBoxRefData,
} from "../../../data/reducers/skybox-purchases/skybox-purchases.reducer";
import FullLoader from "../../../shared/components/loader/FullLoader";
import { fetchVendorsListForDropdown } from "../../../data/reducers/vendors/cached-vendors.reducer";

let latestId = 1;

const AddEditPOModal = ({
  isVisible,
  handleClose,
  onSubmit,
  selectedRecord,
  setSelectedRecord,
  setModalOpen,
  accountsData,
  vendorData,
  loading,
}) => {
  const dispatch = useDispatch();
  const skyBoxRefDataList = useSelector((state) => state.skybox).skyBoxRefData;
  const [shouldDisableAdd, setShouldDisableAdd] = useState(true);
  const [innerLoading, setInnerLoading] = useState(false);
  const [loadingData, setLoadingData] = useState({});

  const [skyBoxRef, setSkyBoxRef] = useState("");
  const [listSkyBoxRefs, setListSkyBoxRefs] = useState([
    {
      id: 1,
    },
  ]);
  const [inputValues, setInputValues] = useState([]);

  const { seasonList } = useSelector((state) => state.season);
  const { vendorsListForDropdown } = useSelector(
    (state) => state.cachedVendors
  );
  const { accountListByVendor } = useSelector((state) => state.accounts);
  const { skyboxMultipleRefData } = useSelector((state) => state.skybox);
  const isEdited = useSelector((state) => state.purchaseOrder).addOrEdit;

  useEffect(() => {
    if (!accountListByVendor.length) {
      dispatch(getAccountsListByVendor());
    }
  }, [accountListByVendor]);

  useEffect(() => {
    if (!vendorsListForDropdown.length) {
      dispatch(fetchVendorsListForDropdown());
    }
  }, [vendorsListForDropdown]);

  useEffect(() => {
    if (!seasonList.length) {
      dispatch(getSeasonsList());
    }
  }, [seasonList]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    getValues,
    watch,
  } = useForm();
  const watchAllFields = watch();

  const [isInputUpdated, setIsInputUpdated] = useState(false);

  useEffect(() => {
    if (!selectedRecord) {
      return;
    }
    const updatedValues = getValues();
    //compare selectedRecord and updated values

    const originalDate = selectedRecord?.purchaseOrderDate?.split("T")[0];

    if (
      selectedRecord.description !== updatedValues.description ||
      selectedRecord.seasonName !== updatedValues.seasonName ||
      (selectedRecord?.seasonId || 0) !== (updatedValues?.seasonId || 0) ||
      selectedRecord.totalScheduled !== updatedValues.totalScheduled ||
      selectedRecord.vendorId !== updatedValues.vendorId ||
      selectedRecord.vendorName !== updatedValues.vendorName ||
      selectedRecord.accountId !== updatedValues.accountId ||
      selectedRecord.accountName !== updatedValues.accountName ||
      originalDate !== updatedValues.purchaseOrderDate
    ) {
      setIsInputUpdated(true);
    } else {
      setIsInputUpdated(false);
    }
  }, [getValues()]);

  //If selected Record Exists aka Edit else Add
  const [isEdit, setIsEdit] = useState(false);
  const [prompt, setPrompt] = useState(false);
  const [showPrompt, setShowPrompt] = useState(true);
  const [dropVal, setdropVal] = useState({
    name: "",
    vendor: "",
    purchase: "",
  });
  const [inactive, setInactive] = useState(false);

  const customStyles = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
  };

  // update values in form if selected record is changed
  useEffect(async () => {
    setInactive(false);
    setShouldDisableAdd(true);
    setShowPrompt(true);
    dispatch(resetskyBoxRefData());
    setLoadingData({});
    latestId = 1;
    if (selectedRecord) {
      // as soon as selected record is changed, set values in modal popup
      setIsEdit(true);
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value);
      }
      setdropVal({
        ...dropVal,
        season: selectedRecord.seasonId
          ? seasonList.filter(
              (season) => season?.id == selectedRecord.seasonId
            )[0]
          : "",
        vendor: selectedRecord.vendorId
          ? vendorsListForDropdown.filter(
              (vendor) => vendor?.id == selectedRecord.vendorId
            )[0]
          : "",
        name: selectedRecord?.accountId
          ? accountListByVendor.filter(
              (account) => account.accountId == selectedRecord.accountId
            )[0]
          : "",
      });
      if (selectedRecord?.skyBoxRef)
        setSkyBoxRef({ id: selectedRecord?.skyBoxRef });
      else {
        await dispatch(getListSkybox(""));
        setSkyBoxRef(undefined);
      }
      setValue(
        "purchaseOrderDate",
        selectedRecord?.purchaseOrderDate?.substr(0, 10)
      );
    } else {
      await dispatch(getListSkybox(""));
      // if selected record is undefined, reset
      setIsEdit(false);
      setdropVal({
        name: accountsData
          ? accountListByVendor.filter(
              (account) => account.accountId == accountsData
            )[0]
          : "",
        vendor: vendorData
          ? vendorsListForDropdown.filter(
              (vendor) => vendor?.id == vendorData
            )[0]
          : "",
        purchase: "",
        season: "",
      });
      if (vendorData) {
        setValue(
          "vendorId",
          vendorsListForDropdown.filter((vendor) => vendor?.id == vendorData)[0]
            ?.id,
          {
            shouldValidate: true,
            shouldDirty: true,
          }
        );
      }
      setSkyBoxRef(undefined);
      reset();
      setValue("purchaseOrderDate", inputDate());
      setListSkyBoxRefs([
        {
          id: 1,
        },
      ]);
    }
  }, [isVisible]);

  const getOrderRefs = async () => {
    try {
      await dispatch(getPurchaseOrderRefs(selectedRecord.id));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(async () => {
    if (isVisible) {
      setInnerLoading("fetch");
      if (selectedRecord?.id) {
        await getOrderRefs();
      }
      setInnerLoading(false);
    }
  }, [isVisible]);

  useEffect(() => {
    if (
      selectedRecord &&
      skyBoxRefDataList.length > 0 &&
      skyBoxRefDataList[0]?.purchaseOrderId === selectedRecord?.id
    ) {
      resetSkyRefRows();
    } else {
      if (selectedRecord) {
        setListSkyBoxRefs([]);
      } else {
        setListSkyBoxRefs([
          {
            id: 1,
          },
        ]);
      }
    }
  }, [skyBoxRefDataList, selectedRecord]);

  const handleReset = () => {
    resetSkyRefRows();
    setInactive(false);
    if (selectedRecord) {
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
      if (selectedRecord?.skyBoxRef)
        setSkyBoxRef({ id: selectedRecord?.skyBoxRef });
      else setSkyBoxRef("");
      setdropVal({
        ...dropVal,
        season: selectedRecord.seasonId
          ? seasonList.filter(
              (season) => season.id == selectedRecord.seasonId
            )[0]
          : "",
        vendor: {
          id: selectedRecord?.vendorId,
          name: selectedRecord?.vendorName,
        },
        name: selectedRecord?.accountId
          ? accountListByVendor.filter(
              (account) => account.accountId == selectedRecord.accountId
            )[0]
          : "",
      });
      setValue(
        "purchaseOrderDate",
        selectedRecord?.purchaseOrderDate?.substr(0, 10)
      );
      setInputValues([]);
    } else {
      setdropVal({
        ...dropVal,
        name: accountsData
          ? accountListByVendor.filter(
              (account) => account.accountId == accountsData
            )[0]
          : "",
        vendor: vendorData
          ? vendorsListForDropdown.filter(
              (vendor) => vendor?.id == vendorData
            )[0]
          : "",
        season: "",
      });
      setValue("purchaseOrderDate", inputDate());
      reset();
      setSkyBoxRef(undefined);
      setValue("skyBoxRef", undefined);
      setInputValues([]);
    }
  };
  const openSeason = (e) => {
    setSelectedRecord({
      purchaseOrderDate: getValues("purchaseOrderDate"),
      autoGenerate: getValues("autoGenerate"),
      redirect: true,
      description: getValues("description"),
      totalScheduled: getValues("totalScheduled"),
      skyBoxRef: skyBoxRef?.id,
      vendorId: getValues("vendorId"),
      accountId: getValues("accountId"),
      id: selectedRecord?.id,
      purchaseOrderId: selectedRecord?.purchaseOrderId,
    });
    setModalOpen(true);
    handleClose();
  };

  const resetSkyRefRows = () => {
    if (selectedRecord) {
      setListSkyBoxRefs(
        skyBoxRefDataList.map((ref) => {
          latestId = latestId + 1;
          setValue(
            `allocatedSkyBoxAmount-${latestId - 1}`,
            ref.allocatedSkyBoxAmount,
            {
              shouldValidate: true,
              shouldDirty: true,
            }
          );
          return {
            id: latestId - 1,
            value: {
              id: ref.skyBoxPurchaseId,
              allocatableAmount:
                ref.remainingSkyboxValue + ref.allocatedSkyBoxAmount,
              total: ref.totalSkyboxValue,
            },
          };
        })
      );
    } else {
      setListSkyBoxRefs([]);
    }
  };

  const callList = async (id) => {
    try {
      const input = inputValues.find((inp) => inp.id === id);
      setLoadingData((loadingData) => ({ ...loadingData, [id]: true }));
      await dispatch(
        getListSkyboxForMultipeRefs({ input: input.value, id: id })
      );
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoadingData((loadingData) => ({ ...loadingData, [id]: false }));
    }
  };

  useEffect(async () => {
    if (skyBoxRef?.id && skyBoxRef?.total) setPrompt(true);
    else if (skyBoxRef?.id) {
      const res = await dispatch(getListSkybox(selectedRecord?.skyBoxRef));
      setValue(
        "skyboxRefAmount",
        res?.payload?.data ? res.payload?.data[0]?.total : 0
      );
      setPrompt(false);
    } else setPrompt(false);
  }, [skyBoxRef]);

  //----------------------- update details -----------------------------//
  const [isUpdate, setUpdate] = useState(false);
  const validateSubmit = async (data) => {
    const skyRefs = [];

    listSkyBoxRefs.forEach((skRef) => {
      if (skRef.value) {
        const ref = {
          skyBoxPurchaseId: skRef?.value?.id,
          allocatedSkyBoxAmount: data[`allocatedSkyBoxAmount-${skRef.id}`],
          autoSkyBoxMatch: false,
          purchaseOrderId: selectedRecord ? selectedRecord?.id : 0,
        };
        skyRefs.push(ref);
      }
      delete data[`allocatedSkyBoxAmount-${skRef.id}`];
    });

    data.listSkyboxRefs = skyRefs;

    if (!isUpdate) {
      data.skyBoxRef = skyRefs.length ? String(skyRefs.length) : null;
    }

    onSubmit(data);

    setUpdate(false);
  };

  const onAddClickButton = () => {
    latestId = latestId + 1;
    setListSkyBoxRefs([...listSkyBoxRefs, { id: latestId }]);
  };

  const getTotalAllocatingValues = () => {
    let value = 0;
    listSkyBoxRefs.forEach((x) => {
      const allocatingValue =
        parseFloat(watchAllFields[`allocatedSkyBoxAmount-${x.id}`]) || 0;
      value += allocatingValue;
    });
    return value;
  };

  const getAllSkyBoxRefIds = () => {
    const refs = [];
    listSkyBoxRefs.forEach((x) => {
      const id = x?.value?.id;
      refs.push(id);
    });
    return refs;
  };

  const getTotalSkyBoxValue = () => {
    return listSkyBoxRefs.reduce((a, c) => a + (c?.value?.total || 0), 0);
  };

  const getTotalAllocatableTime = () => {
    return listSkyBoxRefs.reduce(
      (a, c) => a + (c?.value?.allocatableAmount || 0),
      0
    );
  };

  const shouldShowPrompt = () => {
    return listSkyBoxRefs.some((x) => x.value);
  };

  const getValidate = (refs, x) => {
    if (!refs.value) {
      return true;
    }
    return (
      parseFloat(x) <=
      (listSkyBoxRefs.find((x) => x.id === refs.id)?.value?.allocatableAmount ||
        0)
    );
  };

  const getMax = (refs) => {
    return listSkyBoxRefs.find((x) => x.id === refs.id)?.value
      ?.allocatableAmount;
  };

  const isInValid = (refs) => {
    if (
      parseFloat(watchAllFields[`allocatedSkyBoxAmount-${refs.id}`]) >
      getMax(refs)
    ) {
      return true;
    }
    return false;
  };

  const shouldDisableConfirmatButton = () => {
    return listSkyBoxRefs.some((refs) => {
      return isInValid(refs);
    });
  };

  const getVendorData = () => {
    if (selectedRecord?.accountName == "Default Account") {
      const vendorDropDownOptions = vendorsListForDropdown?.filter((vendor) => {
        return (
          vendor?.categoryId === 2 &&
          accountListByVendor?.some(
            (item) =>
              item.vendorId == vendor.id &&
              item.accountId == selectedRecord?.accountId
          )
        );
      });
      return vendorDropDownOptions;
    }

    if (accountsData) {
      return vendorsListForDropdown.filter(
        (vendor) =>
          vendor.categoryId === 1 &&
          accountListByVendor?.some((acc) => acc.vendorId === vendor?.id)
      );
    }
    return vendorsListForDropdown.filter((vendor) => vendor.categoryId === 1);
  };

  const isAllotableValueZeroForAnyRow = () => {
    return listSkyBoxRefs.some((x) => x.value?.allocatableAmount === 0);
  };

  const customFilter = (option, searchText) => {
    if (
      String(option?.data?.id)
        ?.toLowerCase()
        .startsWith(searchText?.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
        {innerLoading === "fetch" && <FullLoader />}
        <Form onSubmit={handleSubmit(validateSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>PURCHASE ORDERS</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/purchases",
                name: "Purchase Order",
                active: false,
              },
              {
                name: isEdited ? "Edit Purchase Order" : "New Purchase Order",
                active: true,
              },
            ]}
          />
          <Modal.Body>
            <Container className="container-fluid p-2 w-100 h-100" fluid>
              <Row>
                <p className="title-accounts">Basic Information</p>
                <Col sm={6}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Description
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        readOnly={selectedRecord?.autoGenerate}
                        isInvalid={!!errors.description}
                        {...register("description", { required: true })}
                        placeholder="Description"
                      />
                      <Form.Control.Feedback type="invalid">
                        Description is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Date
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        type="date"
                        readOnly={selectedRecord?.autoGenerate}
                        isInvalid={!!errors.purchaseOrderDate}
                        {...register("purchaseOrderDate", { required: true })}
                      />
                      <Form.Control.Feedback type="invalid">
                        Date is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Row className="mt-3">
                  <Col>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        Season
                      </Form.Label>
                      <Col sm="7">
                        <Controller
                          name="seasonId"
                          control={control}
                          // rules={{ required: true }}
                          render={({
                            field,
                            fieldState: { invalid, error },
                          }) => {
                            return (
                              <>
                                <Select
                                  required
                                  isDisabled={selectedRecord?.autoGenerate}
                                  isInvalid={!!errors.seasonId}
                                  value={dropVal?.season}
                                  onChange={async (e) => {
                                    field.onChange(e?.id);
                                    setdropVal({ ...dropVal, season: e });
                                  }}
                                  isClearable
                                  styles={customStyles}
                                  options={seasonList}
                                  getOptionLabel={(option) =>
                                    `${option.seasonName}`
                                  }
                                />
                                {/* {invalid && error ? <div className="custom-invalid-feedback">Season is required.</div> : ""} */}
                              </>
                            );
                          }}
                        />
                      </Col>
                      <Col sm="1">
                        <Button onClick={openSeason}>
                          <img src={Add} width={15} height={15} />
                        </Button>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Row} className="ms-2">
                      <Form.Label column sm="4">
                        Total Scheduled
                      </Form.Label>
                      <Col sm="7">
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>$</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            type="float"
                            readOnly={isEdited || selectedRecord?.autoGenerate}
                            isInvalid={!!errors.totalScheduled}
                            {...register("totalScheduled", {
                              required: true,
                              onChange: (e) => {
                                setShowPrompt(true);
                                setShouldDisableAdd(true);
                              },
                            })}
                            placeholder="$0.00"
                          />
                          <Form.Control.Feedback type="invalid">
                            Total Scheduled is required.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Row>

              <hr />
              <Row>
                <p className="title-accounts">Vendor Details</p>
                <Col sm={6}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Vendor
                    </Form.Label>
                    <Col sm="7">
                      <Controller
                        name="vendorId"
                        control={control}
                        rules={{
                          required: vendorData ? false : true,
                          message: "error message",
                        }}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <div>
                              <Select
                                required
                                // isDisabled={
                                //   selectedRecord?.autoGenerate || !!vendorData
                                // }
                                isInvalid={!!errors.vendorId}
                                value={dropVal.vendor}
                                onChange={(e) => {
                                  field.onChange(e?.id);
                                  setdropVal({
                                    ...dropVal,
                                    vendor: e,
                                    // name: accountsData ? dropVal.name : "",
                                    name: dropVal.name,
                                  });
                                }}
                                styles={customStyles}
                                // isClearable
                                options={getVendorData()}
                                getOptionLabel={(option) =>
                                  `${option.id}  ${option.name}`
                                }
                              />
                              {invalid && error ? (
                                <div className="custom-invalid-feedback">
                                  Vendor is required.
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Name on Account
                    </Form.Label>
                    <Col sm="7">
                      <Controller
                        name="accountId"
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <div>
                              <Select
                                required
                                isInvalid={!!errors.accountId}
                                value={dropVal.name}
                                isDisabled={
                                  selectedRecord?.autoGenerate ||
                                  !(dropVal?.vendor?.id > 0) ||
                                  !!accountsData
                                }
                                onChange={(e) => {
                                  setInactive(false);
                                  field.onChange(e?.accountId);
                                  setValue("accountName", e?.name);
                                  setdropVal({ ...dropVal, name: e });
                                  if (e?.categoryId !== 1) setInactive(true);
                                }}
                                styles={customStyles}
                                // isClearable
                                options={accountListByVendor.filter(
                                  (acc) => acc.vendorId === dropVal?.vendor?.id
                                )}
                                getOptionLabel={(option) =>
                                  `${option.accountId}  ${option.name}`
                                }
                              />
                            </div>
                          );
                        }}
                      />
                    </Col>
                    {inactive && (
                      <Row className="mt-4">
                        <p style={{ color: "#EB5757", fontSize: 14 }}>
                          This account is
                          {dropVal?.name?.categoryId == 2
                            ? " inactive"
                            : " revoked"}
                          . Please activate the account before trying to create
                          a Purchase Order
                        </p>
                      </Row>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              {isEdited && <hr />}
              {isEdited && (
                <div style={{ textAlign: "right" }}>
                  <Button
                    type="submit"
                    variant="primary"
                    className="ss-modal-primary-btn mx-2"
                    onClick={() => {
                      setUpdate(true);
                    }}
                    disabled={!isInputUpdated}
                  >
                    Update details
                  </Button>
                </div>
              )}

              <hr />
              <Row>
                <Col sm="6">
                  <p className="title-accounts">Account Details</p>
                  <p>
                    {" "}
                    <span className="text-muted">Total Alloted Value</span>{" "}
                    {formatMoney(getTotalAllocatingValues())}
                  </p>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <Button onClick={onAddClickButton}>
                    <img src={Add} width={15} height={15} /> Add
                  </Button>
                </Col>
              </Row>
              <div className="over">
                {listSkyBoxRefs.map((refs) => (
                  <div key={refs?.id}>
                    <Row className="mt-3">
                      <Col sm="4">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3">
                            SkyBox Ref
                          </Form.Label>
                          <Col sm="7">
                            <Controller
                              name={`skyBoxRef-${refs.id}`}
                              control={control}
                              render={({ field }) => {
                                return (
                                  <>
                                    <Select
                                      onFocus={(e) => e.target.select()}
                                      value={
                                        listSkyBoxRefs.find(
                                          (x) => x.id == refs.id
                                        )?.value || ""
                                      }
                                      onChange={(e) => {
                                        if (!e) {
                                          setListSkyBoxRefs([
                                            ...listSkyBoxRefs.map((skRefs) => {
                                              if (skRefs.id === refs.id) {
                                                return {
                                                  ...skRefs,
                                                  value: e,
                                                };
                                              } else {
                                                return skRefs;
                                              }
                                            }),
                                          ]);
                                          setValue(
                                            `allocatedSkyBoxAmount-${refs.id}`,
                                            0
                                          );
                                          setShowPrompt(true);
                                          setShouldDisableAdd(true);
                                        } else {
                                          field.onChange(e?.id);
                                          const isItAlreadySelected =
                                            listSkyBoxRefs.some(
                                              (x) => x?.value?.id === e?.id
                                            );
                                          if (
                                            isItAlreadySelected &&
                                            refs?.value?.id !== e?.id
                                          ) {
                                            dispatch(
                                              errorMessage(
                                                "SkyRef is already selected"
                                              )
                                            );
                                          } else {
                                            setListSkyBoxRefs([
                                              ...listSkyBoxRefs.map(
                                                (skRefs) => {
                                                  if (skRefs.id === refs.id) {
                                                    return {
                                                      ...skRefs,
                                                      value: e,
                                                    };
                                                  } else {
                                                    return skRefs;
                                                  }
                                                }
                                              ),
                                            ]);
                                            setValue(
                                              `allocatedSkyBoxAmount-${refs.id}`,
                                              e.allocatableAmount
                                            );
                                            setShowPrompt(true);
                                            setShouldDisableAdd(true);
                                          }
                                        }
                                      }}
                                      onInputChange={(e) => {
                                        if (e != "") {
                                          const data = inputValues.find(
                                            (x) => x.id === refs.id
                                          );
                                          if (data) {
                                            setInputValues([
                                              ...inputValues.filter(
                                                (x) => x.id !== refs.id
                                              ),
                                              { id: refs.id, value: e },
                                            ]);
                                          } else {
                                            setInputValues([
                                              ...inputValues,
                                              { id: refs.id, value: e },
                                            ]);
                                          }
                                        }
                                      }}
                                      isClearable
                                      menuPortalTarget={document.body}
                                      menuPosition={"fixed"}
                                      styles={customStyles}
                                      options={skyboxMultipleRefData[refs.id]}
                                      getOptionLabel={(option) =>
                                        `${option.id}`
                                      }
                                      filterOption={customFilter}
                                    />
                                    <Form.Text className="text-muted">
                                      {loadingData[refs.id] && "Loading"}
                                    </Form.Text>
                                  </>
                                );
                              }}
                            />
                          </Col>
                          <Col xs={1}>
                            <Button
                              onClick={() => {
                                callList(refs.id);
                              }}
                            >
                              <img src={Search} width={15} height={20} />
                            </Button>
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group as={Row}>
                          <Form.Label column sm="4">
                            Sky box Value
                          </Form.Label>
                          <Col sm="6">
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control
                                type="float"
                                disabled
                                value={refs?.value?.total || 0}
                                isInvalid={!!errors.skyboxRefAmount}
                                {...register(`skyboxRefAmount-${refs.id}`)}
                                placeholder="Skybox Value"
                              />
                            </InputGroup>
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col sm="3">
                        <Form.Group as={Row}>
                          <Form.Label column sm="4">
                            Allotted Value
                          </Form.Label>
                          <Col sm="6">
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control
                                type="float"
                                // value={skyBoxRef?.total ? skyBoxRef?.total : 0}
                                // isInvalid={!!errors.skyboxRefAmount}
                                {...register(
                                  `allocatedSkyBoxAmount-${refs.id}`,
                                  {
                                    onChange: (e) => {
                                      setShowPrompt(true);
                                      setShouldDisableAdd(true);
                                    },
                                  }
                                )}
                                isInvalid={
                                  !!errors[`allocatedSkyBoxAmount-${refs.id}`]
                                }
                                placeholder="0.00"
                                onKeyPress={(e) =>
                                  !/[0-9]|\./.test(e.key) && e.preventDefault()
                                }
                                disabled={
                                  !refs?.value?.id ||
                                  refs?.value?.allocatableAmount === 0
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {!!errors[`allocatedSkyBoxAmount-${refs.id}`] &&
                                  errors[`allocatedSkyBoxAmount-${refs.id}`]
                                    .type === "required" &&
                                  "Alloting Value is required"}
                              </Form.Control.Feedback>

                              {!isEdit && (
                                <div>
                                  {isInValid(refs) && (
                                    <Form.Text
                                      style={{ display: "block" }}
                                      className="invalid-feedback"
                                    >
                                      Alloting value should be less than or
                                      equal to {getMax(refs)}
                                    </Form.Text>
                                  )}
                                </div>
                              )}
                            </InputGroup>
                            <Form.Text className="text-muted">
                              Allottable : {getMax(refs)}
                            </Form.Text>
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col sm="1">
                        <Button
                          variant="link"
                          className="table-action-button"
                          onClick={() => {
                            setListSkyBoxRefs(
                              listSkyBoxRefs.filter((x) => x.id !== refs.id)
                            );
                            if (
                              listSkyBoxRefs.filter((x) => x.id !== refs.id)
                            ) {
                              setShouldDisableAdd(false);
                            }
                            setShowPrompt(true);
                          }}
                        >
                          <img
                            style={{ width: "15px", height: "100%" }}
                            src={DeleteIcon}
                            alt="Delete Purchase Orders"
                          />
                        </Button>
                      </Col>
                    </Row>
                    {refs?.value?.allocatableAmount === 0 && (
                      <div>
                        <p
                          className="text-danger"
                          style={{
                            color: "rgb(235, 87, 87)",
                            fontSize: "14px",
                          }}
                        >
                          The allottable value of the selected Skybox PO is 0.
                          Please select another Skybox PO.
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {shouldShowPrompt() && showPrompt ? (
                <Row className="mt-4">
                  <Col sm={8}>
                    <p style={{ color: "#EB5757", fontSize: 14 }}>
                      Purchase Order {selectedRecord?.purchaseOrderId} has a
                      total value of{" "}
                      {formatMoney(watchAllFields[`totalScheduled`])} and sum of
                      Skybox Ref {getAllSkyBoxRefIds().join(", ")} has a total
                      value of {formatMoney(getTotalAllocatingValues())} There
                      is a difference of{" "}
                      {formatMoney(
                        Math.abs(
                          (parseFloat(watchAllFields[`totalScheduled`]) || 0) -
                            getTotalAllocatingValues()
                        )
                      )}
                      . Are you sure you want to complete this match?
                    </p>
                  </Col>
                  <Col sm={3}>
                    <Button
                      variant="primary"
                      className="ss-modal-primary-btn mr-2"
                      onClick={() => {
                        setShowPrompt(false);
                        setShouldDisableAdd(false);
                      }}
                      disabled={shouldDisableConfirmatButton()}
                    >
                      Confirm
                    </Button>
                  </Col>
                  <Col xs={1}>
                    <Button
                      className=""
                      onClick={() => {
                        setSkyBoxRef("");
                        setInputValues([]);
                        setValue("skyBoxRef", "");
                        setShowPrompt(false);
                        setShouldDisableAdd(true);
                      }}
                    >
                      <span>X</span>
                    </Button>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Container>
          </Modal.Body>
          <div className="d-flex justify-content-center my-4">
            <Button
              type="submit"
              variant="primary"
              className="ss-modal-primary-btn mx-2"
              disabled={
                shouldDisableAdd ||
                inactive ||
                isAllotableValueZeroForAnyRow() ||
                loading
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isEdited ? "Save" : "Add"}
                {loading && <Loader style={{ marginLeft: "8px" }} />}
              </div>
            </Button>

            <Button
              variant="light"
              onClick={handleReset}
              className="ss-modal-secondary-btn mx-2"
            >
              {isEdited ? "Reset" : "Clear"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddEditPOModal;
