import { Route, Switch } from "react-router-dom";
import SkyBoxDashboard from "../../../pages/skyboxPurchases/SkyBoxDashboard";

const PSLRoutes = () => {
  return (
    <Switch>
      <Route path="/skybox-purchases" exact>
        <SkyBoxDashboard />
      </Route>
    </Switch>
  );
};

export default PSLRoutes;
