import HeaderPart from "./parts/HeaderPart";
import TableHead from "./parts/TableHead";
import DescriptionHeader from "./parts/DescriptionHeader";
import FooterPart from "./parts/FooterPart";

import { useEffect } from "react";
import Loader from "../loader/Loader";

const QuickTixTable = ({
  headerName,
  paging,
  columns,
  renderRow,
  data,
  selectedTabIndex,
  tabs,
  onTabSelectionChange,
  headOptions,
  headButton,
  setpageNumber,
  setSortField,
  sortDir,
  middleOptions,
  setSortDir,
  sortField,
  noHeader = false,
  previousPage,
  nextPage,
  paramerterNavigation,
  loading = false,
}) => {
  return (
    <div className="quicktix-table">
      {!noHeader && (
        <HeaderPart
          headerName={headerName}
          tabs={tabs}
          headButton={headButton}
          selectedTabIndex={selectedTabIndex}
          onTabSelectionChange={onTabSelectionChange}
          headOptions={headOptions}
          middleOptions={middleOptions}
        />
      )}

      <TableHead
        columns={columns}
        setSortField={setSortField}
        sortDir={sortDir}
        setSortDir={setSortDir}
        sortField={sortField}
      />

      <div className="table-body">
        {loading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "200px",
            }}
          >
            <Loader size="xl" />
          </div>
        )}

        {!loading &&
          (data?.length > 0 ? (
            data.map((d, i) => renderRow(d, i))
          ) : (
            <h5 className="p-5 text-center">No records to display</h5>
          ))}
      </div>

      {paging && (
        <FooterPart
          paramerterNavigation={paramerterNavigation}
          nextPage={nextPage}
          previousPage={previousPage}
          paging={paging}
          setpageNumber={setpageNumber}
          setSortDir={setSortDir}
          sortDir={sortDir}
          sortField={sortField}
          setSortField={setSortField}
        />
      )}
    </div>
  );
};

export default QuickTixTable;

// {pageType === "accounts" ? (
//   <HeaderPart
//     headerName={headerName}
//     selectedPageSize={selectedPageSize}
//     onPageSizeChange={onPageSizeChange}
//     ExportCsv = {ExportCsv}
//   />
// ) : (
//   <DescriptionHeader
//     accOption={accOption}
//     setaccOption={setaccOption}
//     selectedPageSize={selectedPageSize}
//     onPageSizeChange={onPageSizeChange}
//     propData={data}
//     ExportCsv = {ExportCsv}
//   />
// )}
