import React, { useEffect, useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import FilterPanel2024 from "../../shared/components/panels/filter-panel/FilterPanel2024";
import { mixpanel_contants } from "../../mixpanel";
import { getVendorsCategory } from "../../data/reducers/vendors/vendors.reducer";
import { fetchVendorsListForDropdown } from "../../data/reducers/vendors/cached-vendors.reducer";

const VendorsFilterPanel = ({
  filters,
  setFilters,
  resetHandler,
  error,
  cardError,
}) => {
  const dispatch = useDispatch();

  const { category } = useSelector((state) => state.vendors);

  const { vendorsListForDropdown } = useSelector(
    (state) => state.cachedVendors
  );

  useEffect(() => {
    if (!category.length) {
      dispatch(getVendorsCategory());
    }
  }, [category]);

  useEffect(() => {
    if (!vendorsListForDropdown.length) {
      dispatch(fetchVendorsListForDropdown());
    }
  }, [vendorsListForDropdown]);

  const defaultValues = useMemo(() => {
    return Object.entries(filters).map(([key, value]) => ({ key, value }));
  }, [filters]);

  //style for dropdown
  const customStyles = {
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };
  return (
    <>
      <FilterPanel2024
        defaultValues={defaultValues}
        filtersCount={
          Object.entries(filters).filter(
            ([key, value]) =>
              value !== "" &&
              key !== "searchString" &&
              key !== "PageNumber" &&
              key !== "PageSize" &&
              key !== "SortDir" &&
              key !== "SortField" &&
              !(key === "CategoryId" && value === 0)
          ).length
        }
        searchPlaceholder="Search for Vendor Name, Inventory, Total Charges ..."
        dashboard={mixpanel_contants.VENDORS}
        onReset={() => {
          setFilters({
            ...filters,
            PageNumber: 1,
            searchString: "",
            CategoryId: 0,
            Id: "",
          });
          if (error || cardError) {
            resetHandler();
          }
        }}
        onSearch={(data, filterPanelVisible) => {
          setFilters({
            ...filters,
            PageNumber: 1,
            searchString: filterPanelVisible ? "" : data.searchString,
            CategoryId: filterPanelVisible ? data.CategoryId : 0,
            Id: filterPanelVisible ? data.Id : "",
          });
        }}
        renderAdvancedPanel={(
          register,
          handelSubmit,
          errros,
          control,
          closePanel,
          getValues
        ) => (
          <Row>
            <Col sm="7" lg="4" xl="3">
              <Form.Group as={Row}>
                <Form.Label>Vendor</Form.Label>
                <Col>
                  <Controller
                    name="Id"
                    control={control}
                    render={({ field }) => {
                      const value = vendorsListForDropdown.find(
                        (v) => v.id == field.value
                      );
                      return (
                        <Select
                          value={value === undefined ? null : value}
                          onChange={(e) => field.onChange(e.id)}
                          styles={customStyles}
                          options={vendorsListForDropdown}
                          getOptionLabel={(option) =>
                            `${option.id}  ${option.name}`
                          }
                        />
                      );
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm="7" lg="4" xl="3">
              <Form.Group as={Row}>
                <Form.Label>Type</Form.Label>
                <Col>
                  <Controller
                    name="CategoryId"
                    control={control}
                    render={({ field }) => {
                      const value = category.find((v) => v.id == field.value);
                      return (
                        <Select
                          value={value === undefined ? null : value}
                          onChange={(e) => field.onChange(e.id)}
                          styles={customStyles}
                          options={category}
                          getOptionLabel={(option) => `${option.type}`}
                        />
                      );
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        )}
      />
    </>
  );
};

export default React.memo(VendorsFilterPanel);
