import { Route, Switch } from "react-router-dom";
import CardDashBoard from "../../../pages/fundSources/cards/CardsDashboard";
import SingleCardDetail from "../../../pages/fundSources/cards/SingleCardDetails"

const CardsRoutes = () => {
  return (
    <Switch>
      <Route path="/fundSources/cards" exact>
        <CardDashBoard />
      </Route>
      <Route path="/fundSources/cards/:id" exact>
        <SingleCardDetail />
      </Route>
    </Switch>
  );
};

export default CardsRoutes;