import { Route, Switch } from "react-router-dom";
import EventsDashboard from "../../../pages/events/EventsDashboard";

const InventoryRoutes = () => {
  return (
    <Switch>
      <Route path="/events" exact>
        <EventsDashboard />
      </Route>
    </Switch>
  );
};

export default InventoryRoutes;