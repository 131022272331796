import moment from "moment";
import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row, Dropdown, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { errorMessage } from "../../data/reducers/alerts/alert.reducer";
import {
  exportExcelSingleSeason,
  exportPdfSingleSeason,
  getSeasonByID,
  getSeasonDeatails,
} from "../../data/reducers/season/season.reducer";
import ChargesCard from "../../shared/components/accountActions/ChargesCard";
import ModalBreadcrumb from "../../shared/components/modals/ModalBreadcrumb";
import { withLoader } from "../../utils/hoc/withLoader";
import CollapsibleTable from "./CollapsibleTable";
import Select from "react-select";
import Export from "../../images/export.svg";
import { fetchVendorsListForDropdown } from "../../data/reducers/vendors/cached-vendors.reducer";

const SingleSeasonDashboard = ({ setBusy }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  //selectors
  const { data, paging } = useSelector((state) => state.season).seasonData;
  const selectedSeason = useSelector((state) => state.season).selectedSeason;
  const { vendorsListForDropdown: vendorDetails } = useSelector(
    (state) => state.cachedVendors
  );
  //states
  const [sortDir, setSortDir] = useState(true);
  const [sortField, setSortField] = useState(null);
  const [pageNumber, setpageNumber] = useState(1);
  const [pageSize, setpageSize] = useState(40);
  const [vendor, setVendor] = useState(null);
  const [triggerHook, setHookTrigger] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(getSeasonByID(id));
    }
  }, [id]);

  const { vendorsListForDropdown } = useSelector(
    (state) => state.cachedVendors
  );
  useEffect(() => {
    if (!vendorsListForDropdown.length) {
      dispatch(fetchVendorsListForDropdown());
    }
  }, [vendorsListForDropdown]);

  useEffect(() => {
    if (vendorDetails?.length > 0) {
      setVendor(vendorDetails[0]);
    }
  }, [vendorDetails]);

  useEffect(() => {
    if (id && vendor?.id) {
      setpageNumber(1);
      setHookTrigger((hook) => !hook);
    }
  }, [id, vendor?.id]);

  useEffect(() => {
    fetchData();
  }, [pageSize, pageNumber, sortField, sortDir, triggerHook]);

  const fetchData = async () => {
    try {
      setBusy(true);
      const data = {
        pageNumber,
        pageSize,
        sortDir,
        sortField,
        seasonId: id,
        vendorId: vendor?.id,
      };
      if (vendor?.id && id) {
        const response = await dispatch(getSeasonDeatails(data));
        if (response.error) {
          dispatch(errorMessage(response.error.message));
        }
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  const seasonDetails = [
    {
      id: 1,
      name: "Sport",
      key: "sportName",
    },
    {
      id: 2,
      name: "Season Code",
      key: "seasonCode",
    },
    {
      id: 6,
      name: "Gowri Sankar",
      render: () => {
        return (
          <ChargesCard
            text="Total PO Amount"
            price={data?.[0]?.seasonDashboardDetails?.totalPOAmounts || 0}
            number={false}
            bg="rgba(24, 144, 255, 0.2)"
            color="#0241A0"
          />
        );
      },
    },
    {
      id: 3,
      name: "Start Date",
      key: "startDate",
      value: moment(selectedSeason?.startDate).format("MM/DD/YYYY"),
    },
    {
      id: 4,
      name: "End Date",
      key: "endDate",
      value: moment(selectedSeason?.endDate).format("MM/DD/YYYY"),
    },
    {
      id: 6,
      render: () => {
        return (
          <ChargesCard
            text="Number of Accounts"
            price={data?.[0]?.seasonDashboardDetails?.totalAccounts || 0}
            number={true}
            bg="rgba(188, 219, 1, 0.2)"
            color="#849900"
          />
        );
      },
    },
  ];

  const seasonsCol = [
    {
      columnName: "Name on Account",
    },
    {
      columnName: "Vendor",
      sort: true,
      sortName: "name",
    },
    {
      columnName: "Total $",
    },
    {
      columnName: "Email/Phone Number",
      sort: true,
      sortName: "email",
    },
    {
      columnName: "Address",
      sort: true,
      sortName: "address",
    },
    {
      columnName: "Last 4/Credit Card",
    },
    {
      columnName: "Exp/CVC",
    },
    {
      columnName: "Action",
    },
  ];

  const onExportCSV = async () => {
    setBusy(true);
    await dispatch(
      exportExcelSingleSeason({
        seasonId: id,
        vendorId: vendor?.id,
        data: vendor,
      })
    );
    setBusy(false);
  };

  const onExportPdf = async () => {
    setBusy(true);
    await dispatch(
      exportPdfSingleSeason({
        seasonId: id,
        vendorId: vendor?.id,
        data: vendor,
      })
    );
    setBusy(false);
  };

  const headOptions = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small>Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue="40"
              onChange={(e) => setpageSize(e.target.value)}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div className="mt-3 d-flex align-items-center justify-content-end">
          <Dropdown
            className="position-inherit"
            onClick={(e) => e.currentTarget.blur()}
          >
            <Dropdown.Toggle className="ss-light-button" id="dropdown-basic">
              <img src={Export} alt=" " />
              <span>Export</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={onExportCSV}>
                Export as Excel
              </Dropdown.Item>
              <hr className="hr-full" />
              <Dropdown.Item onClick={onExportPdf}>Export as PDF</Dropdown.Item>
              <hr className="hr-half" />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  };

  //style for dropdown
  const customStyles = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };

  return (
    <div className="container-fluid pt-3">
      <Container className="page-upper-navigation d-flex justify-content-between align-items-center">
        {/* <Row> */}
        <Col md={4}>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                name: "Seasons",
                active: false,
                to: "/season",
              },
              {
                name: selectedSeason?.seasonName,
                active: true,
              },
            ]}
          />
        </Col>
        <Col md={{ span: 4, offset: 4 }} className="text-end">
          <NavLink className="ss-back-button text-end" to="/season">
            <small>Back to Season</small>
          </NavLink>
        </Col>
        {/* </Row> */}
      </Container>
      <Container className="root pt-3 mb-3 mt-3" fluid>
        <div className="">
          <h1 className="mylogo-title h4">{selectedSeason?.seasonName}</h1>
        </div>
        <hr />
        <Row className="p-3">
          {seasonDetails?.map((season) => {
            if (season?.render) {
              return (
                <Col sm={4} key={season?.id} className="mb-1">
                  {season.render()}
                </Col>
              );
            } else {
              return (
                <Col sm={4} key={season?.id} className="mb-1">
                  <Row>
                    <Col sm={4}>
                      <p className="text-muted">{season?.name}</p>
                    </Col>
                    <Col sm={8} style={{ fontWeight: "600" }}>
                      {season.value ?? selectedSeason?.[season?.key]}
                    </Col>
                  </Row>
                </Col>
              );
            }
          })}
          <Col sm={4} className="mb-1">
            <Row>
              <Col sm={4}>
                <p className="text-muted">Vendor</p>
              </Col>
              <Col sm={8}>
                <Select
                  value={vendor}
                  onChange={(e) => {
                    setVendor(e);
                  }}
                  styles={customStyles}
                  options={vendorDetails}
                  getOptionLabel={(option) => `${option.id}  ${option.name}`}
                  isSearchable
                />{" "}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <CollapsibleTable
        headerName={
          vendorDetails?.find((vndr) => vndr?.id === vendor?.id)?.name
        }
        paging={paging}
        columns={seasonsCol}
        data={data?.[0]?.seasonDetailsDTO ?? []}
        sortDir={sortDir}
        setSortDir={setSortDir}
        sortField={sortField}
        setSortField={setSortField}
        headOptions={headOptions}
        setpageNumber={setpageNumber}
      />
    </div>
  );
};

export default withLoader(SingleSeasonDashboard);
