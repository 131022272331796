import "../../styles/DashboardStyles.scss";
import { formatMoney } from "../../../utils/formatter/currencyFormatter";

const VerticalChargesCard = ({ bg, fontbg, text, price, number }) => {
  return (
    <>
      <div
        style={{
          backgroundColor: `${bg}`,
          color: `${fontbg}`,
        }}
        className="main-card rounded w-75 d-flex mb-1 p-2 flex-row align-items-center justify-content-evenly"
      >
        <p className="my-text h-100 w-50 m-auto">{text}</p>
        <p className="my-price d-flex flex-column align-items-center h-100 w-50">
          {number ? price : formatMoney(price)}
        </p>
      </div>
    </>
  );
};

export default VerticalChargesCard;
