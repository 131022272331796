import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { accountRulesServices } from "../../services/accounts/account-rules-service";
import fileDownload from "js-file-download";

export const createAccountRules = createAsyncThunk(
  "accounts/CreateAccountRules",
  async (payload, thunkAPI) => {
    const response = await accountRulesServices.createAccountRules(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getSelectedRules = createAsyncThunk(
  "accounts/getSelectedRules",
  async (payload, thunkAPI) => {
    const response = await accountRulesServices.getSelectedRules(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const updateAccountRules = createAsyncThunk(
  "accounts/UpdateAccountRules",
  async (payload, thunkAPI) => {
    const response = await accountRulesServices.updateAccountRules(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportCSVAccountRules = createAsyncThunk(
  "accounts/exportCSVAccount",
  async (payload, thunkAPI) => {
    const response = await accountRulesServices.exportCSVAccountRules(payload);
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "AccountRules.csv")
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportPdfAccountRules = createAsyncThunk(
  "accounts/exportPdfAccountRules",
  async (payload, thunkAPI) => {
    const response = await accountRulesServices.exportPdfAccountRules(payload);
    // if (response.isSuccessful === true) {
    //   fileDownload(response.data, "AccountRules.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

export const deleteAccountRules = createAsyncThunk(
  "accounts/DeleteAccountRules",
  async (payload, thunkAPI) => {
    const response = await accountRulesServices.deleteAccountRules(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAccountRules = createAsyncThunk(
  "accounts/getAccountRules",
  async (payload, thunkAPI) => {
    const response = await accountRulesServices.getAccountRules(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

const accountRulesSlice = createSlice({
  name: "accountRules",
  initialState: {
    paging: {},
    accountsRules: [],
    selectedAccRules: {},
  },
  reducers: {
    removeSelected: (state) => {
      state.selectedAccRules = {};
    },
  },
  extraReducers: {
    [createAccountRules.fulfilled]: (state, action) => {
      state.accountsRules.push(action.payload);
    },
    [getAccountRules.fulfilled]: (state, action) => {
      state.accountsRules = action.payload.data;
      state.paging = action.payload.paging;
    },
    [updateAccountRules.fulfilled]: (state, action) => {
      const index = state.accountsRules.findIndex((account) => account.id == action.payload.id);
      state.accountsRules[index] = action.payload;
      state.selectedAccRules = action.payload;
    },
    [deleteAccountRules.fulfilled]: (state, action) => {
      state.accountsRules = [
        ...state.accountsRules.filter(
          (account) => account.id !== action.payload.id
        ),
      ];
    },
  },
});

export const { removeSelected } = accountRulesSlice.actions;

export default accountRulesSlice.reducer;
