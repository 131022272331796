import { Route, Switch } from "react-router-dom";
import AddressBook from "../../../pages/addressBook/AddressDashboard";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";


const AddressBookRoutes = () => {
  return (
    <Switch>

      <Route path="/addressbook" exact>
        <AddressBook />
      </Route>

    </Switch>
  );
};

export default AddressBookRoutes;