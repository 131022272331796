import { poChargesServices } from "../../services/purchaseOrders/po-charges.service";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import fileDownload from "js-file-download";
import { flushTransactions } from "../transactions/cached-transactions.reducer";
import { flushTransactionsCards } from "../transactions/cached-transaction-cards.reducer";
import thunk from "redux-thunk";

export const exportCSVPurchaseCharges = createAsyncThunk(
  "purchaseOrderCharges/exportCSVPurchaseCharges",
  async (payload, thunkAPI) => {
    const response = await poChargesServices.exportCSVPurchaseOrderCharges(
      payload
    );
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "PurchaseOrderCharges.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportPdfPurchaseCharges = createAsyncThunk(
  "purchaseOrderCharges/exportPdfPurchaseCharges",
  async (payload, thunkAPI) => {
    const response = await poChargesServices.exportPdfPurchaseOrderCharges(
      payload
    );
    // if (response.isSuccessful === true) {
    //   fileDownload(response.data, "PurchaseOrderCharges.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

export const createPOCharges = createAsyncThunk(
  "purchaseOrderCharges/createPOCharges",
  async (payload, thunkAPI) => {
    const response = await poChargesServices.createPurchaseOrderCharges(
      payload
    );
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushTransactions());
      thunkAPI.dispatch(flushTransactionsCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const updatePOCharges = createAsyncThunk(
  "purchaseOrderCharges/updatePOCharges",
  async (payload, thunkAPI) => {
    const response = await poChargesServices.updatePurchaseOrderCharges(
      payload
    );
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushTransactions());
      thunkAPI.dispatch(flushTransactionsCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const deletePOCharges = createAsyncThunk(
  "purchaseOrderCharges/deletePOCharges",
  async (payload, thunkAPI) => {
    const response = await poChargesServices.deletePurchaseOrderCharges(
      payload
    );
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushTransactions());
      thunkAPI.dispatch(flushTransactionsCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAllPOCharges = createAsyncThunk(
  "purchaseOrderCharges/getAllPOCharges",
  async (payload, thunkAPI) => {
    const response = await poChargesServices.getPurchaseOrderCharges(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

const poChargeSlice = createSlice({
  name: "purchaseOrderCharge",
  initialState: {
    purchaseOrderCharges: [],
    paging: {},
  },
  extraReducers: {
    [getAllPOCharges.fulfilled]: (state, action) => {
      state.purchaseOrderCharges = action.payload.data;
      state.paging = action.payload.paging;
    },
    [createPOCharges.fulfilled]: (state, action) => {
      state.purchaseOrderCharges.push(action.payload);
    },
    [updatePOCharges.fulfilled]: (state, action) => {
      const index = state.purchaseOrderCharges.findIndex(
        (po) => po.id == action.payload.id
      );
      state.purchaseOrderCharges[index] = action.payload;
      state.selectedCharges = action.payload;
    },
    [deletePOCharges.fulfilled]: (state, action) => {
      state.purchaseOrderCharges = [
        ...state.purchaseOrderCharges.filter(
          (po) => po.id !== action.payload.id
        ),
      ];
    },
  },
});

export const { removeSelected } = poChargeSlice.actions;

export default poChargeSlice.reducer;
