import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import { errorMessage, successMessage } from "../alerts/alert.reducer";

export const fetchAccounts = createAsyncThunk(
  "cachedAccounts/fetchData",
  async (params) => {
    try {
      const filterKeys = [
        "searchString",
        "Address",
        "Email",
        "Name",
        "SportId",
        "AccountId",
        "TeamId",
        "VendorId",
      ];
      const hasFilters = Object.entries(params).filter(([key, value]) => {
        if (filterKeys.includes(key)) {
          return value != "";
        }
        return false;
      });

      const response = hasFilters.length
        ? await api.get("Accounts/search", params)
        : await api.get("Accounts/getall", params);
      return {
        params,
        response,
      };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const flushAccounts = createAsyncThunk(
  "cachedAccounts/flushAccounts",
  () => true
);

export const deleteAccount = createAsyncThunk(
  "cachedAccounts/delete",
  async (id) => {
    try {
      await api.delete("Accounts/delete", id);
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateAccount = createAsyncThunk(
  "cachedAccounts/update",
  async (payload) => {
    try {
      await api.update("Accounts/update", payload);
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const createAccount = createAsyncThunk(
  "cachedAccounts/create",
  async (payload, { dispatch }) => {
    try {
      await api.create("Accounts/create", payload);
      dispatch(successMessage("Account was created successfully"));
    } catch (error) {
      console.log("error", error);
      dispatch(errorMessage(error?.message ?? "Something went wrong"));
    }
  }
);

const cachedAccountSlice = createSlice({
  name: "cachedAccounts",
  initialState: {
    data: null,
    error: null,
    loading: false,
  },
  extraReducers: {
    // create
    [createAccount.pending]: (state) => {
      state.loading = true;
    },
    [createAccount.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
      state.data = null;
    },
    [createAccount.rejected]: (state, action) => {
      state.error = action.error;
    },

    // update
    [updateAccount.pending]: (state) => {
      state.loading = true;
    },
    [updateAccount.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
      state.data = null;
    },
    [updateAccount.rejected]: (state, action) => {
      state.error = action.error;
    },

    // delete
    [deleteAccount.pending]: (state) => {
      state.loading = true;
    },
    [deleteAccount.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
      state.data = null;
    },
    [deleteAccount.rejected]: (state, action) => {
      state.error = action.error;
    },

    // fetch
    [fetchAccounts.pending]: (state) => {
      state.loading = true;
    },
    [fetchAccounts.fulfilled]: (state, action) => {
      const { params, response } = action.payload;
      state.loading = false;
      state.error = null;
      if (!state.data) {
        state.data = {
          [JSON.stringify(params)]: {
            accounts: response.data,
            paging: response.paging,
            timestamp: Date.now(),
          },
        };
      } else {
        state.data[JSON.stringify(params)] = {
          accounts: response.data,
          paging: response.paging,
          timestamp: Date.now(),
        };
      }
    },
    [fetchAccounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // flush
    [flushAccounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = null;
    },
  },
});

export default cachedAccountSlice.reducer;
