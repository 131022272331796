import { Route, Switch } from "react-router-dom";
import AccountDashboard from "../../../pages/accounts/AccountDashboard";
import SingleAccountDetail from "../../../pages/accounts/SingleAccountDetail";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const AccountsRoutes = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/accounts" />
      </Route>
      <Route path="/accounts" exact>
        <AccountDashboard />
      </Route>
      <Route path="/accounts/:id" exact>
        <SingleAccountDetail />
      </Route>
    </Switch>
  );
};

export default AccountsRoutes;