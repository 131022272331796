import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PlaidServices } from '../../services/plaid/plaid.service';

export const getAllPlaid = createAsyncThunk(
    'Plaid/getAllPlaid',
    async (payload, thunkAPI) => {
        const response = await PlaidServices.getAllPlaid(payload);
        if (response.isSuccessful === true) {
            return response;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const getLinkToken = createAsyncThunk(
    'Plaid/getlinktoken',
    async (payload, thunkAPI) => {
        const response = await PlaidServices.getLinkToken(payload);
        if (response.isSuccessful === true) {
            return response;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const getAccessToken = createAsyncThunk(
    'Plaid/getAccesstoken',
    async (payload, thunkAPI) => {
        const response = await PlaidServices.getAccessToken(payload);
        if (response.isSuccessful === true) {
            return response;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const deletePlaid = createAsyncThunk(
    'Plaid/DeletePlaid',
    async (payload, thunkAPI) => {
        const response = await PlaidServices.deletePlaid(payload);
        if (response.isSuccessful === true) {
            return response.data;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

// export const filterPlaid = createAsyncThunk(
//     'Plaid/filterPlaid',
//     async (payload, thunkAPI) => {
//         const response = await PlaidServices.filterPlaid(payload);
//         if (response.isSuccessful === true) {
//             return response;
//         } else {
//             thunkAPI.rejectWithValue(response.message);
//             throw new Error(response.message);
//         }
//     }
// );

export const createPlaid = createAsyncThunk(
    'Plaid/CreatePlaid',
    async (payload, thunkAPI) => {
        const response = await PlaidServices.createPlaid(payload);
        if (response.isSuccessful === true) {
            return response.data;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const updatePlaid = createAsyncThunk(
    'Plaid/UpdatePlaid',
    async (payload, thunkAPI) => {
        const response = await PlaidServices.updatePlaid(payload);
        if (response.isSuccessful === true) {
            return response.data;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);


const PlaidSlice = createSlice({
    name: 'plaid',
    initialState: {
        plaid: [],
        paging: {},
    },
    extraReducers: {
        [getAllPlaid.fulfilled]: (state, action) => {
            state.plaid = action.payload.data;
            state.paging = action.payload.paging;
        },
        [createPlaid.fulfilled]: (state, action) => {
            state.plaid.push(action.payload);
        },
        [updatePlaid.fulfilled]: (state, action) => {
            const index = state.plaid.findIndex(
                (Plaid) => Plaid.id == action.payload.id
            );
            state.plaid[index] = action.payload;
        },
        [deletePlaid.fulfilled]: (state, action) => {
            state.plaid = [
                ...state.plaid.filter((Plaid) => Plaid.id !== action.payload.id),
            ];
        },
        // [filterPlaid.fulfilled]: (state, action) => {
        //     state.Plaid = action.payload.data;
        //     state.paging = action.payload.paging;
        // },

        // [headerInfo.fulfilled]: (state, action) => {
        //     state.cards = action.payload;
        // }
    },
});

export default PlaidSlice.reducer;

