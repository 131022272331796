import React from "react";

const WeekHeader = ({ weekDays }) => {
	return (
		<div
			className="table-head d-flex justify-content-between px-3 py-2 align-items-center"
			style={{ backgroundColor: "#0241a0", color: "white", minHeight: "50px" }}
		>
			{weekDays.map((column, index) => (
				<div
					key={index}
					style={{
						flexGrow: column.flexGrow > 0 ? column.flexGrow : 1,
						flexBasis: 0,
						textAlign: "center",
						// minWidth: "160px",
						// maxWidth: "160px",
					}}
					className="mx-2"
				>
					<small>{column.day}</small>
				</div>
			))}
		</div>
	);
};

export default WeekHeader;
