import { Route, Switch } from "react-router-dom";
import PlaidDashBoard from "../../../pages/fundSources/plaid/PlaidDashboard";

const PlaidRoutes = () => {
  return (
    <Switch>
      <Route path="/fundSources/plaid-accounts" exact>
        <PlaidDashBoard />
      </Route>
    </Switch>
  );
};

export default PlaidRoutes;