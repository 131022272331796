import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SyncDataServices } from "../../services/sync/sync.service";
import { successMessage } from "../alerts/alert.reducer";

export const getAllSyncData = createAsyncThunk(
	"Skybox/getAllSyncData",
	async (payload, thunkAPI) => {
		const response = await SyncDataServices.getAllSyncData(payload);
		if (response.isSuccessful === true) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const filterSyncData = createAsyncThunk(
	"Skybox/filterSyncData",
	async (payload, thunkAPI) => {
		const response = await SyncDataServices.filterSyncData(payload);
		if (response.isSuccessful === true) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const getPaymentCalendarData = createAsyncThunk(
	"Calendar/getPaymentCalendarData",
	async (payload, thunkAPI) => {
		const response = await SyncDataServices.getPaymentCalendarData(payload);
		if (response.isSuccessful === true) {
			thunkAPI.dispatch(successMessage("Fetched the data successfully"));
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

const SkyboxSlice = createSlice({
	name: "sync",
	initialState: {
		syncData: [],
		paging: {},
		calendarData: [],
	},
	extraReducers: {
		[getAllSyncData.fulfilled]: (state, action) => {
			state.syncData = action.payload.data;
			state.paging = action.payload.paging;
		},
		[filterSyncData.fulfilled]: (state, action) => {
			state.syncData = action.payload.data;
			state.paging = action.payload.paging;
		},
		[getPaymentCalendarData.fulfilled]: (state, action) => {
			state.calendarData = action.payload.data;
		},
	},
});

export default SkyboxSlice.reducer;
