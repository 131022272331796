import mixpanel from "mixpanel-browser";
const MIXPANEL_PROJECT_KEY = process.env.REACT_APP_MIXPANEL_PROJECT_KEY;
mixpanel.init(MIXPANEL_PROJECT_KEY, { debug: false });

let actions = {
  identify: (id) => {
    try {
      mixpanel.identify(id);
    } catch (err) {
      console.error("mixpanel:identify ", err);
    }
  },
  reset: () => {
    mixpanel.reset();
  },
  alias: (id) => {
    try {
      mixpanel.alias(id);
    } catch (err) {
      console.error("mixpanel:alias ", err);
    }
  },
  track: (name, props) => {
    try {
      mixpanel.track(name, props);
    } catch (err) {
      console.error("mixpanel:track ", err);
    }
  },
  people: {
    set: (props) => {
      try {
        mixpanel.people.set(props);
      } catch (err) {
        console.error("mixpanel:people.set ", err);
      }
    },
  },
  time_event: (event) => {
    try {
      mixpanel.time_event(event);
    } catch (err) {
      console.error("mixpanel:timeInterval ", err);
    }
  },
};

export const mixpanel_event_constants = {
  PAGE_EXIT: "page_exit",
  PAGE_VIEW: "page_view",
  BUTTON_CLICK: "button_click",
  RANDOM_BUTTON_CLICK: "random_button_click",
};

export const mixpanel_button_name = {
  RUNRULES: "Run Rules",
  EXPORT_SELECTED_AS_CSV: "Export as CSV As CSV",
  EXPORT_SELECTED_AS_PDF: "Export as CSV As PDF",
  EXPORT_AS_CSV: "Export As CSV",
  EXPORT_AS_PDF: "Export As PDF",
  EXPORT_PAYMENT_PLAN_AS_CSV: "Export Payment Plans As CSV",
  EXPORT_SELECTED_PAYMENT_PLAN_AS_CSV: "Export as CSV Payment Plans As CSV",
  DOWNLOAD_TEMPLATE: "Download Template",
  UPLOAD: "Upload",
  VENDOR_RULES: "Vendor Rules",
  ACCOUNTS_RULES: "Accounts Rules",
  PURCHASE_ORDER: "Purchase Order",
  UNLINK_VENDOR_ACCOUNT: "Unlink Vendor & Account",
  ADD_PO: "Add Purchase Order",
  SEARCH: "Search",
  FILTER: "Filter",
  ADD_VENDOR: "Add vendor",
  SYNC_SKYBOX: "Sync To Skybox",
  EDIT: "Edit",
  DELETE: "Delete",
  ADD: "Add",
  COLLAPSE: "Collapse Button",
};

export const mixpanel_contants = {
  ACCOUNT: "Accounts Dashboard",
  ADDRESSBOOK: "Addressbook Dashboard",
  SEASON: "Season Dashboard",
  PURCHASEORDERS: "Purchaseorders Dashboard",
  SKYBOX_PURCHASES: "Skybox Purchases Dashboard",
  SALES: "Sales Dashboard",
  EVENTS: "Events Dashboard",
  TRANSACTIONS: "Transactions Dashboard",
  FINANCE: "Finance Dashboard",
  PSL: "PSL Dashboard",
  CARDS: "Cards Dashboard",
  PAYPAL: "Paypal Dashboard",
  PLAID_ACCOUNTS: "Plaid accounts Dashboard",
  SPORTS_TEAMS: "Sports & Teams Dashboard",
  REVEAL_MARKET: "Reveal Market Dashboard",
  SYNC: "Sync Dashboard",
  BI_REPORT: "Bi Report Dashbaord",
  EMAIL: "Email Dashboard",
  UPLOAD: "Upload Email Dashoboard",
  PAYMENT_CALENDAR: "Payment Calendar Dashboard",
  VENDORS: "Vendors Dashboard",
  SINGLE_PO: "Single Purchaseorder Page",
};
export let Mixpanel = actions;
