import {
    api,
    getAuthHeaders,
    get,
    post,
    update,
    del,
    getCSV,
} from '../services.common';

const getAllPlaid = async (data) => {
    const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
    const url = `${api}CardInfo/getallplaidaccounts?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data?.sortField || ''}`;
    const authHeader = getAuthHeaders();
    return await get(url, { ...authHeader });
};

const getLinkToken = async (data) => {
    const url = `${api}CardInfo/getlinktoken`;
    const authHeader = getAuthHeaders();
    return await post(url, data, { ...authHeader });
};

const getAccessToken = async (data) => {
    const url = `${api}CardInfo/getaccesstoken?publicToken=${data.publicToken}`;
    const authHeader = getAuthHeaders();
    return await get(url, { ...authHeader });
};

const createPlaid = async (formData) => {
    const url = `${api}CardInfo/createplaiaccount`;
    const authHeader = getAuthHeaders();
    return await post(url, formData, { ...authHeader });
};

const updatePlaid = async (data) => {
    const url = `${api}CardInfo/updateplaidaccount/${data.id}`;
    const authHeader = getAuthHeaders();
    return await update(url, data, { ...authHeader });
};

const deletePlaid = async (id) => {
    const url = `${api}CardInfo/deleteplaidaccount/${id}`;
    const authHeader = getAuthHeaders();
    return await del(url, { ...authHeader });
};
export const PlaidServices = {
    getLinkToken,
    getAllPlaid,
    getAccessToken,
    updatePlaid,
    createPlaid,
    deletePlaid 
};
