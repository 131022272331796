import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CollapsibleTable from "../CollapsibleTable";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableHead from "../../../shared/components/table/parts/TableHead";
import TableRowCard from "../../../shared/components/table/TableRowCard";
import { columnError } from "../../../shared/components/table/data/error";
import moment from "moment";
import { getMatchedTickets } from "../../../data/reducers/inventory/inventory.reducers";
import { errorMessage } from "../../../data/reducers/alerts/alert.reducer";

const FulfillmentInventoryModal = ({
  isVisible,
  handleClose,
  selectedRecord,
  selectedTabIndex,
  headOptions,
  setpageNumber,
  setSortField,
  sortDir,
  setSortDir,
  sortField,
  selectedTickets,
  setSelectedTickets,
  inventoryCol,
  setAddEditInventoryModalVisibility,
  setAddEditInventoryEventModalVisibility,
}) => {
  const dispatch = useDispatch();

  const [isEdit, setEdit] = useState(false);
  const ticketData = useSelector((state) => state.inventory).matchedTickets;
  const [isReset, setIsReset] = useState(false);
  const [totTickets, setTotTickets] = useState(0);
  const [searchMoreEventsButton, setSearchMoreEventsButton] = useState(false);

  // reset form on visibility toggle
  useEffect(() => {
    reset();

    if (selectedTickets?.tickets?.length === 0)
      setSearchMoreEventsButton(false);
  }, [isVisible]);

  useEffect(async () => {
    if (selectedRecord) {
      const earlyData = ticketData;

      const response = await dispatch(
        getMatchedTickets({
          eventDate: selectedRecord?.eventDateTime,
          section: selectedRecord?.section,
          row: searchMoreEventsButton ? null : selectedRecord?.row,
          vendorId: selectedRecord?.vendorId,
        })
      );

      if (
        isVisible &&
        searchMoreEventsButton &&
        response?.payload?.isSuccessful &&
        (response?.payload?.data === null ||
          response?.payload?.data.length <= earlyData?.length)
      ) {
        dispatch(errorMessage("No additional tickets found"));
      }
    }
  }, [isVisible, selectedRecord, searchMoreEventsButton]);

  // update values in form if selected record is changed
  useEffect(() => {
    if (selectedRecord) {
      // as soon as selected record is changed, set values in modal popup
      setEdit(true);
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    } else {
      // if selected record is undefined, reset
      setEdit(false);
      reset();
    }
  }, [selectedRecord, isVisible]);
  // setup react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm();

  useEffect(() => {
    let total = 0;
    ticketData?.forEach((t) => {
      total += t.quantity;
    });
    setTotTickets(total);
  }, [ticketData]);

  return (
    <>
      <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
        <Form onSubmit={handleClose}>
          {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
          <Modal.Header closeButton>
            <Modal.Title>FULFILLMENT STATUS - PENDING</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                name: "Sales",
                active: true,
              },
              {
                name: `Event - ${selectedRecord?.eventName}`,
                active: false,
              },
            ]}
          />
          <Modal.Body>
            <div className="container-fluid p-2 w-100 h-100">
              <div className="quicktix-table">
                <TableHead
                  columns={inventoryCol}
                  setSortField={setSortField}
                  sortDir={sortDir}
                  setSortDir={setSortDir}
                  sortField={sortField}
                />

                <div className="table-body">
                  {selectedRecord ? (
                    <TableRowCard
                      key={selectedRecord.id}
                      columns={
                        selectedRecord.nodata ? columnError : inventoryCol
                      }
                      rowData={selectedRecord}
                    />
                  ) : (
                    <h5 className="p-5 text-center">No records to display</h5>
                  )}
                </div>
              </div>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    marginBottom: 3,
                    width: 1,
                    height: 40,
                  },
                }}
              >
                <Paper style={{ backgroundColor: "#0241a0" }} elevation={3}>
                  <div className="mt-2 d-flex justify-content-around align-items-center text-white">
                    <p>{selectedRecord?.eventName}</p>
                    <p>
                      {moment(selectedRecord?.eventDateTime).format(
                        "MM-DD-YYYY h:mm A"
                      )}
                    </p>
                    <p>{totTickets} Tickets</p>
                  </div>
                </Paper>
              </Box>

              <CollapsibleTable
                paging={false}
                columns={[]}
                setSortField={setSortField}
                sortDir={sortDir}
                sortField={sortField}
                setSortDir={setSortDir}
                selectedTabIndex={selectedTabIndex}
                data={ticketData || []}
                headOptions={headOptions}
                setpageNumber={setpageNumber}
                selectedTickets={selectedTickets}
                setSelectedTickets={setSelectedTickets}
                isHeader={false}
                isReset={isReset}
              />
            </div>
            <div style={{ textAlign: "center" }}>
              <button
                style={{
                  background: "none",
                  border: "none",
                  color: !searchMoreEventsButton && "#069",
                  textDecoration: !searchMoreEventsButton && "underline",
                }}
                disabled={searchMoreEventsButton}
                onClick={(e) => {
                  e.preventDefault();
                  setSearchMoreEventsButton(true);
                }}
              >
                search more tickets without row
              </button>
            </div>
          </Modal.Body>
          {selectedTickets?.tickets?.length > 0 && (
            <div
              style={{ color: "#1842A3" }}
              className="d-flex justify-content-around"
            >
              <h6>
                {selectedTickets?.tickets?.length}{" "}
                {selectedTickets?.tickets?.length == 1
                  ? "seat is"
                  : "seats are"}{" "}
                selected
              </h6>
              <p>Section {selectedTickets?.section}</p>
              <p>Row {selectedTickets?.row}</p>
              <p>
                Seat No.{" "}
                {selectedTickets?.tickets?.map(
                  (seat, i) =>
                    seat +
                    (i < selectedTickets?.tickets?.length - 1 ? ", " : "")
                )}
              </p>
            </div>
          )}
          <div className="d-flex justify-content-center my-4">
            <Button
              variant="primary"
              className="ss-modal-primary-btn mx-2"
              disabled={
                selectedTickets?.tickets?.length !== selectedRecord?.quantity
              }
              onClick={() => {
                setAddEditInventoryModalVisibility(false);
                setAddEditInventoryEventModalVisibility(true);
              }}
            >
              Generate
            </Button>
            <Button
              variant="light"
              onClick={() => {
                document
                  .querySelectorAll("input[type=checkbox]")
                  .forEach((el) => (el.checked = false));
                setSelectedTickets({
                  open: null,
                  section: null,
                  row: null,
                  tickets: [],
                  ticketsId: [],
                });
                setSearchMoreEventsButton(false);
                setIsReset(!isReset);
              }}
              className="ss-modal-secondary-btn mx-2"
            >
              Reset
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default FulfillmentInventoryModal;
