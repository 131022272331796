import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PaypalServices } from '../../services/paypal/paypal.service';
import fileDownload from 'js-file-download';

export const getAllPaypal = createAsyncThunk(
    'Paypal/getAllPaypal',
    async (payload, thunkAPI) => {
        const response = await PaypalServices.getAllPaypal(payload);
        if (response.isSuccessful === true) {
            return response;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

// export const getPaypalById = createAsyncThunk(
//   "Paypal/getPaypalById",
//   async (payload, thunkAPI) => {
//     const response = await PaypalServices.getPaypalById(payload);
//     if (response.isSuccessful === true) {
//       return response.data;
//     } else {
//       thunkAPI.rejectWithValue(response.message);
//       throw new Error(response.message);
//     }
//   }
// );


export const createPaypal = createAsyncThunk(
    'Paypal/CreatePaypal',
    async (payload, thunkAPI) => {
        const response = await PaypalServices.createPaypal(payload);
        if (response.isSuccessful === true) {
            return response.data;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const updatePaypal = createAsyncThunk(
    'Paypal/UpdatePaypal',
    async (payload, thunkAPI) => {
        const response = await PaypalServices.updatePaypal(payload);
        if (response.isSuccessful === true) {
            return response.data;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const deletePaypal = createAsyncThunk(
    'Paypal/DeletePaypal',
    async (payload, thunkAPI) => {
        const response = await PaypalServices.deletePaypal(payload);
        if (response.isSuccessful === true) {
            return response.data;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

const PaypalSlice = createSlice({
    name: 'paypal',
    initialState: {
        paypal: [],
        paging: {},
    },
    extraReducers: {
        [getAllPaypal.fulfilled]: (state, action) => {
            state.paypal = action.payload.data;
            state.paging = action.payload.paging;
        },
        [createPaypal.fulfilled]: (state, action) => {
            state.paypal.push(action.payload);
        },
        [updatePaypal.fulfilled]: (state, action) => {
            const index = state.paypal.findIndex(
                (PSL) => PSL.id == action.payload.id
            );
            state.paypal[index] = action.payload;
        },
        [deletePaypal.fulfilled]: (state, action) => {
            state.paypal = [
                ...state.paypal.filter((PSL) => PSL.id !== action.payload.id),
            ];
        },
    },
});

export default PaypalSlice.reducer;

