import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { errorMessage } from "../../../data/reducers/alerts/alert.reducer";
import { getPurchaseOrderRefs } from "../../../data/reducers/skybox-purchases/skybox-purchases.reducer";
import { formatMoney } from "../../../utils/formatter/currencyFormatter";
import { formatDate } from "../../../utils/formatter/dateFormatter";
import IconButton from "@mui/material/IconButton";
import Down from "../../../images/downarrow.svg";
import Up from "../../../images/uparrow.svg";
import Collapse from "@mui/material/Collapse";
import TableHead from "../../../shared/components/table/parts/TableHead";
import { TableContainer } from "@mui/material";
import TableRowCard from "../../../shared/components/table/TableRowCard";
import { columnError } from "../../../shared/components/table/data/error";
import { withLoader } from "../../../utils/hoc/withLoader";

const Row = (props) => {
  const {
    ind,
    row,
    open,
    setOpen,
    data,
    renderButtons,
    setBusy,
    columns,
    align,
  } = props;

  const dispatch = useDispatch();
  const skyBoxRefDataList = useSelector((state) => state.skybox).skyBoxRefData;

  const SkyBoxRefData = [
    {
      columnName: "SkyBox Ref",
      render: (data) => (
        <a
          target="blank"
          variant="link"
          className="ss-link"
          href={
            "https://skybox.vividseats.com/purchases/" + data.skyBoxPurchaseId
          }
        >
          {data.skyBoxPurchaseId}
        </a>
      ),
    },
    {
      columnName: "SkyBox Value",
      render: (data) => <>{formatMoney(data.totalSkyboxValue)}</>,
    },
    {
      columnName: "Alloted Value",
      render: (data) => <>{formatMoney(data.allocatedSkyBoxAmount)}</>,
    },
    {
      columnName: "Balance Value",
      render: (data) => <>{formatMoney(data.remainingSkyboxValue)}</>,
    },
  ];

  const getPurchaseOrderRefsData = async (id) => {
    try {
      setBusy(true);
      const response = await dispatch(getPurchaseOrderRefs(id));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      //data
      setBusy(false);
    }
  };

  return (
    <>
      <div
        className="table-row-card my-3 d-flex justify-content-between px-3 align-items-center"
        style={{
          minHeight: "64px",
          boxShadow: "0px 2px 6px rgb(0 0 0 / 5%)",
          backgroundColor: "white",
        }}
      >
        {columns?.map((column) => (
          <small
            key={data?.id}
            style={{
              flexGrow: column.flexGrow > 0 ? column.flexGrow : 1,
              flexBasis: 0,
              textAlign: "center",
            }}
            className="mx-2"
            align="center"
          >
            {column.render(row, ind, open, setOpen, getPurchaseOrderRefsData, {
              ...data,
            })}
          </small>
        ))}
      </div>
      <div
        style={{
          flexGrow: 1,
          flexBasis: 0,
          backgroundColor: "#FBFBFB",
        }}
      >
        <Collapse in={open[ind]} timeout="auto" unmountOnExit>
          <div id="myDiv">
            <TableHead columns={SkyBoxRefData} bcolor="#e8f1fb" color="black" />
          </div>

          <TableContainer
            sx={{
              maxHeight: 300,
            }}
          >
            <div className="table-body">
              {skyBoxRefDataList?.length > 0 &&
              row?.purchaseOrderId === skyBoxRefDataList[0]?.purchaseOrderId ? (
                skyBoxRefDataList.map((d) => (
                  <TableRowCard
                    align={align}
                    key={d.id}
                    columns={d.nodata ? columnError : SkyBoxRefData}
                    rowData={d}
                  />
                ))
              ) : (
                <h5 className="p-5 text-center">No records to display</h5>
              )}
            </div>
          </TableContainer>
        </Collapse>
      </div>
    </>
  );
};

export default withLoader(Row);
