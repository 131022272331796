import { getTopCards } from "../../../data/reducers/cards/cards.routes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VerticalChargesCard from "../../../shared/components/accountActions/charges-card";
import Link from "../../../images/Link.svg";
import "../../../shared/styles/DashboardStyles.scss";
import { Container } from "react-bootstrap";

const CardDetails = ({ details }) => {
  const [isShown, setIsShown] = useState(false);
  const togglePassword = () => {
    setIsShown(isShown ? false : true);
  };

  const dispatch = useDispatch();
  const data = useSelector((state) => state.cards).cardById;
  useEffect(async () => {
    if (details.cardId != undefined) {
      await dispatch(getTopCards(details?.cardId?.trim()));
    }
  }, [details.cardId]);
  return (
    <>
      <Container className="root pt-3 mb-3 mt-3" fluid>
        <div className="my-title h-20 d-flex justify-content-between align-items-center">
          <p className="mylogo-title">{details?.cardId}</p>
        </div>
        <hr className="mt-2 mb-3 hr align-self-center" />
        <div className="body h-75 w-100 d-flex flex-row justify-content-evenly ">
          <div className="my-col d-flex flex-column justify-content-evenly">
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Name</p>
              <p className="my-p h-100 w-50">
                {details?.firstName} {details?.lastName}{" "}
                <span>
                  <img className="link-svg" src={Link} alt="link" />
                </span>
              </p>
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Company</p>
              <p className="my-p h-100 w-50">{details?.company}</p>
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">CC Number</p>
              <p className="my-p h-100 w-50">{details?.ccNumber}</p>
            </div>
          </div>
          <div className="my-col d-flex flex-column justify-content-evenly">
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Status</p>
              <p className="my-p h-100 w-50">
                {details?.active ? "Active" : "Inactive"}
              </p>
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Account</p>
              <p className="my-p h-100 w-50">{details?.cardId}</p>
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">CVV Number</p>
              {details?.securityCode && (
                <p className="my-p h-100 w-50">
                  <span style={{ display: "inline-block", width: "50px" }}>
                    {isShown ? details?.securityCode : "***"}
                  </span>
                  <span
                    onClick={togglePassword}
                    style={{
                      color: "#0241a0",
                      cursor: "pointer",
                      paddingLeft: "10px",
                    }}
                  >
                    <i className="fa fa-eye" aria-hidden="true"></i>
                  </span>
                </p>
              )}
            </div>
          </div>
          <div className="my-col d-flex flex-column justify-content-evenly">
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Source Type</p>
              <p className="my-p h-100 w-50">Card</p>
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">CC Account</p>
              <p className="my-p h-100 w-50">{details?.ccAccount}</p>
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Expiry Date</p>
              <p className="my-p h-100 w-50">{details?.expDate}</p>
            </div>
          </div>

          <div className="my-col d-flex flex-column justify-content-between align-items-end">
            <VerticalChargesCard
              bg="rgba(24, 144, 255, 0.2)"
              fontbg="#0241A0"
              text="Total Spent"
              price={data?.totalSpent}
            />
            <VerticalChargesCard
              bg="rgba(188, 219, 1, 0.2)"
              fontbg="#849900"
              text="Total Transactions"
              number
              price={data?.totalTransaction}
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default CardDetails;
