import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { accountServices } from "../../services/accounts/account.service";
import { eventServices } from "../../services/events/event.service";
import fileDownload from "js-file-download";

export const createAccount = createAsyncThunk(
  "accounts/CreateAccount",
  async (payload, thunkAPI) => {
    const response = await accountServices.createAccount(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const updateAccount = createAsyncThunk(
  "accounts/UpdateAccount",
  async (payload, thunkAPI) => {
    const response = await accountServices.updateAccount(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAllAccounts = createAsyncThunk(
  "accounts/getAllAccounts",
  async (payload, thunkAPI) => {
    const response = await accountServices.getAllAccounts(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const lookupAccounts = createAsyncThunk(
  "accounts/lookupAccounts",
  async (payload, thunkAPI) => {
    const response = await accountServices.lookupAccounts(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportCSVAccount = createAsyncThunk(
  "accounts/exportCSVAccount",
  async (payload, thunkAPI) => {
    const response = await accountServices.exportCSVAccount(payload);
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "Accounts.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportSelectedAsCSVAccount = createAsyncThunk(
  "accounts/exportSelectedAsCSVAccount",
  async (payload, thunkAPI) => {
    const response = await accountServices.exportSelectedAsCSVAccount(payload);
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "AccountsFilter.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportPdfAccount = createAsyncThunk(
  "accounts/exportPdfAccount",
  async (payload, thunkAPI) => {
    const response = await accountServices.exportPdfAccount(payload);
    // if (response.isSuccessful === true) {
    //   fileDownload(response.data, "Accounts.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

export const exportSelectedAsPdfAccount = createAsyncThunk(
  "accounts/exportSelectedAsPdfAccount",
  async (payload, thunkAPI) => {
    const response = await accountServices.exportSelectedAsPdfAccount(payload);
    // if (response.isSuccessful === true) {
    //   fileDownload(response.data, "AccountsFilter.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

export const getAccountsList = createAsyncThunk(
  "accounts/getAccountsList",
  async (thunkAPI) => {
    const response = await accountServices.getAccountsList();
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAccountsListByVendor = createAsyncThunk(
  "accounts/getAccountsListByVendor",
  async (thunkAPI) => {
    const response = await accountServices.getAccountsList(true);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAccountsCards = createAsyncThunk(
  "accounts/getAccountsCards",
  async (payload, thunkAPI) => {
    const response = await accountServices.getAccountsCards(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const filterAccount = createAsyncThunk(
  "accounts/filterAccount",
  async (payload, thunkAPI) => {
    const response = await accountServices.filterAccount(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAccountIdDetails = createAsyncThunk(
  "accounts/getAccountIdDetails",
  async (payload, thunkAPI) => {
    const response = await accountServices.getAccountIdDetails(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);
export const getAccountTeamList = createAsyncThunk(
  "accounts/getAccountTeamList",
  async (thunkAPI) => {
    const response = await accountServices.getAccountTeams();
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);
export const getAccountSportList = createAsyncThunk(
  "accounts/getAccountSportList",
  async (payload, thunkAPI) => {
    const response = await accountServices.getAccountSports();
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAccountCardList = createAsyncThunk(
  "CardInfo/getAccountCardList",
  async (payload, thunkAPI) => {
    const response = await accountServices.getCardsList();
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getCardsByAccountId = createAsyncThunk(
  "CardInfo/getAccountCardListById",
  async (payload, thunkAPI) => {
    const response = await accountServices.getCardsByAccountId(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getActiveEventsCountByAccountId = createAsyncThunk(
  "accounts/getActiveEventsCountByAccountId",
  async (payload, thunkAPI) => {
    const response = await eventServices.getActiveEventsCountByAccountId(
      payload
    );
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAccountCardsInfo = createAsyncThunk(
  "CardInfo/getAccountCardsInfo",
  async (payload, thunkAPI) => {
    const response = await accountServices.getAccountCardsInfo(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getPaymentPlansByAccount = createAsyncThunk(
  "Accounts/GetPaymentPlansByAccount",
  async (payload, thunkAPI) => {
    const response = await accountServices.getPaymentPlansByAccount(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }


  }
)

const accountSlice = createSlice({
  name: "account",
  initialState: {
    accounts: [],
    accountsList: [],
    accountListByVendor: [],
    paging: {},
    cards: {},
    cardsById: {},
    selectedAccountDetails: {},
    csvContent: [],
    teamList: [],
    sportList: [],
    cardList: [],
    newlyCreated: {
      team: {},
      sport: {},
    },
    teamsPaging: {},
    sportsPaging: {},
    allTeams: [],
    allSports: [],
    categories: [],
    activeEvents: 0,
    accountCardsInfo: [],
    paymentPlansInfo: {}
  },
  extraReducers: {
    [getAccountCardsInfo.fulfilled]: (state, action) => {
      state.accountCardsInfo = action.payload;
    },
    [getAccountsListByVendor.fulfilled]: (state, action) => {
      state.accountListByVendor = action.payload;
    },
    [getAccountIdDetails.fulfilled]: (state, action) => {
      state.selectedAccountDetails = action.payload;
    },

    [getAllAccounts.fulfilled]: (state, action) => {
      state.accounts = action.payload.data;
      state.paging = action.payload.paging;
    },

    [getAccountsCards.fulfilled]: (state, action) => {
      state.cards = action.payload;
    },
    [filterAccount.fulfilled]: (state, action) => {
      state.accounts = action.payload.data;
      state.paging = action.payload.paging;
    },
    [createAccount.fulfilled]: (state, action) => {
      state.accounts.push(action.payload);
    },
    [updateAccount.fulfilled]: (state, action) => {
      const index = state.accounts.findIndex(
        (account) => account.id == action.payload.id
      );
      state.accounts[index] = action.payload;
      state.selectedAccountDetails = action.payload;
    },

    [getAccountTeamList.fulfilled]: (state, action) => {
      state.teamList = action.payload;
    },
    [getAccountsList.fulfilled]: (state, action) => {
      state.accountsList = action.payload;
    },
    [getAccountSportList.fulfilled]: (state, action) => {
      state.sportList = action.payload;
    },
    [getAccountCardList.fulfilled]: (state, action) => {
      state.cardList = action.payload;
    },
    [lookupAccounts.fulfilled]: (state, action) => {
      state.categories = action.payload.data;
    },
    [getCardsByAccountId.fulfilled]: (state, action) => {
      state.cardsById = action.payload;
    },
    [getActiveEventsCountByAccountId.fulfilled]: (state, action) => {
      state.activeEvents = action.payload;
    },
    [getPaymentPlansByAccount.fulfilled]: (state, action) => {
      state.paymentPlansInfo = action.payload
    }
  },
});

export const { removeSelected } = accountSlice.actions;

export default accountSlice.reducer;
