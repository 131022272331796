import { Route, Switch } from "react-router-dom";
import VendorsDashboard from "../../../pages/vendors/VendorDashBoard";
import SingleVendorDetail from "../../../pages/vendors/SingleVendorDetails";

const VendorsRoutes = () => {
  return (
    <Switch>
      <Route path="/vendors" exact>
        <VendorsDashboard />
      </Route>
      <Route path="/vendors/:id" exact>
        <SingleVendorDetail />
      </Route>
    </Switch>
  );
};

export default VendorsRoutes;