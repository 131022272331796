/* eslint-disable no-unused-vars */

// forms and components
import { useForm } from "react-hook-form";
import { Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Mixpanel } from "../../mixpanel";
import mixpanel from "mixpanel-browser";
// redux
import { useDispatch } from "react-redux";
import { loginUser } from "../../data/reducers/authentication/auth.reducer";

// misc
import { withLoader } from "../../utils/hoc/withLoader";
import { errorMessage } from "../../data/reducers/alerts/alert.reducer";
import GoogleSVG from "../../images/Google.svg";
import { useHistory } from "react-router";

// styles
import "./login.scss";
import { constants } from "../../config";
import { useEffect } from "react";

const mailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const LoginPage = ({ setBusy }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setBusy(true);
      const response = await dispatch(loginUser(data));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        // history.push('/accounts');
        history.push(window.location.pathname + window.location.search);
        Mixpanel.people.set({
          $email: data?.username,
        });
        Mixpanel.identify(data?.username);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setBusy(false);
    }
  };

  const handleGoogleSignIn = () => {
    window.location.href = constants.GOOGLE_AUTH_URI;
  };

  return (
    <div className="container-fluid loginPage">
      <Row className="vh-100">
        <Col className="login-cover d-flex flex-column justify-content-center align-items-center cover-text">
          <div className="text-center">
            <h1>Welcome To Salem Seats</h1>
            <div className="text-center">
              Salem Seats is a ticket service providing customers
              <br />
              with access to all sporting events, concerts,
              <br />
              and Broadway shows, even if they’re sold out.
            </div>
          </div>
        </Col>
        <Col className="d-flex flex-column justify-content-center align-items-center">
          <h3 className="w-50 text-center">Login To Your Account</h3>
          <div className="w-50">
            {process.env.REACT_APP_SHOW_LOGIN === "TRUE" ? (
              <div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group className="mt-3">
                    <Form.Label>Email Id</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.username}
                      type="email"
                      {...register("username", {
                        required: true,
                        pattern: mailRegex,
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      Email is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mt-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.password}
                      type="password"
                      {...register("password", {
                        required: true,
                        validate: (data) => data.length >= 8,
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      Password is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Row className="mt-3">
                    <Col>
                      <Form.Group>
                        <Form.Check
                          className="remember-forgotpass"
                          type="checkbox"
                          label="Remember Me"
                          {...register("remember")}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="text-end">
                      <Link
                        className="remember-forgotpass"
                        to="/forgot-password"
                      >
                        {" "}
                        Forgot Password ?
                      </Link>
                    </Col>
                  </Row>
                  <div className="text-center">
                    <Button
                      type="submit"
                      variant="primary"
                      className="mt-3 w-50"
                    >
                      Login
                    </Button>
                  </div>
                </Form>
              </div>
            ) : (
              ""
            )}
            <InputGroup className="google-auth mt-5 flex-row">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <img src={GoogleSVG} alt="" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                onClick={handleGoogleSignIn}
                className="ss-google-signin"
                as="button"
              >
                Sign In With Your Work Account
              </Form.Control>
            </InputGroup>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withLoader(LoginPage);
