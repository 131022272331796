//reducers

import QuickTixTable from "../../../shared/components/table/QuickTixTable";
import TableRowCard from "../../../shared/components/table/TableRowCard";
import DeleteIcon from "../../../images/delete.svg";
import EditIcon from "../../../images/edit.svg";
import DeleteConfirmationModal from "../../../shared/components/modals/DeleteConfirmationModal";

import { NavLink, useParams } from "react-router-dom";
import React, { useState } from "react";
import { Col, Row, Button, Dropdown, Form } from "react-bootstrap";

// images
import Export from "../../../images/Link.svg";
import Add from "../../../images/add.svg";

import { withLoader } from "../../../utils/hoc/withLoader";
import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import PaypalDetails from "./PaypalDetails";
import AddEditPaypalIDModal from "./modals/AddEditPaypalIDModal";
import { formatDate } from "../../../utils/formatter/dateFormatter";
import { formatTime } from "../../../utils/formatter/timeFormatter";
import { formatMoney } from "../../../utils/formatter/currencyFormatter";

const SinglePaypalDetail = ({ setBusy }) => {
  const { id } = useParams();
  const details = {
    id,
    name: "Paypal1",
    status: "Active",
    totalSpent: 547185,
    totalTransactions: 60,
  };

  const [deleteConfirmationVisible, setDeleteConfirmationVisibility] =
    useState(false);
  const [selectedTabIndex, setTabIndex] = useState(0);

  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [paypalIdModalVisibilty, setPaypalIDModalVisibility] = useState(false);
  const [column, setColumn] = useState([]);
  const [paging, setpaging] = useState({});

  const [pageSize, setpageSize] = useState(40);
  const [pageNumber, setpageNumber] = useState(1);

  const onEntrySubmitted = async (data, option) => {};

  const onDelete = async (mainId, option) => {
    setDeleteConfirmationVisibility(false);
  };

  const onEditAction = (data) => {};

  const onDeleteAction = (data) => {
    setDeleteConfirmationVisibility(false);
  };

  const data = [
    {
      vendor: "TicketMaster",
      dateAndTime: "12/23/21 22:43",
      purchaseOrder: "13518977_NBA-ML2021",
      accId: 6674655,
      amount: 442.55,
      description: "Ticketmaster",
      notes: "--",
      name: "Paypal1",
    },
    {
      vendor: "TicketMaster",
      dateAndTime: "12/23/21 22:43",
      purchaseOrder: "13518977_NBA-ML2021",
      accId: 6674655,
      amount: 442.55,
      description: "Ticketmaster",
      notes: "--",
      name: "Paypal1",
    },
    {
      vendor: "TicketMaster",
      dateAndTime: "12/23/21 22:43",
      purchaseOrder: "13518977_NBA-ML2021",
      accId: 6674655,
      amount: 442.55,
      description: "Ticketmaster",
      notes: "--",
      name: "Paypal1",
    },
    {
      vendor: "TicketMaster",
      dateAndTime: "12/23/21 22:43",
      purchaseOrder: "13518977_NBA-ML2021",
      accId: 6674655,
      amount: 442.55,
      description: "Ticketmaster",
      notes: "--",
      name: "Paypal1",
    },
  ];

  const transactionCol = [
    {
      columnName: "Vendor",
      columnKey: "vendor",
    },
    {
      columnName: "Date/Time",
      render: (data) => (
        <>
          {formatDate(data.dateAndTime)}
          <br />
          {formatTime(data.dateAndTime)}
        </>
      ),
      // columnKey: 'dateAndTime',
      flexGrow: 0.5,
    },

    {
      columnName: "Purchase Order",
      columnKey: "purchaseOrder",
    },
    {
      columnName: "Accounts ID",
      columnKey: "accId",
    },
    {
      columnName: "Amount",
      render: (data) => <>{formatMoney(data?.amount)}</>,
      flexGrow: 0.5,
    },
    {
      columnName: "Description",
      columnKey: "description",
      flexGrow: 0.5,
    },
    {
      columnName: "Notes",
      render: (data) => (
        <div
          style={{
            overflow: "hidden",
          }}
        >
          {data?.notes}
        </div>
      ),
      flexGrow: 0.5,
    },
    {
      columnName: "Actions",
      render: (data) => (
        <small className="table-row-card-actions d-flex">
          <Button
            variant="link"
            className="table-action-button green_bg"
            onClick={() => {
              // setSelectedRecordId(data.accountId);
              setSelectedRecord(data);
              setPaypalIDModalVisibility(true);
            }}
          >
            <img src={EditIcon} alt=" " />
          </Button>
          <Button
            variant="link"
            className="table-action-button"
            onClick={() => {
              // setSelectedRecordId(data.id);
              setSelectedRecord(data);
              setDeleteConfirmationVisibility(true);
            }}
          >
            <img src={DeleteIcon} alt=" " />
          </Button>
        </small>
      ),
    },
  ];

  const headOptions = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small>Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultvalue="40"
              onChange={(e) => setpageSize(e.target.value)}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div className="text-end mt-3 d-flex">
          <Dropdown>
            <Dropdown.Toggle className="ss-light-button" id="dropdown-basic">
              <img src={Export} alt=" " />
              <span>Export</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>Export as CSV</Dropdown.Item>
              <hr className="hr-full" />
              <Dropdown.Item>Export as PDF</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button
            onClick={async () => {
              await setSelectedRecord(undefined);
              await setPaypalIDModalVisibility(true);
            }}
            variant="primary"
            className="ms-2 ss-light-button"
          >
            <img src={Add} alt=" " />
            <span>Add</span>
          </Button>
        </div>
      </>
    );
  };
  return (
    <div className="container-fluid pt-3">
      {/* Upper Navigation */}
      <div className="page-upper-navigation">
        <Row className="justify-content-between">
          <Col>
            <ModalBreadcrumb
              breadcrumbLinks={[
                {
                  name: "Fund Sources",
                  active: true,
                },
                {
                  to: "/paypal",
                  name: "Paypal",
                  active: true,
                },
                {
                  to: "/paypal/AzQyhnj2+85641vgbWnm954gvbh5",
                  name: "Paypal1",
                  active: false,
                },
              ]}
            />
          </Col>
          <Col className="text-end justify-content-center">
            <NavLink
              className="ss-back-button text-end"
              to="/fundSources/paypal"
            >
              <small>Back to Paypal</small>
            </NavLink>
          </Col>
        </Row>
      </div>
      <PaypalDetails details={details} />

      {/* Data Table */}
      <QuickTixTable
        headerName="Transactions"
        paging={paging}
        columns={transactionCol}
        data={data || []}
        headOptions={headOptions}
        setpageNumber={setpageNumber}
        renderRow={(rowData) => (
          <TableRowCard
            key={rowData.id}
            columns={transactionCol}
            rowData={rowData}
          />
        )}
      />
      <AddEditPaypalIDModal
        preFilledId={id}
        isVisible={paypalIdModalVisibilty}
        handleClose={() => setPaypalIDModalVisibility(false)}
        onSubmit={(data) => onEntrySubmitted(data, 0)}
        selectedRecord={selectedRecord}
      />
      <DeleteConfirmationModal
        isVisible={deleteConfirmationVisible}
        handleClose={() => setDeleteConfirmationVisibility(false)}
        onConfirmation={() => onDelete()}
      />
    </div>
  );
};

export default withLoader(SinglePaypalDetail);
