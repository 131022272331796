import {
  api,
  getAuthHeaders,
  get,
  post,
  update,
  del,
  getCSV,
  getPDF
} from "../services.common";

const getAllAddress = async (data) => {
  const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
  const url = `${api}AddressBook/getalladdressbooks?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getAddressList = async (data) => {
  const url = `${api}AddressBook/getlist`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getAddressById = async (id) => {
  const url = `${api}AddressBook/getaddressbook/${id}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const createAddress = async (data) => {
  const url = `${api}AddressBook/create`;
  const authHeader = getAuthHeaders();
  return await post(url, data, { ...authHeader });
};

const updateAddress = async (data) => {
  const url = `${api}AddressBook/update/${data.id}`;
  const authHeader = getAuthHeaders();
  return await update(url, data, { ...authHeader });
};



const deleteAddress = async (id) => {
  const url = `${api}AddressBook/delete/${id}`;
  const authHeader = getAuthHeaders();
  return await del(url, { ...authHeader });
};

const filterAddress = async (data) => {
  const url = `${api}AddressBook/search?searchString=${data.searchText || ""}&PageNumber=${data.pageNumber || ""}&PageSize=${data.pageSize || ""}&Name=${data.name || ""}&Email=${data.email || ""}&Address=${data.address || ""}&City=${data.city || ""}&State=${data.state || ""}&PostalCode=${data.postalCode || ""}&MobileNumber=${data.mobileNumber || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const exportCSVAddressBook = async () => {
  const url = `${api}AddressBook/exportaddressbookcsv`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const exportSelectedAsCSVAddress = async (data) => {
  const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
  const url = `${api}AddressBook/searchexportcsv?searchString=${data.searchText || ""}&Address=${data.address || ''}&Email=${data.email || ""}&Name=${data.name || ""}&City=${data.city || ""}&State=${data.state || ""}&MobileNumber=${data.mobileNumber || ""}&PostalCode=${data.postalCode || ""}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const exportPdfAddressBook = async () => {
  const url = `${api}AddressBook/exportaddressbookpdf`;
  const authHeader = getAuthHeaders();
  return await getPDF(url, { ...authHeader }, "AddressBook.pdf");
};

const exportSelectedAsPdfAddress = async (data) => {
  const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
  const url = `${api}AddressBook/searchexportpdf?searchString=${data.searchText || ""}&Address=${data.address || ''}&Email=${data.email || ""}&Name=${data.name || ""}&City=${data.city || ""}&State=${data.state || ""}&MobileNumber=${data.mobileNumber || ""}&PostalCode=${data.postalCode || ""}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
  const authHeader = getAuthHeaders();
  return await getPDF(url, { ...authHeader }, "AddressBookFilter.pdf");
};

const getCardsByAddressId = async (addressId) => {
  const url = `${api}AddressBook/cards/${addressId}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getAccountsByAddressId = async (addressId) => {
  const url = `${api}AddressBook/accounts/${addressId}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const addressBookServices = {
  getAllAddress,
  createAddress,
  updateAddress,
  deleteAddress,
  filterAddress,
  getAddressById,
  getAddressList,
  exportCSVAddressBook,
  exportSelectedAsCSVAddress,
  exportPdfAddressBook,
  exportSelectedAsPdfAddress,
  getCardsByAddressId,
  getAccountsByAddressId
};
