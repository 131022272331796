import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Image, Modal, Row } from 'react-bootstrap'
import IconButton from "@mui/material/IconButton";
import Up from "../../../images/uparrow.svg";
import Down from "../../../images/downarrow.svg";

const HistoryRow = ({ val }) => {
    const greenColor = "#219653";
    const black = "black";
    const fontSize = "14px";

    const [show, toggleShow] = useState(false);
    
    const vendorName = ( val.vendorName.isUpdated ? {color :  greenColor , fontSize} : {color : black,fontSize});
    const accountName =   (val.accountName.isUpdated ? {color :  greenColor,fontSize} : {color : black,fontSize});
    const date = (val.purchaseOrderDate.isUpdated ? {color :  greenColor , fontSize}:{color :  black, fontSize});
    const seasonName =   (val.seasonName.isUpdated ? {color :  greenColor, fontSize} :  {color :  black, fontSize});
    const description =  (val.description.isUpdated ? {color :  greenColor, fontSize} : {color : black, fontSize});
    const skyBoxRefCount =    (val.skyBoxRefCount.isUpdated ? {color :  greenColor, fontSize} :{color :  black, fontSize});
    const skyboxBalance =  (val.skyboxBalance.isUpdated ?  {color : greenColor, fontSize} :  {color : black, fontSize});
    const totalScheduled = (val.totalScheduled.isUpdated ? {color :  greenColor , fontSize}:{color :  black, fontSize});
        
    

    return (
        <>
            <div className='mainContainer'>
                <Container className='tableBody'>
                    <Row className='tableUpperRow' onClick={() => toggleShow(!show)}>
                        <Col style={{fontSize}} className='tableCol' >{val.updatedBy}</Col>
                        <Col style={{fontSize}} >{val.updatedDate}</Col>
                        <Col style={vendorName}> {val.vendorName.value ?? "NA"} <br /> {val.vendorId.value}</Col>
                        <Col style={accountName}> {val.accountName.value ?? "NA"} <br /> {val.accountId.value} </Col>
                        <Col  style={date}> {val.purchaseOrderDate.value.split("T")[0]} </Col>
                        <Col  style={seasonName}> {val.seasonName.value}</Col>
                        <Col style={description} >{val.description.value}</Col>
                        <Col style={skyBoxRefCount} > {val.skyBoxRefCount.value ?? "0"} <IconButton> <img src={ show ? Up : Down} style={{ marginLeft: '5px' }} alt="up down image" /></IconButton> </Col>
                        <Col style={skyboxBalance} > ${val.skyboxBalance.value ?? "0"} </Col>
                        <Col style={totalScheduled} >${val.totalScheduled.value}</Col>
                    </Row>
                </Container>


                {show && <Container className='innerTableBody'>

                    {/* ---------------- Header ----------------- */}
                    <Row className='innerTableHead'>
                        <Col>SkyBox Ref</Col>
                        <Col>SkyBox Value</Col>
                        <Col>Allocated Value</Col>
                        <Col>Balance Value</Col>
                    </Row>
                    {/* ---------------- Header ----------------- */}


                    {val.skyboxDetails.length > 0 ? val.skyboxDetails.map((data, index) => {
                        const givingStyle = (data.isUpdated ? {color : greenColor,fontSize} : {color : "black",fontSize})
                        return (
                            <Row style={givingStyle} className='innerTableRow' key={index}>
                                <Col > {data.skyBoxPurchaseId} </Col>
                                <Col> ${data.totalSkyboxValue ?? '0'} </Col>
                                <Col> ${data.allocatedSkyBoxAmount ?? '0'} </Col>
                                <Col> ${data.remainingSkyboxValue ?? '0'} </Col>
                            </Row>
                        );
                    }) :
                        <div className='notFound'>
                            No records to display
                        </div>
                    }

                </Container>}
            </div>
        </>
    )
}

export default HistoryRow