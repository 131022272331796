import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ChairIcon from "../../images/chair.svg";

export default function BasicCard(props) {
  const {
    selectedTickets,
    setSelectedTickets,
    rec,
    number,
    seatId,
    isAvailable,
    isReset,
    row,
    openInd,
  } = props;
  const [check, setcheck] = useState(false);

  useEffect(() => {
    setcheck(false);
  }, [isReset]);

  useEffect(() => {
    if (selectedTickets?.tickets?.includes(number)) {
      document.getElementById(
        openInd + row.section + row.row + number
      ).checked = true;
      setcheck(true);
    }
  }, []);

  const handleCheck = (event) => {
    let updatedList = selectedTickets.tickets;
    let updatedListId = selectedTickets.ticketsId;

    if (event.target.checked) {
      updatedList.push(event.target.name);
      updatedListId.push(event.target.value);
      setcheck(true);
    } else {
      updatedList.splice(updatedList.indexOf(event.target.name), 1);
      updatedListId.splice(updatedListId.indexOf(event.target.value), 1);
      setcheck(false);
    }

    updatedList.sort((a, b) => {
      return a - b;
    });

    setSelectedTickets({
      open: openInd,
      section: row?.section,
      row: row?.row,
      tickets: updatedList,
      ticketsId: updatedListId,
    });
  };

  return (
    <Card
      variant="outlined"
      sx={{
        minWidth: 50,
        maxWidth: 200,
        height: "90%",
        backgroundColor: check && "#E9F1FC",
        border: check && "2px solid #4F96FF",
        marginTop: 2,
      }}
    >
      <CardContent>
        <div className="d-flex justify-content-between">
          <h6
            style={{
              color:
                isAvailable && rec?.generationStatus !== "Generating"
                  ? "#4F96FF"
                  : "#7F7F7F",
            }}
          >
            {isAvailable && rec?.generationStatus !== "Generating"
              ? "Available"
              : "Unavailable"}
          </h6>

          {isAvailable && rec?.generationStatus !== "Generating" && (
            <div>
              <input
                id={openInd + row.section + row.row + number}
                name={number}
                value={seatId}
                style={{ width: "16px", height: "16px" }}
                type="checkbox"
                onChange={handleCheck}
              />
            </div>
          )}
        </div>

        <h6 style={{ color: "#4D4D4D", textAlign: "start" }}>Seat Number</h6>

        <div className="d-flex justify-content-between">
          <img src={ChairIcon} alt="chair icon" />
          <h5 style={{ color: "#1842A3" }} className="mt-2">
            {number}
          </h5>
        </div>
        {rec?.generationStatus === "Generating" && (
          <div style={{ color: "#198754", fontSize: "80%", margin: "auto" }}>
            Generating Token
          </div>
        )}
        {rec?.generationStatus === "Failed" && (
          <div style={{ color: "red", fontSize: "80%", margin: "auto" }}>
            Token Generation Failed
          </div>
        )}
      </CardContent>
    </Card>
  );
}
