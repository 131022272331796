import axios from "axios";
import { constants } from "../../config.js";

export const secrets = {
  SIMPLE_ACCESS_TOKEN: process.env.REACT_APP_SIMPLE_ACCESS_TOKEN,
  SMS_POOL_API_KEY: process.env.REACT_APP_SMS_POOL_API_KEY,
};

export const api = constants.endpoint;
export const error = (error, data) => {
  return {
    status: "error",
    isSuccessful: false,
    message: error,
    data: data ?? null,
  };
};

export const getAuthHeaders = () => {
  return {
    Authorization: "Bearer " + localStorage.getItem(constants.KEY_AUTH_TOKEN),
  };
};

export const success = (data, paging, statusMessage) => {
  return {
    status: "success",
    isSuccessful: true,
    data: data,
    paging: paging,
    statusMessage,
  };
};

export const get = async (url, headers) => {
  try {
    const response = await axios.get(url, { headers });
    if (response.data.httpStatus === 200) {
      return success(
        response.data.data,
        response.data.paging,
        response.data.statusMessage
      );
    } else {
      return error(response.data.statusMessage);
    }
  } catch (e) {
    if (e.response.data) {
      return error(e.response.data.statusMessage);
    }
    return error(e);
  }
};

export const post = async (url, data, headers) => {
  try {
    const response = await axios.post(url, data, { headers });
    if (response.data.httpStatus === 200) {
      return success(response.data.data);
    } else {
      return error(response.data.statusMessage, response?.data?.data);
    }
  } catch (e) {
    if (e.response.data) {
      return error(e.response.data.statusMessage, e.response?.data?.data);
    }
    return error(e);
  }
};

export const postFile = async (url, data, headers) => {
  try {
    const response = await axios.post(url, data, {
      ...headers,
      "Content-Type": "multipart/form-data",
    });
    if (response.data.httpStatus === 200) {
      return success(response.data.data);
    } else {
      return error(response.data.statusMessage);
    }
  } catch (e) {
    if (e.response.data) {
      return error(e.response.data.statusMessage);
    }
    return error(e);
  }
};

export const postAuth = async (url, data, headers) => {
  try {
    return await axios.post(url, data, { headers });
  } catch (e) {
    return error(e);
  }
};

export const download = async (url, data, headers) => {
  try {
    let response = null;
    if (data === null) {
      response = await axios.get(url, {
        headers,
        responseType: "blob",
      });
    } else {
      response = await axios.post(url, data, {
        headers,
        responseType: "blob",
      });
    }
    var contentDisposition = response.headers["content-disposition"];
    var filename = contentDisposition.match(/filename=(?<filename>[^,;]+);/)[0];
    return success({
      file: response.data,
      filename: filename.split("=")[1].replace(";", ""),
    });
  } catch (e) {
    return error(e);
  }
};

export const update = async (url, data, headers) => {
  try {
    const response = await axios.put(url, data, { headers });
    if (response.data.httpStatus === 200) {
      return success(response.data.data, null, response.data.statusMessage);
    } else {
      return error(response.data.statusMessage);
    }
  } catch (e) {
    if (e.response.data) {
      return error(e.response.data.statusMessage);
    }
    return error(e);
  }
};

export const getCSV = async (url, headers) => {
  try {
    const response = await axios.get(url, { headers });
    if (response.request.status === 200) {
      return success(response);
    } else {
      return error(response.data.statusText);
    }
  } catch (e) {
    if (e.response.data) {
      return error(e.response.data.statusText);
    }
    return error(e);
  }
};

export const del = async (url, headers) => {
  try {
    const response = await axios.delete(url, { headers });
    if (response.data.httpStatus === 200) {
      return success(response.data.data);
    } else {
      return error(response.data.statusMessage);
    }
  } catch (e) {
    if (e.response?.data) {
      return error(e.response.data.statusMessage);
    }
    return error(e);
  }
};

export const getPDF = async (url, headers, name) => {
  try {
    await axios
      .get(url, {
        responseType: "arraybuffer",
        headers: {
          ...headers,
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      });
  } catch (e) {
    console.log(e);
  }
};

export const downloadExcel = async (url, data, headers) => {
  try {
    let response = null;
    if (data === null) {
      response = await axios.get(url, {
        headers,
        responseType: "blob",
      });
    } else {
      response = await axios.post(url, data, {
        headers,
        responseType: "blob",
      });
    }
    return success({ file: response.data });
  } catch (e) {
    return error(e);
  }
};
