import React, { useEffect, useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { mixpanel_contants } from "../../../mixpanel";
import FilterPanel2024 from "../../../shared/components/panels/filter-panel/FilterPanel2024";
import { fetchVendorsListForDropdown } from "../../../data/reducers/vendors/cached-vendors.reducer";

const SkyboxFilterPanel = ({ filters, setFilters, resetHandler, error }) => {
  const dispatch = useDispatch();

  const { vendorsListForDropdown } = useSelector(
    (state) => state.cachedVendors
  );

  useEffect(() => {
    if (!vendorsListForDropdown.length) {
      dispatch(fetchVendorsListForDropdown());
    }
  }, [vendorsListForDropdown]);

  const defaultValues = useMemo(() => {
    return Object.entries(filters).map(([key, value]) => ({ key, value }));
  }, [filters]);

  //style for dropdown
  const customStyles = {
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };

  return (
    <FilterPanel2024
      defaultValues={defaultValues}
      filtersCount={
        Object.entries(filters).filter(
          ([key, value]) =>
            value !== "" &&
            key !== "StartDate" &&
            key !== "EndDate" &&
            key !== "searchString" &&
            key !== "PageNumber" &&
            key !== "PageSize" &&
            key !== "SortDir" &&
            key !== "SortField"
        ).length
      }
      searchPlaceholder="Search for Vendor, Skybox Ref, Created date, Value  ..."
      dashboard={mixpanel_contants.SKYBOX_PURCHASES}
      onReset={() => {
        setFilters({
          ...filters,
          PageNumber: 1,
          searchString: "",
          VendorId: "",
          MinAmount: "",
          MaxAmount: "",
        });
        if (error) {
          resetHandler();
        }
      }}
      onSearch={(data, filterPanelVisible) => {
        setFilters({
          ...filters,
          PageNumber: 1,
          searchString: filterPanelVisible ? "" : data.searchString,
          VendorId: filterPanelVisible ? data.VendorId : "",
          MinAmount: filterPanelVisible ? data.MinAmount : "",
          MaxAmount: filterPanelVisible ? data.MaxAmount : "",
        });
      }}
      renderAdvancedPanel={(
        register,
        handelSubmit,
        errros,
        control,
        closePanel,
        getValues
      ) => (
        <Row>
          <Col>
            <Form.Group className="pt-3" as={Row}>
              <Form.Label column sm="4">
                Vendor
              </Form.Label>
              <Col sm="7">
                <Controller
                  name="VendorId"
                  control={control}
                  render={({ field }) => {
                    const value = vendorsListForDropdown.find(
                      (v) => v.id == field.value
                    );
                    return (
                      <Select
                        value={value === undefined ? null : value}
                        onChange={(e) => field.onChange(e.id)}
                        styles={customStyles}
                        options={vendorsListForDropdown}
                        getOptionLabel={(option) =>
                          `${option.id}  ${option.name}`
                        }
                      />
                    );
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row} className="pt-3">
              <Form.Label column sm="4">
                Min Amount
              </Form.Label>
              <Col sm="7">
                <Form.Control
                  type="number"
                  isInvalid={!!errros.MinAmount}
                  placeholder="MinAmount"
                  {...register("MinAmount")}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row} className="pt-3">
              <Form.Label column sm="4">
                Max Amount
              </Form.Label>
              <Col sm="7">
                <Form.Control
                  type="number"
                  isInvalid={!!errros.MaxAmount}
                  placeholder="MaxAmount"
                  {...register("MaxAmount")}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      )}
    />
  );
};

export default React.memo(SkyboxFilterPanel);
