export const formatMoney = (value, format = "en-US", currency = "USD") => {
  var formatter = new Intl.NumberFormat(format, {
    style: "currency",
    currency,
  });

  if (isNaN(value)) {
    return formatter.format(0);
  }

  return formatter.format(value);
};

export const formatNumberToFourDigits = (number, divider = "-") => {
  const givenNumber = String(number);
  const result = givenNumber.match(/.{1,4}/g) ?? [];
  return result.join(divider)
}

export const formatMobileNumber = (num) => {
  if (!num) {
    return "-"
  }
  const length = num?.length;
  const mbNum = num?.slice(length - 10)
  const formattedMbNO = mbNum.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  return formattedMbNO
}