import moment from "moment";
import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import Alert from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import { successMessage } from "../../../data/reducers/alerts/alert.reducer";
import Copy from "../../../images/copy.svg";

const TicketInformationModal = ({
  isVisible,
  handleClose,
  selectedRecord,
  deliveryMessages,
}) => {
  const dispatch = useDispatch();
  const dataColumns = [
    {
      id: 1,
      name: "Marketplace",
      key: "marketplace",
    },
    {
      id: 2,
      name: "External Reference",
      key: "externalReference",
    },
    {
      id: 3,
      name: "Customer Name",
      key: "customerName",
    },
    {
      id: 4,
      name: "Customer Email",
      key: "customerEmail",
    },
    {
      id: 5,
      name: "Customer Phone",
      key: "customerPhone",
    },
  ];
  return (
    <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>TICKET INFORMATION</Modal.Title>
      </Modal.Header>
      <ModalBreadcrumb
        breadcrumbLinks={[
          {
            name: "Sales",
            active: true,
          },
          {
            name: `Event - ${selectedRecord?.eventName}`,
            active: true,
          },
        ]}
      />
      <Modal.Body>
        <div className="container-fluid p-2 w-100 h-100">
          <Row>
            <Col>
              <b>Event Name : </b> {selectedRecord?.eventName}
            </Col>
            <Col sm="2">
              <b>Date : </b>{" "}
              {moment(selectedRecord?.eventDateTime).format("MM-DD-YYYY")}
            </Col>
          </Row>
          <Row className="mt-3 py-2">
            <Col>
              <p>
                <b>Section : </b> {selectedRecord?.section}
              </p>
            </Col>
            <Col xs={8}>
              <b>Row : </b> {selectedRecord?.row}
            </Col>
            <Col>
              <p>
                <b>Ticket : </b> {selectedRecord?.bookedSeat?.length}{" "}
                {selectedRecord?.bookedSeat?.length <= 1 ? "Seat" : "Seats"}
              </p>
            </Col>
          </Row>{" "}
          <Row>
            <Col>
              <p>
                <b>Seat Numbers : </b>{" "}
                {selectedRecord?.bookedSeat?.map(
                  (seat, i) =>
                    seat + (i < selectedRecord?.bookedSeat?.length-1 ? ", " : "")
                )}{" "}
              </p>
            </Col>
          </Row>
          <Row>
            {dataColumns?.map((dt) => (
              <Col md={6} key={dt?.id} className="py-2">
                <Row>
                  <Col md={6}>
                    <b>{dt?.name}</b>
                  </Col>
                  <Col md={6}>
                    <p style={{ wordWrap: "break-word" }}>
                      {selectedRecord?.recipientTicketDetails?.[dt.key]}
                    </p>
                  </Col>
                </Row>
              </Col>
            ))}
            <Col md={12}>
              <Row>
                <Col md={3}>
                  <b>Send Delivery Message</b>
                </Col>
                <Col>
                  <p>
                    {deliveryMessages?.find(
                      (msg) =>
                        String(msg?.id) ===
                        String(
                          selectedRecord?.recipientTicketDetails?.messageId
                        )
                    )?.deliveryMessage ?? ""}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-2">
            <Alert
              severity="success"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      selectedRecord?.ticketMasterTokenLink
                    );
                    dispatch(successMessage("Copied !!!"));
                  }}
                >
                  <img
                    src={Copy}
                    alt="copy icon"
                    style={{ marginRight: "12px" }}
                  />
                  Copy
                </Button>
              }
            >
              <div className="mt-1 d-flex justify-content-around">
                <div style={{ color: "#03791E" }}>
                  Sale successfully generated and ticket URL is:
                </div>
                <div className="text-primary" style={{ marginLeft: "12px" }}>
                  {selectedRecord?.ticketMasterTokenLink}
                </div>
              </div>
            </Alert>
          </Row>
        </div>
      </Modal.Body>
      <div className="d-flex justify-content-center my-4">
        <Button
          variant="primary"
          className="ss-modal-primary-btn mx-2"
          onClick={() => handleClose()}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default TicketInformationModal;
