import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import {
	errorMessage,
	successMessage,
} from "../../../data/reducers/alerts/alert.reducer";
import React, { useEffect } from "react";
import {
	Modal,
	Button,
	Row,
	Col,
	Form,
	InputGroup,
	ProgressBar,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { withLoader } from "../../../utils/hoc/withLoader";
import styles from "../styles/email.module.css";
import UploadEmail from "../../../images/UploadCsv.svg";
import Download from "../../../images/DownloadEmail.svg";
import QuickTixTable from "../../../shared/components/table/QuickTixTable";
import TableRowCard from "../../../shared/components/table/TableRowCard";
import {
	downloadTampleteEmailData,
	downloadTemplateEmail,
	uploadCsvFiles,
} from "../../../data/reducers/email/email.reducer";
import { columnError } from "../../../shared/components/table/data/error";
import { formatDate } from "../../../utils/formatter/dateFormatter";
import VectorImg from "../../../images/VectorImg.svg";

const UploadFileModal = ({
	setBusy,
	isVisible,
	handleThisClose,
	onUploadCompleted,
}) => {
	const dispatch = useDispatch();
	const tamplateEmailData = useSelector((state) => state.emails).tamplateData;

	const [fileData, setFileData] = useState([]);
	const [progressBar, setProgressBar] = useState(0);

	const onUploadProgress = (event) => {
		const percentCompleted = Math.round((event.loaded * 100) / event.total);
		setProgressBar(percentCompleted);
	};

	const simulateUpload = () => {
		let progress = 0;
		let interval;
		interval = setInterval(() => {
			if (progress < 100) {
				progress += Math.floor(Math.random() * 10) + 1;
				if (progress >= 100) {
					progress = 100;
				}
				onUploadProgress({ loaded: progress, total: 100 });
			} else {
				clearInterval(interval);
			}
		}, 500);
	};

	const hiddenFileInput = React.useRef(null);

	const handleClick = (event) => {
		hiddenFileInput.current.click();
	};

	const handleFile = async (event) => {
		const file = event.target.files[0];
		if (file) {
			if (file.size > 4 * 1024 * 1024) {
				// File is larger than 4mb
				//  alert("File is too large. Maximum size is 4MB."
				const message = `File is too large. Maximum size is 4MB.`;
				dispatch(errorMessage(message));
			} else {
				await simulateUpload();
				const formdata = new FormData();
				formdata.append("file", file);
				setFileData(file);
			}
		}
	};

	// upload email details
	const onUploadData = async (data) => {
		try {
			const message = `The ${fileData.name} file uploaded successfully.`;
			setBusy(true);
			const formdata = new FormData();
			formdata.append("file", fileData);
			const action = uploadCsvFiles(formdata);
			const response = await dispatch(action);
			if (response.error) {
				dispatch(errorMessage(response.error.message));
			} else {
				dispatch(successMessage(message));
				onUploadCompleted();
				handleThisClose();
			}
		} catch (e) {
			dispatch(errorMessage(e));
		} finally {
			setBusy(false);
		}
	};

	// cancel button
	const removeFile = () => {
		setFileData("");
		setProgressBar("");
		setValue("");
		reset();
	};

	// download Tamplate
	const downloadTemplate = async () => {
		setBusy(true);
		await dispatch(downloadTemplateEmail());
		setBusy(false);
	};

	useEffect(async (data) => {
		await dispatch(downloadTampleteEmailData());
	}, []);

	const {
		handleSubmit,
		formState: { errors },
		control,
		reset,
		setValue,
	} = useForm();

	const uploadCol = [
		{
			columnName: "File Name",
			columnKey: "fileName",
			flexGrow: 1,
		},
		{
			columnName: "Size",
			columnKey: "size",
			flexGrow: 1,
			render: (data) => {
				// const i = parseInt(
				//   Math.floor(Math.log(data?.size) / Math.log(1024)),
				//   1024
				// );
				return <div>{(data?.size / 1024 / 1024).toFixed(4)} MB</div>;
			},
		},
		{
			columnName: "Upload Date",
			columnKey: "uploadedOn",
			flexGrow: 1,
			render: (data) => <>{formatDate(data.uploadedOn)}</>,
		},

		{
			columnName: "Upload By",
			columnKey: "uploadedBy",
			flexGrow: 1,
		},
		{
			columnName: "Status",
			columnKey: "status",
			flexGrow: 1,
			render: (data) => {
				if (data.uploadStatus === false) {
					return <div style={{ color: "#FF3737" }}>Failed</div>;
				} else if (data.uploadStatus === true) {
					return <div>Uploaded</div>;
				}
			},
		},
	];

	return (
		<>
			<Modal
				dialogClassName="modal-xl"
				show={isVisible}
				onHide={handleThisClose}
			>
				<Form
					onSubmit={handleSubmit(onUploadData)}
					style={{ fontWeight: "500" }}
				>
					<Modal.Header closeButton>
						<Modal.Title>Emails</Modal.Title>
					</Modal.Header>
					<ModalBreadcrumb
						breadcrumbLinks={[
							{
								to: "/email",
								name: "Email",
								active: false,
							},
							{
								name: "Upload files",
								active: true,
							},
						]}
					/>
					<Modal.Body>
						<div className="container-fluid p-2 w-100 h-100">
							<Col>
								{progressBar ? (
									<div>{/* {fileData?.name} */}</div>
								) : (
									<div className={styles.uploadFile}>
										<div onClick={handleClick}>
											<img
												src={UploadEmail}
												alt=" "
												className={styles.uploadImg}
											/>
											<br />
											<input
												type="file"
												multiple={false}
												ref={hiddenFileInput}
												onChange={handleFile}
												className={styles.uploadFileName}
												style={{ marginLeft: "8rem", display: "none" }}
												accept=".csv"
											/>
											<p
												style={{
													fontWeight: "500",
													color: "#00000",
													fontSize: "20px",
												}}
											>
												Please upload .csv file.
											</p>
											<p className={styles.uploadFileName}>
												Drag and Drop file here or{" "}
												<a id="input-file-now" style={{ color: "#0241A0" }}>
													browse the file
												</a>
											</p>
											<p
												style={{
													color: "#A7A7A7",
													fontWeight: "500",
													fontSize: "15px",
												}}
											>
												max file size 4 MB
											</p>
										</div>
									</div>
								)}

								<br />
								{progressBar ? (
									<div>
										<div className="progress mb-2">
											<div
												className="progress-bar progress-bar-striped progress-bar-animated"
												rol="progressbar"
												aria-label="progressbar"
												aria-aria-valuenow={progressBar}
												aria-valuemin={"0"}
												aria-valuemax={"100"}
												style={{ width: `${progressBar}%` }}
											></div>
										</div>
										File upload - {progressBar}%
										{fileData && (
											<div className="d-flex mt-3 mb-2c">
												<img
													src={VectorImg}
													alt=" "
													style={{ paddingRight: "10px" }}
												/>{" "}
												{fileData?.name} -{" "}
												{(fileData?.size / 1024 / 1024).toFixed(4)} MB
											</div>
										)}
									</div>
								) : (
									<div></div>
								)}
							</Col>
							<div
								style={{
									height: "50vh",
									overflowY: "scroll",
								}}
							>
								<QuickTixTable
									headerName="Recent Upload Files"
									data={tamplateEmailData || []}
									columns={uploadCol}
									renderRow={(rowData, index) => (
										<TableRowCard
											key={rowData.id}
											columns={rowData.nodata ? columnError : uploadCol}
											rowData={rowData}
											className="over"
										/>
									)}
								/>
							</div>
						</div>
					</Modal.Body>
					<div className="d-flex justify-content-between align-items-center flex-wrap">
						<div className="d-flex justify-content-evenly my-4 mx-2">
							<Button
								type="submit"
								variant="primary"
								disabled={fileData.length === 0 || progressBar < 100}
								className="ss-modal-primary-btn mx-2 w-auto"
							>
								Upload
							</Button>
							<Button
								variant="light"
								onClick={removeFile}
								className="ss-modal-secondary-btn mx-2 w-auto"
							>
								Cancel
							</Button>
						</div>
						<div>
							<div
								onClick={downloadTemplate}
								style={{
									background: "#0241A0",
									height: "46px",
									width: "219px",
									color: "#fff",
									borderRadius: "8px",
									paddingTop: "10px",
									textAlign: "center",
									cursor: "pointer",
									justifyContent: "space-between",
									marginRight: "1.5rem",
								}}
								className="my-4 mx-3"
							>
								<img
									src={Download}
									alt=" "
									style={{ color: "#fff", paddingRight: "10px" }}
								/>
								Download template
							</div>
						</div>
					</div>
				</Form>
			</Modal>
		</>
	);
};

export default withLoader(UploadFileModal);
