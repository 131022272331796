import {
	api,
	getAuthHeaders,
	get,
	post,
	update,
	del,
	getCSV,
	getPDF
} from '../services.common';

const getAllLoans = async (data) => {
	const sortDir = data.lsortDir == false ? 'Descending' : 'Ascending'
	const url = `${api}LoanCharges/getall?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.lsortField || ""}`;
	const authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};

const createLoan = async (formData) => {
	const url = `${api}LoanCharges/create`;
	const authHeader = getAuthHeaders();
	return await post(url, formData, { ...authHeader });
};

const getSyncBanking = async () => {
	const url = `${api}SyncData/synctransactionsdata`;
	const authHeader = getAuthHeaders();
	return await post(url, null, { ...authHeader });
};

const deleteLoan = async (id) => {
	const url = `${api}LoanCharges/delete/${id}`;
	const authHeader = getAuthHeaders();
	return await del(url, { ...authHeader });
};

const updateLoan = async (data) => {
	const url = `${api}LoanCharges/update/${data.id}`;
	const authHeader = getAuthHeaders();
	return await update(url, data, { ...authHeader });
};

const filterLoan = async (data) => {
	const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
	const url = `${api}LoanCharges/search?searchString=${data.searchText}&Name=${data.name}&PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&Amount=${data.amount}&Date=${data.date}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
	const authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};

const getBankBalance = async () => {
	const url = `${api}LoanCharges/getBankBalance`;
	const authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};

const exportCSVLoan = async () => {
	const url = `${api}LoanCharges/exportcsv`;
	const authHeader = getAuthHeaders();
	return await getCSV(url, { ...authHeader });
};

const exportPdfLoan = async () => {
	const url = `${api}LoanCharges/exportpdf`;
	const authHeader = getAuthHeaders();
	return await getPDF(url, { ...authHeader }, "LoanCharges.pdf");
};

const exportSelectedAsCSVLoans = async (data) => {
	const url = `${api}LoanCharges/searchexportcsv?searchString=${data.searchText}&Name=${data.name || ""}&Amount=${data.amount}&Date=${data.date}`;
	const authHeader = getAuthHeaders();
	return await getCSV(url, { ...authHeader });
};

const exportSelectedAsPdfLoans = async (data) => {
	const url = `${api}LoanCharges/searchexportpdf?searchString=${data.searchText || ""}&Name=${data.name || ""}&Amount=${data.amount}&Date=${data.date}`;
	const authHeader = getAuthHeaders();
	return await getPDF(url, { ...authHeader }, "LoansFilter.pdf");
};

const headerInfo = async () => {
	const url = `${api}LoanCharges/getheaderinfoloans`;
	const authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};

export const LoanServices = {
	exportPdfLoan,
	exportSelectedAsPdfLoans,
	createLoan,
	updateLoan,
	deleteLoan,
	filterLoan,
	getBankBalance,
	exportCSVLoan,
	getAllLoans,
	headerInfo,
	getSyncBanking,
	exportSelectedAsCSVLoans
};
