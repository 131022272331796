import { formatMoney } from "../../../utils/formatter/currencyFormatter";
import { withLoaderForImmediateParent } from "../../../utils/hoc/withLoader";
const ChargesCard = ({ title, amount, bg, color, render, loading }) => {
  return (
    <div
      className="p-2 rounded text-center"
      style={{ backgroundColor: `${bg}`, color: `${color}` }}
    >
      <div className="">
        <b>{title}</b>
      </div>
      <h3 className="pt-2">{amount}</h3>
    </div>
  );
};

export default withLoaderForImmediateParent(ChargesCard);
