/* eslint-disable no-mixed-spaces-and-tabs */
import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import { Modal, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatMoney } from "../../../utils/formatter/currencyFormatter";
import { formatDate } from "../../../utils/formatter/dateFormatter";

import "../Skybox.scss";

const VendorDetailModal = ({ isVisible, handleClose, selectedRecord }) => {
  const { performer, venue } = "";
  return (
    <>
      <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>VENDORS</Modal.Title>
        </Modal.Header>
        <ModalBreadcrumb
          breadcrumbLinks={[
            {
              to: "/vendors",
              name: "Vendors",
              active: true,
            },
            {
              name: selectedRecord?.id,
              active: false,
            },
          ]}
        />
        <Modal.Body>
          <div className="container-fluid p-2 w-100 h-100">
            <Row>
              <p className="text-bold">Basic Information</p>
              <Col>
                <Row>
                  <Col>Id: </Col>
                  <Col className="text-bold">{selectedRecord?.id} </Col>
                </Row>

                <br />
                <Row>
                  <Col>Vendor: </Col>
                  <Col className="text-bold">{selectedRecord?.vendorName} </Col>
                </Row>
                <br />
                <Row>
                  <Col>Created Date: </Col>
                  <Col className="text-bold">
                    {formatDate(selectedRecord?.createdDate)}{" "}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>Value: </Col>
                  <Col className="text-bold">
                    {formatMoney(selectedRecord?.total)}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>Performer: </Col>
                  <Col className="text-bold">{performer} </Col>
                </Row>
                <br />
                <Row>
                  <Col>Venue: </Col>
                  <Col className="text-bold">{venue} </Col>
                </Row>
                <br />
                <Row>
                  <Col>Seats: </Col>
                  <Col className="text-bold">{selectedRecord?.row}</Col>
                </Row>
              </Col>
            </Row>
            <br />
            <hr />
            <Row>
              <p className="text-bold">More Information</p>
            </Row>
            <Row>
              <Col>SkyBox</Col>
              <Col sm="9" className="text-bold">
                <a
                  variant="link"
                  target="blank"
                  className="ss-link"
                  href={
                    "https://skybox.vividseats.com/purchases/" +
                    selectedRecord?.id
                  }
                >
                  https://skybox.vividseats.com/purchases/{selectedRecord?.id}
                </a>
              </Col>
            </Row>
            <br />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VendorDetailModal;
