import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Image, Modal, Row } from 'react-bootstrap'
import HistoryIcon from "../../../images/history2.svg";
import { withLoader } from '../../../utils/hoc/withLoader';
import "./historyModal.scss"
import { useDispatch, useSelector } from 'react-redux';
import { getPOHistory } from '../../../data/reducers/purchaseOrders/po.history.reducer';
import HistoryRow from './HistoryRow';
import dot from "../../../images/dot.svg"

const HistoryPO = ({ isVisible, handleClose, selectedRecord }) => {

    const dispatch = useDispatch();
    const { historyList } = useSelector(state => state.poHistoy);

    const [poHistoryList, setPoHistoryList] = useState([]);

    useEffect(() => {
        if (isVisible && selectedRecord) {
            dispatch(getPOHistory(selectedRecord.id));
        }
    }, [isVisible, selectedRecord]);

    useEffect(() => {
        if (historyList && isVisible) {
            setPoHistoryList(historyList);
        }
    }, [historyList, isVisible]);

 

    return (
        <>
            <Modal dialogClassName="modal-xl" className='historyModal' show={isVisible} onHide={handleClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title> <Image width={"24px"} style={{ marginTop: "-4px" }} src={HistoryIcon} /> History</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container className='upperCon'>
                            <Row>
                                <Col sm={4} className='light'>Purchase Order : <span className='dark' style={{ color: "black" }}>{selectedRecord?.purchaseOrderId}</span></Col>
                                <Col sm={4} />
                                <Col sm={4} style={{ textAlign: "right" }} > <span className='light'>Last Updated:</span> <span className='dark'>{poHistoryList[0]?.updatedDate}</span></Col>
                            </Row>
                        </Container>

                        <section className='indicator'> <img src={dot} alt="" /> indicates modified content</section>

                        <div className='tableCover'>

                            <Container className='tableHeader'>
                                <Row>
                                    <Col>Updated by</Col>
                                    <Col>Updated on</Col>
                                    <Col>Vendor</Col>
                                    <Col>Name/<br />Account ID</Col>
                                    <Col>Date</Col>
                                    <Col>Season</Col>
                                    <Col>Description</Col>
                                    <Col>SkyBox Ref</Col>
                                    <Col>SkyBox Value</Col>
                                    <Col>Total Scheduled</Col>
                                </Row>
                            </Container>

                            {poHistoryList.map((val, index) => {
                                return (
                                    <HistoryRow key={index} val={val} />
                                )
                            })}

                        </div>

                    </Modal.Body>
                </Form>
            </Modal>
        </>
    )
}

export default withLoader(HistoryPO);