import {
  api,
  getAuthHeaders,
  get,
  post,
  del,
  update,
  getCSV,
  getPDF
} from "../services.common";

const getAllVendorPurchaseOrders = async (data) => {
  if (data.id) {
    const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
    const url = `${api}VendorPurchaseOrders/getall?vendorId=${data.id}&PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
    const authHeader = getAuthHeaders();
    return await get(url, { ...authHeader });
  }
};

const exportCSVVendorPurchase = async (id) => {
  const url = `${api}VendorPurchaseOrders/exportcsv?vendorId=${id}`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const exportPdfVendorPurchase = async (id) => {
  const url = `${api}VendorPurchaseOrders/exportpdf?vendorId=${id}`;
  const authHeader = getAuthHeaders();
  return await getPDF(url, { ...authHeader }, "VendorPurchaseOrders.pdf");
};


const getVendorPurchaseOrderById = async (data) => {
  const url = `${api}VendorPurchaseOrders/get/${data.id}?vendorId=${data.vendorId}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const createVendorPurchaseOrder = async (data) => {
  const url = `${api}VendorPurchaseOrders/create?vendorId=${data?.vendorId}`;
  const authHeader = getAuthHeaders();
  return await post(url, data.formData, { ...authHeader });
};

const updateVendorPurchaseOrder = async (data) => {
  const url = `${api}VendorPurchaseOrders/update/${data?.formData.id}?vendorId=${data?.vendorId}`;
  const authHeader = getAuthHeaders();
  return await update(url, data.formData, { ...authHeader });
};

const deleteVendorPurchaseOrder = async (data) => {
  const url = `${api}VendorPurchaseOrders/delete/${data?.mainId}?vendorId=${data?.id}`;
  const authHeader = getAuthHeaders();
  return await del(url, { ...authHeader });
};

export const vendorPurchaseOrdersServices = {
  getAllVendorPurchaseOrders,
  getVendorPurchaseOrderById,
  createVendorPurchaseOrder,
  updateVendorPurchaseOrder,
  deleteVendorPurchaseOrder,
  exportCSVVendorPurchase,
  exportPdfVendorPurchase
};
