import axios from "axios";
import jwtDecode from "jwt-decode";
import { constants } from "../../../config";
import { api, success, error, post, postAuth } from "../services.common";
import { Mixpanel } from "../../../mixpanel";

const checkLogin = () => {
  const token = localStorage.getItem(constants.KEY_AUTH_TOKEN);
  const role = localStorage.getItem(constants.KEY_USER_ROLE);
  if (token === undefined || token === null) {
    return {
      loggedIn: false,
    };
  } else {
    // check if token is expired
    const jsonToken = jwtDecode(token);
    if (jsonToken.exp < (new Date().getTime() + 1) / 1000) {
      return {
        loggedIn: false,
      };
    } else {
      Mixpanel.alias(jsonToken.Email_Id)
      Mixpanel.people.set({
        $email: jsonToken.Email_Id,
      })
      Mixpanel.identify(jsonToken.Email_Id);
      return {
        loggedIn: true,
        role
      };
    }
  }
};

const loginAuthentication = async (username, password, remember) => {
  try {
    const response = await post(`${api}auth/login`, {
      username,
      password,
    });

    // save token if response successful
    if (response.isSuccessful) {
      const credentials = response.data;
      // save token
      localStorage.setItem(constants.KEY_AUTH_TOKEN, credentials.token);
      localStorage.setItem(constants.KEY_USER_ROLE, credentials.role);
    }

    return response;
  } catch (e) {
    return error(e);
  }
};

const googleAuthLogin = async (payload) => {
  try {
    const response = await post(`${api}googleAuth/login`, payload);

    // save token if response successful
    if (response.isSuccessful) {
      const credentials = response.data;
      console.log(credentials)
      // save token
      localStorage.setItem(constants.KEY_AUTH_TOKEN, credentials.token);
      localStorage.setItem(constants.KEY_USER_ROLE, credentials.role);
      const jsonToken = jwtDecode(credentials.token);
      if (jsonToken) {
        Mixpanel.alias(jsonToken?.Email_Id)
        Mixpanel.people.set({
          $email: jsonToken?.Email_Id,
        })
        Mixpanel.identify(jsonToken?.Email_Id);
      }
    }

    return response;
  } catch (e) {
    return error(e);
  }
};

const googleRefreshToken = async (payload) => {
  try {
    const response = await post(`${api}googleAuth/refresh`, payload);

    // save token if response successful
    if (response.isSuccessful) {
      const credentials = response.data;
      // save token
      localStorage.setItem(constants.KEY_AUTH_TOKEN, credentials.token);
      localStorage.setItem(constants.KEY_USER_ROLE, credentials.role);
      const jsonToken = jwtDecode(credentials.token);

      if (jsonToken) {
        Mixpanel.alias(jsonToken?.Email_Id)
        Mixpanel.people.set({
          $email: jsonToken?.Email_Id,
        })
        Mixpanel.identify(jsonToken?.Email_Id);
      }
    }

    return response;
  } catch (e) {
    return error(e);
  }
};

const logoutUser = () => {
  localStorage.clear();
  sessionStorage.clear();
  return true;
};

const changePassword = async (data) => {
  const url = `${api}Auth/changepassword`;
  return await postAuth(url, data);
};

const sendTempPass = async (data) => {
  const url = `${api}Auth/temppassword`;
  return await postAuth(url, data);
};

export const authServices = {
  loginAuthentication,
  checkLogin,
  logoutUser,
  sendTempPass,
  changePassword,
  googleAuthLogin,
  googleRefreshToken
};