import React from 'react'
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"
import { errorMessage, successMessage } from '../../data/reducers/alerts/alert.reducer';
import { changePassword } from '../../data/reducers/authentication/auth.reducer';
import { useForm } from "react-hook-form";

function ChangePassword({ emailSent, setBusy, setEmailSent, email }) {
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors }
    } = useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const onSubmit = async (data) => {
        const req = {
            oldPassword: data.oldPassword,
            newPassword: data.newPassword,
            email
        }
        try {
            setBusy(true);
            const res = await dispatch(changePassword(req))
            if (res.payload?.status == 200) {
                dispatch(successMessage(res.payload?.data?.message))
                history.push('/login')
            } else {
                dispatch(errorMessage("Please check your password and email. Password must be greater than 8 and must include atleast one uppercase letter, one lower case letter and one symbol"))
            }
        } catch (e) {
            dispatch(errorMessage(e))
        } finally {
            setBusy(false);
        }
    };
    return (
        <div>
            <Row>
                <Col className="d-flex flex-column justify-content-center align-items-center">
                    <div className="password-forgot-cont m-0">

                        <div style={{ color: "#A7A7A7" }}>
                            Enter a temporary password that has been send to
                            your email.
                        </div>
                        <div>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group className="mt-3">
                                    <Form.Control
                                        isInvalid={!!errors.oldPassword}
                                        placeholder="Temporary Password"
                                        type="password"
                                        {...register("oldPassword", { required: true })}
                                    />
                                    <Form.Control.Feedback type="invalid">Please enter temporary or old password!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mt-3">
                                    <Form.Control
                                        isInvalid={!!errors.newPassword}
                                        placeholder="New Password"
                                        type="password"
                                        {...register("newPassword", { required: true })}
                                    />
                                    <Form.Control.Feedback type="invalid">Please enter a new password!</Form.Control.Feedback>
                                </Form.Group><Form.Group className="mt-3">
                                    <Form.Control
                                        isInvalid={!!errors.confirm}
                                        placeholder="Confirm New Password"
                                        type="password"
                                        {...register("confirm", {
                                            required: true,
                                            validate: value => getValues('newPassword') == value,
                                        })}
                                    />
                                    <Form.Control.Feedback type="invalid">Please confirm the new password!</Form.Control.Feedback>
                                </Form.Group>
                                <div className="text-center">
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="mt-3 w-50">
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
            {emailSent &&
                <p className="text-center fs-14 " style={{ color: "#0241A0" }}>Password has been changed successfully!</p>
            }
        </div>
    )
}

export default ChangePassword
