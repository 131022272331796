import {
	api,
	getAuthHeaders,
	get,
	download,
	getCSV,
	post,
} from "../services.common";

const getAllEvents = async (data) => {
	const sortDir = data.sortDir == false ? "Descending" : "Ascending";
	const url = `${api}api/SkyboxTicketMasterEvents/getall?PageNumber=${
		data.pageNumber
	}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${
		data.sortField || ""
	}&currentDateTime=${data.currentDateTime}
  &IsStatusActive=${data.IsStatusActive === 0 ? true : false}`;
	const authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};

const filterEvent = async (data) => {
	const sortDir = data.sortDir == false ? "Descending" : "Ascending";
	const url = `${api}api/SkyboxTicketMasterEvents/search?searchString=${
		data.searchText || ""
	}&TeamId=${data.teamId}&LeagueId=${data.leagueId}&Email=${
		data.email
	}&StartDate=${data.startDate}&EndDate=${data.endDate}&Section=${
		data.section
	}&Verified=${data.verified}&PageNumber=${data.pageNumber}&PageSize=${
		data.pageSize
	}&SortDir=${sortDir}&SortField=${data.sortField || ""}&currentDateTime=${
		data.currentDateTime
	}&IsStatusActive=${data.IsStatusActive === 0 ? true : false}`;
	const authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};

const getEventsTicketTokens = async (data) => {
	const url = `${api}api/SkyboxTicketMasterEvents/get-event-ticket-tokens/${data.id}?section=${data.section}&row=${data.row}`;
	const authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};

const getActiveEventsCountByAccountId = async (id) => {
	const url = `${api}api/SkyboxTicketMasterEvents/get-active-events-count/${id}`;
	const authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};

const getEventsCsv = async (data) => {
	let url = `${api}api/SkyboxTicketMasterEvents/exportcsv?&currentDateTime=${
		data.currentDateTime
	}&IsStatusActive=${data.IsStatusActive === 0 ? true : false}`;
	if (!data.getAll) {
		url = `${api}api/SkyboxTicketMasterEvents/exportcsv?searchString=${
			data.searchText || ""
		}&TeamId=${data.teamId}&LeagueId=${data.leagueId}&Email=${
			data.email
		}&StartDate=${data.startDate}&EndDate=${data.endDate}&Section=${
			data.section
		}&Verified=${data.verified}&currentDateTime=${
			data.currentDateTime
		}&IsStatusActive=${data.IsStatusActive === 0 ? true : false}`;
	}
	const authHeader = getAuthHeaders();
	return await getCSV(url, { ...authHeader });
};

const addSyncEventsPriorityData = async (data) => {
	const url = `${api}api/SkyboxTicketMasterEvents/add-sync-events-priority-data`;
	const authHeader = getAuthHeaders();
	return await post(url, data, { ...authHeader });
};

export const eventServices = {
	getAllEvents,
	filterEvent,
	getEventsTicketTokens,
	getActiveEventsCountByAccountId,
	getEventsCsv,
	addSyncEventsPriorityData,
};
