import { Modal, Button } from "react-bootstrap";
import crossdelete from "../../../images/delete_prompt.svg";
import Loader from "../loader/Loader";

const DeleteConfirmationModal = ({
  text,
  onConfirmation,
  isVisible,
  handleClose,
  customMessage,
  loading = false,
}) => {
  return (
    <Modal dialogClassName="modal-lg" show={isVisible} onHide={handleClose}>
      <div className="delete-svg-div d-flex flex-row justify-content-center align-items-center p-2">
        <img
          className="delete-svg"
          style={{ height: "80px", width: "80px" }}
          src={crossdelete}
          alt=""
        />
      </div>

      <div className="d-flex flex-column justify-content-evenly align-items-center">
        {text ? (
          <p> {text} </p>
        ) : (
          <>
            <p className="my-text">
              {customMessage ? "" : "Do you really want to delete this record?"}
            </p>
            <p className="my-text">
              {customMessage ? customMessage : "This process cannot be undone."}
            </p>
          </>
        )}
      </div>
      <Modal.Footer className="justify-content-center">
        <Button
          className="ss-modal-primary-btn"
          disabled={loading}
          onClick={onConfirmation}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Delete
            {loading && <Loader style={{ marginLeft: "8px" }} />}
          </div>
        </Button>
        <Button
          variant="light"
          className="ss-modal-secondary-btn"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmationModal;
