import axios from "axios";
import { constants } from "../../config";
import { getAuthHeaders } from "./services.common";

const success = (data, paging, statusMessage) => {
  return {
    status: "success",
    isSuccessful: true,
    data,
    paging,
    statusMessage,
  };
};

const error = (error, data) => {
  return {
    status: "error",
    isSuccessful: false,
    message: error,
    data: data ?? null,
  };
};

export const buildUrlWithParams = (baseUrl, params = {}) => {
  const validParams = Object.entries(params)
    .filter(([key, value]) => value !== null && value !== undefined)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
  return `${baseUrl}?${validParams}`;
};

const baseURL = constants.endpoint;

const get = async (endpoint, params) => {
  try {
    const urlWithParams = buildUrlWithParams(`${baseURL}${endpoint}`, params);
    const authHeader = getAuthHeaders();
    const response = await axios.get(urlWithParams, { headers: authHeader });
    if (response.data.httpStatus === 200) {
      return success(
        response.data.data,
        response.data.paging,
        response.data.statusMessage
      );
    } else {
      throw new Error(response);
    }
  } catch (e) {
    throw new Error(e);
  }
};

const del = async (endpoint, id) => {
  try {
    const urlWithId = `${baseURL}${endpoint}/${id}`;
    const authHeader = getAuthHeaders();
    const response = await axios.delete(urlWithId, { headers: authHeader });
    if (response.data.httpStatus === 200) {
      return success(response.data.data);
    } else {
      return error(response.data.statusMessage);
    }
  } catch (e) {
    if (e.response?.data) {
      return error(e.response.data.statusMessage);
    }
    return error(e);
  }
};

const update = async (endpoint, payload) => {
  try {
    const urlWithId = `${baseURL}${endpoint}/${payload.id}`;
    const authHeader = getAuthHeaders();
    const response = await axios.put(urlWithId, payload, {
      headers: authHeader,
    });
    if (response.data.httpStatus === 200) {
      return success(response.data.data, null, response.data.statusMessage);
    } else {
      throw error(response.data.statusMessage);
    }
  } catch (e) {
    if (e.response.data) {
      throw error(e.response.data.statusMessagee);
    }
    throw error(e);
  }
};

const create = async (endpoint, payload) => {
  try {
    const urlWithId = `${baseURL}${endpoint}`;
    const authHeader = getAuthHeaders();
    const response = await axios.post(urlWithId, payload, {
      headers: authHeader,
    });
  } catch (e) {
    if (e.response.data) {
      throw error(e.response.data.statusMessage);
    }
    throw error(e);
  }
};

const api = {
  get,
  delete: del,
  update,
  create,
};

export { api };
