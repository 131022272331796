import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { emailServices } from "../../services/email/email.service";
import fileDownload from "js-file-download";

export const getAllEmail = createAsyncThunk(
  "emails/getAllEmail",
  async (payload, thunkAPI) => {
    const response = await emailServices.getAllEmail(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const filterData = createAsyncThunk(
  "emails/filterData",
  async (payload, thunkAPI) => {
    const response = await emailServices.filterData(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const createEmail = createAsyncThunk(
  "emails/CreateEmail",
  async (payload, thunkAPI) => {
    const response = await emailServices.createEmail(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

// Gender Data

export const genderEmail = createAsyncThunk(
  "emails/genderEmail",
  async (thunkAPI) => {
    const response = await emailServices.genderEmail();
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const downloadTemplateEmail = createAsyncThunk(
  "emails/downloadTemplateEmail",
  async (thunkAPI) => {
    const response = await emailServices.downloadTemplateEmail();
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "EmailTemplate.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const downloadTampleteEmailData = createAsyncThunk(
  "emails/downloadTampleteEmailData",
  async (thunkAPI) => {
    const response = await emailServices.downloadTampleteEmailData();
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const downloadUploadedFiles = createAsyncThunk(
  "emails/downloadUploadedFiles",
  async (paylod, thunkAPI) => {
    const response = await emailServices.downloadUploadedFiles(paylod);
    if (response.isSuccessful === true) {
      await fileDownload(response?.data?.data, "EmailTemplate.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const uploadCsvFiles = createAsyncThunk(
  "emails/uploadCsvFiles",
  async (paylod, thunkAPI) => {
    const response = await emailServices.uploadCsvFiles(paylod);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const downloadEmailList = createAsyncThunk(
  "emails/downloadEmailList",
  async (thunkAPI) => {
    const response = await emailServices.downloadEmailList();
    if (response.isSuccessful === true) {
      await fileDownload(response.data?.data, "EmailDataList.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getEmailData = createAsyncThunk(
  "emails/getEmailData",
  async (id, thunkAPI) => {
    try {
      const response = await emailServices.getEmailData(id);
      if (response.isSuccessful === true) {
        return response.data;
      } else {
        thunkAPI.rejectWithValue(response.message);
        throw new Error(response.message);
      }
    } catch (error) {
      console.error("Error in getEmailData:", error);
      throw error;
    }
  }
);

export const getEmailLogs = createAsyncThunk(
  "emails/getEmailLogs",
  async (username, thunkAPI) => {
    try {
      const response = await emailServices.getEmailLogs(username);
      if (response.isSuccessful === true) {
        return response.data;
      } else {
        thunkAPI.rejectWithValue(response.message);
        throw new Error(response.message);
      }
    } catch (error) {
      console.error("Error in getEmail logs:", error);
      throw error;
    }
  }
);

export const getBalance = createAsyncThunk(
  "emails/getBalance",
  async (id, thunkAPI) => {
    const response = await emailServices.getBalance(id);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const updateEmail = createAsyncThunk(
  "emails/updateEmail",
  async (payload, thunkAPI) => {
    const response = await emailServices.updateEmail(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getRecoveryEmailData = createAsyncThunk(
  "emails/getAllRecoveryEmails",
  async (_, thunkAPI) => {
    try {
      const response = await emailServices.getAllRecoveryEmail();
      if (response.isSuccessful === true) {
        return response.data;
      } else {
        thunkAPI.rejectWithValue(response.message);
        throw new Error(response.message);
      }
    } catch (error) {
      console.error("Error in getEmailData:", error);
      throw error;
    }
  }
);

export const createRecoveryEmail = createAsyncThunk(
  "emails/createRecoveryEmail",
  async (email, thunkAPI) => {
    try {
      const response = await emailServices.createRecoveryEmail(email);
      if (response.isSuccessful === true) {
        return response.data;
      } else {
        thunkAPI.rejectWithValue(response.message);
        throw new Error(response.message);
      }
    } catch (error) {
      console.error("Error in getEmailData:", error);
      throw error;
    }
  }
);

export const setDefaultRecoveryEmail = createAsyncThunk(
  "emails/setDefaultRecoveryEmail",
  async (email, thunkAPI) => {
    try {
      const response = await emailServices.setDefaultRecoveryEmail(email);
      if (response.isSuccessful === true) {
        return response.data;
      } else {
        thunkAPI.rejectWithValue(response.message);
        throw new Error(response.message);
      }
    } catch (error) {
      console.error("Error in getEmailData:", error);
      throw error;
    }
  }
);

const emailSlice = createSlice({
  name: "email",
  initialState: {
    emails: [],
    emailLogs: [],
    tamplateData: [],
    genderList: [],
    uploadedData: [],
    uploadCsv: [],
    paging: {},
    copyData: [],
    balance: {
      textVerified: 0,
      smsPool: 0,
    },
    recoveryEmails: [],
  },
  // reducers :{},
  extraReducers: {
    [getAllEmail.fulfilled]: (state, action) => {
      state.emails = action.payload.data;
      state.paging = action.payload.paging;
    },
    [filterData.fulfilled]: (state, action) => {
      state.emails = action.payload.data;
      state.paging = action.payload.paging;
    },
    [createEmail.fulfilled]: (state, action) => {
      state.emails = action.payload.data;
    },
    [downloadTampleteEmailData.fulfilled]: (state, action) => {
      state.tamplateData = action.payload.data;
    },
    [genderEmail.fulfilled]: (state, action) => {
      state.genderList = action.payload;
    },
    [downloadUploadedFiles.fulfilled]: (state, action) => {
      state.uploadedData = action.payload;
    },
    [uploadCsvFiles.fulfilled]: (state, action) => {
      state.uploadCsv = action.payload;
    },
    [getEmailData.fulfilled]: (state, action) => {
      state.copyData = action.payload;
    },
    [getBalance.fulfilled]: (state, action) => {
      state.balance.smsPool = action.payload.balance;
      state.balance.textVerified = action.payload.credit_balance;
    },
    [getRecoveryEmailData.fulfilled]: (state, action) => {
      state.recoveryEmails = action.payload;
    },
    [getEmailLogs.fulfilled]: (state, action) => {
      state.emailLogs = action.payload;
    },
  },
});

export const { emailSliceData } = emailSlice.actions;

export default emailSlice.reducer;
