import { Modal, Button } from "react-bootstrap";
import crossdelete from "../../../images/delete_prompt.svg";

const ConfirmationModal = ({
  onConfirmation,
  isVisible,
  handleClose,
  confirmationTxt,
  OnConfirmBtnTxt = "Confirm",
  handleClseBtnTxt = "Cancel",
}) => {
  return (
    <Modal dialogClassName="modal-lg" show={isVisible} onHide={handleClose}>
      <div className="delete-svg-div d-flex flex-row justify-content-center align-items-center">
        <img className="delete-svg" src={crossdelete} alt="" />
      </div>

      <div className="d-flex flex-column justify-content-evenly align-items-center">
        <p className="my-text text-center">{confirmationTxt}</p>
        <p className="my-text">This process cannot be undone.</p>
      </div>
      <Modal.Footer className="justify-content-center">
        <Button className="ss-modal-primary-btn" onClick={onConfirmation}>
          {OnConfirmBtnTxt}
        </Button>
        <Button
          variant="light"
          className="ss-modal-secondary-btn"
          onClick={handleClose}
        >
          {handleClseBtnTxt}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
