import React, { useRef, useState, useEffect } from "react";
import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  InputGroup,
  ProgressBar,
} from "react-bootstrap";
import CopyImg from "../../../images/copy.svg";
import EyeIcon from "../../../images/ShowEye.svg";
import { useDispatch, useSelector } from "react-redux";
import { errorMessage, successMessage } from "../../../data/reducers/alerts/alert.reducer";
import { formatDate } from "../../../utils/formatter/dateFormatter";
import { updateEmail } from "../../../data/reducers/email/email.reducer";
import { withLoader } from "../../../utils/hoc/withLoader";
import Select from 'react-select'
import { getCardTypeDetails } from "../../../data/reducers/cards/cards.routes";
import { formatMobileNumber } from "../../../utils/formatter/currencyFormatter";
import { getAddressByID } from "../../../data/reducers/addressBook/addressBook.reducer";
import { getAddressList } from "../../../data/reducers/addressBook/addressBook.reducer";

const CopyDetailsModal = ({
  isVisible,
  handleThisClose,
  selectedEmailData,
  setBusy,
  getEmailData
}) => {
  const dispatch = useDispatch();
  const { cardTypeLookup } = useSelector((state) => state.cards);
  const selectedAddress = useSelector(
    (state) => state.addressBook
  ).selectedAddress;

  const modalBodyRef = useRef(null);
  const [isShown, setIsShown] = useState(false);
  const [cardTypeId, setCardTypeId] = useState(0);
  const [zipCode, setZipCode] = useState("")
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [showAddressErrorMessage, setShowAddressErrorMessage] = useState(false);
  const [showZipCodeErrorMessage, setShowZipCodeErrorMessage] = useState(false)
  const addressDetails = useSelector((state) => state.addressBook).addressList;

  const [addressId, setAddressId] = useState(0)


  useEffect(() => {
    setFirstName(selectedEmailData?.firstName || "");
    setLastName(selectedEmailData?.lastName || "");
  }, [selectedEmailData])

  React.useEffect(() => {
    dispatch(getCardTypeDetails());
    setShowErrorMessage(false);
    setShowAddressErrorMessage(false)
    setCardTypeId(0)
    dispatch(getAddressList())
  }, []);

  React.useEffect(() => {
    if (selectedEmailData?.addressId) {
      fetchAddressById(selectedEmailData?.addressId)
      setAddressId(selectedEmailData?.addressId)
      setZipCode(selectedEmailData?.zipCode)
    } else {
      setAddressId(0)
      setZipCode("")
      setShowErrorMessage(false);
      setShowAddressErrorMessage(false);
      setShowZipCodeErrorMessage(false)
    }
  }, [selectedEmailData])

  const togglePassword = () => {
    setIsShown((prevState) => !prevState);
  };

  const updateEmailData = async (data) => {
    try {
      setBusy(true);
      const response = await dispatch(updateEmail(data));
      if (response?.error) {
        dispatch(errorMessage(response?.error?.message));
      } else {
        dispatch(successMessage("Email data updated successfully"))
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setBusy(false)
      getEmailData(data)
    }
  }

  const fetchAddressById = async (id) => {
    try {
      setBusy(true)
      await dispatch(getAddressByID(id));
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setBusy(false)
    }
  }

  // copy function
  const handleCopy = async () => {
    let text = modalBodyRef.current.textContent;

    let items = text.split(
      /(Basic Details|First Name|Last Name|Email|Password|Gender|Birth Date|Email Mob No|TM Mob No|Address|Zip\/Postal Code|Recovery Email \(Optional\)|Card Details|CC Account|CC Number|Card Type|Card ID|Security Code|Card Exp Date|Status Details|Email Status|Forward Status|TM status)(.*?)(?=(Basic Details|First Name|Last Name|Email|Password|Gender|Birth Date|Email Mob No|TM Mob No|Address|Zip\/Postal Code|Recovery Email \(Optional\)|Card Details|CC Account|CC Number|Card Type|Card ID|Security Code|Card Exp Date|Status Details|Email Status|Forward Status|TM status|$))/g
    );

    items = items.filter(
      (item) =>
        item.trim() !== ":" &&
        !/^(Basic Details|Card Details|Status Details)$/.test(item.trim())
    );

    let firstNameItemInd = items.findIndex(item => item == "First Name");
    items[firstNameItemInd + 1] = firstName;
    let lastNameItemInd = items.findIndex(item => item == "Last Name");
    items[lastNameItemInd + 1] = lastName;

    let result = "";
    let isFirstEmail = true;
    for (let i = 0; i < items.length; i += 2) {
      let key = items[i].trim();
      let value = items[i + 1].trim();

      if (key !== "" && value !== "") {
        if (
          key === "Gender" ||
          key === "Birth Date" ||
          key === "Card ID" ||
          key === "Email Status" ||
          key === "Forward Status" ||
          key === "TM status" ||
          key === "Recovery Email (Optional)" ||
          (key === "Email" && (value === "Mob No" || value === "StatusEnabled"))
        ) {
          continue;
        } else if (
          key === "First Name" ||
          key === "Last Name" ||
          (key === "Email" && isFirstEmail) ||
          key === "TM Mob No" ||
          key === "Address" ||
          key === "Zip/Postal Code" ||
          key === "CC Account" ||
          key === "CC Number" ||
          key === "Card Type" ||
          key === "Security Code" ||
          key === "Card Exp Date"
        ) {
          result += `• ${key}: ${value}\n`;
          if (key === "Email") {
            isFirstEmail = false;
          }
        } else if (key === "Password") {
          result += `• ${key}: ${selectedEmailData.password}\n`;
        } else if (key === "Status Details") {
          result += `\n${key}\n`;
        }
      }
    }

    await navigator.clipboard.writeText(result);
    dispatch(successMessage("Copied Successfully!"));
  };

  function getForwardingStatus(isForwardingEnabled) {
    if (isForwardingEnabled === true) {
      return "Enabled";
    } else if (isForwardingEnabled === false) {
      return "Pending";
    } else if (isForwardingEnabled === null) {
      return "Pending";
    } else {
      return "";
    }
  }

  const forwardingStatus = getForwardingStatus(
    selectedEmailData.isForwardingEnabled
  );

  function getCreatedStatus(isCreated) {
    if (isCreated === true) {
      return "Enabled";
    } else if (isCreated === false) {
      return "Pending";
    } else if (isCreated === null) {
      return "Pending";
    } else {
      return "";
    }
  }

  const createdStatus = getCreatedStatus(selectedEmailData.isCreated);

  function getTMStatus(tmCreateStatus) {
    if (tmCreateStatus === true) {
      return "Enabled";
    } else if (tmCreateStatus === false) {
      return "Pending";
    } else if (tmCreateStatus === null) {
      return "Pending";
    } else {
      return "";
    }
  }

  const tmStatus = getTMStatus(selectedEmailData.tmCreateStatus);

  const customStyles = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };


  const saveData = () => {
    if (!firstName || !lastName) {
      return;
    }
    updateEmailData({ ...selectedEmailData, firstName, lastName })

  }

  return (
    <>
      <Modal
        dialogClassName="modal-xl"
        show={isVisible}
        onHide={handleThisClose}
      >
        <Form style={{ fontWeight: "500" }}>
          <Modal.Header closeButton>
            <Modal.Title>Emails</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/email",
                name: "Email",
                active: false,
              },
              {
                name: `${selectedEmailData?.cardInfo?.emailId
                  ? selectedEmailData?.cardInfo?.emailId
                  : "-"
                  }`,
                active: true,
              },
            ]}
          />
          <Modal.Body ref={modalBodyRef}>
            <div className="container-fluid p-2 w-100 h-100">
              <Row>
                <p className="title-accounts">Basic Details</p>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3" className="text-right">
                      First Name
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        column
                        sm="4"
                        className="text-right"
                        type="text"
                        placeholder="First Name"
                        value={firstName}
                        autoComplete="nope"
                        onChange={(e) => {
                          setFirstName(e.target.value)
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4" className="text-right">
                      Last Name
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        column
                        sm="4"
                        className="text-right"
                        type="text"
                        placeholder="Last Name"
                        value={lastName}
                        autoComplete="nope"
                        onChange={(e) => {
                          setLastName(e.target.value)
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Email
                    </Form.Label>
                    <Form.Label
                      column
                      sm="8"
                      className="text-right"
                      style={{
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      {`${selectedEmailData.username}@gmail.com`}
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Password
                    </Form.Label>
                    <Form.Label
                      column
                      sm="8"
                      style={{ fontWeight: "400", color: "#000000" }}
                    >
                      <div style={{ display: "flex", position: "relative" }}>
                        {isShown ? (
                          <p>{selectedEmailData.password}</p>
                        ) : (
                          <p>********</p>
                        )}
                        <img
                          style={{
                            position: "absolute",
                            right: "50%",
                            top: "25%",
                            cursor: "pointer",
                          }}
                          src={EyeIcon}
                          alt=" "
                          onClick={togglePassword}
                        />
                      </div>
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Gender
                    </Form.Label>

                    <Form.Label
                      column
                      sm="8"
                      className="text-right"
                      style={{
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      {selectedEmailData.genderName}
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Birth Date
                    </Form.Label>

                    <Form.Label
                      column
                      sm="8"
                      className="text-right"
                      style={{
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      {selectedEmailData?.dateOfBirth
                        ? formatDate(selectedEmailData?.dateOfBirth)
                        : "N/A"}
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Email Mob No
                    </Form.Label>

                    <Form.Label
                      column
                      sm="8"
                      className="text-right"
                      style={{
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      {formatMobileNumber(selectedEmailData?.mobileNumber)}
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      TM Mob No
                    </Form.Label>

                    <Form.Label
                      column
                      sm="8"
                      className="text-right"
                      style={{
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      {formatMobileNumber(selectedEmailData?.tmNumber)}
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              {(
                <Row>
                  <Col sm={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Address
                      </Form.Label>

                      <Form.Label
                        column
                        sm="8"
                        className="text-right"
                        style={{
                          fontWeight: "400",
                          color: "#000000",
                        }}
                      >
                        {selectedEmailData?.addressId ? [selectedAddress?.address1, selectedAddress?.address2, selectedAddress?.city, selectedAddress?.state].filter(x => x).join(", ") : "-"}

                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        Zip/Postal Code
                      </Form.Label>

                      <Form.Label
                        column
                        sm="8"
                        className="text-right"
                        style={{
                          fontWeight: "400",
                          color: "#000000",
                        }}
                      >
                        {selectedEmailData?.zipCode
                          ? selectedEmailData?.zipCode
                          : "-"}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={6}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Recovery Email (Optional)
                    </Form.Label>

                    <Form.Label
                      column
                      sm="8"
                      className="text-right"
                      style={{
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      {selectedEmailData.recoveryMail
                        ? selectedEmailData.recoveryMail
                        : "-"}
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <p className="title-accounts">Card Details</p>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      CC Account
                    </Form.Label>

                    <Form.Label
                      column
                      sm="8"
                      className="text-right"
                      style={{
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      {selectedEmailData?.cardInfo?.ccAccount
                        ? selectedEmailData?.cardInfo?.ccAccount
                        : "-"}
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      CC Number
                    </Form.Label>

                    <Form.Label
                      column
                      sm="8"
                      className="text-right"
                      style={{
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      {selectedEmailData?.cardInfo?.ccNumber
                        ? selectedEmailData?.cardInfo?.ccNumber
                        : "-"}
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Card Type
                    </Form.Label>

                    <Form.Label
                      column
                      sm="8"
                      className="text-right"
                      style={{
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      {selectedEmailData?.cardInfo?.cardType
                        ? selectedEmailData?.cardInfo?.cardType
                        : "-"}
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Card ID
                    </Form.Label>

                    <Form.Label
                      column
                      sm="8"
                      className="text-right"
                      style={{
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      {selectedEmailData?.cardInfo?.cardId
                        ? selectedEmailData?.cardInfo?.cardId
                        : "-"}
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Security Code
                    </Form.Label>

                    <Form.Label
                      column
                      sm="8"
                      className="text-right"
                      style={{
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      {selectedEmailData?.cardInfo?.securityCode
                        ? selectedEmailData?.cardInfo?.securityCode
                        : "-"}
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Card Exp Date
                    </Form.Label>

                    <Form.Label
                      column
                      sm="8"
                      className="text-right"
                      style={{
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      {selectedEmailData?.cardInfo?.expDate
                        ? selectedEmailData?.cardInfo?.expDate
                        : "-"}
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <p className="title-accounts">Status Details</p>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Email
                    </Form.Label>

                    <Form.Label
                      column
                      sm="8"
                      className="text-right"
                      style={{
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      {createdStatus}


                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Forward
                    </Form.Label>

                    <Form.Label
                      column
                      sm="8"
                      className="text-right"
                      style={{
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      {forwardingStatus}
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      TM
                    </Form.Label>

                    <Form.Label
                      column
                      sm="8"
                      className="text-right"
                      style={{
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      {selectedEmailData?.isCreateTicketMasterAccountChecked ? tmStatus : "Not Applied"}

                      {!selectedEmailData?.isCreateTicketMasterAccountChecked && <Button
                        variant="primary"
                        className="ss-modal-primary-btn mx-2 px-2"
                        style={{ minWidth: "unset" }}
                        onClick={(e) => {
                          if (zipCode && String(zipCode).length === 5) {
                            setShowZipCodeErrorMessage(false)
                            updateEmailData({ ...selectedEmailData, isCreateTicketMasterAccountChecked: true })
                          } else {
                            setShowZipCodeErrorMessage(true)
                          }
                        }}
                      >
                        Create TicketMaster
                      </Button>}
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Card
                    </Form.Label>

                    <Form.Label
                      column
                      sm="8"
                      className="text-right"
                      style={{
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      {selectedEmailData?.isCreateCardChecked === true ? "Enabled" : "Not Applied"}
                      {!selectedEmailData?.isCreateCardChecked && (
                        <Button
                          variant="primary"
                          className="ss-modal-primary-btn mx-2 px-2"
                          style={{ minWidth: "unset" }}
                          onClick={(e) => {
                            if (cardTypeId && addressId) {
                              setShowErrorMessage(false)
                              setShowAddressErrorMessage(false)
                              updateEmailData({
                                ...selectedEmailData, isCreateCardChecked: true, cardTypeId: cardTypeId, addressId: addressId
                              })
                            }
                            if (!cardTypeId) {
                              setShowErrorMessage(true)
                            } else {
                              setShowErrorMessage(false)
                            }
                            if (!addressId) {
                              setShowAddressErrorMessage(true)
                            } else {
                              setShowAddressErrorMessage(false)
                            }

                          }}
                        >
                          Apply
                        </Button>
                      )}
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              {(!selectedEmailData?.isCreateCardChecked || !selectedEmailData?.isCreateTicketMasterAccountChecked) && (
                <>
                  <Row>
                    <Col sm={6}>
                      {!selectedEmailData?.isCreateTicketMasterAccountChecked && (
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" style={{ wordWrap: 'break-word' }}>
                            Zip/Postal Code
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control
                              onChange={e => {
                                setZipCode(e.target.value)
                              }}
                              value={zipCode}
                              placeholder="Enter code"
                              maxLength={5}
                              minLength={5}
                            />
                            {showZipCodeErrorMessage && (
                              <div className="custom-invalid-feedback">
                                Check ZipCode Validility.
                              </div>
                            )}
                          </Col>
                        </Form.Group>
                      )}
                    </Col>
                    <Col>
                      {!selectedEmailData?.isCreateCardChecked && (
                        <Form.Group as={Row}>
                          <Form.Label column sm="4">
                            Card Type
                          </Form.Label>
                          <Col sm="8">
                            <Select
                              value={cardTypeLookup?.find(x => x?.id === cardTypeId) ?? null}
                              onChange={(e) => {
                                setCardTypeId(e?.id ?? 0)
                              }}
                              styles={customStyles}
                              options={cardTypeLookup}
                              getOptionLabel={({ cardType }) => cardType}

                            />
                          </Col>
                          {showErrorMessage && (
                            <div className="custom-invalid-feedback">
                              CardType is required.
                            </div>
                          )}
                        </Form.Group>
                      )}
                    </Col>
                  </Row>
                  {!selectedEmailData?.isCreateCardChecked && <Row className="mt-2">
                    <Col sm={6}>
                    </Col>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          Address
                        </Form.Label>
                        <Col sm="8">
                          <Select
                            value={addressDetails?.find(x => x?.id === addressId) ?? null}
                            onChange={(e) => {
                              setAddressId(e?.id ?? null)
                            }}
                            isClearable
                            isSearchable
                            styles={customStyles}
                            options={addressDetails}
                            getOptionLabel={(option) => {
                              if (
                                option.address1 != undefined ||
                                option.address2 != undefined ||
                                option.city != undefined ||
                                option.state != undefined ||
                                option.postalCode != undefined
                              )
                                return `${option.address1} ${option.address2} ${option.city} ${option.state} ${option.postalCode}`;
                            }}

                          />
                        </Col>
                        {showAddressErrorMessage && (
                          <div className="custom-invalid-feedback">
                            Address is required.
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>}

                </>
              )}
            </div>
          </Modal.Body>
          <div className="d-flex justify-content-center my-4">
            <Button
              type="submit"
              variant="primary"
              className="ss-modal-primary-btn mx-2"
              onClick={(e) => {
                e.preventDefault();
                handleCopy();
              }}
            >
              <img src={CopyImg} alt="copy" /> Copy Details
            </Button>
            <Button
              variant="light"
              className="ss-modal-secondary-btn mx-2"
              disabled={firstName == selectedEmailData?.firstName && lastName == selectedEmailData?.lastName}
              onClick={saveData}
            >
              Save
            </Button>
            <Button
              variant="light"
              className="ss-modal-secondary-btn mx-2"
              onClick={handleThisClose}
            >
              Close
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default withLoader(CopyDetailsModal);
