import ModalBreadcrumb from "../../../../shared/components/modals/ModalBreadcrumb";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { withLoader } from "../../../../utils/hoc/withLoader";
import { successMessage } from "../../../../data/reducers/alerts/alert.reducer";
import Copy from "../../../../images/copyClip.svg";

const style = { height: "20px", width: "20px", marginLeft: "10px" };

const divStyle = {
  display: "flex",
  justifyContent: "space-between",
  cursor: "pointer",
};

const WexDetailsModal = ({ isVisible, handleClose }) => {
  const dispatch = useDispatch();
  const addedCard = useSelector((state) => state.cards).selectedCard;

  return (
    <>
      <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>CARDS</Modal.Title>
        </Modal.Header>
        <ModalBreadcrumb
          breadcrumbLinks={[
            {
              name: "Fund Sources",
              active: true,
            },
            {
              name: "Cards",
              active: true,
            },
            {
              name: "WEX Details",
              active: false,
            },
          ]}
        />
        <Modal.Body>
          <div className="container-fluid p-2 w-100 h-100">
            <Row>
              <p className="text-bold d-flex justify-content-between">
                Card Information
              </p>
              <Col sm={5}>
                <Row>
                  <Col>Name: </Col>
                  {addedCard?.firstName && addedCard?.lastName && (
                    <Col>
                      <div
                        title="copy to clipboard"
                        style={divStyle}
                        onClick={() => {
                          dispatch(successMessage("Copied !!"));
                          navigator.clipboard.writeText(
                            `${addedCard?.firstName} ${addedCard?.lastName}`
                          );
                        }}
                      >
                        {addedCard?.firstName} {addedCard?.lastName}
                        <img src={Copy} style={style} alt="copy clipboard" />
                      </div>
                    </Col>
                  )}
                </Row>
                <br />
                <Row>
                  <Col>CC Number: </Col>
                  {addedCard?.ccNumber && (
                    <Col>
                      <div
                        title="copy to clipboard"
                        style={divStyle}
                        onClick={() => {
                          dispatch(successMessage("Copied !!"));
                          navigator.clipboard.writeText(addedCard?.ccNumber);
                        }}
                      >
                        {addedCard?.ccNumber}
                        <img src={Copy} style={style} alt="copy clipboard" />
                      </div>
                    </Col>
                  )}
                </Row>
                <br />
                <Row>
                  <Col>Expiration: </Col>
                  {addedCard?.expDate && (
                    <Col>
                      <div
                        title="copy to clipboard"
                        style={divStyle}
                        onClick={() => {
                          dispatch(successMessage("Copied !!"));
                          navigator.clipboard.writeText(addedCard?.expDate);
                        }}
                      >
                        {addedCard?.expDate}
                        <img src={Copy} style={style} alt="copy clipboard" />
                      </div>
                    </Col>
                  )}
                </Row>
                <br />
              </Col>
              <Col sm={5} style={{ marginLeft: "auto" }}>
                <Row>
                  <Col>Email: </Col>
                  {addedCard?.emailId && (
                    <Col>
                      <div
                        title="copy to clipboard"
                        style={divStyle}
                        onClick={() => {
                          dispatch(successMessage("Copied !!"));
                          navigator.clipboard.writeText(addedCard?.emailId);
                        }}
                      >
                        {addedCard?.emailId}
                        <img src={Copy} style={style} alt="copy clipboard" />
                      </div>
                    </Col>
                  )}
                </Row>
                <br />
                <Row>
                  <Col>Security Code: </Col>
                  {addedCard?.securityCode && (
                    <Col>
                      <div
                        title="copy to clipboard"
                        style={divStyle}
                        onClick={() => {
                          dispatch(successMessage("Copied !!"));
                          navigator.clipboard.writeText(
                            addedCard?.securityCode
                          );
                        }}
                      >
                        {addedCard?.securityCode}
                        <img src={Copy} style={style} alt="copy clipboard" />
                      </div>
                    </Col>
                  )}
                </Row>
                <br />
                <Row>
                  <Col>Card Type: </Col>
                  {addedCard?.cardType && (
                    <Col>
                      <div
                        title="copy to clipboard"
                        style={divStyle}
                        onClick={() => {
                          dispatch(successMessage("Copied !!"));
                          navigator.clipboard.writeText(addedCard?.cardType);
                        }}
                      >
                        {addedCard?.cardType}
                        <img src={Copy} style={style} alt="copy clipboard" />
                      </div>
                    </Col>
                  )}
                </Row>
                <br />
              </Col>
            </Row>
            <hr />
            <Row>
              <p className="text-bold d-flex justify-content-between">
                Address Information
              </p>
              <Col sm={5}>
                <Row>
                  <Col>Address 1: </Col>
                  {addedCard?.address1 && (
                    <Col>
                      <div
                        title="copy to clipboard"
                        style={divStyle}
                        onClick={() => {
                          dispatch(successMessage("Copied !!"));
                          navigator.clipboard.writeText(addedCard?.address1);
                        }}
                      >
                        {addedCard?.address1}
                        <img src={Copy} style={style} alt="copy clipboard" />
                      </div>
                    </Col>
                  )}
                </Row>
                <br />
                <Row>
                  <Col>Address 2: </Col>
                  {addedCard?.address2 && (
                    <Col>
                      <div
                        title="copy to clipboard"
                        style={divStyle}
                        onClick={() => {
                          dispatch(successMessage("Copied !!"));
                          navigator.clipboard.writeText(addedCard?.address2);
                        }}
                      >
                        {addedCard?.address2}
                        <img src={Copy} style={style} alt="copy clipboard" />
                      </div>
                    </Col>
                  )}
                </Row>
                <br />
                <Row>
                  <Col>City: </Col>
                  {addedCard?.city && (
                    <Col>
                      <div
                        title="copy to clipboard"
                        style={divStyle}
                        onClick={() => {
                          dispatch(successMessage("Copied !!"));
                          navigator.clipboard.writeText(addedCard?.city);
                        }}
                      >
                        {addedCard?.city}
                        <img src={Copy} style={style} alt="copy clipboard" />
                      </div>
                    </Col>
                  )}
                </Row>
                <br />
              </Col>
              <Col sm={5} style={{ marginLeft: "auto" }}>
                <Row>
                  <Col>State: </Col>
                  {addedCard?.state && (
                    <Col>
                      <div
                        title="copy to clipboard"
                        style={divStyle}
                        onClick={() => {
                          dispatch(successMessage("Copied !!"));
                          navigator.clipboard.writeText(addedCard?.state);
                        }}
                      >
                        {addedCard?.state}
                        <img src={Copy} style={style} alt="copy clipboard" />
                      </div>
                    </Col>
                  )}
                </Row>
                <br />
                <Row>
                  <Col>Postal Code: </Col>
                  {addedCard?.postalCode && (
                    <Col>
                      <div
                        title="copy to clipboard"
                        style={divStyle}
                        onClick={() => {
                          dispatch(successMessage("Copied !!"));
                          navigator.clipboard.writeText(addedCard?.postalCode);
                        }}
                      >
                        {addedCard?.postalCode}
                        <img src={Copy} style={style} alt="copy clipboard" />
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <div className="d-flex justify-content-center my-4">
          <Button
            variant="primary"
            className="ss-modal-primary-btn mx-2"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default withLoader(WexDetailsModal);
