import React, { useEffect, useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { mixpanel_contants } from "../../../mixpanel";
import FilterPanel2024 from "../../../shared/components/panels/filter-panel/FilterPanel2024";
import { getvendorsListForDropdown } from "../../../data/reducers/vendors/vendors.reducer";
import {
  getAccountsList,
  getAccountSportList,
  getAccountTeamList,
} from "../../../data/reducers/accounts/accounts.reducers";
import { getSeasonsList } from "../../../data/reducers/season/season.reducer";
import { fetchVendorsListForDropdown } from "../../../data/reducers/vendors/cached-vendors.reducer";

const sourceArray = [
  { id: "", value: "Select" },
  { id: "AMEX", value: "AMEX" },
  { id: "Comdata", value: "Comdata" },
  { id: "Paypal", value: "Paypal" },
  { id: "Wex", value: "Wex" },
];

const TransactionsFilterPanel = ({
  filters,
  setFilters,
  resetHandler,
  error,
  cardError,
}) => {
  const dispatch = useDispatch();

  const { teamList, sportList, accountsList } = useSelector(
    (state) => state.accounts
  );
  const { vendorsListForDropdown } = useSelector(
    (state) => state.cachedVendors
  );
  const { seasonList } = useSelector((state) => state.season);

  useEffect(() => {
    if (!vendorsListForDropdown.length) {
      dispatch(fetchVendorsListForDropdown());
    }
  }, [vendorsListForDropdown]);

  useEffect(() => {
    if (!accountsList.length) {
      dispatch(getAccountsList());
    }
  }, [accountsList]);

  useEffect(() => {
    if (!sportList.length) {
      dispatch(getAccountSportList());
    }
  }, [sportList]);

  useEffect(() => {
    if (!teamList.length) {
      dispatch(getAccountTeamList());
    }
  }, [teamList]);

  useEffect(() => {
    if (!seasonList.length) {
      dispatch(getSeasonsList());
    }
  }, [seasonList]);

  const defaultValues = useMemo(() => {
    return Object.entries(filters).map(([key, value]) => ({ key, value }));
  }, [filters]);

  const customStyles = {
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };
  const handleNonCharacterInput = (e) => {
    const inputValue = e.currentTarget.value;
    const sanitizedValue = inputValue
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1');
    e.currentTarget.value = sanitizedValue;
  }

  return (
    <FilterPanel2024
      defaultOpen={true}
      defaultValues={defaultValues}
      filtersCount={
        Object.entries(filters).filter(
          ([key, value]) =>
            value !== "" &&
            key !== "isReviewed" &&
            key !== "StartDate" &&
            key !== "EndDate" &&
            key !== "searchString" &&
            key !== "PageNumber" &&
            key !== "PageSize" &&
            key !== "SortDir" &&
            key !== "SortField"
        ).length
      }
      dashboard={mixpanel_contants.TRANSACTIONS}
      searchPlaceholder="Search for Card Account, Vendor, Date, Amount..."
      onReset={() => {
        setFilters({
          ...filters,
          SourceType: "",
          MinAmount: "",
          MaxAmount: "",
          VendorId: "",
          Description: "",
          PurchaseOrdersId: "",
          AccountId: "",
          CcNumber: "",
          SourceId: "",
          CardName: "",
          TeamId: "",
          SportId: "",
          month: "",
          SeasonId: "",
          searchString: "",
        });
        if (error || cardError) {
          resetHandler();
        }
      }}
      onSearch={(data, filterPanelVisible) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          PageNumber: 1,
          SourceType: filterPanelVisible ? data.SourceType : "",
          MinAmount: filterPanelVisible ? data.MinAmount : "",
          MaxAmount: filterPanelVisible ? data.MaxAmount : "",
          VendorId: filterPanelVisible ? data.VendorId : "",
          Description: filterPanelVisible ? data.Description : "",
          PurchaseOrdersId: filterPanelVisible ? data.PurchaseOrdersId : "",
          AccountId: filterPanelVisible ? data.AccountId : "",
          CcNumber: filterPanelVisible ? data.CcNumber : "",
          SourceId: filterPanelVisible ? data.SourceId : "",
          CardName: filterPanelVisible ? data.CardName : "",
          TeamId: filterPanelVisible ? data.TeamId : "",
          SportId: filterPanelVisible ? data.SportId : "",
          month: filterPanelVisible ? data.month : "",
          SeasonId: filterPanelVisible ? data.SeasonId : "",
          searchString: !filterPanelVisible ? data.searchString : "",
        }));
      }}
      onSubmit={(e) => {}}
      renderAdvancedPanel={(register, handleSubmit, errors, control) => (
        <Row>
          <Col>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                Name on Card
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  placeholder="Name on Card"
                  {...register("CardName")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="pt-3">
              <Form.Label column sm="4">
                Source Type
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="SourceType"
                  control={control}
                  render={({ field }) => {
                    const value = sourceArray.find((s) => s.id == field.value);
                    return (
                      <div>
                        <Select
                          required
                          isInvalid={!!errors.SourceType}
                          value={value === undefined ? null : value}
                          onChange={(e) => {
                            field.onChange(e?.id);
                          }}
                          styles={customStyles}
                          options={sourceArray}
                          getOptionLabel={(option) => `${option.value}`}
                        />
                      </div>
                    );
                  }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="pt-3">
              <Form.Label column sm="4">
                Vendor
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="VendorId"
                  control={control}
                  render={({ field }) => {
                    const value = vendorsListForDropdown.find(
                      (v) => v.id == field.value
                    );
                    return (
                      <Select
                        value={value === undefined ? null : value}
                        onChange={(e) => field.onChange(e?.id)}
                        styles={customStyles}
                        options={vendorsListForDropdown}
                        getOptionLabel={(option) =>
                          `${option?.id}  ${option.name}`
                        }
                      />
                    );
                  }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="pt-3">
              <Form.Label column sm="4">
                Description
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  placeholder="Description"
                  {...register("Description")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="pt-3">
              <Form.Label column sm="4">
                Purchase Order
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  {...register("PurchaseOrdersId")}
                  placeholder="Purchase Order"
                />
              </Col>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                Last 4 Digits
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  placeholder="Last 4 Digits"
                  {...register("CcNumber")}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="pt-3">
              <Form.Label column sm="4">
                Account
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="AccountId"
                  control={control}
                  render={({ field }) => {
                    const value = accountsList.find(
                      (a) => a.accountId == field.value
                    );

                    return (
                      <div>
                        <Select
                          required
                          isInvalid={!!errors.AccountId}
                          value={value === undefined ? null : value}
                          onChange={(e) => field.onChange(e.accountId)}
                          styles={customStyles}
                          options={accountsList}
                          getOptionLabel={(option) =>
                            `${option.accountId}  ${option.name}`
                          }
                        />
                      </div>
                    );
                  }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="pt-3">
              <Form.Label column sm="4">
                Sport
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="SportId"
                  control={control}
                  render={({ field }) => {
                    const value = sportList.find((s) => s.id == field.value);
                    return (
                      <Select
                        value={value === undefined ? null : value}
                        onChange={(e) => field.onChange(e.id)}
                        styles={customStyles}
                        options={sportList}
                        getOptionLabel={(option) => `${option.sportName}`}
                      />
                    );
                  }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="pt-3">
              <Form.Label column sm="4">
                Min Amount
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="float"
                  onInput={(e) => handleNonCharacterInput(e)}
                  {...register("MinAmount")}
                  placeholder="Min Amount"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="pt-3">
              <Form.Label column sm="4">
                Max Amount
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="float"
                  {...register("MaxAmount")}
                  placeholder="Max Amount"
                  onInput={(e) => handleNonCharacterInput(e)}
                />
              </Col>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group as={Row} className="pt-3">
              <Form.Label column sm="4">
                Card Number
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  {...register("SourceId")}
                  placeholder="Card Number"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="pt-3">
              <Form.Label column sm="4">
                Team
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="TeamId"
                  control={control}
                  render={({ field }) => {
                    const value = teamList.find((t) => t.id == field.value);
                    return (
                      <Select
                        value={value === undefined ? null : value}
                        onChange={(e) => field.onChange(e.id)}
                        styles={customStyles}
                        options={teamList}
                        getOptionLabel={(option) => `${option.teamName}`}
                      />
                    );
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="pt-3">
              <Form.Label column sm="4">
                Season
              </Form.Label>
              <Col sm="8">
                <Controller
                  name="SeasonId"
                  control={control}
                  render={({ field }) => {
                    const value = seasonList.find((s) => s.id == field.value);
                    return (
                      <Select
                        value={value === undefined ? null : value}
                        onChange={(e) => field.onChange(e.id)}
                        styles={customStyles}
                        options={seasonList}
                        getOptionLabel={(option) => `${option.seasonName}`}
                      />
                    );
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      )}
    />
  );
};

export default TransactionsFilterPanel;
