import { cardServices } from "../../services/cards/cards.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import fileDownload from "js-file-download";

export const exportCSV = createAsyncThunk(
  "cards/exportCSV",
  async (thunkAPI) => {
    const response = await cardServices.exportCSVCards();
    if (response.isSuccessful === true) {
      fileDownload(response.data, "CardsTransactions.csv")
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const createCard = createAsyncThunk(
  "cards/CreateCard",
  async (payload, thunkAPI) => {
    const response = await cardServices.createCard(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const createCardWEX = createAsyncThunk(
  "cards/CreateCardWEX",
  async (payload, thunkAPI) => {
    const response = await cardServices.createCardWEX(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const updateCard = createAsyncThunk(
  "cards/updateCard",
  async (payload, thunkAPI) => {
    const response = await cardServices.updateCard(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const updateCardWEX = createAsyncThunk(
  "cards/updateCardWEX",
  async (payload, thunkAPI) => {
    const response = await cardServices.updateCardWEX(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const deleteCard = createAsyncThunk(
  "cards/deleteCard",
  async (payload, thunkAPI) => {
    const response = await cardServices.deleteCard(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const deleteCardWEX = createAsyncThunk(
  "cards/deleteCardWEX",
  async (payload, thunkAPI) => {
    const response = await cardServices.deleteCardWEX(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAllCards = createAsyncThunk(
  "cards/getAllCards",
  async (payload, thunkAPI) => {
    const response = await cardServices.getAllCards(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getTopCards = createAsyncThunk(
  "cards/getTopCards",
  async (payload, thunkAPI) => {
    const response = await cardServices.getTopCards(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAllCardsTransactions = createAsyncThunk(
  "cards/getAllCardsTransactions",
  async (payload, thunkAPI) => {
    const response = await cardServices.getAllCardsTransactions(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getTransactionById = createAsyncThunk(
  "cards/getTransactionById",
  async (payload, thunkAPI) => {
    const response = await cardServices.getTransactionById(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getCardById = createAsyncThunk(
  "cards/getCardById",
  async (payload, thunkAPI) => {
    const response = await cardServices.getCardById(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const filterCard = createAsyncThunk(
  "cards/filterCard",
  async (payload, thunkAPI) => {
    const response = await cardServices.filterCard(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getCardTypeDetails =  createAsyncThunk(
  "cards/getCardTypeDetails",
  async (payload, thunkAPI) => {
    const response = await cardServices.getCardTypeDetails(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

const cardSlice = createSlice({
  name: "cards",
  initialState: {
    Cards: [],
    cardsTransactions: [],
    selectedCard: {},
    paging: {},
    cardById: {},
    cardTypeLookup:[]
  },
  extraReducers: {
    [getCardTypeDetails.fulfilled]: (state, action) => {
      state.cardTypeLookup = action.payload
    },
    [getAllCards.fulfilled]: (state, action) => {
      state.Cards = action.payload.data;
      state.paging = action.payload.paging;
    },
    [getTopCards.fulfilled]: (state, action) => {
      state.cardById = action.payload[0];
    },
    [getAllCardsTransactions.fulfilled]: (state, action) => {
      state.cardsTransactions = action.payload.data;
      state.paging = action.payload.paging;
    },
    [getTransactionById.fulfilled]: (state, action) => {
      state.cardsTransactions = action.payload.data;
      state.paging = action.payload.paging;
    },
    [filterCard.fulfilled]: (state, action) => {
      state.Cards = action.payload.data;
      state.paging = action.payload.paging;
    },
    [getCardById.fulfilled]: (state, action) => {
      state.selectedCard = action.payload;
    },
    [createCard.fulfilled]: (state, action) => {
      state.Cards.push(action.payload);
    },
    [updateCard.fulfilled]: (state, action) => {
      const index = state.Cards.findIndex((po) => po.id == action.payload.id);
      state.Cards[index] = action.payload;
      state.selectedCard = action.payload;
    },
    [deleteCard.fulfilled]: (state, action) => {
      state.Cards = [
        ...state.Cards.filter(
          (po) => po.id !== action.payload.id
        ),
      ];
    },
  },
});

export const { removeSelected } = cardSlice.actions;

export default cardSlice.reducer;
