import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { emailServices } from "../../services/email/email.service";
import fileDownload from "js-file-download";

export const getUploadEmails = createAsyncThunk("upload_email/getUploadEmails", async (payload, thunkAPI) => {
    const response = await emailServices.getUploadEmails(payload);
    if (response.isSuccessful === true) {
        return response;
    } else {
        thunkAPI.rejectWithValue(response.message);
        throw new Error(response.message);
    }
})

export const downloadEmailTemplate = createAsyncThunk("upload_email/downloadEmailTemplate", async (payload, thunkAPI) => {
    const response = await emailServices.downloadUploadEmailTemplate();
    if (response.isSuccessful === true) {
        fileDownload(response.data?.data, "UploadEmailTemplate.csv");
    } else {
        thunkAPI.rejectWithValue(response.message);
        throw new Error(response.message);
    }
})

export const uploadEmailCsvFiles = createAsyncThunk(
    "upload_email/uploadEmailCsvFiles",
    async (paylod, thunkAPI) => {
        const response = await emailServices.uploadEmailCsvFiles(paylod);
        if (response.isSuccessful === true) {
            return response.data;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const createUploadEmail = createAsyncThunk(
    "upload_email/createUploadEmail",
    async (paylod, thunkAPI) => {
        const response = await emailServices.createUploadEmail(paylod);
        if (response.isSuccessful === true) {
            return response.data;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

const uploadEmailReducer = createSlice({
    name: "upload_email",
    initialState: {
        uploadEmailArray: null,
        paging: null
    },
    extraReducers: {
        [getUploadEmails.fulfilled]: (state, action) => {
            state.uploadEmailArray = action.payload.data;
            state.paging = action.payload.paging
        }
    }
})

export default uploadEmailReducer.reducer;