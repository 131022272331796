import { buildUrlWithParams } from "../api";
import {
  api,
  getAuthHeaders,
  get,
  post,
  update,
  del,
  getCSV,
  getPDF,
} from "../services.common";

const getAllAccounts = async (data) => {
  const sortDir = data.sortDir == false ? "Descending" : "Ascending";
  const url = `${api}Accounts/getall?PageNumber=${data.pageNumber}&PageSize=${
    data.pageSize
  }&SortDir=${sortDir}&SortField=${data.sortField || ""}&categoryId=${
    data?.categoryId || 0
  }`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getAccountsList = async (getByVendors = false) => {
  let url = "";
  if (getByVendors) {
    url = `${api}Accounts/getlist?byVendors=true`;
  } else {
    url = `${api}Accounts/getlist`;
  }
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getCardsList = async () => {
  const url = `${api}CardInfo/getlist`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getAccountById = async (id) => {
  const url = `${api}Accounts/get/${id}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const filterAccount = async (data) => {
  const sortDir = data.sortDir == false ? "Descending" : "Ascending";
  const url = `${api}Accounts/search?searchString=${
    data.searchText || ""
  }&SportId=${data.sportId}&Address=${data.Addresses}&Email=${
    data.email
  }&AccountId=${data.accountId || ""}&Name=${data.name}&TeamId=${
    data.teamId
  }&VendorId=${data.vendorId}&PageNumber=${data.pageNumber}&PageSize=${
    data.pageSize
  }&SortDir=${sortDir}&SortField=${data.sortField || ""}&categoryId=${
    data?.categoryId
  }`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const exportCSVAccount = async (data) => {
  const url = `${api}Accounts/exportcsv?categoryId=${data.categoryId || 0}`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const exportPdfAccount = async (data) => {
  const url = `${api}Accounts/exportpdf?categoryId=${data.categoryId || 0}`;
  const authHeader = getAuthHeaders();
  return await getPDF(url, { ...authHeader }, "Accounts.pdf");
};

const exportSelectedAsCSVAccount = async (data) => {
  const authHeader = getAuthHeaders();
  return await getCSV(
    buildUrlWithParams(`${api}Accounts/searchexportcsv`, data),
    { ...authHeader }
  );
};

const exportSelectedAsPdfAccount = async (data) => {
  const authHeader = getAuthHeaders();
  return await getPDF(
    buildUrlWithParams(`${api}Accounts/searchexportpdf`, data),
    { ...authHeader },
    "AccountsFilter.pdf"
  );
};

const createAccount = async (formData) => {
  const url = `${api}Accounts/create`;
  const authHeader = getAuthHeaders();
  return await post(url, formData, { ...authHeader });
};

const updateAccount = async (data) => {
  const url = `${api}Accounts/update/${data.id}`;
  const authHeader = getAuthHeaders();
  return await update(url, data, { ...authHeader });
};

const deleteAccount = async (id) => {
  const url = `${api}Accounts/delete/${id}`;
  const authHeader = getAuthHeaders();
  return await del(url, { ...authHeader });
};

const getAccountIdDetails = async (accountId) => {
  const url = `${api}Accounts/getaccountiddetails/${accountId}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};
const getAccountSports = async () => {
  const url = `${api}Accounts/sports`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getAccountsCards = async (data) => {
  const url = `${api}Accountscards/get?searchString=${
    data.searchText || ""
  }&SportId=${data.sportId || ""}&Address=${data.Addresses || ""}&Email=${
    data.email || ""
  }&AccountId=${data.accountId || ""}&Name=${data.name || ""}&TeamId=${
    data.teamId || ""
  }&VendorId=${data.vendorId || ""}&categoryId=${data?.categoryId}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getAccountTeams = async () => {
  const url = `${api}Accounts/teams`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const lookupAccounts = async () => {
  const url = `${api}Accounts/lookupAccounts`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getCardsByAccountId = async (data) => {
  const url = `${api}AccountsCards/getbyaccountid/${data}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getAccountCardsInfo = async (data) => {
  const url = `${api}cardinfo/getaccountcardsinfo/${data.accountId}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getPaymentPlansByAccount = async (data) => {
  const url = `${api}Accounts/paymentplansbyaccount?SortField=${
    data.sortField || ""
  }&PageNumber=${data.pageNumber || 1}&PageSize=${
    data.pageSize || 0
  }&accountId=${data.id}&SortDir=${
    data.sortDir == false ? "Descending" : "Ascending"
  }`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const accountServices = {
  createAccount,
  updateAccount,
  deleteAccount,
  getCardsByAccountId,
  exportCSVAccount,
  filterAccount,
  getAllAccounts,
  getAccountById,
  getAccountsCards,
  getAccountIdDetails,
  getAccountSports,
  getAccountTeams,
  getAccountsList,
  getCardsList,
  exportSelectedAsCSVAccount,
  exportPdfAccount,
  exportSelectedAsPdfAccount,
  lookupAccounts,
  getAccountCardsInfo,
  getPaymentPlansByAccount,
};
