import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { vendorRulesServices } from "../../services/vendors/vendor-rules.service";
import fileDownload from "js-file-download";

export const exportCSVVendorRules = createAsyncThunk(
  "vendorRules/exportCSVVendorRules",
  async (payload, thunkAPI) => {
    const response = await vendorRulesServices.exportCSVVendorRules(payload);
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "VendorRules.csv")
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportPdfVendorRules = createAsyncThunk(
  "vendorRules/exportPdfVendorRules",
  async (payload, thunkAPI) => {
    const response = await vendorRulesServices.exportPdfVendorRules(payload);
    // if (response.isSuccessful === true) {
    //   fileDownload(response.data, "VendorRules.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

export const createVendorRules = createAsyncThunk(
  "vendorRules/createVendorRules",
  async (payload, thunkAPI) => {
    const response = await vendorRulesServices.createVendorRule(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);
export const getAllVendorRules = createAsyncThunk(
  "vendorRules/getAllVendorRules",
  async (payload, thunkAPI) => {
    const response = await vendorRulesServices.getAllVendorRules(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);
export const updateVendorRules = createAsyncThunk(
  "vendorRules/updateVendorRules",
  async (payload, thunkAPI) => {
    const response = await vendorRulesServices.updateVendorRule(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);
export const deleteVendorRules = createAsyncThunk(
  "vendorRules/deleteVendorRules",
  async (payload, thunkAPI) => {
    const response = await vendorRulesServices.deleteVendorRule(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getVendorRules = createAsyncThunk(
  "vendorRules/getVendorRules",
  async (payload, thunkAPI) => {
    const response = await vendorRulesServices.getAllVendorRules(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

const vendorRulesSlice = createSlice({
  name: "vendorRules",
  initialState: {
    vendorsRules: [],
    paging: {},
    selectedVendorRule: {}
  },
  reducers: {
    removeSelected: (state) => {
      state.selectedVendorRule = {};
    },
  },
  extraReducers: {
    [getVendorRules.fulfilled]: (state, action) => {
      state.vendorsRules = action.payload.data;
      state.paging = action.payload.paging;
    },
    [createVendorRules.fulfilled]: (state, action) => {
      state.vendorsRules.push(action.payload);
    },
    [updateVendorRules.fulfilled]: (state, action) => {
      const index = state.vendorsRules.findIndex((v) => v.id == action.payload.id);
      state.vendorsRules[index] = action.payload;
      state.selectedVendorRule = action.payload;
    },
    [deleteVendorRules.fulfilled]: (state, action) => {
      state.vendorsRules = [
        ...state.vendorsRules.filter(
          (vendor) => vendor.id !== action.payload.id
        ),
      ];
    },
  },
});
export const { removeSelected } = vendorRulesSlice.actions;
export default vendorRulesSlice.reducer;
