import { Route, Switch } from "react-router-dom";
import PurchaseOrderDashboard from "../../../pages/purchaseOrders/PurchaseOrderDashboard";
import SinglePurchaseOrderDetail from "../../../pages/purchaseOrders/SinglePurchaseOrderDetails";

const PORoutes = () => {
  return (
    <Switch>
      <Route path="/purchases" exact>
        <PurchaseOrderDashboard />
      </Route>
      <Route path="/purchases/:id" exact>
        <SinglePurchaseOrderDetail />
      </Route>
    </Switch>
  );
};

export default PORoutes;
