import React, { useState } from "react";
import "./login.scss";
import { useForm } from "react-hook-form";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { withLoader } from '../../utils/hoc/withLoader';
import { useDispatch } from 'react-redux';
import { errorMessage, successMessage } from '../../data/reducers/alerts/alert.reducer';
import { resetPassword } from '../../data/reducers/authentication/auth.reducer';
import { useHistory } from "react-router-dom"
import ChangePassword from "./ChangePassword";
function ForgotPassword({ setBusy }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [emailSent, setEmailSent] = useState(false)
  const [email, setEmail] = useState(undefined)

  const mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setBusy(true);
      const res = await dispatch(resetPassword(data))
      if (res.payload?.status == 200) {
        setEmailSent(true)
        setEmail(data.login)
      } else {
        dispatch(errorMessage("Error sending email. Please check your email and try again"))
      }
    } catch (e) {
      console.log(e)
    } finally {
      setBusy(false);
    }
  };

  return (
    <React.Fragment>
      <Container className="loginPage vh-100" fluid>
        <Row>
          <Col className="d-flex flex-column justify-content-center align-items-center">
            <div className="password-forgot-cont">
              <h3>
                Password Reset
              </h3>
              <br />
              <div style={{ color: "#A7A7A7" }}>
                Enter your e-mail address and we’ll send a temporary password.
              </div>
              <div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group className="mt-3">
                    <Form.Control
                      isInvalid={!!errors.login}
                      placeholder="xecx@gmail.com"
                      type="email"
                      disabled={emailSent}
                      {...register("login", { required: true, pattern: mailRegex })}
                    />
                    <Form.Control.Feedback type="invalid">Please enter valid E-mail address!</Form.Control.Feedback>
                  </Form.Group>
                  {!emailSent && <div className="text-center">
                    <Button
                      type="submit"
                      variant="primary"
                      className="mt-3 w-50">
                      Submit
                    </Button>
                  </div>}
                </Form>
              </div>
            </div>
          </Col>
        </Row>

        {emailSent &&
          <>
            <p className="text-center fs-14 " style={{ color: "#0241A0" }}>Password has been sent successfully!</p>
            <hr size="3" />
            <ChangePassword emailSent={emailSent} setEmailSent={setEmailSent} email={email} setBusy={setBusy} />
          </>
        }

      </Container>

    </React.Fragment>
  );
}


export default withLoader(ForgotPassword)