import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { poHistoryServices } from "../../services/purchaseOrders/po-history.service";

export const getPOHistory = createAsyncThunk("poHistory/getPOHistory", async (payload, thunkAPI) => {
    const response = await poHistoryServices.getPurchaseOrderHistory(payload);
    if (response.isSuccessful === true) {
        return response;
    } else {
        thunkAPI.rejectWithValue(response.message);
        throw new Error(response.message);
    }
});

const poHistory = createSlice({
    name: "poHistory",
    initialState: {
        historyList: null
    },
    extraReducers: {
        [getPOHistory.fulfilled]: (state, action) => {
            state.historyList = action.payload.data
        },
    }
});

export default poHistory.reducer;