import { errorMessage } from "../../../data/reducers/alerts/alert.reducer";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Nav,
  Row,
  Container,
} from "react-bootstrap";

import AddEditPlaidModal from "./modals/AddEditPlaid";
import { withLoader } from "../../../utils/hoc/withLoader";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

//components
import { formatMoney } from "../../../utils/formatter/currencyFormatter";
import QuickTixTable from "../../../shared/components/table/QuickTixTable";
import TableRowCard from "../../../shared/components/table/TableRowCard";
import { columnError } from "../../../shared/components/table/data/error";
import DeleteConfirmationModal from "../../../shared/components/modals/DeleteConfirmationModal";

// images
import Export from "../../../images/export.svg";
import Add from "../../../images/add.svg";
import DeleteIcon from "../../../images/delete.svg";
import EditIcon from "../../../images/edit.svg";

import "../../../shared/styles/ModalStyles.scss";
import { Link } from "react-router-dom";
import {
  getAllPlaid,
  updatePlaid,
  createPlaid,
  deletePlaid,
} from "../../../data/reducers/plaid/plaid.reducer";
import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Mixpanel,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../../mixpanel";

const PlaidDashboard = ({ setBusy }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  //---------------------------------------------------------------------------------------------------------------//
  const queryParameters = new URLSearchParams(window.location.search);
  const page = queryParameters.get("page");

  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.PLAID_ACCOUNTS,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.PLAID_ACCOUNTS,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);
  //-------------------- Param navigation ---------------//

  useEffect(() => {
    setpageNumber(page);
  }, [page, location]);

  //-------------- on page size change --------------//
  const navigateToMainPage = () => {
    history.push(`/fundSources/plaid-accounts?page=1`);
  };

  const previousPage = () => {
    if (Number(pageNumber) > 1) {
      history.push(
        `/fundSources/plaid-accounts?page=${Number(pageNumber) - 1}`
      );
      setpageNumber(Number(pageNumber - 1));
    }
  };

  const nextPage = () => {
    history.push(`/fundSources/plaid-accounts?page=${Number(pageNumber) + 1}`);
    setpageNumber(Number(pageNumber + 1));
  };

  //---------------------------------------------------------------------------------------------------------------//

  const [addAccountModalVisible, setAddEditAccountModalVisibility] =
    useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisibility] =
    useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(undefined);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [pageSize, setpageSize] = useState(40);
  const [pageNumber, setpageNumber] = useState(1);
  const [sortField, setSortField] = useState(null);
  const [sortDir, setSortDir] = useState(true);
  const [searchData, setsearchData] = useState(null);

  const fetchPlaidData = async () => {
    try {
      setBusy(true);
      const data = {
        pageNumber: page,
        pageSize,
        sortDir,
        sortField,
      };
      const response = await dispatch(getAllPlaid(data));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };
  useEffect(() => {
    fetchPlaidData();
  }, [pageSize, pageNumber, sortField, sortDir]);

  const list = useSelector((state) => state.plaid).plaid;
  const paging = useSelector((state) => state.plaid).paging;

  // delete account
  const onDeletePlaid = async (id) => {
    try {
      setBusy(true);
      const response = await dispatch(deletePlaid(id));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        // close modal
        fetchPlaidData();
        setDeleteConfirmationVisibility(false);
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  // // add or update account details
  const onEntrySubmitted = async (data, accessToken) => {
    try {
      setBusy(true);
      const action = data?.id
        ? updatePlaid({ ...data, accessToken })
        : createPlaid({ ...data, accessToken });
      const response = await dispatch(action);
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        setAddEditAccountModalVisibility(false);
        fetchPlaidData();
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  const plaidCol = [
    {
      columnName: "Name",
      columnKey: "name",
    },
    {
      columnName: "Institution",
      columnKey: "institution",
    },
    {
      columnName: (
        <>
          Item <br /> ID
        </>
      ),
      render: (data) => <>{data?.itemId}</>,
    },
    {
      columnName: "Actions",
      render: (data) => (
        <small className="table-row-card-actions d-flex">
          <Button
            variant="link"
            className="table-action-button green_bg"
            onClick={() => {
              setSelectedRecordId(data.accountId);
              setSelectedRecord(data);
              setAddEditAccountModalVisibility(true);
            }}
          >
            <img src={EditIcon} alt=" " />
          </Button>
          <Button
            variant="link"
            className="table-action-button"
            onClick={() => {
              setSelectedRecordId(data.id);
              setSelectedRecord(data);
              setDeleteConfirmationVisibility(true);
            }}
          >
            <img src={DeleteIcon} alt=" " />
          </Button>
        </small>
      ),
    },
  ];

  const headOptions = () => {
    return (
      <>
        <div
          onClick={() => {
            Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
              page: mixpanel_contants.PLAID_ACCOUNTS,
            });
          }}
          className="d-flex align-items-center justify-content-end"
        >
          <small>Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue="40"
              onChange={(e) => {
                setpageSize(e.target.value);
                navigateToMainPage();
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
          <Button
            onClick={async (e) => {
              e.currentTarget.blur();
              await setSelectedRecord(undefined);
              await setSelectedRecordId(undefined);
              await setAddEditAccountModalVisibility(true);
            }}
            variant="primary"
            className="ms-2 ss-light-button"
          >
            <img src={Add} alt=" " />
            <span>Add</span>
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className="container-fluid">
      <ModalBreadcrumb
        breadcrumbLinks={[
          {
            name: "Fund Sources",
            active: true,
          },
          {
            name: "Plaid Accounts",
            active: false,
          },
        ]}
      />
      {/* Data Table */}
      <QuickTixTable
        previousPage={previousPage}
        nextPage={nextPage}
        paramerterNavigation={true}
        headerName="Plaid Account"
        paging={paging}
        columns={plaidCol}
        data={list || []}
        headOptions={headOptions}
        setpageNumber={setpageNumber}
        sortDir={sortDir}
        setSortDir={setSortDir}
        sortField={sortField}
        setSortField={setSortField}
        renderRow={(rowData, index) => (
          <TableRowCard
            key={rowData.id}
            columns={rowData.nodata ? columnError : plaidCol}
            rowData={rowData}
          />
        )}
      />

      {/* Modal Popups */}
      {addAccountModalVisible && (
        <AddEditPlaidModal
          isVisible={addAccountModalVisible}
          handleClose={() => setAddEditAccountModalVisibility(false)}
          onSubmit={onEntrySubmitted}
          selectedRecord={selectedRecord}
        />
      )}

      <DeleteConfirmationModal
        isVisible={deleteConfirmationVisible}
        handleClose={() => setDeleteConfirmationVisibility(false)}
        onConfirmation={() => onDeletePlaid(selectedRecordId)}
      />
    </div>
  );
};

export default withLoader(PlaidDashboard);
