import {
  api,
  getAuthHeaders,
  get,
  post,
  update,
  del,
  getCSV,
} from "../services.common";

const getAllSkybox = async (data) => {
  const sortDir = data.sortDir == false ? "Descending" : "Ascending";
  const url = `${api}SkyboxPurchase/getall?PageNumber=${data.pageNumber
    }&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""
    }&month=${data?.month || ""}&StartDate=${data?.startDate || ""}&EndDate=${data?.endDate || ""
    }`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getListSkybox = async (data) => {
  if (data) {
    const url = `${api}SkyboxPurchase/getlist?query=${data}`;
    const authHeader = getAuthHeaders();
    return await get(url, { ...authHeader });
  }
};

const getSyncSkybox = async () => {
  const url = `${api}SyncData/syncskyboxdata`;
  const authHeader = getAuthHeaders();
  return await post(url, null, { ...authHeader });
};

const filterSkybox = async (data) => {
  const sortDir = data.sortDir == false ? "Descending" : "Ascending";
  const url = `${api}SkyboxPurchase/search?searchString=${data.searchText || ""
    }&StartDate=${data?.startDate || ""}&EndDate=${data?.endDate || ""
    }&VendorId=${data.vendorId || ""}&PageNumber=${data.pageNumber}&PageSize=${data.pageSize
    }&MinAmount=${data.minAmount || 0}&MaxAmount=${data.maxAmount || 0
    }&SortDir=${sortDir}&SortField=${data.sortField || ""}&month=${data?.month || ""
    }`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getPurchaseOrderRefs = async (id) => {
  const url = `${api}PurchaseOrders/getrefs/${id}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const SkyboxServices = {
  filterSkybox,
  getAllSkybox,
  getSyncSkybox,
  getListSkybox,
  getPurchaseOrderRefs,
};
