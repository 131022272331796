import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import DeleteIcon from "../../../images/delete.svg";
import { useEffect, useState } from "react";
import Add from "../../../images/add.svg";
import { useForm } from "react-hook-form";
import {
  formatDate,
  formatPlanDate,
  inputDate,
} from "../../../utils/formatter/dateFormatter";
import "./ButtonStyles.scss";
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Container,
  InputGroup,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { successMessage } from "../../../data/reducers/alerts/alert.reducer";
import Loading from "../../../shared/components/loader/Loader";

const PaymentPlanModal = ({
  isVisible,
  handleClose,
  onSubmit,
  selectedRecord,
  loading,
}) => {
  // setup react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm();

  //If selected Record Exists aka Edit else Add
  const [isEdit, setIsEdit] = useState(false);
  const [isPaymentPlanVisible, setPaymentPlanVisible] = useState(false);
  const [totalAmount, setTotalAmount] = useState("");
  const [period, setPeriod] = useState(0);
  const [array, setArray] = useState([]);

  useEffect(() => {
    for (let i = 0; i < array.length; i++) {
      setValue(`amountPerInterval${i}`, array[i]?.amount);
      setValue(`dateInterval${i}`, array[i]?.date?.substr(0, 10));
      setValue("period", array.length);
    }
    calculateTotal();
  }, [array]);

  // reset form on visibility toggle
  useEffect(() => {
    reset();
    setPaymentPlanVisible(false);
  }, [isVisible]);

  // update values in form if selected record is changed
  useEffect(() => {
    if (selectedRecord?.length > 0) {
      // as soon as selected record is changed, set values in modal popup
      setIsEdit(true);
      setPeriod(selectedRecord.length);
      setValue("installment", 0);
      handleSelectedRecord();
      setPaymentPlanVisible(true);
      calculateTotal();
    } else {
      // if selected record is undefined, reset
      setIsEdit(false);
      setPaymentPlanVisible(false);
      reset();
    }
  }, [selectedRecord, isVisible]);

  const onDetailSubmit = (data) => {
    // Date
    let startDate;
    // Amount
    let sum = 0;
    let arr = [];
    startDate = data?.startDate;
    for (let i = 0; i < data?.period; i++) {
      if (i == 0) {
        startDate = new Date(data?.startDate);
        startDate = startDate.toLocaleDateString("en-US", {
          timezone: "America/New_York",
        });
      } else {
        startDate = new Date(startDate);
        if (data?.paymentInterval === "start") {
          startDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth() + 1,
            1
          );
        } else if (data?.paymentInterval === "mid") {
          startDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth() + 1,
            15
          );
        } else if (data?.paymentInterval === "end") {
          startDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth() + 2,
            0
          );
          startDate = new Date(
            startDate.getTime() - startDate.getTimezoneOffset() * 60000
          );
        }
      }
      arr.push({
        amount: parseFloat(data?.installment),
        date: formatPlanDate(startDate),
      });
      setValue(`amountPerInterval${i}`, data?.installment);
      setValue(`dateInterval${i}`, startDate);
    }

    setArray([...arr]);
    setTotalAmount(parseFloat(data?.amount) - sum);
    setPaymentPlanVisible(true);
  };

  // When any data in the form changes
  const calculateTotal = () => {
    let sum = 0;
    for (let j = 0; j < parseFloat(array.length); j++) {
      if (getValues(`amountPerInterval${j}`) == "") sum += 0;
      else sum += parseFloat(getValues(`amountPerInterval${j}`));
    }
    if (isNaN(sum)) setValue("amount", "");
    else setValue("amount", sum);
  };

  const onAdd = () => {
    let arr = array;
    let startDate;
    const paymentInterval = getValues("paymentInterval");
    startDate = new Date(getValues(`dateInterval${array.length - 1}`));
    if (paymentInterval === "start") {
      startDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        1
      );
    } else if (paymentInterval === "mid") {
      startDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        15
      );
    } else if (paymentInterval === "end") {
      startDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 2,
        0
      );
      startDate = new Date(
        startDate.getTime() - startDate.getTimezoneOffset() * 60000
      );
    }
    setValue(`dateInterval${array.length}`, formatPlanDate(startDate));
    setValue(`amountPerInterval${array.length}`, 0);
    calculateTotal();
    arr.push({
      amount: parseFloat(0),
      date: formatPlanDate(startDate),
    });
    setArray([...arr]);
  };
  const onDelete = (i) => {
    let arr = array;
    if (i !== -1) arr.splice(i, 1);
    setArray([...arr]);
    // calculateTotal()
  };
  const handleSelectedRecord = () => {
    setValue("period", selectedRecord.length);
    let arr = [];
    for (let i = 0; i < selectedRecord.length; i++) {
      arr.push({
        amount: selectedRecord[i]?.amount,
        date: selectedRecord[i].paymentDate,
      });
    }
    setArray([...arr]);
  };
  const dispatch = useDispatch();
  const validateSubmit = async (data) => {
    let plans = [];
    let sum = 0;
    for (let i = 0; i < parseInt(data?.period); i++) {
      let date = data["dateInterval" + i];
      let amount = data["amountPerInterval" + i];
      if (amount != "" && amount != 0) {
        amount = parseFloat(amount);
        sum += amount;
        plans.push({
          amount,
          paymentDate: date,
        });
      }
    }
    let dates = plans.map((plan) => plan.paymentDate);
    plans.sort((a, b) => a.paymentDate.localeCompare(b.paymentDate));
    let changed = false;
    if (plans?.length === selectedRecord?.length) {
      dates.map((date) => {
        let req = plans?.filter((plan) => date == plan?.paymentDate);
        let sel = selectedRecord.filter(
          (plan) => date == plan?.paymentDate?.split("T")[0]
        );
        if (req.length > 0 && sel.length > 0) {
          if (req[0]?.amount != sel[0]?.amount) {
            changed = true;
          }
        } else {
          changed = true;
        }
      });
    } else {
      changed = true;
    }
    if (changed) onSubmit(data);
    else {
      handleClose();
      dispatch(successMessage("Payment Plan Updated Successfully"));
    }
  };

  return (
    <>
      <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>PAYMENT PLANS</Modal.Title>
        </Modal.Header>
        <ModalBreadcrumb
          breadcrumbLinks={[
            {
              to: "/purchases",
              name: "Purchase Order",
              active: false,
            },
            {
              name: isEdit ? "Edit Payment Plan" : "New Payment Plan",
              active: true,
            },
          ]}
        />
        <Modal.Body>
          <Container className="p-2 w-100 h-100" fluid>
            <Form onSubmit={handleSubmit(onDetailSubmit)}>
              <Row>
                <p className="title-accounts">Create Payment Plan</p>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Amount
                    </Form.Label>
                    <Col sm="7">
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="number"
                          step="0.0001"
                          {...register("amount")}
                          placeholder="0.00"
                        />
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="5">
                      Start Date
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        type="date"
                        defaultValue={inputDate()}
                        isInvalid={!!errors.startDate}
                        {...register("startDate", { required: true })}
                      />
                      <Form.Control.Feedback type="invalid">
                        Start Date is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Installment Amount
                    </Form.Label>
                    <Col sm="7">
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="number"
                          step="0.0001"
                          isInvalid={!!errors.installment}
                          {...register("installment", { required: !isEdit })}
                          placeholder="12"
                        />
                        <Form.Control.Feedback type="invalid">
                          Installment Amount is required.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="5">
                      Installment Timeframe
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        isInvalid={!!errors.paymentInterval}
                        {...register("paymentInterval", { required: true })}
                        as="select"
                      >
                        <option value="start">Start of Month</option>
                        <option value="mid">Mid Month</option>
                        <option value="end">End of Month</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Period
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        type="number"
                        min="1"
                        isInvalid={!!errors.period}
                        {...register("period", { required: true })}
                        placeholder="12"
                      />
                      <Form.Control.Feedback type="invalid">
                        Period is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>

                <Col></Col>
              </Row>
              <Row className="justify-content-center align-items-center mt-4">
                <Button
                  type="submit"
                  variant="primary"
                  className="ss-modal-primary-btn mx-2 w-25"
                >
                  Create
                </Button>
              </Row>
            </Form>
            {isPaymentPlanVisible && (
              <>
                <hr />
                <p className="title-accounts">Payment Plan</p>
                <Form onSubmit={handleSubmit(validateSubmit)}>
                  <div className="over">
                    <Row>
                      <Col sm={5}>
                        Total Installments&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <strong>{array.length}</strong>
                      </Col>
                      <Col sm={5}></Col>
                      <Col sm={2} className="justify-content-end">
                        <Button
                          onClick={onAdd}
                          variant="primary"
                          className="ms-2 ss-light-button"
                        >
                          <img src={Add} alt="Add" />
                          <span>Add</span>
                        </Button>
                      </Col>
                    </Row>
                    {isPaymentPlanVisible &&
                      array.map((x, i) => (
                        <Row
                          key={i}
                          className="justify-content-between align-items-center mt-4"
                        >
                          <Col sm={5}>
                            <Form.Control
                              {...register(`dateInterval${i}`)}
                              onChange={(e) => {
                                setValue(`dateInterval${i}`, e.target.value);
                                let arr = [];
                                arr = array;
                                arr[i].date = e.target.value;
                                setArray([...array]);
                              }}
                              type="date"
                            />
                          </Col>
                          <Col sm={4}>
                            <Form.Control
                              {...register(`amountPerInterval${i}`)}
                              key={i}
                              onChange={(e) => {
                                if (
                                  (e.target.value.includes(".") &&
                                    e.target.value.slice(-1) == 0) ||
                                  e.target.value == 0
                                ) {
                                  setValue(
                                    `amountPerInterval${i}`,
                                    e.target.value
                                  );
                                } else if (e.target.value.slice(-1) !== ".") {
                                  let arr = [];
                                  arr = array;
                                  arr[i].amount =
                                    parseFloat(e.target.value) || "";
                                  setArray([...array]);
                                  calculateTotal();
                                }
                              }}
                              type="number"
                              step="0.0001"
                            />
                          </Col>
                          <Col sm={2}>
                            <Button
                              onClick={() => onDelete(i)}
                              variant="link"
                              className="leButton"
                            >
                              <img src={DeleteIcon} alt="Delete" />
                            </Button>
                            <Form.Control
                              className="d-none"
                              {...register(`id${i}`)}
                            />
                          </Col>
                        </Row>
                      ))}
                  </div>
                  <div className="d-flex justify-content-center my-4">
                    <Button
                      type="submit"
                      variant="primary"
                      className="ss-modal-primary-btn mx-2"
                      disabled={loading}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {isEdit ? "Save" : "Add"}
                        {loading && <Loading style={{ marginLeft: "8px" }} />}
                      </div>
                    </Button>
                    <Button
                      type={!isEdit ? "reset" : ""}
                      variant="light"
                      onClick={() => {
                        if (selectedRecord.length > 0) handleSelectedRecord();
                        reset();
                      }}
                      className="ss-modal-secondary-btn mx-2"
                    >
                      {isEdit ? "Reset" : "Clear"}
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentPlanModal;
