import {
  api,
  getAuthHeaders,
  get,
  post,
  del,
  update,
  getCSV,
  getPDF
} from "../services.common";

const getAllVendorCharges = async (data) => {
  const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
  if (data.id) {
    const url = `${api}VendorCharges/getall?vendorId=${data.id}&PageNumber=${data.pageNumber || 1}&PageSize=${data.pageSize || 40}&SortDir=${sortDir}&SortField=${data?.sortField || ''}`;
    const authHeader = getAuthHeaders();
    return await get(url, { ...authHeader });
  }
};
const getVendorChargeById = async (data) => {
  const url = `${api}VendorCharges/get/${data.id}?vendorId=${data.vendorId}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const createVendorCharge = async (data) => {
  const url = `${api}VendorCharges/create?vendorId=${data?.vendorId}`;
  const authHeader = getAuthHeaders();
  return await post(url, data.formData, { ...authHeader });
};

const updateVendorCharge = async (data) => {
  const url = `${api}VendorCharges/update/${data?.formData.id}?vendorId=${data?.vendorId}`;
  const authHeader = getAuthHeaders();
  return await update(url, data.formData, { ...authHeader });
};

const exportCSVVendorCharges = async (id) => {
  const url = `${api}VendorCharges/exportcsv?vendorId=${id}`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const exportPdfVendorCharges = async (id) => {
  const url = `${api}VendorCharges/exportpdf?vendorId=${id}`;
  const authHeader = getAuthHeaders();
  return await getPDF(url, { ...authHeader }, "VendorCharges.pdf");
};

const deleteVendorCharge = async (data) => {
  const url = `${api}VendorCharges/delete/${data?.mainId}?vendorId=${data?.id}`;
  const authHeader = getAuthHeaders();
  return await del(url, { ...authHeader });
};

export const vendorChargesServices = {
  getAllVendorCharges,
  getVendorChargeById,
  createVendorCharge,
  updateVendorCharge,
  deleteVendorCharge,
  exportCSVVendorCharges,
  exportPdfVendorCharges
};
