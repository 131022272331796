import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo } from "react";
import { errorMessage } from "../../data/reducers/alerts/alert.reducer";
import {
  fetchVendorsCards,
  flushVendorsCards,
} from "../../data/reducers/vendors/cached-vendors-cards.reducer";
import { formatMoney } from "../../utils/formatter/currencyFormatter";
import CardPanel from "../../shared/components/panels/card-panel/CardPanel";

const VendorDashboardCards = ({ filters, totalVendors, setCardError }) => {
  const dispatch = useDispatch();

  const {
    data: cachedVendorsCardsObject,
    error,
    loading,
  } = useSelector((state) => state.cachedVendorsCards);

  useEffect(() => {
    if (error) {
      dispatch(flushVendorsCards());
      setCardError(error);
    }
  }, []);

  const { data: vendorsCards } = useMemo(() => {
    const data = cachedVendorsCardsObject?.[JSON.stringify(filters)];
    if (!data) {
      return { data: undefined };
    }
    return data;
  }, [cachedVendorsCardsObject, filters]);

  useEffect(() => {
    if (error) {
      dispatch(errorMessage(error?.message ?? "Something went wrong"));
    } else if (!vendorsCards) {
      dispatch(fetchVendorsCards(filters));
    }
  }, [error, vendorsCards, filters]);

  const data = useMemo(
    () => [
      {
        head: "Past Charges",
        val:
          vendorsCards?.pastCharges == null
            ? "N/A"
            : formatMoney(vendorsCards.pastCharges),
      },
      {
        head: "Future Charges",
        val:
          vendorsCards?.futureCharges == null
            ? "N/A"
            : formatMoney(vendorsCards.futureCharges),
      },
      {
        head: "Inventory",
        val:
          vendorsCards?.inventory == null
            ? "N/A"
            : formatMoney(vendorsCards.inventory),
      },
      {
        head: "Total Vendors",
        val: vendorsCards?.totalItemsCount === null ? "N/A" : totalVendors || 0,
      },
    ],
    [vendorsCards, totalVendors]
  );

  if (data == null) {
    return "Not defined";
  }

  return <CardPanel data={data} loader={loading} />;
};

export default React.memo(VendorDashboardCards);
