import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import { Modal, Button, Row, Col, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getCustomerURL } from "../../../data/reducers/inventory/inventory.reducers";
import { errorMessage } from "../../../data/reducers/alerts/alert.reducer";

const FulfillmentEventModal = ({
  isVisible,
  handleClose,
  selectedRecord,
  selectedTickets,
  setAddEditInventoryModalVisibility,
  setBusy,
  deliveryMessages,
}) => {
  const categories = useSelector((state) => state.vendors).category;
  const [isEdit, setEdit] = useState(false);
  const [type, setType] = useState(undefined);
  const [isUpdated, setIsUpdated] = useState(false);
  const [selectedOption, setSelectedOption] = useState(1);

  const dispatch = useDispatch();
  // reset form on visibility toggle
  useEffect(() => {
    reset();
    setIsUpdated(false);
  }, [isVisible]);

  // update values in form if selected record is changed
  useEffect(() => {
    if (selectedRecord) {
      // as soon as selected record is changed, set values in modal popup
      setEdit(true);
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
      if (selectedRecord?.categoryId && categories?.length > 0)
        setType(
          categories?.filter((c) => c?.id === selectedRecord?.categoryId)[0]
        );
    } else {
      // if selected record is undefined, reset
      setEdit(false);
      reset();
    }
  }, [selectedRecord, isVisible]);

  // setup react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm();

  const onSubmit = async (data) => {
    setIsUpdated(true);

    try {
      const action = getCustomerURL({
        invoiceId: 0,
        eventId: 0,
        section: data?.section,
        row: data?.row,
        marketPlace: data?.marketPlace,
        externalReference: data?.externalReference,
        customerName: data?.customerName,
        customerEmail: data?.customerEmail,
        customerPhone: `+${data?.dialCode}${data?.customerPhone}`,
        deliveryMessageId: selectedOption,
        seat: selectedTickets?.ticketsId,
        id: 0,
        ticketMasterEventsId: selectedRecord?.id,
      });

      const response = await dispatch(action);
      if (response?.error) {
        console.log(response?.error?.message);
      } else {
        console.log("barcode saved successfully");
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      //setBusy(false);
    }
  };

  return (
    <>
      <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>GENERATE TICKET FORM</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                name: "Events",
                active: true,
              },
              {
                name: `Event - ${selectedRecord?.eventName}`,
                active: true,
              },
              {
                name: "Select Seat",
                active: true,
              },
              {
                name: "Customer Form",
                active: false,
              },
            ]}
          />
          <Modal.Body>
            <div className="container-fluid p-2 w-100 h-100">
              <Row>
                <Col>
                  <b>Event Name : </b> {selectedRecord?.eventName}
                </Col>
                <Col sm="2">
                  <b>Date : </b>{" "}
                  {moment(selectedRecord?.eventDateTime).format("MM-DD-YYYY")}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <b>Section : </b> {selectedTickets?.section}
                </Col>
                <Col xs={8}>
                  <b>Row : </b> {selectedTickets?.row}
                </Col>
                <Col>
                  <b>Ticket : </b> {selectedTickets?.tickets?.length}{" "}
                  {selectedTickets?.tickets?.length == 1 ? "Seat" : "Seats"}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <b>Seat Number : </b>{" "}
                  {selectedTickets?.tickets?.map(
                    (seat, i) =>
                      seat +
                      (i < selectedTickets?.tickets?.length - 1 ? ", " : "")
                  )}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={6}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Marketplace
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        placeholder="Enter MarketPlace"
                        isInvalid={!!errors.marketPlace}
                        {...register("marketPlace")}
                        readOnly={isUpdated}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      External Reference
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        placeholder="Enter External Reference"
                        isInvalid={!!errors.externalReference}
                        {...register("externalReference" )}
                        readOnly={isUpdated}
                      />
                    </Col>
                  </Form.Group>
                </Col>

                <Col sm={6} className="mt-4">
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Customer Name
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        placeholder="Enter Name"
                        isInvalid={!!errors.customerName}
                        {...register("customerName", { required: true })}
                        readOnly={isUpdated}
                      />
                      <Form.Control.Feedback type="invalid">
                        Customer Name is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>

                <Col sm={6} className="mt-4">
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Customer Email
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        type="email"
                        placeholder="Enter Email"
                        isInvalid={!!errors.customerEmail}
                        {...register("customerEmail", { required: true })}
                        readOnly={isUpdated}
                      />
                      <Form.Control.Feedback type="invalid">
                        Customer Email is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm={12} className="mt-4">
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Customer Phone
                    </Form.Label>
                    <Col sm="3">
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">+</InputGroup.Text>
                        <Form.Control
                          placeholder="Country Code"
                          isInvalid={!!errors.dialCode}
                          {...register("dialCode", {
                            required: true,
                            validate: {
                              isValid: (v) => /(?:\+?(\d{1,3}))/.test(v),
                            },
                          })}
                          readOnly={isUpdated}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.dialCode &&
                          errors.dialCode?.type === "isValid"
                            ? "Enter Valid Country code"
                            : "Country Code is required."}
                        </Form.Control.Feedback>
                      </InputGroup>
                      <p className="text-muted" style={{ fontSize: "12px" }}>
                        <span style={{ textDecoration: "underline" }}>+1</span>{" "}
                        xxxx xxxxx
                      </p>
                    </Col>
                    <Col sm="4">
                      <Form.Control
                        type="number"
                        placeholder="Enter Phone"
                        isInvalid={!!errors.customerPhone}
                        {...register("customerPhone", {
                          required: true,
                          validate: {
                            isValid: (v) => String(v).length <= 10,
                          },
                        })}
                        readOnly={isUpdated}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.customerPhone &&
                        errors?.customerPhone.type === "isValid"
                          ? "Enter Valid Phone Number"
                          : "Customer Phone is required."}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>

              <hr />

              <Row className="mt-3">
                <Col sm={10}>
                  <Row>
                    <Form.Label column sm="3">
                      Send Delivery Message
                    </Form.Label>

                    {deliveryMessages?.map((d) => {
                      return (
                        <Col className="align-center-radio d-flex" key={d?.id}>
                          <Form.Check
                            type="radio"
                            name="Contains"
                            value={d.id}
                            checked={selectedOption === d.id}
                            onChange={(e) => {
                              setSelectedOption(parseInt(e.target.value));
                            }}
                            disabled={isUpdated}
                          />
                          &nbsp;
                          <label for="Contains">{d.deliveryMessage}</label>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
              {isUpdated && (
                <>
                  <hr />
                  <Row>
                    <Alert severity="success">
                      <div className="mt-1 d-flex justify-content-around">
                        <div style={{ color: "#03791E" }}>
                          Ticket link generation in progress, once completed it
                          will be sent via mail/SMS within 5 minutes.
                        </div>
                      </div>
                    </Alert>
                  </Row>
                </>
              )}
            </div>
          </Modal.Body>

          <div className="d-flex justify-content-center my-4">
            {isUpdated ? (
              <Button
                variant="primary"
                className="ss-modal-primary-btn mx-2"
                onClick={() => handleClose()}
              >
                Close
              </Button>
            ) : (
              <>
                <Button
                  variant="light"
                  className="ss-modal-secondary-btn mx-2"
                  onClick={() => {
                    handleClose();
                    setAddEditInventoryModalVisibility(true);
                  }}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  className="ss-modal-primary-btn mx-2"
                >
                  Generate
                </Button>
                <Button
                  variant="light"
                  className="ss-modal-secondary-btn mx-2"
                  onClick={() => handleClose()}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default FulfillmentEventModal;
