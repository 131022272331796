import { combineReducers } from "redux";
import authReducer from "./authentication/auth.reducer";
import alertReducer from "./alerts/alert.reducer";
import accountReducer from "./accounts/accounts.reducers";
import cachedAccountsReducer from "./accounts/cached-accounts.reducers";
import vendorReducer from "./vendors/vendors.reducer";
import vendorChargesReducer from "./vendors/vendor-charges.reducer";
import vendorPurchaseOrdersReducer from "./vendors/vendor-purchaseOrders.reducer";
import vendorRulesReducer from "./vendors/vendor-rules.reducer";
import accountChargesReducer from "./accounts/account-charges.reducer";
import accountRulesReducer from "./accounts/account-rules.reducer";
import addressBookReducer from "./addressBook/addressBook.reducer";
import cardReducer from "./cards/cards.routes";
import accountPurchaseOrdersReducer from "./accounts/account-purchase-orders.reducer";
import purchaseOrdersReducer from "./purchaseOrders/po.reducer.js";
import purchaseOrderChargesReducer from "./purchaseOrders/po-charges.reducer";
import purchaseOrderPaymentReducer from "./purchaseOrders/po-paymentPlans.reducer";
import pslReducer from "./psl/psl.reducer";
import loanReducer from "./finance/loans.reducer";
import skyboxReducer from "./skybox-purchases/skybox-purchases.reducer";
import paypalReducer from "./paypal/paypal.reducer";
import plaidReducer from "./plaid/plaid.reducer";
import transactionsReducer from "./transactions/transactions.reducer";
import seasonsReducer from "./season/season.reducer";
import biReportReducer from "./bi-report/bi-report.reducers";
import syncReducer from "./sync/sync.reducer";
import sportsAndTeamsReducer from "./sports&teams/sports&teams.reducer";
import revealMarketReducer from "./reveal-market/reveal-market.reducer";
import accountLocationReducer from "./accounts/account-location.reducer";
import eventsReducers from "./events/events.reducers";
import inventoryReducers from "./inventory/inventory.reducers";
import emailReducer from "./email/email.reducer";
import poHistoryReducer from "./purchaseOrders/po.history.reducer";
import uploadEmailReducer from "./email/upload.email.reducer";
import cachedAccountsCardsReducers from "./accounts/cached-accounts-cards.reducers.js";
import cachedPurchaseOrdersReducer from "./purchaseOrders/cached-po.reducer.js";
import cachedPurchaseOrdersCardsReducer from "./purchaseOrders/cached-po-cards.reducer.js";
import cachedTransactionsReducer from "./transactions/cached-transactions.reducer.js";
import cachedTransactionCardsReducer from "./transactions/cached-transaction-cards.reducer.js";
import cachedSkyboxPurchasesReducer from "./skybox-purchases/cached-skybox-purchases.reducer.js";
import cachedVendorsReducer from "./vendors/cached-vendors.reducer.js";
import cachedVendorsCardsReducer from "./vendors/cached-vendors-cards.reducer.js";

const rootReducer = combineReducers({
  cachedAccounts: cachedAccountsReducer,
  cachedAccountsCards: cachedAccountsCardsReducers,
  cachedVendorsCards: cachedVendorsCardsReducer,
  cachedVendors: cachedVendorsReducer,

  cachedPurchaseOrders: cachedPurchaseOrdersReducer,
  cachedPurchaseOrdersCards: cachedPurchaseOrdersCardsReducer,
  cachedSkyboxPurchases: cachedSkyboxPurchasesReducer,
  cachedTransactions: cachedTransactionsReducer,
  cachedTransactionsCards: cachedTransactionCardsReducer,

  auth: authReducer,
  alerts: alertReducer,
  accounts: accountReducer,
  accountCharges: accountChargesReducer,
  accountRules: accountRulesReducer,
  accountPurchaseOrders: accountPurchaseOrdersReducer,
  accountLocation: accountLocationReducer,
  events: eventsReducers,
  inventory: inventoryReducers,
  vendors: vendorReducer,
  vendorCharges: vendorChargesReducer,
  vendorPurchaseOrders: vendorPurchaseOrdersReducer,
  vendorRules: vendorRulesReducer,
  purchaseOrder: purchaseOrdersReducer,
  purchaseOrderCharge: purchaseOrderChargesReducer,
  purchaseOrderPayment: purchaseOrderPaymentReducer,
  addressBook: addressBookReducer,
  cards: cardReducer,
  psl: pslReducer,
  loans: loanReducer,
  skybox: skyboxReducer,
  paypal: paypalReducer,
  plaid: plaidReducer,
  transactions: transactionsReducer,
  season: seasonsReducer,
  biReport: biReportReducer,
  sync: syncReducer,
  sportsAndTeams: sportsAndTeamsReducer,
  revealMarket: revealMarketReducer,
  emails: emailReducer,
  uploadEmail: uploadEmailReducer,
  poHistoy: poHistoryReducer,
});

export default rootReducer;
