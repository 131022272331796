import { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { formatDate } from "../../../utils/formatter/dateFormatter";
import TableRowCard from "../../../shared/components/table/TableRowCard";
import QuickTixTable from "../../../shared/components/table/QuickTixTable";
import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import { getAccountLocationHistory } from "../../../data/reducers/accounts/account-location.reducer";

const LocationHistoryModal = ({ isVisible, handleClose, accountId }) => {
  const dispatch = useDispatch();
  const { accountLocationHistory, historyPaging } = useSelector(
    (state) => state.accountLocation
  );
  const [paging, setPaging] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setPaging(historyPaging);
  }, [historyPaging]);

  useEffect(() => {
    if (isVisible) {
      setpageNumber(1);
      setSortField(null);
      setpageSize(40);
      setSortDir(true);
    }
  }, [isVisible]);

  //paging datas
  const [pageNumber, setpageNumber] = useState(1);
  const [sortField, setSortField] = useState(null);
  const [sortDir, setSortDir] = useState(true);
  const [pageSize, setpageSize] = useState(40);

  const headOptions = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end py-3">
          <small className="text-muted">Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue="40"
              onChange={(e) => setpageSize(e.target.value)}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (isVisible) {
      const data = {
        pageNumber,
        pageSize,
        sortDir,
        sortField,
        accountId,
      };
      fetchLocationHistory(data);
    }
  }, [isVisible, pageNumber, pageSize, sortDir, sortField, accountId]);

  const fetchLocationHistory = async (data) => {
    try {
      setLoading(true);
      await dispatch(getAccountLocationHistory({ ...data }));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const column = [
    {
      columnName: "Section",
      columnKey: "section",
      // render: () => (
      //   "Section"
      // )
    },
    {
      columnName: "Row",
      columnKey: "row",
      // render: () => (
      //   "Row"
      // )
    },
    {
      columnName: "Low Seat",
      columnKey: "lowSeat",
      // render: () => (
      //   "Low Seat"
      // )
    },
    {
      columnName: "High Seat",
      columnKey: "highSeat",
      // render: () => (
      //   "High Seat"
      // )
    },
    {
      columnName: "Season Code",
      columnKey: "seasonCode",
      // render: () => (
      //   "Season Code - 2022Rs"
      // )
    },
    {
      columnName: "Date Changed",
      render: (data) => {
        if (data?.deletedDate) {
          return <>{data?.deletedDate ? formatDate(data.deletedDate) : ""}</>;
        } else if (data?.updatedDate) {
          return <>{data?.updatedDate ? formatDate(data.updatedDate) : ""}</>;
        } else if (data?.createdDate) {
          return <>{data?.createdDate ? formatDate(data.createdDate) : ""}</>;
        } else {
          return "";
        }
      },
    },
    {
      columnName: "Status",
      render: (data) => {
        if (data?.deletedDate) {
          return (
            <div
              style={{ backgroundColor: "#FF00001A", color: "#870202" }}
              className="py-2 px-4"
            >
              <span>Record Deleted</span>
            </div>
          );
        } else if (data?.updatedDate) {
          return (
            <div
              style={{ backgroundColor: "#06BF0E21", color: "#038409" }}
              className="py-2 px-4"
            >
              <span>Record Updated</span>
            </div>
          );
        } else if (data?.createdDate) {
          return (
            <div
              style={{ backgroundColor: "#3C95FF1A", color: "#023E86" }}
              className="py-2 px-4"
            >
              <span>Record Added</span>
            </div>
          );
        } else {
          return "";
        }
      },
    },
  ];

  return (
    <>
      <Modal
        dialogClassName="modal-xl"
        show={isVisible}
        onHide={handleClose}
        className="modal-custom-close"
      >
        <Modal.Header closeButton>
          <Modal.Title>Accounts</Modal.Title>
        </Modal.Header>
        <ModalBreadcrumb
          breadcrumbLinks={[
            {
              to: "/accounts",
              name: "Accounts",
              active: false,
            },
            {
              name: "History",
              active: true,
            },
          ]}
        />

        <Modal.Body>
          {headOptions()}
          <QuickTixTable
            loading={loading}
            paging={paging}
            columns={column}
            data={accountLocationHistory}
            setSortField={setSortField}
            sortDir={sortDir}
            sortField={sortField}
            setSortDir={setSortDir}
            setpageNumber={setpageNumber}
            renderRow={(rowData) => (
              <TableRowCard
                key={rowData.id}
                columns={column}
                rowData={rowData}
              />
            )}
            noHeader
            // headOptions={headOptions}
          />
          <div className="d-flex justify-content-center my-4">
            <Button onClick={handleClose} className="ss-modal-primary-btn mx-2">
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LocationHistoryModal;
