import { Modal } from "react-bootstrap";
import warning from "../../../images/warning.svg";

const DeleteConfirmationModal = ({
  selectedRecord,
  isVisible,
  handleClose,
  selectedAccount,
}) => {
  return (
    <Modal dialogClassName="modal-lg" show={isVisible} onHide={handleClose}>
      <div className="delete-svg-div p-3 d-flex flex-row justify-content-center align-items-center">
        <img className="delete-svg  w-25 h-25" src={warning} alt="" />
      </div>

      <div className="d-flex flex-column justify-content-evenly align-items-center py-3 px-5">
        <p className="text-warning">
          This account is{" "}
          {selectedAccount.categoryId === 2 ? " inactive" : " revoked"}. Please
          activate the account before trying to{" "}
          {selectedRecord ? " update" : " create"} a Purchase Order
        </p>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;
