
import {
    api,
    get,
    getAuthHeaders,
} from '../services.common';

const getAllBIReport = async (data) => {
    const url = `${api}PowerBI/getall?id=0`
    const authHeader = getAuthHeaders();
    return await get(url, { ...authHeader });
}

export const BIReportServices = {
    getAllBIReport,
}