import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";

import { useDispatch } from "react-redux";
import Loader from "../../../shared/components/loader/Loader";
import { inputDate } from "../../../utils/formatter/dateFormatter";
import { successMessage } from "../../../data/reducers/alerts/alert.reducer";
import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";

const AddEditAccountRulesModal = ({
  isVisible,
  handleClose,
  onSubmit,
  selectedRecord,
  loading,
}) => {
  const [isEdit, setEdit] = useState(false);
  const [sDate, setsDate] = useState(inputDate());
  const [eDate, seteDate] = useState(inputDate(true));
  // setup react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  const dispatch = useDispatch();
  // reset form on visibility toggle
  useEffect(() => {
    reset();
  }, [isVisible]);

  // update values in form if selected record is changed
  useEffect(() => {
    if (selectedRecord) {
      // as soon as selected record is changed, set values in modal popup
      setEdit(true);
      setsDate(String(selectedRecord?.startDate).split("T")[0]);
      seteDate(String(selectedRecord?.endDate).split("T")[0]);
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    } else {
      // if selected record is undefined, reset
      setsDate(inputDate());
      seteDate(inputDate(true));
      setEdit(false);
      reset();
    }
  }, [selectedRecord, isVisible]);

  const handleReset = () => {
    for (const [key, value] of Object.entries(selectedRecord)) {
      setValue(key, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    setsDate(String(selectedRecord?.startDate).split("T")[0]);
    seteDate(String(selectedRecord?.endDate).split("T")[0]);
  };

  const handleClear = () => {
    setsDate(inputDate());
    seteDate(inputDate(true));
    reset();
  };

  const validateSubmit = async (data) => {
    if (
      selectedRecord &&
      selectedRecord.card == data.card &&
      selectedRecord.name == data.name
    ) {
      handleClose();
      dispatch(successMessage("Account Rules Updated Successfully"));
    } else {
      onSubmit(data);
    }
  };

  return (
    <>
      <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
        <Form onSubmit={handleSubmit(validateSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>RULES</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/accounts",
                name: "Accounts",
                active: false,
              },
              {
                name: isEdit ? "Edit Rule" : "New Rule",
                active: true,
              },
            ]}
          />
          <Modal.Body>
            <div className="container-fluid p-2 w-100 h-100">
              <Row>
                <p className="title-accounts">Basic Information</p>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Name
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        isInvalid={!!errors.name}
                        {...register("name", { required: true })}
                        placeholder="Name"
                      />
                      <Form.Control.Feedback type="invalid">
                        Name is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Card Id
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        isInvalid={!!errors.card}
                        {...register("card", { required: true })}
                        placeholder="Card Id"
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Card is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <div className="d-flex justify-content-center my-4">
            <Button
              disabled={loading}
              type="submit"
              variant="primary"
              className="ss-modal-primary-btn mx-2"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isEdit ? "Save" : "Add"}
                {loading && <Loader style={{ marginLeft: "8px" }} />}
              </div>
            </Button>
            <Button
              variant="light"
              onClick={() => (isEdit ? handleReset() : handleClear())}
              className="ss-modal-secondary-btn mx-2"
            >
              {isEdit ? "Reset" : "Clear"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddEditAccountRulesModal;
