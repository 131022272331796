import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { successMessage } from "../../../data/reducers/alerts/alert.reducer";


const AddEditVendorRulesModal = ({
  isVisible,
  handleClose,
  onSubmit,
  selectedRecord,
}) => {

  const [isEdit, setEdit] = useState(false);
  const [selectedRule, setSelectedRule] = useState(0)
  // setup react hook form

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const handleReset = () => {
    for (const [key, value] of Object.entries(selectedRecord)) {
      setValue(key, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    setSelectedRule(selectedRecord?.searchId)
  };
  const handleClear = () => {
    reset();
    setSelectedRule(0)
  }
  // reset form on visibility toggle
  useEffect(() => {
    reset();
    setSelectedRule(0)
  }, [isVisible]);

  // update values in form if selected record is changed
  useEffect(() => {
    if (selectedRecord) {
      // as soon as selected record is changed, set values in modal popup
      setEdit(true);
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
      setSelectedRule(selectedRecord?.searchId)
    } else {
      // if selected record is undefined, reset
      setEdit(false);
      reset();
    }
  }, [selectedRecord, isVisible]);
  const dispatch = useDispatch();
  const validateSubmit = async (data) => {
    if (selectedRecord && selectedRecord.matchingRule == data.matchingRule && selectedRecord.searchId == data.searchId) {
      handleClose()
      dispatch(successMessage("Vendor Rules Updated Successfully"))
    } else {
      onSubmit(data)
    }
  }
  return (
    <>
      <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
        <Form onSubmit={handleSubmit(validateSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>RULES</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/vendors",
                name: "Vendors",
                active: false,
              },
              {
                name: isEdit ? "Edit Rule" : "New Rule",
                active: true,
              },
            ]}
          />
          <Modal.Body>
            <div className="container-fluid p-2 w-100 h-100">
              <Row>
                <p className="title-accounts">Basic Information</p>
                <Col sm={5}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Name
                    </Form.Label>
                    <Col>
                      <Form.Control
                        isInvalid={!!errors.name}
                        disabled={isEdit}
                        {...register("name", { required: true })}
                        placeholder="Name"
                      />
                      <Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3" >
                <Col sm={5}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Matching Rules
                    </Form.Label>
                    <Col >
                      <Form.Control
                        isInvalid={!!errors.matchingRule}
                        {...register("matchingRule", { required: true })}
                        placeholder="Matching Rules"
                      />
                      <Form.Control.Feedback type="invalid">Matching Rule is required.</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={10}>
                  <Row>
                    <Form.Label column sm="3">Search Criteria</Form.Label>
                    <Col className="align-center-radio d-flex">
                      <Form.Check
                        type="radio"
                        name="Contains"
                        value={2}
                        onClick={(e) => setSelectedRule(e.target.value)}
                        checked={selectedRule == 2}
                        {...register("searchId", { required: true })}

                      />
                      &nbsp;
                      <label for="Contains">Contains</label>
                    </Col>
                    <Col className="align-center-radio d-flex">
                      <Form.Check
                        type="radio"
                        onClick={(e) => setSelectedRule(e.target.value)}
                        checked={selectedRule == 1}
                        name="Begins With"
                        value={1}
                        {...register("searchId", { required: true })}
                      />
                      &nbsp;
                      <label for="Begins With">Begins With</label>
                    </Col>
                    <Col className="align-center-radio d-flex">
                      <Form.Check
                        type="radio"
                        onClick={(e) => setSelectedRule(e.target.value)}
                        checked={selectedRule == 3}
                        name="Ends With"
                        {...register("searchId", { required: true })}
                        value={3}
                      />
                      &nbsp;
                      <label for="Ends With">Ends With</label>
                    </Col>
                    <Col className="align-center-radio d-flex">
                      <Form.Check
                        type="radio"
                        name="Exact"
                        onClick={(e) => setSelectedRule(e.target.value)}
                        {...register("searchId", { required: true })}
                        value={4}
                        checked={selectedRule == 4}
                      />
                      &nbsp;
                      <label for="Exact">Exact</label>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <div className="d-flex justify-content-center my-4">
            <Button
              type="submit"
              variant="primary"
              className="ss-modal-primary-btn mx-2"
            >
              {isEdit ? "Save" : "Add"}
            </Button>
            <Button
              variant="light"
              onClick={() => isEdit ? handleReset() : handleClear()}
              className="ss-modal-secondary-btn mx-2"
            >
              {isEdit ? "Reset" : "Clear"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddEditVendorRulesModal;
