import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BIReportServices } from '../../services/bi-report/bi-report.service';

export const getAllBIReport = createAsyncThunk(
    'BIReport/getAllBIReport',
    async (payload, thunkAPI) => {
        const response = await BIReportServices.getAllBIReport(payload);
        if (response.isSuccessful === true) {
            return response;
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

const BiReportSlice = createSlice({
    name: 'report',
    initialState: {
        report: {},
        paging: {},
    },
    extraReducers: {
        [getAllBIReport.fulfilled]: (state, action) => {
            state.report = action.payload.data?.filter(data => data.visible === true)[0];
            state.paging = action.payload.paging;
        },
    },
});

export default BiReportSlice.reducer;