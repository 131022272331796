import { Route, Switch } from "react-router-dom";
import TransactionDashboard from "../../../pages/transactions/TransactionDashboard";

const TransactionsRoutes = () => {
  return (
    <Switch>
      <Route path="/transactions" exact>
        <TransactionDashboard />
      </Route>
    </Switch>
  );
};

export default TransactionsRoutes;
