import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";

const AddEditVendorRulesModal = ({
  isVisible,
  handleClose,
  onSubmit,
  selectedRecord,
}) => {
  const dispatch = useDispatch();
  const [dropVal, setdropVal] = useState({
    name: "",
    vendor: "",
  });
  const [selectedRule, setSelectedRule] = useState(0);

  const customStyles = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };
  const [isEdit, setEdit] = useState(false);
  // setup react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    getValues,
  } = useForm();

  const { vendorsListForDropdown: vendorDetails } = useSelector(
    (state) => state.cachedVendors
  );
  const handleReset = () => {
    for (const [key, value] of Object.entries(selectedRecord)) {
      setValue(key, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    setValue("name", "");
    setSelectedRule(selectedRecord?.searchId);
    setdropVal({
      vendor: vendorDetails.filter(
        (account) => account.id == selectedRecord.vendorId
      )[0],
    });
  };

  // reset form on visibility toggle
  useEffect(() => {
    reset();
  }, [isVisible]);

  // update values in form if selected record is changed
  useEffect(() => {
    if (selectedRecord) {
      // as soon as selected record is changed, set values in modal popup
      setEdit(true);
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }

      setSelectedRule(selectedRecord?.searchId);
      setValue("name", "");
      setdropVal({
        vendor: vendorDetails.filter(
          (account) => account.id == selectedRecord.vendorId
        )[0],
      });
    } else {
      // if selected record is undefined, reset
      setEdit(false);
      reset();
    }
  }, [selectedRecord, isVisible]);

  const handleClear = () => {
    reset();
    setValue("name", "");
  };
  return (
    <>
      <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>RULES</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/vendors",
                name: "Vendors",
                active: false,
              },
              {
                name: isEdit ? "Edit Rule" : "New Rule",
                active: true,
              },
            ]}
          />
          <Modal.Body>
            <div className="container-fluid p-2 w-100 h-100">
              <Row>
                <p className="title-accounts">Basic Information</p>
                <Col sm={5}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Vendor
                    </Form.Label>
                    <Col sm="9">
                      <Controller
                        name="vendorId"
                        control={control}
                        rules={{ required: true, message: "error message" }}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <div>
                              <Select
                                required
                                isInvalid={!!errors.vendorId}
                                value={dropVal.vendor}
                                onChange={(e) => {
                                  field.onChange(e?.id);
                                  setdropVal({ ...dropVal, vendor: e });
                                }}
                                isClearable
                                styles={customStyles}
                                // isClearable
                                options={vendorDetails}
                                getOptionLabel={(option) =>
                                  `${option.id}  ${option.name}`
                                }
                              />
                              {invalid && error ? (
                                <div className="custom-invalid-feedback">
                                  Vendor is required.
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm={2}></Col>
                <Col sm={5}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Name
                    </Form.Label>
                    <Col>
                      <Form.Control
                        isInvalid={!!errors.name}
                        {...register("name", { required: true })}
                        placeholder="Name"
                      />
                      <Form.Control.Feedback type="invalid">
                        Name is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={5}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Matching Rules
                    </Form.Label>
                    <Col>
                      <Form.Control
                        isInvalid={!!errors.description}
                        {...register("description", { required: true })}
                        placeholder="Matching Rules"
                      />
                      <Form.Control.Feedback type="invalid">
                        Matching Rule is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={10}>
                  <Row>
                    <Form.Label column sm="3">
                      Search Criteria
                    </Form.Label>
                    <Col className="align-center-radio d-flex">
                      <Form.Check
                        type="radio"
                        name="Contains"
                        value={2}
                        onClick={(e) => setSelectedRule(e.target.value)}
                        checked={selectedRule == 2}
                        {...register("searchId", { required: true })}
                      />
                      &nbsp;
                      <label for="Contains">Contains</label>
                    </Col>
                    <Col className="align-center-radio d-flex">
                      <Form.Check
                        type="radio"
                        name="Begins With"
                        value={1}
                        onClick={(e) => setSelectedRule(e.target.value)}
                        checked={selectedRule == 1}
                        {...register("searchId", { required: true })}
                      />
                      &nbsp;
                      <label for="Begins With">Begins With</label>
                    </Col>
                    <Col className="align-center-radio d-flex">
                      <Form.Check
                        type="radio"
                        name="Ends With"
                        {...register("searchId", { required: true })}
                        value={3}
                        onClick={(e) => setSelectedRule(e.target.value)}
                        checked={selectedRule == 3}
                      />
                      &nbsp;
                      <label for="Ends With">Ends With</label>
                    </Col>
                    <Col className="align-center-radio d-flex">
                      <Form.Check
                        type="radio"
                        name="Exact"
                        {...register("searchId", { required: true })}
                        value={4}
                        onClick={(e) => setSelectedRule(e.target.value)}
                        checked={selectedRule == 4}
                      />
                      &nbsp;
                      <label for="Exact">Exact</label>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <div className="d-flex justify-content-center my-4">
            <Button
              type="submit"
              variant="primary"
              className="ss-modal-primary-btn mx-2"
            >
              {isEdit ? "Save" : "Add"}
            </Button>
            <Button
              variant="light"
              onClick={() => (isEdit ? handleReset() : handleClear())}
              className="ss-modal-secondary-btn mx-2"
            >
              {isEdit ? "Reset" : "Clear"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddEditVendorRulesModal;
