import {
    api,
    getAuthHeaders,
    get,
    post,
    update,
    del
} from "../services.common";


export const setDefaultCard = async (card_data) => {
    const url = `${api}/AccountRules/setDefaultCard`;
    const authHeader = getAuthHeaders();
    return await post(url, card_data, { ...authHeader });
}