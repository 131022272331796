import { useDispatch, useSelector } from "react-redux";
import CardPanel from "../../../shared/components/panels/card-panel/CardPanel";
import React, { useEffect, useMemo } from "react";
import {
  fetchAccountsCards,
  flushAccountsCards,
} from "../../../data/reducers/accounts/cached-accounts-cards.reducers";
import { formatMoney } from "../../../utils/formatter/currencyFormatter";
import { errorMessage } from "../../../data/reducers/alerts/alert.reducer";

const DashboardCards = ({ filters, setCardError }) => {
  const dispatch = useDispatch();

  const {
    data: cachedAccountsCardsObject,
    error,
    loading,
    callCardsApi,
  } = useSelector((state) => state.cachedAccountsCards);

  useEffect(() => {
    if (error) {
      dispatch(flushAccountsCards());
      setCardError(error);
    }
  }, []);

  const { data: accountsCards } = useMemo(() => {
    const data = cachedAccountsCardsObject?.[JSON.stringify(filters)];
    if (!data) {
      return { data: undefined };
    }
    return data;
  }, [cachedAccountsCardsObject, filters]);

  useEffect(() => {
    if (error) {
      dispatch(errorMessage(error?.message ?? "Something went wrong"));
    } else if (!accountsCards && callCardsApi != null) {
      dispatch(fetchAccountsCards(filters));
    }
  }, [error, callCardsApi]);

  const data = useMemo(
    () => [
      {
        head: "Past Charges",
        val:
          accountsCards?.pastCharges == null ||
          accountsCards?.pastCharges == undefined
            ? "--"
            : formatMoney(accountsCards.pastCharges),
      },
      {
        head: "Future Charges",
        val:
          accountsCards?.futureCharges == null ||
          accountsCards?.futureCharges == undefined
            ? "--"
            : formatMoney(accountsCards.futureCharges),
      },
      {
        head: "Inventory",
        val:
          accountsCards?.inventory == null ||
          accountsCards?.inventory == undefined
            ? "--"
            : formatMoney(accountsCards.inventory),
      },
      {
        head: "Account Credits",
        val:
          accountsCards?.accountCredits == null ||
          accountsCards?.accountCredits == undefined
            ? "--"
            : formatMoney(accountsCards.accountCredits),
      },
    ],
    [accountsCards]
  );

  return <CardPanel data={data} loader={loading} />;
};

export default React.memo(DashboardCards);
