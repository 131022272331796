/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { withLoader } from "../../../utils/hoc/withLoader";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";

import DeleteConfirmationModal from "../../../shared/components/modals/DeleteConfirmationModal";
import {
  errorMessage,
  successMessage,
} from "../../../data/reducers/alerts/alert.reducer";

//components
import FilterPanel from "../../../shared/components/panels/filter-panel/FilterPanel";
import QuickTixTable from "../../../shared/components/table/QuickTixTable";
import TableRowCard from "../../../shared/components/table/TableRowCard";
import { columnError } from "../../../shared/components/table/data/error";
import {
  createTeam,
  createSport,
  updateTeam,
  updateSport,
  getAllSports,
  getAllTeams,
  filterTeams,
  exportSelectedAsCSVTeams,
  exportSelectedAsPdfTeams,
  deleteTeam,
  exportCSVTeams,
  exportPDFTeams,
} from "../../../data/reducers/sports&teams/sports&teams.reducer";

// images
import Export from "../../../images/export.svg";
import DeleteIcon from "../../../images/delete.svg";
import EditIcon from "../../../images/edit.svg";
import Add from "../../../images/add.svg";

import "../../../shared/styles/ModalStyles.scss";
import AddEditSportsModal from "./modals/AddEditSportsModal";
import AddEditTeamsModal from "./modals/AddEditTeamsModal";
import { Controller } from "react-hook-form";
import {
  getAccountSportList,
  getAccountTeamList,
} from "../../../data/reducers/accounts/accounts.reducers";
import {
  Mixpanel,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../../mixpanel";

const SportsAndTeams = ({ setBusy }) => {
  const dispatch = useDispatch();

  //selectors
  const { allTeams, allSports, teamsPaging, sportsPaging } = useSelector(
    (state) => state.sportsAndTeams
  );
  const teamsList = useSelector((state) => state.accounts).teamList;
  const sportsList = useSelector((state) => state.accounts).sportList;
  //use states
  const [sortField, setSortField] = useState(null);
  const [sortDir, setSortDir] = useState(true);
  const [addTeamsModalVisible, setAddEditTeamsModalVisibility] =
    useState(false);
  const [addSportsModalVisible, setAddEditSportsModalVisibility] =
    useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisibility] =
    useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(undefined);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [pageSize, setpageSize] = useState(40);
  const [pageNumber, setpageNumber] = useState(1);
  const [dropVal, setdropVal] = useState({
    team: "",
    sport: "",
  });
  const [searchData, setsearchData] = useState(null);

  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.SPORTS_TEAMS,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.SPORTS_TEAMS,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);

  useEffect(() => {
    dispatch(getAccountSportList());
    dispatch(getAccountTeamList());
  }, []);

  //use effect
  useEffect(() => {
    if (searchData) {
      onSubmit(searchData);
    } else {
      fetchData();
    }
  }, [pageSize, pageNumber]);
  // fetch all address information
  const fetchData = async () => {
    try {
      setBusy(true);
      const data = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        sortDir,
        sortField,
      };
      const response = await dispatch(getAllSports(data));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      }
      const response1 = await dispatch(getAllTeams(data));
      if (response1.error) {
        dispatch(errorMessage(response.error.message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  // delete account
  const onDeleteAccount = async (id) => {
    try {
      setBusy(true);
      const response = await dispatch(deleteTeam(id));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        setDeleteConfirmationVisibility(false);
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      if (searchData) {
        onSubmit(searchData);
      } else {
        fetchData();
      }
      setBusy(false);
    }
  };

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      pageNumber,
      pageSize,
      sortField,
      sortDir,
      searchText: data?.searchText?.trim() || "",
    };
    setsearchData(formData);
    setBusy(true);
    await dispatch(filterTeams(formData));
    setBusy(false);
  };

  // add or update account details
  const onEntrySubmitted = async (data, option) => {
    try {
      let response, message, action;
      setBusy(true);
      switch (option) {
        case 1:
          message = data.id
            ? `Sport Updated Succesfully`
            : `Sport Created Succesfully`;
          action = data?.id ? updateSport(data) : createSport(data);
          response = await dispatch(action);
          break;
        case 2:
          message = data.id
            ? `Team Updated Succesfully`
            : `Team Created Succesfully`;
          action = data?.id ? updateTeam(data) : createTeam(data);
          response = await dispatch(action);
          break;
      }

      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        dispatch(successMessage(message));
        switch (option) {
          case 1:
            setAddEditSportsModalVisibility(false);
            break;
          case 2:
            setAddEditTeamsModalVisibility(false);
            break;
        }
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      fetchData();
      setBusy(false);
    }
  };

  //address table data
  const sportsCol = [
    {
      columnName: "Sports",
      render: (data) => <>{data?.sportName}</>,
    },
    {
      columnName: "Actions",
      render: (data) => (
        <small className="table-row-card-actions d-flex">
          <Button
            variant="link"
            className="table-action-button green_bg"
            onClick={(e) => {
              e.preventDefault();
              setSelectedRecord(data);
              setAddEditSportsModalVisibility(true);
            }}
          >
            <img src={EditIcon} alt="" />
          </Button>
        </small>
      ),
    },
  ];

  const teamsCol = [
    {
      columnName: "Teams",
      render: (data) => <>{data?.teamName}</>,
    },
    {
      columnName: "Sports",
      render: (data) => <>{data.sportName}</>,
    },
    {
      columnName: "Actions",
      render: (data) => (
        <small className="table-row-card-actions d-flex">
          <Button
            variant="link"
            className="table-action-button green_bg"
            onClick={(e) => {
              e.preventDefault();
              setSelectedRecord(data);
              setAddEditTeamsModalVisibility(true);
            }}
          >
            <img src={EditIcon} alt="" />
          </Button>

          <Button
            variant="link"
            className="table-action-button"
            onClick={() => {
              setSelectedRecord(data);
              setSelectedRecordId(data?.id);
              setDeleteConfirmationVisibility(true);
            }}
          >
            <img src={DeleteIcon} alt="delete" />
          </Button>
        </small>
      ),
    },
  ];

  const exportSelectedAsCSV = async () => {
    setBusy(true);
    if (searchData) {
      await dispatch(exportSelectedAsCSVTeams(searchData));
    } else {
      await dispatch(exportCSVTeams());
    }
    setBusy(false);
  };

  const exportSelectedAsPDF = async () => {
    setBusy(true);
    if (searchData) {
      await dispatch(exportSelectedAsPdfTeams(searchData));
    } else {
      await dispatch(exportPDFTeams());
    }
    setBusy(false);
  };

  //table head
  const headOptions1 = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small>Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue="40"
              onChange={(e) => setpageSize(e.target.value)}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div className="text-end mt-3 d-flex align-items-center justify-content-end flex-1">
          {/* <div className="text-end d-flex align-items-end"> */}
          <Button
            onClick={async (e) => {
              e.currentTarget.blur();
              setSelectedRecord(undefined);
              setAddEditSportsModalVisibility(true);
            }}
            variant="primary"
            className="ms-2 ss-light-button"
          >
            <img src={Add} alt=" " />
            <span>Add</span>
          </Button>
        </div>
        {/* </div> */}
      </>
    );
  };
  const headOptions2 = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small>Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue="40"
              onChange={(e) => setpageSize(e.target.value)}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div
          className="text-end mt-3 d-flex flex-1"
          style={{ justifyContent: "between", alignItems: "center" }}
        >
          <div className="text-end d-flex align-items-center">
            <Dropdown onClick={(e) => e.currentTarget.blur()}>
              <Dropdown.Toggle
                className="ms-2 ss-light-button"
                id="dropdown-basic"
              >
                <img src={Export} alt=" " />
                <span>Export</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={exportSelectedAsCSV}>
                  Export as CSV
                </Dropdown.Item>
                <hr className="hr-full" />
                <Dropdown.Item onClick={exportSelectedAsPDF}>
                  Export as PDF
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              onClick={async (e) => {
                e.currentTarget.blur();
                setSelectedRecord(undefined);
                setAddEditTeamsModalVisibility(true);
              }}
              variant="primary"
              className="ms-2 ss-light-button"
            >
              <img src={Add} alt=" " />
              <span>Add</span>
            </Button>
          </div>
        </div>
      </>
    );
  };
  //style for dropdown
  const customStyles = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };
  return (
    <div
      onClick={() => {
        Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
          page: mixpanel_contants.SPORTS_TEAMS,
        });
      }}
      className="container-fluid pt-3"
    >
      {/* Filter Panel */}
      <Row>
        <Col>
          <FilterPanel
            dashboard={mixpanel_contants.SPORTS_TEAMS}
            setPageNumber={setpageNumber}
            searchPlaceholder="Search for Sports, Teams..."
            onReset={() => {
              setpageNumber(1);
              setsearchData(null);
              fetchData();
              setdropVal({
                sport: "",
                team: "",
              });
            }}
            onSubmit={onSubmit}
            renderAdvancedPanel={(register, handleSubmit, errors, control) => (
              <Row>
                <Col>
                  <Form.Group className="pt-3" as={Row}>
                    <Form.Label column sm="4">
                      Sport
                    </Form.Label>
                    <Col sm="8">
                      <Controller
                        name="sportId"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              value={dropVal.sport}
                              onChange={(e) => {
                                field.onChange(e.id);
                                setdropVal({
                                  ...dropVal,
                                  sport: e,
                                });
                              }}
                              styles={customStyles}
                              options={sportsList}
                              getOptionLabel={(option) => `${option.sportName}`}
                            />
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Team
                    </Form.Label>
                    <Col sm="8">
                      <Controller
                        name="teamId"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              value={dropVal.team}
                              onChange={(e) => {
                                field.onChange(e.id);
                                setdropVal({
                                  ...dropVal,
                                  team: e,
                                });
                              }}
                              styles={customStyles}
                              options={teamsList}
                              getOptionLabel={(option) => `${option.teamName}`}
                            />
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            )}
          />
        </Col>
      </Row>

      {/* Data Table */}
      <div className="container-fluid d-flex gap-20">
        <div className="loans_div">
          <QuickTixTable
            headerName=""
            paging={sportsPaging}
            columns={sportsCol}
            data={allSports || []}
            headOptions={headOptions1}
            setpageNumber={setpageNumber}
            sortDir={sortDir}
            setSortDir={setSortDir}
            sortField={sortField}
            setSortField={setSortField}
            renderRow={(rowData, index) => (
              <TableRowCard
                key={rowData.id}
                columns={rowData.nodata ? columnError : sportsCol}
                rowData={rowData}
              />
            )}
          />
        </div>
        <div className="loans_div">
          <QuickTixTable
            headerName=""
            paging={teamsPaging}
            columns={teamsCol}
            data={allTeams || []}
            headOptions={headOptions2}
            setpageNumber={setpageNumber}
            sortDir={sortDir}
            setSortDir={setSortDir}
            sortField={sortField}
            setSortField={setSortField}
            renderRow={(rowData, index) => (
              <TableRowCard
                key={rowData.id}
                columns={rowData.nodata ? columnError : teamsCol}
                rowData={rowData}
              />
            )}
          />
        </div>
      </div>

      {/* Modal Popups */}
      <AddEditSportsModal
        isVisible={addSportsModalVisible}
        handleClose={() => setAddEditSportsModalVisibility(false)}
        onSubmit={(data) => onEntrySubmitted(data, 1)}
        selectedRecord={selectedRecord}
      />
      <AddEditTeamsModal
        isVisible={addTeamsModalVisible}
        handleClose={() => setAddEditTeamsModalVisibility(false)}
        onSubmit={(data) => onEntrySubmitted(data, 2)}
        selectedRecord={selectedRecord}
      />
      <DeleteConfirmationModal
        isVisible={deleteConfirmationVisible}
        handleClose={() => setDeleteConfirmationVisibility(false)}
        onConfirmation={() => onDeleteAccount(selectedRecordId)}
      />
    </div>
  );
};

export default withLoader(SportsAndTeams);
