import {
  api,
  getAuthHeaders,
  post,
  update,
  del,
  get,
  getCSV,
  getPDF,
} from "../services.common";

const createAccountCharges = async (data) => {
  const url = `${api}AccountCharges/create?accountId=${data.Id}`;
  const authHeader = getAuthHeaders();
  return await post(url, data.formData, { ...authHeader });
};

const updateAccountCharges = async (data) => {
  const url = `${api}AccountCharges/update/${data.formData.id}?accountId=${data.Id}`;
  const authHeader = getAuthHeaders();
  return await update(url, data.formData, { ...authHeader });
};

const deleteAccountCharges = async (data) => {
  const url = `${api}AccountCharges/delete/${data.mainId}?accountId=${data.id}`;
  const authHeader = getAuthHeaders();
  return await del(url, { ...authHeader });
};

const exportCSVAccountCharges = async (id) => {
  const url = `${api}AccountCharges/exportcsv?accountId=${id}`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const exportPdfAccountCharges = async (id) => {
  const url = `${api}AccountCharges/exportpdf?accountId=${id}`;
  const authHeader = getAuthHeaders();
  return await getPDF(url, { ...authHeader }, "AccountCharges.pdf");
};

const getAccountCharges = async (data) => {
  const sortDir = data.sortDir == false ? "Descending" : "Ascending";
  const url = `${api}AccountCharges/getall?accountId=${data.id}&PageNumber=${
    data.pageNumber
  }&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${
    data.sortField || ""
  }`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getSelectedCharges = async (data) => {
  const url = `${api}/AccountCharges/get/${data.id}?accountId=${data.accID}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const accountChargesServices = {
  createAccountCharges,
  updateAccountCharges,
  deleteAccountCharges,
  getSelectedCharges,
  getAccountCharges,
  exportPdfAccountCharges,
  exportCSVAccountCharges,
};
