import { Modal, Button } from "react-bootstrap";


const MovePOConfirmationModal = ({ selectedRecord, onConfirmation, isVisible, handleClose }) => {


    return (
        <Modal dialogClassName="modal-md" show={isVisible} onHide={handleClose}>
            <div className=" d-flex flex-row justify-content-flex-start align-items-center p-2 m-2">
                <h5>Move PO ?</h5>
            </div>
            <div className="d-flex flex-column justify-content-evenly align-items-center px-2 mx-2">
                {
                    selectedRecord?.showInNoSkyBoxRef == false ?
                        <p className="my-text">Do you really want to move this PO from the All to No SkyBox Ref section ?</p> :
                        <p className="my-text">Do you really want to move this PO from the No SkyBox Ref to All section ?</p>
                }
            </div>
            <Modal.Footer className="justify-content-center">
                <Button className="ss-modal-primary-btn" onClick={() => { onConfirmation(selectedRecord?.id) }}>
                    Move
                </Button>
                <Button variant="light" className="ss-modal-secondary-btn" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MovePOConfirmationModal;