import React, { useState, useEffect, useRef } from "react";

import {
  errorMessage,
  successMessage,
} from "../../../data/reducers/alerts/alert.reducer";
import { Button, Col, Form, Row } from "react-bootstrap";

// import AddEditAddressModal from "./modals/AddEditAddress";
import { withLoader } from "../../../utils/hoc/withLoader";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../utils/formatter/dateFormatter";

//components
import FilterPanel from "../../../shared/components/panels/filter-panel/FilterPanel";
import QuickTixTable from "../../../shared/components/table/QuickTixTable";
import TableRowCard from "../../../shared/components/table/TableRowCard";
import { columnError } from "../../../shared/components/table/data/error";

// images
import Upload from "../../../images/upload.svg";
import Download from "../../../images/download.svg";

import "../../../shared/styles/ModalStyles.scss";
import { formatMoney } from "../../../utils/formatter/currencyFormatter";
import {
  downloadTemplateMarket,
  filterMarket,
  getAllMarket,
  uploadTemplateMarket,
} from "../../../data/reducers/reveal-market/reveal-market.reducer";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Mixpanel,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../../mixpanel";

const RevealMarket = ({ setBusy }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  //---------------------------------------------------------------------------------------------------------------//
  const queryParameters = new URLSearchParams(window.location.search);
  const page = queryParameters.get("page");

  const [filterUrlData, setFilterUrlData] = useState(null);
  const [filterArray, setFilterArray] = useState([]);
  const [sortField, setSortField] = useState(null);
  const [sortDir, setSortDir] = useState(true);
  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.REVEAL_MARKET,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.REVEAL_MARKET,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (filterUrlData) {
      onSubmit(filterUrlData);
      setFilterArray([
        { key: "searchText", value: filterUrlData?.searchText },
        { key: "balance", value: filterUrlData?.balance },
        { key: "customer", value: filterUrlData?.customer },
        { key: "date", value: filterUrlData?.date },
        { key: "eventDate", value: filterUrlData?.eventDate },
        { key: "eventStatus", value: filterUrlData?.eventStatus },
        { key: "fullfillment", value: filterUrlData?.fullfillment },
        { key: "externalRef", value: filterUrlData?.externalRef },
        { key: "invoice", value: filterUrlData?.invoice },
        { key: "payment", value: filterUrlData?.payment },
        { key: "performer", value: filterUrlData?.performer },
      ]);
    }
  }, [filterUrlData]);

  useEffect(() => {
    fetchMarketData();
  }, [sortDir, sortField]);

  //-------------------- Param navigation ---------------//

  useEffect(() => {
    if (page) {
      setpageNumber(page);
      setFilterUrlData({
        searchText: queryParameters.get("searchText"),
        balance: queryParameters.get("balance"),
        customer: queryParameters.get("customer"),
        date: queryParameters.get("date"),
        eventDate: queryParameters.get("eventDate"),
        eventStatus: queryParameters.get("eventStatus"),
        fullfillment: queryParameters.get("fullfillment"),
        externalRef: queryParameters.get("externalRef"),
        invoice: queryParameters.get("invoice"),
        payment: queryParameters.get("payment"),
        performer: queryParameters.get("performer"),
      });
    } else {
      navigateToMainPage();
    }
  }, [page, location]);

  //--------------------------- filter queries ------------------------//
  const searchQuery = () => {
    var array = window.location.search.slice(1).split("&&");
    var query = "";
    for (let i = 1; i < array.length; i++) {
      query += "&&" + array[i];
    }
    return query;
  };

  //-------------- on page size change --------------//
  const navigateToMainPage = () => {
    history.push(`/settings/reveal-market?page=1`);
  };

  const previousPage = () => {
    if (Number(pageNumber) > 1) {
      history.push(
        `/settings/reveal-market?page=${Number(pageNumber) - 1}${searchQuery()}`
      );
      setpageNumber(Number(pageNumber - 1));
    }
  };

  const nextPage = () => {
    history.push(
      `/settings/reveal-market?page=${Number(pageNumber) + 1}${searchQuery()} `
    );
    setpageNumber(Number(pageNumber + 1));
  };

  //--------------------- filter Navigation -------------------//

  const filterNavigation = (data) => {
    // var url = `/settings/reveal-market?page=${data.searchText ? 1 : page}`
    var url = `/settings/reveal-market?page=1`;

    if (data.searchText) {
      url += `&&searchText=${data.searchText}`;
      setpageNumber(1);
    }
    if (data.balance) url += `&&balance=${data.balance}`;
    if (data.customer) url += `&&customer=${data.customer}`;
    if (data.date) url += `&&date=${data.date}`;
    if (data.eventDate) url += `&&eventDate=${data.eventDate}`;
    if (data.eventStatus) url += `&&eventStatus=${data.eventStatus}`;
    if (data.externalRef) url += `&&externalRef=${data.externalRef}`;
    if (data.fullfillment) url += `&&fullfillment=${data.fullfillment}`;
    if (data.invoice) url += `&&invoice=${data.invoice}`;
    if (data.payment) url += `&&payment=${data.payment}`;
    if (data.performer) url += `&&performer=${data.performer}`;

    history.push(url);
  };

  //---------------------- change Page Size and make page=1 by default ---------------------//
  const changePageSize = () => {
    let search = String(window.location.search).slice(1);
    const str = search.split("&&");
    let remainStr = "";
    for (let i = 1; i < str.length; i++) {
      remainStr += "&&" + str[i];
    }
    history.push(window.location.pathname + "?page=1" + remainStr);
  };

  //---------------------------------------------------------------------------------------------------------------//

  const fileRef = useRef();
  const { revealMarket, paging } = useSelector((state) => state.revealMarket);

  //use states
  const [addAccountModalVisible, setAddEditAccountModalVisibility] =
    useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisibility] =
    useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(undefined);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [pageSize, setpageSize] = useState(40);
  const [pageNumber, setpageNumber] = useState(1);
  const [searchData, setsearchData] = useState(null);

  const fetchMarketData = async () => {
    try {
      setBusy(true);
      const data = {
        pageNumber: page,
        pageSize: pageSize,
        sortDir,
        sortField,
      };
      const response = await dispatch(getAllMarket(data));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      pageNumber,
      pageSize,
      sortField,
      sortDir,
      searchText: data?.searchText?.trim() || "",
    };
    setBusy(true);
    if (
      data?.searchString ||
      data?.balance ||
      data?.customer ||
      data?.date ||
      data?.eventDate ||
      data?.eventStatus ||
      data?.externalRef ||
      data?.fullfillment ||
      data?.invoice ||
      data?.payment ||
      data?.performer
    ) {
      setsearchData(formData);
      await dispatch(filterMarket(formData));
    }
    setBusy(false);
  };

  //address table data
  const marketCol = [
    {
      columnName: "Invoice",
      sort: true,
      sortName: "Invoice",
      render: (data) => <>{data.invoice}</>,
      flexGrow: 0.5,
    },
    {
      columnName: "Customer",
      sort: true,
      sortName: "Customer",
      render: (data) => <>{data.customer}</>,
    },
    {
      columnName: "Date",
      sort: true,
      sortName: "Date",
      render: (data) => <>{formatDate(data.date)}</>,
      flexGrow: 0.5,
    },
    {
      columnName: (
        <>
          External <br /> Ref
        </>
      ),
      sort: true,
      sortName: "ExternalRef",
      render: (data) => <>{data.externalRef}</>,
      flexGrow: 0.5,
    },

    {
      columnName: "Performer",
      columnKey: "performer",
      sort: true,
      sortName: "Performer",
      flexGrow: 0.5,
      render: (data) => <>{data.performer}</>,
    },
    {
      columnName: (
        <>
          Event <br /> Date
        </>
      ),
      sort: true,
      sortName: "EventDate",
      flexGrow: 0.5,
      render: (data) => <>{formatDate(data.eventDate)}</>,
    },
    {
      columnName: (
        <p className="mb-0">
          Event <br /> Status
        </p>
      ),
      sort: true,
      sortName: "EventStatus",
      columnKey: "eventStatus",
      flexGrow: 0.5,
    },
    {
      columnName: "Payment",
      sort: true,
      sortName: "Payment",
      columnKey: "payment",
      flexGrow: 0.5,
    },
    {
      columnName: "Fulfillment",
      sort: true,
      sortName: "Fullfillment",
      columnKey: "fullfillment",
      flexGrow: 0.5,
    },
    {
      columnName: "Balance",
      columnKey: "balance",
      sort: true,
      sortName: "Balance",
      flexGrow: 0.5,
      render: (data) => <>{formatMoney(data.balance)}</>,
    },
  ];

  //download csv file
  const onExportCSV = async () => {
    // await dispatch(exportCSVAevealMarket())
  };

  const exportSelectedAsCSV = async () => {
    setBusy(true);
    if (searchData) {
      // await dispatch(exportSelectedAsCSVAddress(searchData));
    }
    setBusy(false);
  };

  const downloadTemplate = async () => {
    setBusy(true);
    await dispatch(downloadTemplateMarket());
    setBusy(false);
  };

  const handleChange = async (e) => {
    try {
      setBusy(true);
      const [file] = e.target.files;
      const formData = new FormData();
      formData.append("file", file);
      const response = await dispatch(uploadTemplateMarket(formData));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
        e.target.value = "";
      } else {
        window.location.reload();
        dispatch(successMessage("Upload successful"));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  //table head
  const headOptions = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small>Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue="40"
              onChange={(e) => {
                setpageSize(e.target.value);
                changePageSize();
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div className="d-flex mt-3 align-items-center justify-content-end">
          <Button
            onClick={downloadTemplate}
            variant="primary"
            className="mx-2 ss-light-button"
          >
            <img src={Download} alt=" " />
            <span>Download Template</span>
          </Button>
          <div>
            <Button
              onClick={() => fileRef.current.click()}
              variant="primary"
              className="mx-2 ss-light-button"
            >
              <img src={Upload} alt=" " />
              <span>Upload</span>
            </Button>
            <input
              ref={fileRef}
              onChange={handleChange}
              multiple={false}
              type="file"
              hidden
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      onClick={() => {
        Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
          page: mixpanel_contants.REVEAL_MARKET,
        });
      }}
      className="container-fluid pt-3"
    >
      {/* Filter Panel */}
      <Row>
        <Col>
          <FilterPanel
            defaultValues={filterArray}
            setPageNumber={setpageNumber}
            dashboard={mixpanel_contants.REVEAL_MARKET}
            searchPlaceholder="Search for Invoice, Date, Event or Balance..."
            onReset={() => {
              setpageNumber(1);
              setsearchData(null);
              navigateToMainPage();
              fetchMarketData();
            }}
            onSubmit={filterNavigation}
            renderAdvancedPanel={(register, handleSubmit, errors, control) => (
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Invoice
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="Invoice"
                        {...register("invoice")}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group className="pt-3" as={Row}>
                    <Form.Label column sm="4">
                      Date
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="date"
                        placeholder="Date"
                        {...register("date")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group className="pt-3" as={Row}>
                    <Form.Label column sm="4">
                      Customer
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="Customer"
                        {...register("customer")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group className="pt-3" as={Row}>
                    <Form.Label column sm="4">
                      External Ref
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="External Ref"
                        {...register("externalRef")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Performer
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Performer"
                        {...register("performer")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Event Date
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="date"
                        placeholder="Event Date"
                        {...register("eventDate")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Event Status
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Event Status"
                        {...register("eventStatus")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Fulfillment
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="Fulfillment"
                        {...register("fullfillment")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Balance
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="number"
                        step={0.01}
                        placeholder="Balance"
                        {...register("balance")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Payment
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Payment"
                        {...register("payment")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            )}
          />
        </Col>
      </Row>

      {/* Data Table */}
      <QuickTixTable
        previousPage={previousPage}
        nextPage={nextPage}
        paramerterNavigation={true}
        headerName="Reveal Market"
        paging={paging}
        columns={marketCol}
        data={revealMarket || []}
        headOptions={headOptions}
        setpageNumber={setpageNumber}
        sortDir={sortDir}
        setSortDir={setSortDir}
        sortField={sortField}
        setSortField={setSortField}
        renderRow={(rowData, index) => (
          <TableRowCard
            key={rowData.id}
            columns={rowData.nodata ? columnError : marketCol}
            rowData={rowData}
          />
        )}
      />
    </div>
  );
};

export default withLoader(RevealMarket);
