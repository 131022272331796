import { useDispatch, useSelector } from "react-redux";
import CardPanel from "../../../shared/components/panels/card-panel/CardPanel";
import React, { useEffect, useMemo } from "react";
import { formatMoney } from "../../../utils/formatter/currencyFormatter";
import { errorMessage } from "../../../data/reducers/alerts/alert.reducer";
import { formatPlanDate } from "../../../utils/formatter/dateFormatter";
import { IS_REVIEWED_NUM_TO_STR } from "../TransactionDashboard";
import {
  fetchTransactionsCards,
  flushTransactionsCards,
} from "../../../data/reducers/transactions/cached-transaction-cards.reducer";

const TransactionCards = ({ filters, setCardError }) => {
  const dispatch = useDispatch();

  const {
    data: cachedTransactionCardsObject,
    error,
    loading,
    callApi,
  } = useSelector((state) => state.cachedTransactionsCards);

  useEffect(() => {
    if (error) {
      dispatch(flushTransactionsCards());
      setCardError(error);
    }
  }, []);

  const { data: transactionsCards } = useMemo(() => {
    const data =
      cachedTransactionCardsObject?.[
        JSON.stringify({
          ...filters,
          StartDate: formatPlanDate(filters.StartDate),
          EndDate: formatPlanDate(filters.EndDate),
          isReviewed: IS_REVIEWED_NUM_TO_STR[Number(filters.isReviewed)],
        })
      ];
    if (!data) {
      return { data: undefined };
    }
    return data;
  }, [cachedTransactionCardsObject, filters]);

  useEffect(() => {
    if (error) {
      dispatch(errorMessage(error?.message ?? "Something went wrong"));
    } else if (!transactionsCards && callApi != null) {
      dispatch(
        fetchTransactionsCards({
          ...filters,
          StartDate: formatPlanDate(filters.StartDate),
          EndDate: formatPlanDate(filters.EndDate),
          isReviewed: IS_REVIEWED_NUM_TO_STR[Number(filters.isReviewed)],
        })
      );
    }
  }, [error, callApi]);

  const data = useMemo(
    () => [
      {
        head: "Total Charges",
        val:
          transactionsCards?.totalSpent === null ||
          transactionsCards?.totalSpent === undefined
            ? "--"
            : formatMoney(transactionsCards?.totalSpent),
      },
      {
        head: "Total Expected",
        val:
          transactionsCards?.totalExpected === null ||
          transactionsCards?.totalExpected === undefined
            ? "--"
            : formatMoney(transactionsCards?.totalExpected),
      },
      {
        head: "Total Refund",
        val:
          transactionsCards?.totalRefund === null ||
          transactionsCards?.totalRefund === undefined
            ? "--"
            : formatMoney(transactionsCards?.totalRefund),
      },
      {
        head: "Total Transactions",
        val:
          transactionsCards?.totalTransaction === null ||
          transactionsCards?.totalTransaction === undefined
            ? "--"
            : transactionsCards?.totalTransaction || 0,
      },
    ],
    [transactionsCards]
  );

  return <CardPanel data={data} loader={loading} />;
};

export default React.memo(TransactionCards);
