import Logo from '../../../images/celtics_logo.svg';
import VerticalChargesCard from '../../../shared/components/accountActions/charges-card';
import Link from '../../../images/Link.svg';
import '../../../shared/styles/DashboardStyles.scss';
import { Container } from 'react-bootstrap';

const PaypalDetails = ({ details }) => {
	return (
		<>
			<Container className='root pt-3 mb-3' fluid>
				<div className='my-title h-20 d-flex justify-content-between align-items-center'>
					{/* <img className='logo-svg' src={Logo} alt='logo' /> */}
					<p className='mylogo-title'>{details?.name}</p>
					{/* <img className='link-svg' src={Link} alt='link' /> */}
				</div>
				<hr className='' />
				<div className='body h-75 w-100 d-flex flex-row justify-content-between'>
					<div className='my-col d-flex flex-column justify-content-evenly'>
						<div className='my-row d-flex flex-row align-items-center justify-content-between w-100 h-25'>
							<p className='my-ph h-100 w-50'>Id</p>
							<p className='my-p h-100 w-50'>{details?.id}</p>
						</div>
						<div className='my-row d-flex flex-row align-items-center justify-content-between w-100 h-25'>
							<p className='my-ph h-100 w-50'>Status</p>
							<p className='my-p h-100 w-50'>{details?.status}</p>
						</div>
					</div>

					<div className='my-col d-flex flex-column justify-content-between align-items-end'>
						<VerticalChargesCard
							className='py-3 px-3'
							bg='rgba(24, 144, 255, 0.2)'
							fontbg='#0241A0'
							text='Total Spent'
							price={details?.totalSpent}
						/>

						<VerticalChargesCard
							bg='rgba(188, 219, 1, 0.2)'
							fontbg='#849900'
							text='Total Transactions'
							price={details?.totalTransactions}
						/>
					</div>
				</div>
			</Container>
		</>
	);
};

export default PaypalDetails;
