import { Route, Switch } from 'react-router-dom';
import BIReportDashboard from '../../../pages/bi-report/BIReportDashboard';

const LoansRoutes = () => {
	return (
		<Switch>
			<Route path='/bi-report' exact>
				<BIReportDashboard />
			</Route>
		</Switch>
	);
};

export default LoansRoutes;
