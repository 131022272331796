import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { accountPurchaseOrdersServices } from "../../services/accounts/account-purchase-orders.service";
import fileDownload from "js-file-download";
import { flushPurchaseOrders } from "../purchaseOrders/cached-po.reducer";
import { flushPurchaseOrdersCards } from "../purchaseOrders/cached-po-cards.reducer";

export const createAccountPurchaseOrders = createAsyncThunk(
  "accounts/createAccountPurchaseOrders",
  async (payload, thunkAPI) => {
    const response =
      await accountPurchaseOrdersServices.createAccountPurchaseOrders(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushPurchaseOrders());
      thunkAPI.dispatch(flushPurchaseOrdersCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const updateAccountPurchaseOrders = createAsyncThunk(
  "accounts/UpdateAccountPurOrd",
  async (payload, thunkAPI) => {
    const response =
      await accountPurchaseOrdersServices.updateAccountPurchaseOrders(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushPurchaseOrders());
      thunkAPI.dispatch(flushPurchaseOrdersCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const deleteAccountPurchaseOrders = createAsyncThunk(
  "accounts/DeleteAccountPurOrd",
  async (payload, thunkAPI) => {
    const response =
      await accountPurchaseOrdersServices.deleteAccountPurchaseOrders(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushPurchaseOrders());
      thunkAPI.dispatch(flushPurchaseOrdersCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAccountsPurchaseOrders = createAsyncThunk(
  "accounts/getAccountsPurchaseOrders",
  async (payload, thunkAPI) => {
    const response =
      await accountPurchaseOrdersServices.getAccountsPurchaseOrders(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportCSVAccountPurchase = createAsyncThunk(
  "accounts/exportCSVAccountPurchase",
  async (payload, thunkAPI) => {
    const response =
      await accountPurchaseOrdersServices.exportCSVAccountPurchase(payload);
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "AccountPurchaseOrders.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportPdfAccountPurchase = createAsyncThunk(
  "accounts/exportPdfAccountPurchase",
  async (payload, thunkAPI) => {
    const response =
      await accountPurchaseOrdersServices.exportPdfAccountPurchase(payload);
    // if (response.isSuccessful === true) {
    //   // fileDownload(response.data, "AccountPurchaseOrders.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

export const getSelectedPurchaseOrder = createAsyncThunk(
  "accounts/getSelectedPurchaseOrder",
  async (payload, thunkAPI) => {
    const response =
      await accountPurchaseOrdersServices.getSelectedPurchaseOrders(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

const accountPurchaseOrdersSlice = createSlice({
  name: "accountPurchaseOrders",
  initialState: {
    paging: {},
    accountsPurchaseOrders: [],
    selectedAccPurchaseOrders: {},
  },
  reducers: {
    removeSelected: (state) => {
      state.selectedAccPurchaseOrders = {};
    },
  },
  extraReducers: {
    [createAccountPurchaseOrders.fulfilled]: (state, action) => {
      state.accountsPurchaseOrders.push(action.payload);
    },
    [getAccountsPurchaseOrders.fulfilled]: (state, action) => {
      state.accountsPurchaseOrders = action.payload.data;
      state.paging = action.payload.paging;
    },
    [updateAccountPurchaseOrders.fulfilled]: (state, action) => {
      const index = state.accountsPurchaseOrders.findIndex(
        (account) => account.id == action.payload.id
      );
      state.accountsPurchaseOrders[index] = action.payload;
      state.selectedAccPurchaseOrders = action.payload;
    },
    [deleteAccountPurchaseOrders.fulfilled]: (state, action) => {
      state.accountsPurchaseOrders = [
        ...state.accountsPurchaseOrders.filter(
          (account) => account.id !== action.payload.id
        ),
      ];
    },
  },
});

export const { removeSelected } = accountPurchaseOrdersSlice.actions;

export default accountPurchaseOrdersSlice.reducer;
