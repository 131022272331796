import React from "react";
import { Col, Row } from "react-bootstrap";
import { formatMoney } from "../../../utils/formatter/currencyFormatter";

const ChargesCard = ({ text, number, price, bg, color }) => {
	return (
		<Row
			className="align-items-center py-2 rounded"
			style={{
				backgroundColor: `${bg}`,
				color,
			}}
		>
			<Col sm={6} style={{ color }}>
				{text}
			</Col>
			<Col sm={5} style={{ fontWeight: "600", color }}>
				{number ? price : formatMoney(price)}
			</Col>
		</Row>
	);
};

export default ChargesCard;
