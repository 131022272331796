import {
  api,
  getAuthHeaders,
  post,
  update,
  del,
  get,
  getCSV,
  getPDF
} from "../services.common";

const createAccountPurchaseOrders = async (data) => {
  const url = `${api}AccountPurchaseOrders/create?accountId=${data.Id}`;
  const authHeader = getAuthHeaders();
  return await post(url, data.formData, { ...authHeader });
};
const updateAccountPurchaseOrders = async (data) => {
  const url = `${api}AccountPurchaseOrders/update/${data.formData.id}?accountId=${data.Id}`;
  const authHeader = getAuthHeaders();
  return await update(url, data.formData, { ...authHeader });
};
const deleteAccountPurchaseOrders = async (data) => {
  const url = `${api}AccountPurchaseOrders/delete/${data.mainId}?accountId=${data.id}`;
  const authHeader = getAuthHeaders();
  return await del(url, { ...authHeader });
};

const getAccountsPurchaseOrders = async (data) => {
  const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
  const url = `${api}AccountPurchaseOrders/getall?accountId=${data.id}&PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const exportCSVAccountPurchase = async (id) => {
  const url = `${api}AccountPurchaseOrders/exportcsv?accountId=${id}`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const exportPdfAccountPurchase = async (id) => {
  const url = `${api}AccountPurchaseOrders/exportpdf?accountId=${id}`;
  const authHeader = getAuthHeaders();
  return await getPDF(url, { ...authHeader }, "AccountsPurchaseOrders.pdf");
};

const getSelectedPurchaseOrders = async (data) => {
  const url = `${api}AccountPurchaseOrders/get/${data.id}?accountId=${data.accID}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const accountPurchaseOrdersServices = {
  createAccountPurchaseOrders,
  updateAccountPurchaseOrders,
  deleteAccountPurchaseOrders,
  getAccountsPurchaseOrders,
  getSelectedPurchaseOrders,
  exportCSVAccountPurchase,
  exportPdfAccountPurchase
};
