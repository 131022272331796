import "./TableStyle.scss"
const SelectRowCard = ({ columns, rowData, isSelected }) => {
  return (
    <div
      className={!isSelected ?
        "select-table-card my-3 d-flex justify-content-between px-3 py-2 align-items-center" :
        "active-table-card my-3 d-flex justify-content-between px-3 py-2 align-items-center"
      }
    >
      {columns?.map((column, index) => (
        <small
          key={index}
          style={{ flexGrow: column.flexGrow > 0 ? column.flexGrow : 1, flexBasis: 0, textAlign: "center", overflow: "hidden" }}
          className="mx-2">
          {
            column.render ?
              // render column according to the `render` function if configured
              column.render(rowData, index) :

              // else, render column according to the `columnKey` parameter
              rowData[column.columnKey]
          }
        </small>
      ))}
    </div >
  );
};

export default SelectRowCard;
