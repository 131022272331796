import { Route, Switch } from 'react-router-dom';
import FinanceDashboard from '../../../pages/finance/FinanceDashboard';

const LoansRoutes = () => {
	return (
		<Switch>
			<Route path='/finance' exact>
				<FinanceDashboard />
			</Route>
		</Switch>
	);
};

export default LoansRoutes;
