import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';
import { RevealMarketServices } from '../../services/reveal-market/reveal-market.service';

export const getAllMarket = createAsyncThunk(
	'market/getAllMarket',
	async (payload, thunkAPI) => {
		const response = await RevealMarketServices.getAllMarket(payload);
		if (response.isSuccessful === true) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const filterMarket = createAsyncThunk(
	'market/filterMarket',
	async (payload, thunkAPI) => {
		const response = await RevealMarketServices.filterMarket(payload);
		if (response.isSuccessful === true) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const downloadTemplateMarket =  createAsyncThunk(
    "market/downloadTemplateMarket",
    async (thunkAPI) => {
        const response = await RevealMarketServices.downloadTemplateMarket();
        if (response.isSuccessful === true) {
            fileDownload(response?.data?.data, "Reveal Market Template.csv")
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

export const uploadTemplateMarket = createAsyncThunk(
    "market/downloadTemplateMarket",
    async (payload, thunkAPI) => {
        const response = await RevealMarketServices.uploadTemplateMarket(payload);
        if (response.isSuccessful === true) {
            return response.data; 
        } else {
            thunkAPI.rejectWithValue(response.message);
            throw new Error(response.message);
        }
    }
);

const RevealMarketSlice = createSlice({
	name: 'revealMarket',
	initialState: {
		revealMarket: [],
		paging: {},
	},
	extraReducers: {
		[getAllMarket.fulfilled]: (state, action) => {
			state.revealMarket = action.payload.data;
			state.paging = action.payload.paging;
		},
		[filterMarket.fulfilled]: (state, action) => {
			state.revealMarket = action.payload.data;
			state.paging = action.payload.paging;
		},
	},
});

export default RevealMarketSlice.reducer;

