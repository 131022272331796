import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Button, Card, Form, InputGroup } from "react-bootstrap";

// images / icons
import Search from "../../../../images/search.svg";
import Filter from "../../../../images/filter.svg";
import Reset from "../../../../images/reset.svg";
import WhiteFilter from "../../../../images/filter_white.svg";

// styles
import "./FilterPanel.scss";
import {
  Mixpanel,
  mixpanel_button_name,
  mixpanel_event_constants,
} from "../../../../mixpanel";

const FilterPanel2024 = ({
  searchPlaceholder,
  renderAdvancedPanel,
  setsearchData,
  noFilter,
  resetValues,
  dashboard,
  onSearch,
  onReset,
  filtersCount,
  defaultValues,
  defaultOpen = false,
}) => {
  const [filterPanelVisible, setFilterPanelVisible] = useState(false);

  const closePanel = () => {
    setFilterPanelVisible(false);
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    resetField,
    getValues,
    setValue,
  } = useForm();

  useEffect(() => {
    let flag = false;
    if (defaultValues) {
      for (let i = 0; i < defaultValues.length; i++) {
        setValue(defaultValues[i].key, defaultValues[i].value);
        if (defaultValues[i].key != "searchString" && defaultValues[i].value) {
          flag = true;
        }
      }
    }
    if (defaultOpen) {
      setFilterPanelVisible(true);
    }
  }, [defaultValues]);

  return (
    <Form
      className="d-flex flex-column filter-panel my-3"
      onSubmit={(e) => {
        e.preventDefault();
      }}
      autoComplete="off"
    >
      {/* Basic Form */}
      <div className="d-flex ">
        <Form.Group className="flex-grow-1 ">
          <InputGroup>
            <InputGroup.Text className="search-prepend " id="basic-addon1">
              <img className="search-svg" src={Search} alt="" />
            </InputGroup.Text>
            <Form.Control
              className="search-control "
              placeholder={searchPlaceholder}
              isInvalid={!!errors.searchString}
              disabled={filterPanelVisible}
              aria-describedby="basic-addon1"
              {...register("searchString")}
            />
          </InputGroup>
        </Form.Group>

        {/* Action Buttons */}
        <div className="d-flex">
          <Button
            variant="primary"
            className="filter-panel-button ss-light-button"
            onClick={(e) => {
              e.currentTarget.blur();
              onSearch(getValues(), filterPanelVisible);
              !filterPanelVisible &&
                Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                  page: dashboard,
                  buttonName: mixpanel_button_name.SEARCH,
                });
            }}
            type={noFilter ? "" : "submit"}
          >
            <img className="filter-search-svg" src={Search} alt="" />
            <span>Search</span>
          </Button>
          {!noFilter && (
            <Button
              variant="primary"
              id="filterButton"
              className={
                !filterPanelVisible
                  ? "filter-panel-button ss-light-button"
                  : "active ss-light-button"
              }
              active={filterPanelVisible}
              onClick={(e) => {
                setFilterPanelVisible(!filterPanelVisible);
                e.currentTarget.blur();
                Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                  page: dashboard,
                  buttonName: mixpanel_button_name.FILTER,
                });
              }}
            >
              <img
                className="filter-svg"
                src={filterPanelVisible ? WhiteFilter : Filter}
                alt=""
              />
              <span>{filtersCount === 0 ? "" : filtersCount} Filters</span>
            </Button>
          )}
          <Button
            variant="primary"
            className="filter-panel-button ss-light-button"
            onClick={(e) => {
              onReset();
              if (resetValues) {
                resetValues.forEach((field) =>
                  resetField(field?.name, { defaultValue: field.resetValue })
                );
                reset();
              } else {
                reset();
              }
              e.currentTarget.blur();
              setFilterPanelVisible(false);
            }}
          >
            <img className="filter-reset-svg" src={Reset} alt="" />
            <span>{noFilter ? "Clear" : "Reset Filter"}</span>
          </Button>
        </div>
      </div>

      {/* Custom Panel */}
      {!noFilter && (
        <div className={`pt-3 ${filterPanelVisible ? "" : "d-none"}`}>
          <Card>
            <Card.Body>
              {renderAdvancedPanel(
                register,
                handleSubmit,
                errors,
                control,
                closePanel,
                getValues
              )}
            </Card.Body>
          </Card>
        </div>
      )}
    </Form>
  );
};

export default FilterPanel2024;
