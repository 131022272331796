/* eslint-disable no-mixed-spaces-and-tabs */
import { Button } from 'react-bootstrap';

import Back from '../../../../images/back.svg';
import Forward from '../../../../images/forward.svg';



const FooterPart = ({ paging, setpageNumber, setSortField, setSortDir, sortField, sortDir, previousPage, nextPage, paramerterNavigation }) => {


	return (
		<div className='table-footer d-flex p-2 rounded align-items-center' style={{ background: '#ececec' }}>
			{paging && paging.itemsCount == 0 ? (
				<small className='pagination flex-grow-1'>
					Showing 0 records on page 1
				</small>
			) : (
				<small className='pagination flex-grow-1'>
					Showing{' '}
					{parseInt(paging.pageSize) * parseInt(paging.currentPage) -
						(parseInt(paging.pageSize) - 1) || 0}{' '}
					to{' '}
					{parseInt(paging.totalPages) <= parseInt(paging.currentPage)
						? parseInt(paging.totalItemsCount)
						: parseInt(paging.pageSize) *
						parseInt(paging.currentPage) || 0}{' '}
					of {paging.totalItemsCount || 0} records on page{' '}
					{paging.currentPage}
				</small>
			)}
			<div className='table-navigation d-flex'>
				<Button
					variant='link'
					className='table-navigation-button'
					disabled={paging.currentPage == 1}
					onClick={() => {
						setSortField(sortField);
						setSortDir(sortDir)
						if (paramerterNavigation) {
							previousPage();
						} else {
							setpageNumber((prevNum) => prevNum - 1)
						}
					}}>
					<img src={Back} alt=' ' />
				</Button>
				<Button
					variant='link'
					className='table-navigation-button ms-2'
					disabled={paging.totalPages <= paging.currentPage}
					onClick={() => {
						setSortField(sortField);
						setSortDir(sortDir == true ? true : false)
						if (paramerterNavigation) {
							nextPage();
						} else {
							setpageNumber((prevNum) => prevNum + 1)
						}
					}}>
					<img src={Forward} alt=' ' />
				</Button>
			</div>
		</div >
	);
};

export default FooterPart;
