//reducers
import {
  errorMessage,
  successMessage,
} from "../../data/reducers/alerts/alert.reducer";
import { getVendorById } from "../../data/reducers/vendors/vendors.reducer";
import {
  createVendorRules,
  updateVendorRules,
  deleteVendorRules,
  getVendorRules,
  exportCSVVendorRules,
  exportPdfVendorRules,
} from "../../data/reducers/vendors/vendor-rules.reducer";
import {
  createVendorCharges,
  updateVendorCharges,
  deleteVendorCharges,
  getVendorCharges,
  exportCSVVendorCharges,
  exportPdfVendorCharges,
} from "../../data/reducers/vendors/vendor-charges.reducer";

import {
  deletePO,
  updatePO,
  createPO,
  filterPO,
  exportSelectedAsCSVPO,
  exportSelectedAsPdfPO,
  changePOEditState,
} from "../../data/reducers/purchaseOrders/po.reducer";

//components
import QuickTixTable from "../../shared/components/table/QuickTixTable";
import TableRowCard from "../../shared/components/table/TableRowCard";
import { columnError } from "../../shared/components/table/data/error";
import VendorDetails from "./VendorDetails";
import {
  charges,
  rules,
  purchase,
  poCol,
} from "../../shared/components/table/data/vendorsData";

//modals
import AddEditVendorPurchaseOrdersModal from "./modals/AddEditVendorPurchaseOrderes";
import AddEditVendorChargesModal from "./modals/AddEditVendorCharges";
import AddEditVendorRulesModal from "./modals/AddEditVendorRules";
import DeleteConfirmationModal from "../../shared/components/modals/DeleteConfirmationModal";
import AddPaymentPlan from "../purchaseOrders/modals/paymentPlan";

import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Col, Row, Button, Dropdown, Form, Container } from "react-bootstrap";

// images
import Export from "../../images/export.svg";
import Add from "../../images/add.svg";
import Run from "../../images/run.svg";
import Loader from "../../shared/components/loader/Loader";

import { withLoader } from "../../utils/hoc/withLoader";
import ModalBreadcrumb from "../../shared/components/modals/ModalBreadcrumb";
import AddEditSeasonModal from "../season/modals/AddEditSeasonModal";
import { runRules } from "../../data/reducers/transactions/transactions.reducer";
import {
  createSeason,
  getSeasonsList,
} from "../../data/reducers/season/season.reducer";
import { createPOPayment } from "../../data/reducers/purchaseOrders/po-paymentPlans.reducer";
import SelectMatch from "../transactions/modals/SelectMatch";
import { getAccountsList } from "../../data/reducers/accounts/accounts.reducers";
import { useHistory } from "react-router-dom";
import CollapsibleTable from "../purchaseOrders/CollapsibleTable";
import AddEditPOModal from "../purchaseOrders/modals/AddEditPOModal";
import { getPurchaseOrderRefs } from "../../data/reducers/skybox-purchases/skybox-purchases.reducer";
import { fetchVendorsListForDropdown } from "../../data/reducers/vendors/cached-vendors.reducer";

const SingleVendorDetail = ({ setBusy }) => {
  const tabs = ["All Charges", "Rules", "Purchase Orders"];
  const history = useHistory();
  const dispatch = useDispatch();
  //Vendor Id
  const { id } = useParams();

  {
    /* useStates */
  }
  const [ModalVisible, setModalVisibility] = useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisibility] =
    useState(false);
  const [selectedTabIndex, setTabIndex] = useState(0);

  const [VendorChargesModalVisible, setVendorChargesModalVisible] =
    useState(false);
  const [VendorRulesModalVisible, setVendorRulesModalVisible] = useState(false);
  const [
    VendorPurchaseOrdersModalVisible,
    setVendorPurchaseOrdersModalVisible,
  ] = useState(false);
  const [myMainId, setMainId] = useState(undefined);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [selectedRecordId, setSelectedRecordId] = useState(undefined);
  const [column, setColumn] = useState(charges);
  const [data, setData] = useState([]);
  const [paging, setpaging] = useState({});
  const [sortField, setSortField] = useState(null);
  const [sortDir, setSortDir] = useState(true);
  const [pageSize, setpageSize] = useState(40);
  const [pageNumber, setpageNumber] = useState(1);
  const [addSeasonModalVisibility, setAddSeasonModalVisibility] =
    useState(false);
  const [poIntId, setPoIntId] = useState(undefined);
  const [matchedVisibility, setmatchedVisibility] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState(null);
  const [Loading, setLoading] = useState(false);

  const poList = useSelector((state) => state.purchaseOrder).purchaseOrders;
  const skyBoxRefDataList = useSelector((state) => state.skybox).skyBoxRefData;

  useEffect(() => {
    dispatch(getAccountsList());
    dispatch(getSeasonsList());
  }, []);

  const { vendorsListForDropdown } = useSelector(
    (state) => state.cachedVendors
  );
  useEffect(() => {
    if (!vendorsListForDropdown.length) {
      dispatch(fetchVendorsListForDropdown());
    }
  }, [vendorsListForDropdown]);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const tab_id = queryParameters.get("tabIdx");
    if (tab_id) {
      setTabIndex(Number(tab_id));
    }
  }, []);

  {
    /* API Dispatches */
  }

  const addPaymentPlan = async (data) => {
    try {
      const plans = [];
      let sum = 0;
      const message = `Payment Plan created Succesfully`;
      for (let i = 0; i < parseInt(data?.period); i++) {
        const date = data["dateInterval" + i];
        const amount = parseFloat(data["amountPerInterval" + i]);
        sum += amount;
        plans.push({
          amount,
          paymentDate: date,
          purchaseOrderId: selectedRecordId?.purchaseOrderId,
          id: 0,
          matchingTransaction: 0,
          purchaseOrdersId: selectedRecordId.id,
        });
      }

      const action = createPOPayment({ plans });
      const response = await dispatch(action);
      getOrderRefs();
      if (response.error) dispatch(errorMessage(response.error.message));
      else {
        const action = updatePO({
          ...selectedRecordId,
          listSkyboxRefs: [...skyBoxRefDataList],
          totalScheduled: parseFloat(sum),
        });
        dispatch(action);
        dispatch(successMessage(message));
        setModalVisibility(false);
        fetchAll(2);
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  const getOrderRefs = async () => {
    try {
      setBusy(true);
      await dispatch(getPurchaseOrderRefs(selectedRecord.id));
    } catch (error) {
      console.error(error);
    } finally {
      setBusy(false);
    }
  };

  const addSeason = async (data) => {
    if (data?.startDate > data?.endDate) {
      dispatch(errorMessage("Start Date Cannot be Greater Than End Date"));
    } else {
      try {
        const message = data.id
          ? `Season Updated Succesfully`
          : `New Season Created Succesfully`;
        setBusy(true);
        const action = createSeason(data);
        const response = await dispatch(action);
        if (response.error) {
          dispatch(errorMessage(response.error.message));
        } else {
          dispatch(successMessage(message));
          dispatch(getSeasonsList());
          setAddSeasonModalVisibility(false);
          setVendorPurchaseOrdersModalVisible(true);
        }
      } catch (e) {
        dispatch(errorMessage(e));
      } finally {
        setBusy(false);
      }
    }
  };

  const onEntrySubmitted = async (data, option) => {
    try {
      setBusy(true);
      const message = data.id
        ? `Vendor  ${tabs[selectedTabIndex]}  Updated Succesfully`
        : `New Account ${tabs[selectedTabIndex]} Created Succesfully`;
      switch (option) {
        case 0: {
          const action = data.id
            ? updateVendorCharges({ formData: data, vendorId: id })
            : createVendorCharges({
                formData: {
                  ...data,
                  amount: parseFloat(data?.amount),
                  vendorId: parseInt(id),
                  matched: data?.matched === "true" ? true : false,
                },
                vendorId: id,
              });
          const response = await dispatch(action);
          if (response.error) {
            dispatch(errorMessage(response.error.message));
          } else {
            dispatch(successMessage(message));
            setVendorChargesModalVisible(false);
            fetchAll(option);
          }
          break;
        }
        case 1: {
          const action = data.id
            ? updateVendorRules({
                formData: {
                  ...data,
                  vendorId: parseInt(id),
                  searchId: parseInt(data.searchId),
                  name: selectedRecord?.name,
                },
                vendorId: id,
                searchId: parseInt(data?.searchId),
              })
            : createVendorRules({
                formData: {
                  ...data,
                  vendorId: parseInt(id),
                  searchId: parseInt(data?.searchId),
                },
                vendorId: id,
                searchId: parseInt(data?.searchId),
              });
          const response = await dispatch(action);
          if (response.error) {
            dispatch(errorMessage(response.error.message));
          } else {
            dispatch(successMessage(message));
            setVendorRulesModalVisible(false);
            fetchAll(option);
          }
          break;
        }
        case 2: {
          const action = data.id
            ? updatePO({
                vendorId: id,
                ...data,
                seasonId: data.seasonId ? data.seasonId : 0,
                purchaseOrderId: String(data?.purchaseOrderId),
                totalScheduled:
                  data?.totalScheduled != ""
                    ? parseFloat(data?.totalScheduled)
                    : parseFloat(0),
              })
            : createPO({
                ...data,
                seasonId: data.seasonId ? data.seasonId : 0,
                purchaseOrderId: String(data?.purchaseOrderId),
                totalScheduled:
                  data?.totalScheduled != ""
                    ? parseFloat(data?.totalScheduled)
                    : parseFloat(0),
                skyBoxRef:
                  data?.skyBoxRef === "" ? null : data?.skyBoxRef?.toString(),
                vendorId: id,
              });
          const response = await dispatch(action);
          if (response.error) {
            dispatch(errorMessage(response.error.message));
          } else {
            dispatch(successMessage(message));
            setVendorPurchaseOrdersModalVisible(false);
            fetchAll(option);
          }
          break;
        }
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  // {/* useSelectors */ }
  const chargesList = useSelector(
    (state) => state.vendorCharges
  ).vendorsCharges;
  const rulesList = useSelector((state) => state.vendorRules).vendorsRules;
  const purchaseList = useSelector(
    (state) => state.vendorPurchaseOrders
  ).vendorsPurchaseOrders;
  const chargesPaging = useSelector((state) => state.vendorCharges).paging;
  const rulesPaging = useSelector((state) => state.vendorRules).paging;
  const purchasePaging = useSelector(
    (state) => state.vendorPurchaseOrders
  ).paging;
  const details = useSelector((state) => state.vendors).selectedVendorDetails;

  // Handle query params
  const handleQuery = () => {
    const params = new URLSearchParams({ tabIdx: selectedTabIndex });
    history.push(`/vendors/${id}?${params.toString()}`);
  };

  useEffect(() => {
    if (selectedTabIndex === 0) {
      setData(chargesList);
      setpaging(chargesPaging);
    }
    if (selectedTabIndex === 1) {
      setData(rulesList);
      setpaging(rulesPaging);
    }
    if (selectedTabIndex === 2) {
      setData(poList);
      setpaging(purchasePaging);
    }

    handleQuery();
  }, [poList, rulesList, chargesList, selectedTabIndex]);
  // {/*Delete Charges/Rules/PurchaseOrders*/ }
  const onDelete = async (mainId, option) => {
    try {
      setBusy(true);
      let response;
      switch (option) {
        case 0:
          response = await dispatch(deleteVendorCharges({ mainId, id }));
          break;
        case 1:
          response = await dispatch(deleteVendorRules({ mainId, id }));
          break;
        case 2:
          response = await dispatch(deletePO(mainId));
          break;
      }
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        // close modal
        setDeleteConfirmationVisibility(false);
        fetchAll(option);
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  const onEditAction = (data) => {
    setMainId(data?.id);
    setSelectedRecord(data);
    if (selectedTabIndex === 0 && id) {
      setVendorChargesModalVisible(true);
    }
    if (selectedTabIndex === 1 && id) {
      setVendorRulesModalVisible(true);
    }
    if (selectedTabIndex === 2 && id) {
      setSelectedDataId(data?.id);
      setVendorPurchaseOrdersModalVisible(true);
    }
  };

  const onDeleteAction = (data) => {
    setMainId(data?.id);
    setSelectedRecord(data);
    setDeleteConfirmationVisibility(true);
  };

  const callId = (id) => {
    setPoIntId(id);
  };
  useEffect(() => {
    if (id !== undefined) dispatch(getVendorById(id));
  }, [id]);
  useEffect(async () => {
    setBusy(true);
    const data = {
      id,
      pageNumber,
      pageSize,
      sortDir,
      sortField,
    };
    if (selectedTabIndex === 0 && id) {
      setColumn(
        charges(
          onEditAction,
          onDeleteAction,
          setSelectedRecord,
          setmatchedVisibility,
          matchedVisibility
        )
      );
      await dispatch(
        getVendorCharges(data, pageNumber, pageSize, sortDir, sortField)
      );
    }
    if (selectedTabIndex === 1 && id) {
      setColumn(rules(onEditAction, onDeleteAction));
      await dispatch(
        getVendorRules(data, pageNumber, pageSize, sortDir, sortField)
      );
    }
    if (selectedTabIndex === 2 && id) {
      setColumn(
        purchase(
          onEditAction,
          onDeleteAction,
          setModalVisibility,
          setSelectedRecordId
        )
      );
      const data = {
        pageNumber,
        pageSize,
        sortDir,
        sortField,
        accountId: "",
        vendorId: id,
        searchText: "",
        description: "",
        purchaseOrderId: "",
        sportId: "",
        teamId: "",
        startDate: "",
        endDate: "",
        seasonId: "",
        isReviewed: true,
      };
      await dispatch(filterPO(data));
    }
    setBusy(false);
  }, [selectedTabIndex, pageSize, pageNumber, sortField, sortDir]);

  const ExportCSV = async (option) => {
    try {
      setBusy(true);
      let response;
      switch (option) {
        case 0:
          response = await dispatch(exportCSVVendorCharges(id));
          break;
        case 1:
          response = await dispatch(exportCSVVendorRules(id));
          break;
        case 2: {
          const data = {
            pageNumber,
            pageSize,
            sortDir,
            sortField,
            accountId: "",
            vendorId: id,
            searchText: "",
            description: "",
            purchaseOrderId: "",
            sportId: "",
            teamId: "",
            startDate: "",
            endDate: "",
            seasonId: "",
            isReviewed: true,
          };
          response = await dispatch(exportSelectedAsCSVPO(data));
          break;
        }
      }
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  const onExportPdf = async (option) => {
    try {
      setBusy(true);
      let response;
      switch (option) {
        case 0:
          response = await dispatch(exportPdfVendorCharges(id));
          break;
        case 1:
          response = await dispatch(exportPdfVendorRules(id));
          break;
        case 2: {
          const data = {
            pageNumber,
            pageSize,
            sortDir,
            sortField,
            accountId: "",
            vendorId: id,
            searchText: "",
            description: "",
            purchaseOrderId: "",
            sportId: "",
            teamId: "",
            startDate: "",
            endDate: "",
            seasonId: "",
            isReviewed: true,
          };
          response = await dispatch(exportSelectedAsPdfPO(data));
          break;
        }
      }
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  const fetchAll = async (option, sortField, sortDir) => {
    setBusy(true);
    const d = {
      id,
      pageNumber,
      pageSize,
      sortDir,
      sortField,
    };
    switch (option) {
      case 0:
        await dispatch(getVendorCharges(d));
        break;
      case 1:
        await dispatch(getVendorRules(d));
        break;
      case 2: {
        const data = {
          pageNumber,
          pageSize,
          sortDir,
          sortField,
          accountId: "",
          vendorId: id,
          searchText: "",
          description: "",
          purchaseOrderId: "",
          sportId: "",
          teamId: "",
          startDate: "",
          endDate: "",
          seasonId: "",
          isReviewed: true,
        };
        await dispatch(filterPO(data));
        break;
      }
    }
    setBusy(false);
  };
  const callRunRules = async () => {
    setLoading(true);
    await dispatch(runRules());
    const data = {
      id,
      pageNumber,
      pageSize,
      sortDir,
      sortField,
    };
    if (selectedTabIndex === 1 && id) {
      setColumn(rules(onEditAction, onDeleteAction));
      await dispatch(
        getVendorRules(data, pageNumber, pageSize, sortDir, sortField)
      );
    }

    setLoading(false);
  };

  const headOptions = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small>Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue="40"
              onChange={(e) => setpageSize(e.target.value)}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div className="text-end mt-3 d-flex">
          {(selectedTabIndex == 1 || selectedTabIndex == 0) && (
            <Button
              variant="primary"
              className="mx-2 ss-light-button"
              onClick={(e) => {
                e.currentTarget.blur();
                callRunRules();
              }}
              disabled={Loading}
            >
              {Loading ? <Loader /> : <img src={Run} alt=" " />}
              <span>Run Rules</span>
            </Button>
          )}
          <Dropdown>
            <Dropdown.Toggle className="ss-light-button" id="dropdown-basic">
              <img src={Export} alt=" " />
              <span>Export</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => ExportCSV(selectedTabIndex)}>
                Export as CSV
              </Dropdown.Item>

              <hr className="hr-full" />
              <Dropdown.Item onClick={() => onExportPdf(selectedTabIndex)}>
                Export as PDF
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* {selectedTabIndex == 0 && (
            <Button
              variant="primary"
              className="ms-2 ss-light-button"
              onClick={(e) => {
                e.currentTarget.blur();
              }}
            >
              <img src={Upload} alt=" " />
              <span>Upload</span>
            </Button>
          )} */}

          <Button
            onClick={async (e) => {
              e.currentTarget.blur();
              dispatch(changePOEditState(false));
              await setSelectedRecord(undefined);
              await setMainId(undefined);
              {
                selectedTabIndex === 0
                  ? setVendorChargesModalVisible(true)
                  : selectedTabIndex === 1
                  ? setVendorRulesModalVisible(true)
                  : setVendorPurchaseOrdersModalVisible(true);
              }
            }}
            variant="primary"
            className="ms-2 ss-light-button"
          >
            <img src={Add} alt=" " />
            <span>Add</span>
          </Button>
        </div>
      </>
    );
  };

  const goToInventorySold = () => {
    history.push({
      pathname: "/inventory",
      state: { vendorId: id },
    });
  };

  return (
    <div className="container-fluid pt-3">
      {/* Upper Navigation */}

      <Container className="page-upper-navigation d-flex justify-content-between align-items-center">
        <ModalBreadcrumb
          breadcrumbLinks={[
            {
              name: "Vendors",
              active: true,
            },
            {
              name: details.name ? details.name : "",
              active: false,
            },
            {
              name: tabs[selectedTabIndex],
            },
          ]}
        />
        <Col md={{ span: 4, offset: 4 }} className="text-end">
          <div style={{ display: "inline-block" }} className="px-3">
            <button
              className="ss-back-button text-end -2"
              style={{ border: "none" }}
              onClick={goToInventorySold}
            >
              <small>Go to Sales</small>
            </button>
          </div>
          <NavLink className="ss-back-button" to="/vendors">
            <small>Back to Vendors</small>
          </NavLink>
        </Col>
      </Container>

      {/* Vendor Details */}
      <VendorDetails details={details} />

      {/* Data Table */}
      {!(selectedTabIndex === 2) && (
        <QuickTixTable
          headerName="VENDORS"
          paging={paging}
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          onTabSelectionChange={(i) => setTabIndex(i)}
          columns={column}
          setSortField={setSortField}
          sortDir={sortDir}
          sortField={sortField}
          setSortDir={setSortDir}
          data={data || []}
          headOptions={headOptions}
          setpageNumber={setpageNumber}
          renderRow={(rowData) => (
            <TableRowCard
              key={rowData.id}
              columns={rowData.nodata ? columnError : column}
              rowData={rowData}
            />
          )}
        />
      )}
      {selectedTabIndex == 2 && (
        <CollapsibleTable
          headerName="VENDORS"
          paging={paging}
          pageType="VENDORS"
          columns={poCol(
            onEditAction,
            onDeleteAction,
            setModalVisibility,
            setSelectedRecordId,
            dispatch
          )}
          data={poList || []}
          headOptions={headOptions}
          sortDir={sortDir}
          setSortDir={setSortDir}
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          onTabSelectionChange={(i) => {
            setpageNumber(1);
            setTabIndex(i);
          }}
          sortField={sortField}
          setSortField={setSortField}
          setpageNumber={setpageNumber}
          renderRow={(rowData) => (
            <TableRowCard
              key={rowData.id}
              columns={
                rowData.nodata
                  ? columnError
                  : poCol(
                      onEditAction,
                      onDeleteAction,
                      setModalVisibility,
                      setSelectedRecordId,
                      dispatch
                    )
              }
              rowData={rowData}
            />
          )}
        />
      )}

      {/* Modal Popups */}
      <AddEditVendorChargesModal
        vendorName={details?.name}
        isVisible={VendorChargesModalVisible}
        handleClose={() => setVendorChargesModalVisible(false)}
        onSubmit={(data) => onEntrySubmitted(data, 0)}
        selectedRecord={selectedRecord}
      />
      <AddEditVendorRulesModal
        isVisible={VendorRulesModalVisible}
        handleClose={() => setVendorRulesModalVisible(false)}
        onSubmit={(data) => onEntrySubmitted(data, 1)}
        selectedRecord={selectedRecord}
      />
      <DeleteConfirmationModal
        isVisible={deleteConfirmationVisible}
        handleClose={() => setDeleteConfirmationVisibility(false)}
        onConfirmation={() => onDelete(myMainId, selectedTabIndex)}
      />
      {ModalVisible && (
        <AddPaymentPlan
          isVisible={ModalVisible}
          handleClose={() => setModalVisibility(false)}
          onSubmit={(data) => addPaymentPlan(data)}
          purchaseOrderId={selectedRecordId}
        />
      )}

      <AddEditSeasonModal
        isVisible={addSeasonModalVisibility}
        onSubmit={addSeason}
        handleClose={() => {
          setAddSeasonModalVisibility(false);
          setVendorPurchaseOrdersModalVisible(true);
        }}
      />
      {matchedVisibility && (
        <SelectMatch
          isVisible={matchedVisibility}
          setIsVisible={setmatchedVisibility}
          handleClose={() => setmatchedVisibility(false)}
          match={true}
          selectedRecord={selectedRecord}
        />
      )}
      {VendorPurchaseOrdersModalVisible && (
        <AddEditPOModal
          isVisible={VendorPurchaseOrdersModalVisible}
          handleClose={() => setVendorPurchaseOrdersModalVisible(false)}
          onSubmit={(data) => onEntrySubmitted(data, 2)}
          selectedRecord={selectedRecord}
          setModalOpen={setAddSeasonModalVisibility}
          setSelectedRecord={setSelectedRecord}
          vendorData={id}
        />
      )}
    </div>
  );
};

export default withLoader(SingleVendorDetail);
