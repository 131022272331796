import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../services/api";

export const fetchVendors = createAsyncThunk(
  "cachedVendors/fetchData",
  async (params) => {
    try {
      const filterKeys = ["searchString", "Id", "VendorId", "CategoryId"];
      const hasFilters = Object.entries(params).filter(([key, value]) => {
        if (filterKeys.includes(key)) {
          return value != "";
        }
        return false;
      });

      const response = hasFilters.length
        ? await api.get("Vendors/search", params)
        : await api.get("Vendors/getall", params);
      return {
        params,
        response,
      };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchVendorsListForDropdown = createAsyncThunk(
  "cachedVendors/fetchDataForDropdown",
  async () => {
    console.log("working");
    try {
      const response = await api.get("Vendors/getlist");
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const flushVendors = createAsyncThunk(
  "cachedVendors/flushVendors",
  () => true
);

const cachedVendorslice = createSlice({
  name: "cachedVendors",
  initialState: {
    vendorsListForDropdown: [],
    data: null,
    error: null,
    loading: false,
  },
  extraReducers: {
    // fetchVendorsListForDropdown
    [fetchVendorsListForDropdown.fulfilled]: (state, action) => {
      state.vendorsListForDropdown = action.payload ?? [];
    },

    [fetchVendors.pending]: (state) => {
      state.loading = true;
    },
    [fetchVendors.fulfilled]: (state, action) => {
      const { params, response } = action.payload;
      state.loading = false;
      state.error = null;
      if (!state.data) {
        state.data = {
          [JSON.stringify(params)]: {
            vendors: response.data,
            paging: response.paging,
            timestamp: Date.now(),
          },
        };
      } else {
        state.data[JSON.stringify(params)] = {
          vendors: response.data,
          paging: response.paging,
          timestamp: Date.now(),
        };
      }
    },
    [fetchVendors.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // flush
    [flushVendors.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = null;
    },
  },
});

export default cachedVendorslice.reducer;
