import React from "react";
import Collapse from "@mui/material/Collapse";
import { formatMoney } from "../../../utils/formatter/currencyFormatter";
import NextWindowIcon from "../../../images/next_window.svg";
import { useHistory } from "react-router-dom";

const AccountInfo = ({ vendor, vendorId }) => {
  const history = useHistory();
  const getAccountsMaxHeight = (vendor) => {
    const selectedVendor = vendor;
    if (selectedVendor) {
      const maxHeight =
        (selectedVendor?.accounts?.length > 5
          ? 10
          : selectedVendor?.accounts?.length) * 60 ?? 0;
      return maxHeight;
    } else {
      return "0";
    }
  };

  return (
    <Collapse
      in={vendor?.id === vendorId}
      timeout="auto"
      unmountOnExit
      style={{
        backgroundColor: "#3C95FF1A",
        maxHeight: "1000px",
        // getAccountsMaxHeight(vendor, vendorId),
        overflowX: "scroll",
      }}
    >
      <div className="px-2">
        {vendor?.accounts?.map((account) => (
          <div
            className="d-flex justify-content-between align-items-center p-2 my-1 w-100"
            key={vendor?.id}
            style={{ backgroundColor: "#3C95FF1A", cursor: "pointer" }}
            onClick={() => {
              // here tabidx 4 is for the payment plan tab
              window.open(`purchases/${account.purchaseOrderId}?tabIdx=1`,"_blank");
            }}
          >
            <p
              className="mr-2 mb-0 ml-0"
              style={{ flex: "1", minWidth: "100px" }}
            >
              {account?.name ? account?.name : ""}
              <img
                src={NextWindowIcon}
                alt="Next Window Icon"
                style={{ marginLeft: "7px", width: "13px" }}
              />
            </p>
            <div
              className="m-0 text-center"
              style={{ flex: "1", minWidth: "100px" }}
            >
              <p className="mb-0 px-1 text-center">
                {formatMoney(account?.accountTotalScheduled ?? 0)}{" "}
              </p>
            </div>
            <div className="m-0" style={{ flex: "1", minWidth: "100px" }}>
              <p className="mb-0 px-1 text-center">
                {formatMoney(account?.accountTotalPaid ?? 0)}{" "}
              </p>
            </div>
            <div className="m-0" style={{ flex: "1", minWidth: "100px" }}>
              <p className="mb-0 px-1 text-center">
                {formatMoney(
                  (account?.accountTotalScheduled ?? 0) -
                    (account?.accountTotalPaid ?? 0)
                )}{" "}
              </p>
            </div>
          </div>
        ))}
      </div>
    </Collapse>
  );
};

export default AccountInfo;
