import PropTypes from "prop-types";
import "../TableStyle.scss";

const SelectTableHead = ({ columns }) => {

  return (
    <div className="select-table-head d-flex justify-content-between px-3 py-2 align-items-center">
      {columns.map((column, index) => (
        <div
          key={index}
          style={{ flexGrow: column.flexGrow > 0 ? column.flexGrow : 1, flexBasis: 0, textAlign: "center" }}
          className="mx-2">
          <small>{column.columnName}</small>
        </div>
      ))}
    </div>
  );
};

SelectTableHead.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    flexGrow: PropTypes.number,
    columnName: PropTypes.string,
    keyName: PropTypes.string,
    render: PropTypes.func
  })).isRequired
};

export default SelectTableHead;