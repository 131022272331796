import { Redirect, Route } from "react-router-dom";
import Header from "../../shared/components/navigation/Header";
import MenuBar from "../../shared/components/navigation/Menu";
import AccountsRoutes from "./accounts/accounts.routes";
import PORoutes from "./purchaseOrders/purchaseOrder.routes";
import InventoryRoutes from "./inventory/inventory.routes";
import EventsRoutes from "./events/events.routes";
import VendorsRoutes from "./vendors/vendors.routes";
import FinanceRoutes from "./finance/finance.routes";
import PSLRoutes from "./psl/psl.routes";
import FundSourcesRoutes from "./fundSources/paypal.routes";
import SkyBoxPurchasesRoutes from "./skyboxPurchases/skyboxPurchases.routes";
import BIReportRoutes from "./bi-report/bi-report.routes";
import AddressBookRoutes from "./addressBook/addressbook.routes";
import CardsRoutes from "./fundSources/cards.routes";
import PlaidRoutes from "./fundSources/plaid.routes";
import TransactionsRoutes from "./transactions/transactions.routes";
import SeasonsRoutes from "./season/season.routes";
import RevealMarketRoutes from "./settingstab/reveal-market.routes";
import SyncRoutes from "./settingstab/sync.routes";
import SportsAndTeamsRoutes from "./settingstab/sports-teams.routes";
import EmailRoutes from "./email/email.routes";
import PaymentCalendarRoutes from "./paymentCalendar/paymentCalendar.routes";
import { useSelector } from "react-redux";
import React from "react";
const DashboardRoutes = () => {
  const authInfo = useSelector((state) => state.auth);
  const routes = [
    {
      id: 1,
      routes: <InventoryRoutes />,
      permissions: ["Admin", "TicketManager"],
    },
    {
      id: 2,
      routes: <EventsRoutes />,
      permissions: ["Admin", "TicketManager"],
    },
    {
      id: 3,
      routes: <AccountsRoutes />,
      permissions: ["Admin", "User"],
    },
    {
      id: 4,
      routes: <VendorsRoutes />,
      permissions: ["Admin", "User"],
    },
    {
      id: 5,
      routes: <SeasonsRoutes />,
      permissions: ["Admin", "User"],
    },
    {
      id: 6,
      routes: <SkyBoxPurchasesRoutes />,
      permissions: ["Admin", "User"],
    },
    {
      id: 7,
      routes: <FinanceRoutes />,
      permissions: ["Admin", "User"],
    },
    {
      id: 8,
      routes: <PORoutes />,
      permissions: ["Admin", "User"],
    },
    {
      id: 9,
      routes: <PSLRoutes />,
      permissions: ["Admin", "User"],
    },
    {
      id: 10,
      routes: <BIReportRoutes />,
      permissions: ["Admin", "User"],
    },
    {
      id: 11,
      routes: <FundSourcesRoutes />,
      permissions: ["Admin", "User"],
    },
    {
      id: 12,
      routes: <AddressBookRoutes />,
      permissions: ["Admin", "User"],
    },
    {
      id: 13,
      routes: <CardsRoutes />,
      permissions: ["Admin", "User"],
    },
    {
      id: 14,
      routes: <PlaidRoutes />,
      permissions: ["Admin", "User"],
    },
    {
      id: 15,
      routes: <TransactionsRoutes />,
      permissions: ["Admin", "User"],
    },
    {
      id: 16,
      routes: <SyncRoutes />,
      permissions: ["Admin", "User"],
    },
    {
      id: 17,
      routes: <SportsAndTeamsRoutes />,
      permissions: ["Admin", "User"],
    },
    {
      id: 18,
      routes: <RevealMarketRoutes />,
      permissions: ["Admin", "User"],
    },
    {
      id: 19,
      routes: <EmailRoutes />,
      permissions: ["Admin", "User"],
    },
    {
      id: 20,
      routes: <PaymentCalendarRoutes />,
      permissions: ["Admin", "User"],
    },
  ];
  return (
    <>
      <Header />
      <div className="d-flex whole-content" style={{ minHeight: "100vh" }}>
        <div className="vertical-nav-bar">
          <div
            className="menu-container"
            style={{ overflowY: "scroll", height: "70vh" }}
          >
            <div className="menu">
              <MenuBar />
            </div>
          </div>
        </div>
        <div className="main-content">
          {authInfo?.role === "TicketManager" && (
            <Route path="/">
              <Redirect to="/inventory" />
            </Route>
          )}

          {/* {authInfo?.role !== "TicketManager" && (
						<Route path="/">
							<Redirect to="/accounts" />
						</Route>
					)} */}

          {routes?.map((route) => {
            if (!route.permissions.some((x) => x === authInfo.role)) {
              return null;
            }
            return (
              <React.Fragment key={route.id}>{route.routes}</React.Fragment>
            );
          })}

          {/* {authInfo?.role === "TicketManager" && (
						<Route path="*">
							<Redirect to="/inventory" />
						</Route>
					)} */}

          {/* {authInfo?.role !== "TicketManager" && (
						<Route path="*">
							<Redirect to="/accounts" />
						</Route>
					)} */}
        </div>
      </div>
    </>
  );
};

export default DashboardRoutes;
