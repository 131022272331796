import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import PrimaryRoutes from "./router/primary.routes";
import toastr from "toastr";
import "./App.css";
import "./date-range-calendar.scss";

function App() {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [info, setInfo] = useState(false);

  const { infoMessage, successMessage, errorMessage } = useSelector(
    (state) => state.alerts
  );

  useEffect(() => {
    if (infoMessage && !info) {
      setInfo(true);
      toastr.info(infoMessage.message, "", {
        onHidden: () => {
          setInfo(false);
        }
      });
    }
  }, [infoMessage]);

  useEffect(() => {
    if (successMessage && !success) {
      setSuccess(true);
      toastr.success(successMessage.message, "", {
        onHidden: () => {
          setSuccess(false);
        }
      });
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage && !error) {
      setError(true);
      toastr.error(errorMessage.message, "", {
        onHidden: () => {
          setError(false);
        }
      });
    }
  }, [errorMessage]);

  return (
    <div className="App">
      <BrowserRouter>
        <PrimaryRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;
