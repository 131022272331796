import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

import Add from "../../../images/add.svg";
import Loader from "../../../shared/components/loader/Loader";
import { getSeasonsList } from "../../../data/reducers/season/season.reducer";
import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";

const AddEditLocationModal = ({
  isVisible,
  handleClose,
  onSubmit,
  selectedRecord,
  setSelectedRecord,
  setModalOpen,
  loading,
}) => {
  const dispatch = useDispatch();

  //selectors
  const seasonList = useSelector((state) => state.season).seasonList;

  // setup react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    getValues,
  } = useForm();

  //If selected Record Exists aka Edit else Add
  const [isEdit, setIsEdit] = useState(false);
  const [dropVal, setdropVal] = useState({
    season: "",
  });
  // reset form on visibility toggle
  useEffect(() => {
    reset();
    setdropVal({ ...dropVal, season: "" });
    !seasonList.length && dispatch(getSeasonsList(""));
  }, [isVisible]);

  // update values in form if selected record is changed
  useEffect(() => {
    if (selectedRecord) {
      reset();
      // as soon as selected record is changed, set values in modal popup
      setIsEdit(true);
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
      setdropVal({
        ...dropVal,
        season: seasonList?.find(
          (season) => String(season.id) === String(selectedRecord.seasonCodeId)
        ),
      });
    } else {
      // if selected record is undefined, reset
      setIsEdit(false);
      reset();
      setdropVal({ ...dropVal, season: "" });
    }
  }, [selectedRecord, isVisible, seasonList]);

  //Handling Reset Button for moda;
  const handleReset = () => {
    for (const [key, value] of Object.entries(selectedRecord)) {
      setValue(key, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    setdropVal({
      ...dropVal,
      season: seasonList?.find(
        (season) => String(season.id) === String(selectedRecord.seasonCodeId)
      ),
    });
  };

  //handling clear button for modal
  const handleClear = () => {
    reset();
    setdropVal({ ...dropVal, season: "" });
  };

  const customStyles = {
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };

  const openSeason = (e) => {
    if (isEdit) {
      setSelectedRecord({
        ...selectedRecord,
        section: getValues("section"),
        row: getValues("row"),
        highSeat: getValues("highSeat"),
        lowSeat: getValues("lowSeat"),
      });
    } else {
      setSelectedRecord({
        section: getValues("section"),
        row: getValues("row"),
        highSeat: getValues("highSeat"),
        lowSeat: getValues("lowSeat"),
      });
    }
    setModalOpen(true);
    handleClose();
  };

  return (
    <>
      <Modal
        dialogClassName="modal-xl"
        show={isVisible}
        onHide={handleClose}
        className="modal-custom-close"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Account</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/accounts",
                name: "Accounts",
                active: false,
              },
              {
                name: isEdit ? "Edit Location" : "Add Location",
                active: true,
              },
            ]}
          />
          <Modal.Body>
            <div className="container-fluid p-2 w-100 h-100">
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Section
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        isInvalid={!!errors.section}
                        {...register("section", { required: true })}
                        placeholder="Enter Section"
                      />
                      <Form.Control.Feedback type="invalid">
                        Section is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Row
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        isInvalid={!!errors.row}
                        {...register("row", { required: true })}
                        placeholder="Enter Row"
                      />
                      <Form.Control.Feedback type="invalid">
                        Row is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Low Seat
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        isInvalid={!!errors.lowSeat}
                        {...register("lowSeat", { required: true })}
                        placeholder="Enter Low Seat"
                      />
                      <Form.Control.Feedback type="invalid">
                        Low Seat is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      High Seat
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        isInvalid={!!errors.highSeat}
                        {...register("highSeat", { required: true })}
                        placeholder="Enter High Seat"
                      />
                      <Form.Control.Feedback type="invalid">
                        High Seat is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col className="mt-3 col-6">
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Season
                    </Form.Label>
                    <Col sm="7">
                      <Controller
                        name="seasonCodeId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState }) => {
                          return (
                            <>
                              <Select
                                value={dropVal?.season}
                                onChange={async (e) => {
                                  field.onChange(e?.id);
                                  setdropVal({ ...dropVal, season: e });
                                }}
                                isDisabled={selectedRecord?.autoGenerate}
                                isClearable
                                styles={customStyles}
                                options={seasonList}
                                getOptionLabel={(option) =>
                                  `${option.seasonName}`
                                }
                              />
                              {fieldState.invalid && fieldState.error ? (
                                <div className="custom-invalid-feedback">
                                  Season is required.
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                    </Col>
                    <Col xs={1}>
                      <Button onClick={openSeason}>
                        <img src={Add} width={15} height={15} />
                      </Button>
                    </Col>
                  </Form.Group>
                </Col>
                <Col></Col>
              </Row>
              <br />
            </div>
          </Modal.Body>
          <div className="d-flex justify-content-center my-4">
            <Button
              type="submit"
              variant="primary"
              className="ss-modal-primary-btn mx-2"
              disabled={loading}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isEdit ? "Save" : "Add"}
                {loading && <Loader style={{ marginLeft: "8px" }} />}
              </div>
            </Button>
            <Button
              variant="light"
              onClick={() => (isEdit ? handleReset() : handleClear())}
              className="ss-modal-secondary-btn mx-2"
            >
              {isEdit ? "Reset" : "Clear"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddEditLocationModal;
