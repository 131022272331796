import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";

import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { withLoader } from "../../../utils/hoc/withLoader";
import AddressComponent from "./AddressComponent";

const AddEditAddressModal = ({
  setBusy,
  isVisible,
  handleClose,
  onSubmit,
  selectedRecord,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [query, setQuery] = useState("");

  // setup react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm();

  //If selected Record Exists aka Edit else Add
  const [isEdit, setIsEdit] = useState(false);

  // reset form on visibility toggle
  useEffect(() => {
    reset();
    setShowForm(false);
  }, [isVisible]);

  // update values in form if selected record is changed
  useEffect(() => {
    if (selectedRecord) {
      // as soon as selected record is changed, set values in modal popup
      setIsEdit(true);
      setShowForm(true);
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    } else {
      // if selected record is undefined, reset
      setIsEdit(false);
      reset();
    }
  }, [selectedRecord, isVisible]);

  //Handling Reset Button for moda;
  const handleReset = () => {
    for (const [key, value] of Object.entries(selectedRecord)) {
      setValue(key, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    setQuery("");
  };

  //handling clear button for modal
  const handleClear = () => {
    reset();
    setShowForm(false);
    setQuery("");
  };

  const onClickAddress = (place) => {
    let locality = "";
    let administrative_area_level_1 = "";
    let postal_code = "";

    const address = [];
    const address2 = [];
    for (var i = 0; i < place.address_components.length; i++) {
      var addressTypes = place.address_components[i].types;
      if (
        place.address_components[i].types.includes("route") ||
        place.address_components[i].types.includes("street_number")
      ) {
        address.push(place.address_components[i].long_name);
      }

      if (
        !place.address_components[i].types.includes("sublocality_level_1") &&
        (place.address_components[i].types.includes("street_address") ||
          place.address_components[i].types.includes("sublocality"))
      ) {
        address2.push(place.address_components[i].long_name);
      }
      if (
        addressTypes.includes("locality") ||
        place.address_components[i].types.includes("sublocality_level_1")
      ) {
        locality = place.address_components[i].long_name;
      }
      if (addressTypes.includes("administrative_area_level_1")) {
        administrative_area_level_1 = place.address_components[i].short_name;
      }
      if (addressTypes.includes("postal_code")) {
        postal_code = place.address_components[i].long_name;
      }
    }
    setShowForm(true);
    setValue("address1", address.join(" "));
    setValue(
      "address2",
      address2.join(", ") === "Brooklyn" ? "" : address2.join(", ")
    );
    setValue("postalCode", postal_code);
    setValue(
      "city",
      address2.join(", ") === "Brooklyn" ? "Brooklyn" : locality
    );
    setValue("state", administrative_area_level_1);
  };

  return (
    <>
      <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>ADDRESS BOOK</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/addressbook",
                name: "Address Book",
                active: false,
              },
              {
                name: isEdit ? "Edit Address" : "Add Address",
                active: true,
              },
            ]}
          />
          <Modal.Body>
            <AddressComponent
              onClickAddress={onClickAddress}
              query={query}
              setQuery={setQuery}
            />
            {showForm && (
              <>
                <div className="container-fluid p-2 w-100 h-100">
                  <Row>
                    <p className="title-accounts">Address Details</p>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          Address 1
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            isInvalid={!!errors.address1}
                            {...register("address1", { required: true })}
                            placeholder="Address 1"
                          />
                          <Form.Control.Feedback type="invalid">
                            Address 1 is required.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          Address 2
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            isInvalid={!!errors.address2}
                            {...register("address2")}
                            placeholder="Address 2"
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          City
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            type="text"
                            placeholder="City"
                            isInvalid={!!errors.city}
                            {...register("city", { required: true })}
                          />
                          <Form.Control.Feedback type="invalid">
                            City is requires
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          State
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            type="text"
                            isInvalid={!!errors.state}
                            placeholder="State"
                            {...register("state", {
                              required: true,
                            })}
                          />
                          <Form.Control.Feedback type="invalid">
                            State is required.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          Postal Code
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            type="number"
                            isInvalid={!!errors.postalCode}
                            {...register("postalCode", { required: true })}
                            placeholder="Postal Code"
                          />
                          <Form.Control.Feedback type="invalid">
                            Postal Code is required.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          Email
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            type="email"
                            {...register("email")}
                            placeholder="Email"
                            pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          Mobile Number
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            type="number"
                            {...register("mobileNumber")}
                            placeholder="Mobile Number"
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col></Col>
                  </Row>
                </div>
              </>
            )}
          </Modal.Body>
          <div className="d-flex justify-content-center my-4">
            <Button
              type="submit"
              variant="primary"
              className="ss-modal-primary-btn mx-2"
              disabled={!showForm}
            >
              {isEdit ? "Save" : "Add"}
            </Button>
            <Button
              variant="light"
              onClick={() => (isEdit ? handleReset() : handleClear())}
              className="ss-modal-secondary-btn mx-2"
            >
              {isEdit ? "Reset" : "Clear"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default withLoader(AddEditAddressModal);
