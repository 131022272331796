import React, { useEffect } from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import Down from "../../images/downarrow.svg";
import Up from "../../images/uparrow.svg";
import HeaderPart from "../../shared/components/table/parts/HeaderPart";
import FooterPart from "../../shared/components/table/parts/FooterPart";
import TableHead from "../../shared/components/table/parts/TableHead";
import TableRowCard from "../../shared/components/table/TableRowCard";
import { columnError } from "../../shared/components/table/data/error";
import moment from "moment";
import NextWindowIcon from "../../images/next_window.svg";
import EyeIcon from "../../images/view.svg";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from "../../shared/components/loader/Loader";

function Row(props) {
  const { row, open, setOpen, columns } = props;

  const eventDropCol = [
    {
      columnName: "Purchase Order",
      render: (data) => (
        <Link variant="link" className="ss-link" to={"/purchases/" + data.id}>
          {data.purchaseOrderId}
        </Link>
      ),
    },
    {
      columnName: "Name/Account ID",
      render: (data) => (
        <>
          {data?.accountId}
          <br />
          {data?.accountName}
        </>
      ),
    },
    {
      columnName: "Season",
      render: (data) => <>{data?.seasonName}</>,
    },
    {
      columnName: "Description",
      render: (data) => <>{data?.description}</>,
    },
    {
      columnName: "Total Scheduled",
      render: (data) => <>{data?.totalScheduled}</>,
    },

    {
      columnName: "Total Paid",
      render: (data) => <>{data?.totalPaid}</>,
    },
  ];

  const customStyles = { flexGrow: 1, flexBasis: 0, textAlign: "center" };

  return (
    <>
      <div
        className="my-3 d-flex justify-content-between px-3 align-items-center"
        style={{
          minHeight: "64px",
          boxShadow: "0px 2px 6px rgb(0 0 0 / 5%)",
          backgroundColor: "white",
        }}
      >
        {columns?.map((col, i) => {
          if (col.columnName === "Action") {
            return (
              <small
                key={Math.random() + i}
                style={customStyles}
                className="mx-2"
                align="center"
              >
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    if (open) {
                      setOpen(null);
                    } else {
                      setOpen(row?.id);
                    }
                  }}
                >
                  <img src={open === row?.id ? Up : Down} alt="up down image" />
                </IconButton>
              </small>
            );
          }
          return (
            <small
              style={customStyles}
              className="mx-2"
              key={Math.random() + i}
            >
              {col?.render(row)}
            </small>
          );
        })}
      </div>
      <div
        style={{
          flexGrow: 1,
          flexBasis: 0,
          backgroundColor: "#FBFBFB",
        }}
      >
        <Collapse in={open === row?.id} timeout="auto" unmountOnExit>
          <div style={{ maxWidth: row?.eventsLists?.length > 3 && "98.6%" }}>
            <TableHead columns={eventDropCol} bcolor="#e8f1fb" color="black" />
          </div>

          <TableContainer
            sx={{
              maxHeight: 300,
            }}
          >
            <div className="table-body">
              {row?.linkedPurchaseOrders?.length > 0 ? (
                row?.linkedPurchaseOrders.map((d, i) => (
                  <TableRowCard
                    key={d?.id + i}
                    columns={d?.nodata ? columnError : eventDropCol}
                    rowData={d}
                  />
                ))
              ) : (
                <h5 className="p-5 text-center">No records to display</h5>
              )}
            </div>
          </TableContainer>
        </Collapse>
      </div>
    </>
  );
}

export default function CollapsibleTable({
  headerName,
  paging,
  columns,
  data,
  selectedTabIndex,
  tabs,
  onTabSelectionChange,
  headOptions,
  bottomOptions,
  headButton,
  setpageNumber,
  setSortField,
  sortDir,
  middleOptions,
  setSortDir,
  sortField,
  previousPage,
  nextPage,
  loading = false,
  onChangeFieldDirection,
}) {
  const [open, setOpen] = React.useState(null);

  useEffect(() => {
    setOpen(null);
  }, []);

  useEffect(() => {
    setOpen(null);
  }, [selectedTabIndex]);

  return (
    <div className="quicktix-table">
      <HeaderPart
        headerName={headerName}
        tabs={tabs}
        headButton={headButton}
        selectedTabIndex={selectedTabIndex}
        onTabSelectionChange={onTabSelectionChange}
        headOptions={headOptions}
        bottomOptions={bottomOptions}
        middleOptions={middleOptions}
      />

      <TableHead
        onChangeFieldDirection={onChangeFieldDirection}
        columns={columns}
        setSortField={setSortField}
        sortDir={sortDir}
        setSortDir={setSortDir}
        sortField={sortField}
      />

      <TableContainer>
        <div className="table-body">
          {loading && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "200px",
              }}
            >
              <Loader size="xl" />
            </div>
          )}

          {!loading &&
            (data?.length > 0 ? (
              data?.map((row, ind) => {
                return (
                  <Row
                    key={row?.id + Math.random()}
                    ind={ind}
                    row={row}
                    open={open}
                    setOpen={setOpen}
                    data={data}
                    columns={columns}
                  />
                );
              })
            ) : (
              <h5 className="p-5 text-center">No records to display</h5>
            ))}
        </div>
      </TableContainer>

      {paging && (
        <FooterPart
          previousPage={previousPage}
          nextPage={nextPage}
          paramerterNavigation={true}
          paging={paging}
          setpageNumber={setpageNumber}
          setSortDir={setSortDir}
          sortDir={sortDir}
          sortField={sortField}
          setSortField={setSortField}
        />
      )}
    </div>
  );
}
