import React, { useEffect, useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import FilterPanel2024 from "../../../shared/components/panels/filter-panel/FilterPanel2024";
import { mixpanel_contants } from "../../../mixpanel";
import {
  getAccountsList,
  getAccountSportList,
  getAccountTeamList,
} from "../../../data/reducers/accounts/accounts.reducers";
import { getvendorsListForDropdown } from "../../../data/reducers/vendors/vendors.reducer";
import { getSeasonsList } from "../../../data/reducers/season/season.reducer";
import { fetchVendorsListForDropdown } from "../../../data/reducers/vendors/cached-vendors.reducer";

const FilterPanelPO = ({ filters, setFilters }) => {
  const dispatch = useDispatch();

  const { seasonList } = useSelector((state) => state.season);
  const { vendorsListForDropdown } = useSelector(
    (state) => state.cachedVendors
  );

  const { sportList, teamList, accountsList } = useSelector(
    (state) => state.accounts
  );

  useEffect(() => {
    if (!seasonList.length) {
      dispatch(getSeasonsList());
    }
  }, [seasonList]);

  useEffect(() => {
    if (!accountsList.length) {
      dispatch(getAccountsList());
    }
  }, [accountsList]);

  useEffect(() => {
    if (!vendorsListForDropdown.length) {
      dispatch(fetchVendorsListForDropdown());
    }
  }, [vendorsListForDropdown]);

  useEffect(() => {
    if (!sportList.length) {
      dispatch(getAccountSportList());
    }
  }, [sportList]);

  useEffect(() => {
    if (!teamList.length) {
      dispatch(getAccountTeamList());
    }
  }, [teamList]);

  const customStyles = {
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };

  const defaultValues = useMemo(() => {
    return Object.entries(filters).map(([key, value]) => ({ key, value }));
  }, [filters]);

  return (
    <FilterPanel2024
      defaultValues={defaultValues}
      filtersCount={
        Object.entries(filters).filter(
          ([key, value]) =>
            value !== "" &&
            key !== "isReviewed" &&
            key !== "StartDate" &&
            key !== "EndDate" &&
            key !== "searchString" &&
            key !== "PageNumber" &&
            key !== "PageSize" &&
            key !== "SortDir" &&
            key !== "SortField"
        ).length
      }
      searchPlaceholder="Search for Purchase orders, Skybox Ref, Total Scheduled ..."
      dashboard={mixpanel_contants.PURCHASEORDERS}
      onReset={() => {
        setFilters({
          ...filters,
          searchString: "",
          PageNumber: 1,
          VendorId: "",
          Description: "",
          SeasonId: "",
          AccountId: "",
          PurchaseOrderId: "",
          SkyBoxPurchaseId: "",
          SportId: "",
          TeamId: "",
          month: "",
        });
      }}
      onSearch={(data, filterPanelVisible) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          PageNumber: 1,
          VendorId: filterPanelVisible ? data.VendorId : "",
          Description: filterPanelVisible ? data.Description : "",
          SeasonId: filterPanelVisible ? data.SeasonId : "",
          AccountId: filterPanelVisible ? data.AccountId : "",
          PurchaseOrderId: filterPanelVisible ? data.PurchaseOrderId : "",
          SkyBoxPurchaseId: filterPanelVisible ? data.SkyBoxPurchaseId : "",
          SportId: filterPanelVisible ? data.SportId : "",
          TeamId: filterPanelVisible ? data.TeamId : "",
          searchString: !filterPanelVisible ? data.searchString : "",
        }));
      }}
      renderAdvancedPanel={(register, handleSubmit, errors, control) => (
        <Row>
          <Row>
            <Col>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  Vendor
                </Form.Label>
                <Col sm="7">
                  <Controller
                    name="VendorId"
                    control={control}
                    render={({ field }) => {
                      const value = vendorsListForDropdown.find(
                        (v) => v.id == field.value
                      );
                      return (
                        <Select
                          value={value === undefined ? null : value}
                          onChange={(e) => field.onChange(e?.id ?? null)}
                          styles={customStyles}
                          options={vendorsListForDropdown}
                          getOptionLabel={(option) =>
                            `${option.id}  ${option.name}`
                          }
                          isClearable
                        />
                      );
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row}>
                <Form.Label column sm="5">
                  Account
                </Form.Label>
                <Col sm="7">
                  <Controller
                    name="AccountId"
                    control={control}
                    render={({ field }) => {
                      const value = accountsList.find(
                        (v) => v.accountId == field.value
                      );
                      return (
                        <div>
                          <Select
                            required
                            value={value === undefined ? null : value}
                            isInvalid={!!errors.AccountId}
                            onChange={(e) => field.onChange(e?.accountId)}
                            styles={customStyles}
                            options={accountsList}
                            getOptionLabel={(option) =>
                              `${option.accountId}  ${option.name}`
                            }
                            isClearable
                          />
                        </div>
                      );
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Sport
                </Form.Label>
                <Col sm="8">
                  <Controller
                    name="SportId"
                    control={control}
                    render={({ field }) => {
                      const value = sportList.find((v) => v.id == field.value);
                      return (
                        <Select
                          value={value === undefined ? null : value}
                          onChange={(e) => field.onChange(e?.id ?? null)}
                          styles={customStyles}
                          options={sportList}
                          getOptionLabel={(option) => `${option.sportName}`}
                          isClearable
                        />
                      );
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="pt-3">
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  Description
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    {...register("Description")}
                    placeholder="Description"
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col className="pt-3">
              <Form.Group as={Row}>
                <Form.Label column sm="5">
                  Purchase Order
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    {...register("PurchaseOrderId")}
                    placeholder="Purchase Order"
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row} className="pt-3">
                <Form.Label column sm="3">
                  Team
                </Form.Label>
                <Col sm="8">
                  <Controller
                    name="TeamId"
                    control={control}
                    render={({ field }) => {
                      const value = teamList.find((v) => v.id == field.value);
                      return (
                        <Select
                          value={value === undefined ? null : value}
                          onChange={(e) => {
                            field.onChange(e?.id ?? null);
                          }}
                          styles={customStyles}
                          options={teamList}
                          getOptionLabel={(option) => `${option.teamName}`}
                          isClearable
                        />
                      );
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="col-4">
              <Form.Group as={Row} className="pt-3">
                <Form.Label column sm="4">
                  Season
                </Form.Label>
                <Col sm="7">
                  <Controller
                    name="SeasonId"
                    control={control}
                    render={({ field }) => {
                      const value = seasonList.find((v) => v.id == field.value);
                      return (
                        <Select
                          value={value === undefined ? null : value}
                          onChange={(e) => {
                            field.onChange(e?.id ?? null);
                          }}
                          styles={customStyles}
                          options={seasonList}
                          getOptionLabel={(option) => `${option.seasonName}`}
                          isClearable
                        />
                      );
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col className="pt-3 col-4">
              <Form.Group as={Row}>
                <Form.Label column sm="5">
                  Skybox Ref
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    type="number"
                    {...register("SkyBoxPurchaseId")}
                    placeholder="Skybox Ref"
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </Row>
      )}
    />
  );
};

export default FilterPanelPO;
