import { buildUrlWithParams } from "../api";
import {
  api,
  getAuthHeaders,
  get,
  getCSV,
  post,
  update,
  getPDF,
  del,
} from "../services.common";

const getVendorsCategory = async () => {
  const url = `${api}LookupVendor/getall`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const updateVendors = async (data) => {
  const url = `${api}Vendors/update?id=${data?.id}`;
  const authHeader = getAuthHeaders();
  return await update(url, data, { ...authHeader });
};

const getCardsByVendorId = async (data) => {
  const url = `${api}AccountsCards/getbyvendorid/${data}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getAllVendorsList = async (data) => {
  const url = `${api}Vendors/getlist`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getAllVendors = async (data) => {
  const sortDir = data.sortDir == false ? "Descending" : "Ascending";
  const url = `${api}Vendors/getall?PageNumber=${data.pageNumber}&PageSize=${data.pageSize
    }&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getVendorsCards = async () => {
  const url = `${api}Vendors/cards`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getVendorById = async (id) => {
  const url = `${api}Vendors/get/${id}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const exportCSVVendors = async () => {
  const url = `${api}Vendors/exportcsv`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const exportSelectedAsCSVVendors = async (data) => {
  const authHeader = getAuthHeaders();
  return await getCSV(
    buildUrlWithParams(`${api}Vendors/searchexportcsv`, data),
    { ...authHeader }
  );
};

const exportPdfVendors = async () => {
  const url = `${api}Vendors/exportpdf`;
  const authHeader = getAuthHeaders();
  return await getPDF(url, { ...authHeader }, "Vendors.pdf");
};

const exportSelectedAsPdfVendors = async (data) => {
  const authHeader = getAuthHeaders();
  return await getPDF(
    buildUrlWithParams(`${api}Vendors/searchexportpdf`, data),
    { ...authHeader },
    "VendorsFilter.pdf"
  );

};

const searchVendor = async (data) => {
  const sortDir = data.sortDir == false ? "Descending" : "Ascending";
  const url = `${api}Vendors/search?searchString=${data.searchText || ""}&Id=${data.vendorId || ""
    }&SortDir=${sortDir}&SortField=${data.sortField || ""}&CategoryId=${data?.type || 0
    }&PageNumber=${data.pageNumber}&PageSize=${data.pageSize}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const exportCSVVendor = async () => {
  const url = `${api}Vendors/exportcsv`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getVendorIdDetails = async (VendorId) => {
  const url = `${api}Vendors/getVendoriddetails/${VendorId}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getSyncSkybox = async () => {
  const url = `${api}SyncData/syncvendorsdata`;
  const authHeader = getAuthHeaders();
  return await post(url, null, { ...authHeader });
};

const getVendorCategorization = async () => {
  const url = `${api}vendors/getcategorizationlist`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getCards = async (data) => {
  const url = `${api}vendors/getcards?searchString=${data.searchText ?? ""
    }&Id=${data.vendorId || ""}&CategoryId=${data?.type || 0}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

// upload vendor data
const uploadCsvFiles = async (data) => {
  const url = `${api}Vendors/uploadcsv`;
  const authHeader = getAuthHeaders();
  return await post(url, data, { ...authHeader });
};

// download template vendor
const downloadTemplateVendor = async () => {
  const url = `${api}Vendors/csvtemplate`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

// add custom vendor
const addVendor = async (data) => {
  const url = `${api}Vendors/create`;
  const authHeader = getAuthHeaders();
  return await post(url, data, { ...authHeader });
};

//delete Vendor
const deleteVendor = async (data) => {
  const url = `${api}Vendors/delete?vendorId=${data}`;
  const authHeader = getAuthHeaders();
  return await del(url, { ...authHeader });
};

export const vendorServices = {
  exportCSVVendor,
  getAllVendorsList,
  searchVendor,
  getVendorById,
  getVendorsCards,
  getVendorIdDetails,
  getAllVendors,
  exportCSVVendors,
  exportSelectedAsCSVVendors,
  getSyncSkybox,
  getCardsByVendorId,
  updateVendors,
  getVendorsCategory,
  exportPdfVendors,
  exportSelectedAsPdfVendors,
  getVendorCategorization,
  getCards,
  uploadCsvFiles,
  downloadTemplateVendor,
  addVendor,
  deleteVendor,
};
