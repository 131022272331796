import { Route, Switch } from "react-router-dom";
import PaymentDashboard from "../../../pages/paymentCalendar/PaymentDashboard";

const EmailRoutes = () => {
	return (
		<Switch>
			<Route path="/payment-calendar" exact>
				<PaymentDashboard />
			</Route>
		</Switch>
	);
};

export default EmailRoutes;
