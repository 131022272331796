import { Route, Switch } from "react-router-dom";
import SportsDashboard from "../../../pages/settingstab/sports-teams/SportsTeamsDashboard";


const AddressBookRoutes = () => {
    return (
        <Switch>
            <Route path="/settings/sports-and-teams" exact>
                <SportsDashboard />
            </Route>

        </Switch>
    );
};

export default AddressBookRoutes;