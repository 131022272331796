import { Route, Switch } from "react-router-dom";
import SyncDashboard from "../../../pages/settingstab/sync/SyncDashboard";


const syncRoutes = () => {
    return (
        <Switch>
            <Route path="/settings/sync" exact>
                <SyncDashboard />
            </Route>

        </Switch>
    );
};

export default syncRoutes;