import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';
import { PSLServices } from '../../services/psl/psl.service';

export const getAllPSL = createAsyncThunk(
	'PSL/getAllPSL',
	async (payload, thunkAPI) => {
		const response = await PSLServices.getAllPSL(payload);
		if (response.isSuccessful === true) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const deletePSL = createAsyncThunk(
	'PSL/DeletePSL',
	async (payload, thunkAPI) => {
		const response = await PSLServices.deletePSL(payload);
		if (response.isSuccessful === true) {
			return response.data;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const filterPSL = createAsyncThunk(
	'psl/filterPSL',
	async (payload, thunkAPI) => {
		const response = await PSLServices.filterPSL(payload);
		if (response.isSuccessful === true) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const createPSL = createAsyncThunk(
	'PSL/CreatePSL',
	async (payload, thunkAPI) => {
		const response = await PSLServices.createPSL(payload);
		if (response.isSuccessful === true) {
			return response.data;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const updatePSL = createAsyncThunk(
	'PSL/UpdatePSL',
	async (payload, thunkAPI) => {
		const response = await PSLServices.updatePSL(payload);
		if (response.isSuccessful === true) {
			return response.data;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const exportCSVPSL = createAsyncThunk(
	'PSL/exportcsv',
	async (thunkAPI) => {
		const response = await PSLServices.exportCSVPSL();
		if (response.isSuccessful === true) {
			await fileDownload(response.data?.data, 'PSL.csv')
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const exportSelectedAsCSVPSL = createAsyncThunk(
	"psl/exportCSVPSL",
	async (payload, thunkAPI) => {
		const response = await PSLServices.exportSelectedAsCSVPSL(payload);
		if (response.isSuccessful === true) {
			fileDownload(response.data?.data, "PSLFilter.csv")
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const exportPdfPSL = createAsyncThunk(
	'PSL/exportpdf',
	async (thunkAPI) => {
		const response = await PSLServices.exportPdfPSL();
		if (response.isSuccessful === true) {
			await fileDownload(response.data, 'PSL.pdf')
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const exportSelectedAsPdfPSL = createAsyncThunk(
	"psl/exportPdfPSL",
	async (payload, thunkAPI) => {
		const response = await PSLServices.exportSelectedAsPdfPSL(payload);
	// 	if (response.isSuccessful === true) {
	// 		fileDownload(response.data, "PSLFilter.pdf")
	// 	} else {
	// 		thunkAPI.rejectWithValue(response.message);
	// 		throw new Error(response.message);
	// 	}
	}
);

export const headerInfo = createAsyncThunk(
	'PSL/getheaderInfo',
	async (thunkAPI) => {
		const response = await PSLServices.headerInfo();
		if (response.isSuccessful === true) {
			return response.data;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

const PSLSlice = createSlice({
	name: 'psl',
	initialState: {
		psl: [],
		paging: {},
		cards: {},
	},
	extraReducers: {
		[getAllPSL.fulfilled]: (state, action) => {
			state.psl = action.payload.data;
			state.paging = action.payload.paging;
		},
		[createPSL.fulfilled]: (state, action) => {
			state.psl.pop()
			state.psl.unshift(action.payload);
		},
		[updatePSL.fulfilled]: (state, action) => {
			const index = state.psl.findIndex(
				(PSL) => PSL.id == action.payload.id
			);
			state.psl[index] = action.payload;
		},
		[deletePSL.fulfilled]: (state, action) => {
			state.psl = [
				...state.psl.filter((PSL) => PSL.id !== action.payload.id),
			];
		},
		[filterPSL.fulfilled]: (state, action) => {
			state.psl = action.payload.data;
			state.paging = action.payload.paging;
		},

		[headerInfo.fulfilled]: (state, action) => {
			state.cards = action.payload;
		}
	},
});

export default PSLSlice.reducer;

