import Logo from "../../images/celtics_logo.svg";
import VerticalChargesCard from "../../shared/components/accountActions/charges-card";
import LinkImg from "../../images/Link.svg";
import "../../shared/styles/DashboardStyles.scss";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getCardsByVendorId } from '../../data/reducers/vendors/vendors.reducer'
import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
const VendorDetails = ({ details }) => {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.vendors).cardsById;
  useEffect(async () => {
    if (details.id != undefined) {
      await dispatch(getCardsByVendorId(details?.id))
    }
  }, [details.id])

  return (
    <>
      <Container className="root pt-3 mb-3 mt-3" fluid>
        <div className="my-title h-20 d-flex justify-content-between align-items-center">
          <Link className="my-title h-20 w-100 d-flex justify-content-between align-items-center text-decoration-none" to={"/vendors/" + details.vendorId}>
            <img className="logo-svg" src={details?.url} alt="logo" />
            <p className="mylogo-title">{details?.name}</p>
          </Link>


        </div>
        <hr className="mt-2 mb-3 hr align-self-center" />
        <div className="body h-75 w-100 d-flex flex-row justify-content-between">
          <div className="my-col d-flex flex-column justify-content-evenly">
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Id</p>
              <p className="my-p h-100 w-50">{details?.id}</p>
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Address</p>
              <p className="my-p h-100 w-50">{details?.address}</p>
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Description</p>
              <p className="my-p h-100 w-50">{details?.description}</p>
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Type</p>
              <p className="my-p h-100 w-50">{details?.type}</p>
            </div>
          </div>

          <div className="my-col d-flex flex-column justify-content-between align-items-end">
            <VerticalChargesCard
              bg="rgba(24, 144, 255, 0.2)"
              fontbg="#0241A0"
              text="Past Charges"
              price={data?.pastCharges}
            />
            <VerticalChargesCard
              bg="rgba(188, 219, 1, 0.2)"
              fontbg="#849900"
              text="Future Charges"
              price={data?.futureCharges}
            />
            <VerticalChargesCard
              bg="rgba(255, 0, 0, 0.2)"
              fontbg="#990000"
              text="Inventory"
              price={data?.inventory}
            />
          </div>
        </div>

      </Container>
    </>
  );
};

export default VendorDetails;
