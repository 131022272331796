/* eslint-disable no-mixed-spaces-and-tabs */
import ModalBreadcrumb from '../../../shared/components/modals/ModalBreadcrumb';
import { Modal, Button, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { withLoader } from '../../../utils/hoc/withLoader';
import { inputDate } from '../../../utils/formatter/dateFormatter';

const AddEditLoanModal = ({
	isVisible,
	selectedRecord,
	handleClose,
	onSubmit,
}) => {

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
	} = useForm();

	const [isEdit, setIsEdit] = useState(false);
	const [isLoanAmount, setIsLoanAmount] = useState(false);
	const [isRepaidAmount, setIsRepaidAmount] = useState(false);
	const [date, setDate] = useState(inputDate());

	useEffect(() => {
		reset();
	}, [isVisible]);
	useEffect(() => {
		if (selectedRecord) {
			setIsEdit(true);
			setDate(String(selectedRecord?.date)?.substr(0, 10));

			for (const [key, value] of Object.entries(selectedRecord)) {
				setValue(key, value, {
					shouldValidate: true,
					shouldDirty: true,
				});
			}
			if (selectedRecord?.amount > 0) {
				setValue('loanAmount', selectedRecord?.amount)
				setValue('repaidAmount', '')
				setIsLoanAmount(true)
				setIsRepaidAmount(false)
			} else if (selectedRecord?.amount < 0) {
				setValue('repaidAmount', selectedRecord?.amount.toString().substr(1))
				setValue('loanAmount', '')
				setIsLoanAmount(false)
				setIsRepaidAmount(true)
			}
		} else {
			setIsEdit(false);
			setIsLoanAmount(false)
			setIsRepaidAmount(false);
			setDate(inputDate());
			reset();
		}
	}, [selectedRecord, isVisible]);

	const handleReset = () => {
		if (selectedRecord) {
			for (const [key, value] of Object.entries(selectedRecord)) {
				setValue(key, value, {
					shouldValidate: true,
					shouldDirty: true,
				});
			}
			setDate(String(selectedRecord?.date).substr(0, 10));
			if (selectedRecord?.amount > 0) {
				setValue('loanAmount', selectedRecord?.amount)
				setValue('repaidAmount', '')
				setIsLoanAmount(true)
				setIsRepaidAmount(false)
			} else if (selectedRecord?.amount < 0) {
				setValue('repaidAmount', selectedRecord?.amount.toString().substr(1))
				setValue('loanAmount', '')
				setIsLoanAmount(false)
				setIsRepaidAmount(true)
			}
		} else {
			setIsEdit(false);
			setDate('')
			setIsLoanAmount(false)
			setIsRepaidAmount(false)
			reset();
		}
	};

	return (
		<>
			<Modal
				dialogClassName='modal-xl'
				show={isVisible}
				onHide={handleClose}
			>
				<Form onSubmit={
					handleSubmit(onSubmit)
				}>
					<Modal.Header closeButton>
						<Modal.Title>LOANS</Modal.Title>
					</Modal.Header>
					<ModalBreadcrumb
						breadcrumbLinks={[
							{
								to: '/finance',
								name: 'Loans',
								active: false,
							},
							{
								name: isEdit ? 'Edit Loan' : 'Add Loan',
								active: true,
							},
						]}
					/>
					<Modal.Body>
						<div className='container-fluid p-2 w-100 h-100'>
							<Row>
								<p className='title-accounts'>
									Basic Information
								</p>
								<Col>
									<Form.Group as={Row}>
										<Form.Label column sm='4'>
											Name
										</Form.Label>
										<Col sm='7'>
											<Form.Control
												isInvalid={!!errors.name}
												placeholder='Name'
												{...register('name', {
													required: true,
												})}
											/>
											<Form.Control.Feedback type='invalid'>
												Name is required.
											</Form.Control.Feedback>
										</Col>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group as={Row}>
										<Form.Label column sm='4'>
											Date of Loan
										</Form.Label>
										<Col sm='7'>
											<Form.Control
												type="date"
												isInvalid={!!errors.date}
												{...register("date", isEdit ? { required: false } : { required: true })}
												value={date}
												onChange={(e) => {
													setDate(e.target.value);
												}}
											/>
											<Form.Control.Feedback type='invalid'>
												Date of Loan is required.
											</Form.Control.Feedback>
										</Col>
									</Form.Group>
								</Col>
							</Row>
							<hr />
							<Row>
								<p className='title-accounts'>Amount Details</p>
								<Col>
									<Form.Group as={Row}>
										<Form.Label column sm='4'>
											Loan Amount
										</Form.Label>
										<Col sm='7'>
											<InputGroup>
												<InputGroup.Prepend>
													<InputGroup.Text>$</InputGroup.Text>
												</InputGroup.Prepend>
												<Form.Control
													disabled={isRepaidAmount}
													type='number'
													min='0'
													isInvalid={!!errors.loanAmount}
													{...register('loanAmount', {
														required: !isRepaidAmount,
													})}
													onChange={(e) => {
														if (e.target.value) {
															setIsLoanAmount(true)
															setIsRepaidAmount(false)
														}
														else {
															setIsLoanAmount(false)
														}
													}}
													placeholder='Loan Amount'
												/>
												<Form.Control.Feedback type='invalid'>
													Loan Amount or Repaid Amount is required.
												</Form.Control.Feedback>
											</InputGroup>
										</Col>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group as={Row}>
										<Form.Label column sm='4'>
											Repaid Amount
										</Form.Label>
										<Col sm='7'>
											<InputGroup>
												<InputGroup.Prepend>
													<InputGroup.Text>$</InputGroup.Text>
												</InputGroup.Prepend>
												<Form.Control
													disabled={isLoanAmount}
													type='number'
													min='0'
													isInvalid={
														!!errors.repaidAmount
													}
													{...register('repaidAmount', {
														required: !isLoanAmount,
													})}
													onChange={(e) => {
														if (e.target.value) {
															setIsRepaidAmount(true)
															setIsLoanAmount(false)
														}
														else {
															setIsRepaidAmount(false)
														}
													}}
													placeholder='Repaid Amount'
												/>
												<Form.Control.Feedback type='invalid'>
													Loan Amount or Repaid Amount is required.
												</Form.Control.Feedback>
											</InputGroup>
										</Col>
									</Form.Group>
								</Col>
							</Row>
						</div>
					</Modal.Body>
					<div className='d-flex justify-content-center my-4'>
						<Button
							type='submit'
							variant='primary'
							className='ss-modal-primary-btn mx-2'>
							{isEdit ? 'Save' : 'Add'}
						</Button>
						<Button
							variant='light'
							onClick={handleReset}
							className='ss-modal-secondary-btn mx-2'>
							{isEdit ? 'Reset' : 'Clear'}
						</Button>
					</div>
				</Form>
			</Modal >
		</>
	);
};

export default withLoader(AddEditLoanModal);
